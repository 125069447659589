import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap, timeout } from 'rxjs/operators';
import { CondoContact } from '@api/model/contact/condo.contact';
import { Router } from '@angular/router';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { UtilService } from '../../services/util.service';
import { Status } from '@api/model/status';
import { ResidenceService } from '@api/service/residence.service';
import { capitalize } from '@api/util/util';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';

function getFeatures(condo: Condo) {
  return {
    DASHBOARD: {
      label: 'Dashboard',
      icon: 'fa-list-alt',
      route: '/dashboard',
      tags: ['dashboard', 'painel', 'resumo']
    },
    CLASSIFICADOS: {
      label: 'Classificados',
      icon: 'fa-shopping-cart',
      route: '/features/classificados',
      tags: ['classificados', 'anuncios', 'comprar', 'vender']
    },
    FINANCE: {
      label: 'Financeiro',
      icon: 'fa-usd',
      route: '/finance',
      tags: ['dinheiro', 'financeiro', 'transação', 'contas']
    },
    RESERVATIONS: {
      label: 'Reservas',
      icon: 'fa-calendar',
      route: '/reservations',
      tags: ['reservas', 'aprovar', 'reservar']
    },
    MAINTENANCE: {
      label: 'Manutenção',
      icon: 'fa-wrench',
      route: '/maintenance/plans',
      tags: ['manutenção', 'preventiva']
    },
    MAINTENANCE_TICKETS: {
      label: 'Chamados de manutenção',
      icon: 'fa-wrench',
      route: 'maintenance/tickets',
      tags: ['manutenção', 'chamados']
    },
    GATE: { label: 'Portaria', icon: 'fa-building-o', route: '/gate', tags: ['portaria', 'porteiro'] },
    FAQ: {
      label: 'Perguntas Frequentes',
      icon: 'fa-question-circle',
      route: '/features/faq',
      tags: ['perguntas', 'frequentes', 'faq']
    },
    LOST_AND_FOUNDS: {
      label: 'Achados e perdidos',
      icon: 'fa-search',
      route: '/features/lost-and-founds',
      tags: ['achados', 'perdidos']
    },
    REPORTS: { label: 'Relatórios', icon: 'fa-files-o', route: '/reports', tags: ['relatorios'] },
    CONDO: { label: 'Meu condomínio', icon: 'fa-hospital-o', route: '/condo/condos', tags: ['meu condominio'] },
    SETTINGS: {
      label: 'Configurações',
      icon: 'fa-cog',
      route: '/condo/settings',
      tags: ['configuracoes', 'opcoes', 'configuracao']
    },
    MY_RESIDENCES: {
      label: `Minhas ${condo?.customLabels?.residence.plural || 'unidades'}`,
      icon: 'fa-home',
      route: '/residences',
      tags: [
        'unidades',
        'minha',
        'minha unidade',
        'casa',
        'apartamento',
        condo?.customLabels?.residence.plural,
        condo?.customLabels?.residence.singular
      ]
    },
    CHANGELOG: {
      label: 'Histórico de atualizações',
      icon: 'fa-code-fork',
      route: '/changelog',
      tags: ['atualizacao', 'atualizacoes', 'historico', 'funcionalidades']
    },
    DOCUMENTS: {
      label: 'Documentos',
      icon: 'fa-file-text',
      route: '/features/documents',
      tags: ['documentos', 'arquivos', 'convencao', 'atas', 'regimento', 'interno']
    },
    ME: {
      label: 'Meus dados',
      icon: 'fa-user',
      route: '/me',
      tags: ['eu', 'usuario', 'meus dados', 'foto', 'imagem', 'perfil']
    },
    RESIDENCES: {
      label: `${capitalize(condo?.customLabels?.residence.plural) || 'Unidades'}`,
      icon: 'fa-home',
      route: '/condo/residences',
      tags: [
        'unidades',
        'residencias',
        'casas',
        'apartamentos',
        condo?.customLabels?.residence.plural,
        condo?.customLabels?.residence.singular
      ]
    },
    RESIDENTS: {
      label: `${capitalize(condo?.customLabels?.resident.plural) || 'Condôminos'}`,
      icon: 'fa-users',
      route: '/condo/residents',
      tags: [
        'condôminos',
        'moradores',
        'morador',
        'pessoas',
        'usuarios',
        condo?.customLabels?.resident.plural,
        condo?.customLabels?.resident.singular
      ]
    },
    PETS: {
      label: 'Pets',
      icon: 'fa-paw',
      route: '/condo/pets',
      tags: ['pets', 'cachorro', 'gato', 'animais', 'animal']
    },
    DEPENDENTS: {
      label: 'Dependentes',
      icon: 'fa-child',
      route: '/condo/dependents',
      tags: ['dependentes', 'pessoas', 'condôminos', 'moradores']
    },
    VEHICLES: { label: 'Veículos', icon: 'fa-car', route: '/condo/vehicles', tags: ['veiculos', 'carros', 'motos'] },
    GROUPS: {
      label: 'Grupos',
      icon: 'fa-users',
      route: '/condo/residents/groups',
      tags: ['pessoas', 'usuarios', 'moradores', 'administradores', 'grupos']
    },
    ADVICES: { label: 'Avisos', icon: 'fa-newspaper-o', route: '/feed/advice', tags: ['avisos', 'avisar'] },
    OCCURRENCES: { label: 'Ocorrências', icon: 'fa-book', route: '/feed/private', tags: ['ocorrencias'] },
    RESIDENT_VISITORS: {
      label: `Liberação de ${condo?.customLabels?.visitor?.plural || 'visitantes'}`,
      icon: 'fa-users',
      route: '/feed/gate',
      tags: ['liberar', 'visitante', 'liberacao', condo?.customLabels?.visitor?.plural, condo?.customLabels?.visitor?.singular]
    },
    RESIDENT_DELIVERIES: {
      label: 'Encomendas',
      icon: 'fa-archive',
      route: '/feed/gate',
      tags: ['encomendas', 'correios', 'pacotes', 'entregas']
    },
    VOTING: { label: 'Enquetes', icon: 'fa-check-square-o', route: '/feed/voting', tags: ['enquetes', 'perguntas'] },
    GATE_DELIVERIES: {
      label: 'Encomendas',
      iconify: 'fa6-solid:box-archive',
      route: '/gate/deliveries',
      tags: ['encomendas', 'correio', 'pacotes', 'entregas']
    },
    GATE_KEYS: { label: 'Chaves', iconify: 'fa6-solid:key', route: '/gate/keys', tags: ['chaves', 'chaveiro'] },
    GATE_PERMISSIONS: {
      label: `${capitalize(condo?.customLabels?.visitor?.plural) || 'Visitantes'} autorizados`,
      iconify: 'fa6-solid:address-card',
      route: '/gate/permissions',
      tags: [
        'visitantes',
        'liberacoes',
        'liberacao',
        'visitantes autorizados',
        'autorizados',
        condo?.customLabels?.visitor?.plural,
        condo?.customLabels?.visitor?.singular
      ]
    },
    GATE_ACCESSES: {
      label: 'Controle de acesso',
      iconify: 'fa6-solid:arrow-right-arrow-left',
      route: '/gate/access',
      tags: ['acessos', 'controle de acesso', 'controle']
    },
    GATE_VISITORS: {
      label: `${capitalize(condo?.customLabels?.visitor?.singular) || 'Visitante'}`,
      iconify: 'fa6-solid:users',
      route: '/gate/visitants',
      tags: ['cadastro de visitantes', 'visitantes', condo?.customLabels?.visitor?.plural, condo?.customLabels?.visitor?.singular]
    },
    GATE_NOTEBOOK: {
      label: 'Livro da portaria',
      iconify: 'fa6-solid:book',
      route: '/gate/notebook',
      tags: ['livro', 'portaria', 'passagem']
    },
    GATE_EXTERNAL_PERSON_INFO_HISTORY: {
      label: `Histórico de consultas de CPF`,
      iconify: 'fa-solid:history',
      route: '/gate/external-person-info',
      tags: ['consulta', 'externa', 'histórico', 'cpf']
    },
    CAMERAS: { label: 'Câmeras', icon: 'fa-video-camera', route: '/hardware/cameras', tags: ['cameras', 'cftv'] },
    ACTIVE_VISITORS: {
      label: `${capitalize(condo?.customLabels?.visitor?.plural) || 'Visitantes'} ativos`,
      icon: 'fa-users',
      route: '/gate/active-visitors',
      tags: [
        'visitante',
        'visitantes',
        'prestadores',
        'ativo',
        'ativos',
        condo?.customLabels?.visitor?.plural,
        condo?.customLabels?.visitor?.singular
      ]
    },
    RATING: {
      label: 'Avaliações',
      icon: 'fa-star',
      route: '/features/rating',
      tags: ['avaliações', 'avaliação', 'avaliacao', 'avaliacoes']
    },
    DEVICE_REQUEST: {
      label: 'Solicitações de dispositivos',
      icon: 'fa-microchip',
      route: '/features/device-requests',
      tags: ['solicitação', 'dispositivo', 'dispositivos', 'solicitações']
    },
    DATA_SECURITY: {
      label: 'Proteção de dados',
      icon: 'fa-shield',
      route: '/more-options/data-security',
      tags: ['lgpd', 'dados', 'proteção', 'protecao', 'lei']
    },
    CONSTRUCTIONS: {
      label: 'Obras',
      icon: 'fa-exclamation-triangle',
      route: '/condo/constructions',
      tags: ['obra', 'construções', 'construcoes', 'construcao', 'construção', 'construçao', 'obras']
    },
    ASSETS: {
      label: 'Controle de ativos',
      icon: 'fa-archive',
      route: '/features/asset-management',
      tags: ['estoque', 'ativo', 'ativos', 'controle', 'inventario', 'inventário', 'patrimonio', 'patrimônio']
    },
    ADMIN_TOOLS: {
      label: 'Ferramentas administrativas',
      icon: 'fa-shield',
      route: '/features/admin-tools',
      tags: ['admin', 'ferramentas', 'compliance', 'administrativo', 'administrativas', 'exclusão', 'pessoas']
    },
    HIGHLIGHTS: {
      label: 'Destaques e informativos',
      icon: 'fa-bookmark',
      route: '/features/highlights',
      tags: ['destaque', 'destaques', 'informativo', 'informativos', 'aviso', 'avisos', 'banner']
    },
    LOGBOOKS: {
      label: 'Livros de registros',
      iconify: 'bi:book',
      route: '/features/logbooks',
      tags: ['livro', 'livros', 'registro', 'registros']
    }
  };
}

@Component({
  selector: 'app-global-search',
  templateUrl: 'global-search.html',
  styleUrls: ['./global-search.scss']
})
export class GlobalSearchComponent implements OnChanges {
  @Input()
  user: User;

  features = [];

  status: Status = new Status();

  typeAheadDataSource$: Observable<any>;
  selectedLabel = '';
  condoContacts: Array<CondoContact> = [];

  noResult = false;

  onTypeAheadSelect = evt => {
    this.selectedLabel = '';
    this.router.navigate([evt.item.route]);
  };

  constructor(
    private router: Router,
    private utilService: UtilService,
    private residenceService: ResidenceService
  ) {
    this.features.sort((a, b) => a.label.localeCompare(b.label));

    this.typeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.selectedLabel);
    }).pipe(
      distinctUntilChanged(),
      debounceTime(400),
      switchMap((token: string) => {
        token = this.utilService.removeAccents(token);
        const features = this.features.filter(f => f.tags.some(t => t.includes(token)));
        if (features.length || !(this.user.isAdmin() || this.user.isOwner() || this.user.isGatekeeper())) {
          return of(features);
        } else {
          return this.searchResidence(token);
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && changes.user.currentValue) {
      this.buildFeatures(changes.user.currentValue);
    }
  }

  buildFeatures(user: User) {
    this.features = [];
    const features = [];
    const condo: Condo = user.defaultCondo;
    const defaultFeatures = getFeatures(condo);
    if (condo) {
      const hasAccessLiberationPermission = this.user.getPermissionValue({
        condoId: condo._id,
        permission: PERMISSIONS.accessLiberation.view
      });

      const hasExternalPersonInfoHistoryPermission = this.user.getPermissionValue({
        condoId: condo._id,
        permission: PERMISSIONS.externalPersonInfo.history.view
      });

      // Dashboard
      if (user.isAdmin() || user.isOwner()) {
        features.push(defaultFeatures.DASHBOARD);
      }

      // Occurrences
      if (user.isUser() || user.isAdmin() || user.isOwner()) {
        features.push(defaultFeatures.OCCURRENCES);
      }

      // Avisos
      features.push(defaultFeatures.ADVICES);

      // Enquetes
      if ((user.isOwner() || user.isAdmin() || user.isUser()) && !condo.isVotingDisabled()) {
        features.push(defaultFeatures.VOTING);
      }

      // Reservas
      if (!condo.isReservationsDisabled() && (user.isAdmin() || user.isOwner() || user.isGatekeeper() || user.isUser())) {
        features.push(defaultFeatures.RESERVATIONS);
      }

      // Portaria
      if (user.isAdmin() || user.isOwner() || user.isGatekeeper()) {
        if (
          !(condo.isDeliveriesDisabled() && condo.isAccessLiberationDisabled() && condo.isAccessControlDisabled() && condo.isKeysDisabled())
        ) {
          features.push(defaultFeatures.GATE);
        }

        if (!condo.isDeliveriesDisabled()) {
          features.push(defaultFeatures.GATE_DELIVERIES);
        }

        if (!condo.isAccessLiberationDisabled()) {
          features.push(defaultFeatures.GATE_PERMISSIONS);
        }

        if (!condo.isAccessControlDisabled()) {
          features.push(defaultFeatures.GATE_ACCESSES);
          features.push(defaultFeatures.GATE_VISITORS);
          features.push(defaultFeatures.CAMERAS);
          features.push(defaultFeatures.ACTIVE_VISITORS);
        }

        if (!condo.isKeysDisabled()) {
          features.push(defaultFeatures.GATE_KEYS);
        }

        if (!condo.isGateNotebookDisabled()) {
          features.push(defaultFeatures.GATE_NOTEBOOK);
        }

        const hasExternalPersonIntegration = condo.externalPersonInfoData.enabled;
        if (hasExternalPersonInfoHistoryPermission && hasExternalPersonIntegration) {
          features.push(defaultFeatures.GATE_EXTERNAL_PERSON_INFO_HISTORY);
        }
      }

      // Encomendas do condômino
      if (user.isUser() && !condo.isDeliveriesDisabled()) {
        features.push(defaultFeatures.RESIDENT_DELIVERIES);
      }

      // Liberação de acessos do condômino
      if (user.isUser() && !condo.isAccessLiberationDisabled() && hasAccessLiberationPermission) {
        features.push(defaultFeatures.RESIDENT_VISITORS);
      }

      // Unidades, condôminos, pets, dependentes e veículos do condomínio
      if (user.isOwner() || user.isAdmin() || user.isGatekeeper()) {
        features.push(defaultFeatures.RESIDENCES);
        features.push(defaultFeatures.RESIDENTS);
        features.push(defaultFeatures.VEHICLES);
        features.push(defaultFeatures.GROUPS);

        if (!condo.isDependentsDisabled()) {
          features.push(defaultFeatures.DEPENDENTS);
        }

        if (!condo.isPetsDisabled()) {
          features.push(defaultFeatures.PETS);
        }
      }

      // Documentos
      if ((user.isUser() || user.isAdmin() || user.isOwner()) && !condo.isDocumentsDisabled()) {
        features.push(defaultFeatures.DOCUMENTS);
      }

      const hasLogbooksPermission = this.user.getPermissionValue({
        condoId: condo._id,
        permission: PERMISSIONS.logbooks.view
      });
      // Livros de registros
      if (hasLogbooksPermission && condo.isLogbooksEnabled()) {
        features.push(defaultFeatures.LOGBOOKS);
      }

      // Meus dados
      features.push(defaultFeatures.ME);

      // Minhas residências
      if (user.isUser() || user.isAdmin() || user.isOwner()) {
        features.push(defaultFeatures.MY_RESIDENCES);
      }

      // Meu Condomínio
      features.push(defaultFeatures.CONDO);

      // Configurações do condomínio
      if (user.isOwner() || user.isAdmin()) {
        features.push(defaultFeatures.SETTINGS);
      }

      // Classificados
      if ((user.isUser() || user.isAdmin() || user.isOwner()) && !condo.isClassificadosDisabled()) {
        features.push(defaultFeatures.CLASSIFICADOS);
      }

      // Financeiro
      if (((user.isUser() && condo.anyoneViewFinance()) || user.isAdmin() || user.isOwner()) && !condo.isFinanceDisabled) {
        features.push(defaultFeatures.FINANCE);
      }

      // Manutenção
      if (
        user.getPermissionValue({
          condoId: condo._id,
          permission: PERMISSIONS.maintenance.view
        }) &&
        !condo.isMaintenanceDisabled()
      ) {
        features.push(defaultFeatures.MAINTENANCE);
      }

      // Chamados de manutenção
      if (
        !condo.isMaintenanceTicketsDisabled() &&
        user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.maintenanceTickets.view })
      ) {
        features.push(defaultFeatures.MAINTENANCE_TICKETS);
      }

      const hasLostAndFoundViewPermission = user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.lostAndFounds.view });

      // Achados e perdidos
      if (!condo.isLostAndFoundsDisabled() && hasLostAndFoundViewPermission) {
        features.push(defaultFeatures.LOST_AND_FOUNDS);
      }

      // Relatórios
      if (user.isOwner() || user.isAdmin()) {
        features.push(defaultFeatures.REPORTS);
      }

      // Changelog
      features.push(defaultFeatures.CHANGELOG);

      const hasFaqViewPermission = user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.faq.view });

      // FAQ
      if (!condo.isCondoFaqDisabled() && hasFaqViewPermission) {
        features.push(defaultFeatures.FAQ);
      }

      // RATING
      if (!condo.isCondoRatingDisabled()) {
        features.push(defaultFeatures.RATING);
      }

      // DEVICE REQUESTS
      if (user.isGatekeeper() || user.isAdmin() || user.isOwner()) {
        features.push(defaultFeatures.DEVICE_REQUEST);
      }

      // DATA SECURITY
      features.push(defaultFeatures.DATA_SECURITY);

      // OBRAS
      if (!condo.isConstructionsDisabled() && (user.isGatekeeper() || user.isAdmin() || user.isOwner())) {
        features.push(defaultFeatures.CONSTRUCTIONS);
      }

      // Control de ativos
      if (condo.isAssetManagementEnabled() && hasAccessLiberationPermission && (user.isGatekeeper() || user.isAdmin() || user.isOwner())) {
        features.push(defaultFeatures.ASSETS);
      }

      // Ferramentas administrativas
      features.push(defaultFeatures.ADMIN_TOOLS);

      features.sort((a, b) => a.label.localeCompare(b.label));

      this.features = features;
    }
  }

  getItemLabel(item) {
    if (item) {
      return item.label;
    } else {
      return '';
    }
  }

  typeaheadNoResults(evt: boolean) {
    this.noResult = evt;
  }

  searchResidence(token) {
    this.status.setAsDownloading();
    return this.residenceService.searchByToken(this.user.defaultCondo._id, token).pipe(
      timeout(10000),
      map(({ residences }) =>
        residences.map(res => ({
          icon: 'fa-home',
          label: res.identification,
          route: `/condo/residences/${res._id}`
        }))
      ),
      catchError(() => of([])),
      tap(() => this.status.setAsSuccess())
    );
  }
}
