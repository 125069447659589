export const environment = {
  production: false,
  name: 'MERGE_REQUEST',
  backendUrl: 'https://api-mr.econdos.com.br/api/v1/',
  backendUrlV2: 'https://api-mr.econdos.com.br/api/v2/',
  backendUrlV3: 'https://api-mr.econdos.com.br/api/v3/',
  reportsUrl: 'https://reports-mr.econdos.com.br/api/v1/',
  reportsUrlV2: 'https://reports-mr.econdos.com.br/api/v2/',
  visitorUrl: 'https://dev-visitantes.econdos.com.br',
  socketUrl: 'wss://ws-mr.econdos.com.br',
  blindadoUrl: 'https://condominioblindado.azurewebsites.net/'
};

export const mr1Environment = {
  production: false,
  name: 'MERGE_REQUEST_1',
  backendUrl: 'https://api-mr1.econdos.com.br/api/v1/',
  backendUrlV2: 'https://api-mr1.econdos.com.br/api/v2/',
  backendUrlV3: 'https://api-mr1.econdos.com.br/api/v3/',
  reportsUrl: 'https://reports-mr1.econdos.com.br/api/v1/',
  reportsUrlV2: 'https://reports-mr1.econdos.com.br/api/v2/',
  visitorUrl: 'https://dev-visitantes.econdos.com.br',
  socketUrl: 'wss://ws-mr1.econdos.com.br',
  blindadoUrl: 'https://condominioblindado.azurewebsites.net/'
};

export const mr2Environment = {
  production: false,
  name: 'MERGE_REQUEST_2',
  backendUrl: 'https://api-mr2.econdos.com.br/api/v1/',
  backendUrlV2: 'https://api-mr2.econdos.com.br/api/v2/',
  backendUrlV3: 'https://api-mr2.econdos.com.br/api/v3/',
  reportsUrl: 'https://reports-mr2.econdos.com.br/api/v1/',
  reportsUrlV2: 'https://reports-mr2.econdos.com.br/api/v2/',
  visitorUrl: 'https://dev-visitantes.econdos.com.br',
  socketUrl: 'wss://ws-mr2.econdos.com.br',
  blindadoUrl: 'https://condominioblindado.azurewebsites.net/'
};

export const mr3Environment = {
  production: false,
  name: 'MERGE_REQUEST_1',
  backendUrl: 'https://api-mr3.econdos.com.br/api/v1/',
  backendUrlV2: 'https://api-mr3.econdos.com.br/api/v2/',
  backendUrlV3: 'https://api-mr3.econdos.com.br/api/v3/',
  reportsUrl: 'https://reports-mr3.econdos.com.br/api/v1/',
  reportsUrlV2: 'https://reports-mr3.econdos.com.br/api/v2/',
  visitorUrl: 'https://dev-visitantes.econdos.com.br',
  socketUrl: 'wss://ws-mr3.econdos.com.br',
  blindadoUrl: 'https://condominioblindado.azurewebsites.net/'
};
