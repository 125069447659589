import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import swal from 'sweetalert2';
import { User } from '@api/model/user';
import { ModalNewCondoContactComponent } from '../access.control/new.condo.contact/new.condo.contact.modal';
import { CondoContact } from '@api/model/contact/condo.contact';
import { Contact } from '@api/model/interface/contact';
import { debounceTime, takeUntil, tap, timeout, timeoutWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { EcondosQuery } from '@api/model/query';
import { Observable, Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalImportIntelbrasIncontrolVisitorsComponent } from './modal-import-intelbras-incontrol-visitors/modal-import-intelbras-incontrol-visitors.component';
import { ModalFilterComponent } from 'app/components/modal-filter/modal-filter.component';
import { EcondosFilter } from '@api/model/filter';
import { CondoContactServiceV3 } from '@api/serviceV3/condo.contact.service';
import { ContactID } from '@api/model/contact/contact.id';
import { Router } from '@angular/router';
import { TableColumnDefinition, TableComponent, TableStatus } from 'app/components/table/table.component';
import { FormControl } from '@angular/forms';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { Condo } from '@api/model/condo';
import { ModalCondoContactDetailsComponent } from './modal-condo-contact-details/modal-condo-contact-details';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import { Occurrence } from '@api/model/interface/occurrence';
import { OccurrenceServiceV2 } from '@api/serviceV2/occurrence.service';
import { capitalize } from '@api/util/util';

@Component({
  selector: 'app-condo-visitors',
  templateUrl: 'condo.visitors.html',
  styleUrls: ['condo.visitors.scss']
})
export class CondoVisitorsComponent implements OnInit, OnDestroy {
  @ViewChild('visitorsRegisteredTable', { static: true }) visitorsRegisteredTable: TableComponent;
  @ViewChild('visitorsNameCellTemplate', { static: true }) visitorsNameCellTemplate: TemplateRef<any>;
  @ViewChild('visitorsDocumentCellTemplate', { static: true }) visitorsDocumentCellTemplate: TemplateRef<any>;
  @ViewChild('visitorsVehicleCellTemplate', { static: true }) visitorsVehicleCellTemplate: TemplateRef<any>;
  @ViewChild('visitorsCompanyCellTemplate', { static: true }) visitorsCompanyCellTemplate: TemplateRef<any>;
  @ViewChild('visitorsServiceCellTemplate', { static: true }) visitorsServiceCellTemplate: TemplateRef<any>;
  @ViewChild('visitorsTypeCellTemplate', { static: true }) visitorsTypeCellTemplate: TemplateRef<any>;
  @ViewChild('visitorsPhoneCellTemplate', { static: true }) visitorsPhoneCellTemplate: TemplateRef<any>;

  @Input() user: User;

  condo: Condo;
  visitors: CondoContact[] = [];
  totalVisitorsCount = 0;

  isAdminOrOwner = false;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<CondoContact>[] = [];

  today = new Date();

  private unsubscribe: Subject<any> = new Subject();

  filters: EcondosFilter[] = [];

  initialQuery: EcondosQuery = {
    $select:
      'firstName lastName isForeigner ids phones company service type createdAt updatedAt banned banReason birthDate obs specialNeedsDetails specialNeeds',
    $populate: [
      { path: 'picture', select: 'url thumbnail type name format' },
      { path: 'ids.pictures', select: 'url thumbnail type name format' },
      {
        path: 'condoVehicles',
        select: 'plate model chassis type brand color pictures user',
        populate: [
          { path: 'pictures', select: 'url thumbnail type name format' },
          { path: 'user', select: 'firstName lastName' }
        ]
      },
      { path: 'bannedBy', select: 'firstName lastName' }
    ],
    type: { $ne: Contact.TYPES.SUPPLIER },
    deleted: 'false',
    $and: [],
    $or: []
  };

  customQuery = { ...this.initialQuery };

  // Just to control pagination on template
  p = 1;
  itemsPerPage = 15;
  totalData = 0;

  currentSortData: { field: string; order: 'asc' | 'desc' | '' } = { field: 'name', order: 'asc' };

  numberOfActiveFilters = 0;
  private localUser: User;

  public searchToken = new FormControl('');

  constructor(
    private condoContactService: CondoContactServiceV3,
    private utilService: UtilService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private router: Router,
    private occurrenceService: OccurrenceServiceV2
  ) {
    this.condo = this.utilService.getLocalCondo();
    this.localUser = this.utilService.getLocalUser();

    this.isAdminOrOwner = this.localUser.isAdminOnCondo(this.condo._id) || this.localUser.isOwnerOnCondo(this.condo._id);

    const typeOptions = Object.keys(Contact.TYPES_LABEL).map(type => {
      return {
        label: Contact.TYPES_LABEL[type],
        value: type
      };
    });

    this.filters = [
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'phones',
        icon: 'phone',
        label: 'Telefone',
        placeholder: 'Busque pelo telefone',
        searchType: 'regex'
      },
      {
        element: 'vehicles-picker',
        elementSize: 'medium',
        name: 'condoVehicles',
        label: 'Veículo',
        params: {
          placeholder: 'Insira a placa ou chassi do veículo para buscá-lo',
          condo: this.condo,
          filterCriteria: 'VISITORS_ONLY',
          showIcon: true
        },
        searchType: 'match'
      },
      {
        element: 'select',
        elementSize: 'medium',
        name: 'type',
        selectOptions: [{ label: 'Todos', value: '' }, ...typeOptions],
        label: 'Tipo',
        searchType: 'match'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'company',
        label: 'Empresa',
        placeholder: 'Busque pela empresa',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'text',
        name: 'service',
        label: 'Serviço',
        placeholder: 'Busque pelo serviço',
        searchType: 'regex'
      },
      {
        element: 'select',
        elementSize: 'medium',
        name: 'banned',
        icon: 'ban',
        selectOptions: [
          { value: '', label: 'Todos' },
          { value: 'true', label: 'Banidos' },
          { value: 'false', label: 'Não Banidos' }
        ],
        label: 'Status',
        searchType: 'match'
      }
    ];
  }

  ngOnInit() {
    this.tableColumns = [
      { columnId: 'firstName', headerLabel: 'Nome', valueTemplate: this.visitorsNameCellTemplate, sortable: true },
      { columnId: 'ids', headerLabel: 'Documentos', valueTemplate: this.visitorsDocumentCellTemplate },
      { columnId: 'condoVehicles', headerLabel: 'Veículos', valueTemplate: this.visitorsVehicleCellTemplate },
      { columnId: 'company', headerLabel: 'Empresa', valueTemplate: this.visitorsCompanyCellTemplate, sortable: true },
      { columnId: 'service', headerLabel: 'Serviço', valueTemplate: this.visitorsServiceCellTemplate, sortable: true },
      { columnId: 'type', headerLabel: 'Tipo', valueTemplate: this.visitorsTypeCellTemplate, sortable: true },
      { columnId: 'phone', headerLabel: 'Telefone', valueTemplate: this.visitorsPhoneCellTemplate, sortable: true },
      {
        columnId: 'actions',
        headerLabel: 'Ações',
        type: 'actions',
        actionsButtons: [
          {
            icon: 'fa-eye',
            title: `Informações do ${this.condo.customLabels?.visitor?.singular || 'visitante'}`,
            handler: condoContact => this.infoContact(condoContact)
          },
          {
            icon: 'fa-ban',
            title: `Banir ${this.condo.customLabels?.visitor?.singular || 'visitante'}`,
            buttonClass: 'btn-subtle-danger',
            show: condoContact => !condoContact?.banned,
            handler: condoContact => this.banCondoContact(condoContact)
          },
          {
            icon: 'fa-ban',
            title: `Desbanir ${this.condo.customLabels?.visitor?.singular || 'visitante'}`,
            buttonClass: 'btn-subtle-success',
            show: condoContact => condoContact?.banned,
            handler: condoContact => this.unbanCondoContact(condoContact)
          },
          {
            icon: 'fa-pencil',
            title: `Editar ${this.condo.customLabels?.visitor?.singular || 'visitante'}`,
            handler: condoContact => this.editContact(condoContact)
          },
          {
            icon: 'fa-trash',
            title: `Excluir ${this.condo.customLabels?.visitor?.singular || 'visitante'}`,
            handler: condoContact => this.askToDeleteContact(condoContact)
          }
        ]
      }
    ];

    this.searchToken.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe(token => {
      this.clearFilters({ shouldReload: false });
      this.getData({ token });
    });

    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.unsubscribe();
  }

  getData({ page = 0, token = this.searchToken.value } = {}) {
    const query = this.customQuery;

    const { pageSize, sortedColumn, sortOrder } = this.visitorsRegisteredTable.getCurrentState();

    query.$page = page;
    query.$limit = pageSize;
    query.$count = true;

    if (typeof sortedColumn === 'string') {
      query.$sort = `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}`;
    }

    let requestObservable: Observable<{ count: number; contacts: CondoContact[] }>;

    if (token) {
      requestObservable = this.condoContactService.searchByToken(this.condo._id, token, query);
    } else {
      requestObservable = this.condoContactService.getContacts(this.condo._id, query);
    }

    this.status = 'LOADING';

    requestObservable.pipe(timeoutWith(20_000, ErrorBuilder.throwTimeoutError())).subscribe({
      next: response => {
        this.totalVisitorsCount = response.count;
        this.visitors = response.contacts;
        this.status = 'SUCCESS';
      },
      error: () => {
        this.status = 'ERROR';
      }
    });
  }

  askToDeleteContact(contact: CondoContact) {
    const query: EcondosQuery = {
      $select: 'status startDate endDate',
      type: 'GATE',
      subType: GateOccurrence.ALLOW_ACCESS_TYPE,
      status: Occurrence.STATUS.OPENED,
      condoContacts: contact._id,
      $limit: 1
    };

    this.occurrenceService.get(this.condo._id, query).subscribe({
      next: res => {
        if (res.count > 0) {
          swal({
            type: 'warning',
            text: `Há liberações de acesso ativas vinculadas a esse ${
              this.condo.customLabels?.visitor?.singular || 'visitante'
            }, para exclui-lo é necessário, primeiro, remover a liberação de acesso ao qual ele está inserido`
          });
        } else {
          swal({
            type: 'question',
            text: `Deseja remover ${contact.firstName} ${contact.lastName} dos ${
              this.condo.customLabels?.visitor?.plural || 'visitantes'
            }?`,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: '#32DB64',
            cancelButtonColor: '#f53d3d',
            cancelButtonText: 'Não',
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.condoContactService
                .deleteCondoContact(this.condo._id, contact.id)
                .pipe(
                  timeout(10000),
                  tap(() => {
                    this.visitors = this.visitors.filter(visitor => visitor._id !== contact._id);
                    this.totalVisitorsCount--;
                  })
                )
                .toPromise()
                .catch(err => {
                  console.log(err);
                  return Promise.reject(
                    `Não foi possível excluir o ${this.condo.customLabels?.visitor?.singular || 'visitante'}, tente novamente...`
                  );
                });
            }
          }).then(
            () => this.toastr.success(`${capitalize(this.condo.customLabels?.visitor?.singular || 'visitante')} excluído com sucesso!`),
            error => console.log(error)
          );
        }
      }
    });
  }

  createContact() {
    const initialState = {
      condo: this.condo,
      user: this.user,
      callback: contact => this.onCondoContactCreated(contact)
    };
    this.modalService.show(ModalNewCondoContactComponent, { initialState, class: 'modal-lg modal-xl', ignoreBackdropClick: true });
  }

  editContact(condoContact) {
    const initialState = {
      condo: this.condo,
      user: this.user,
      condoContact,
      callback: contact => this.onCondoContactUpdated()
    };
    this.modalService.show(ModalNewCondoContactComponent, { initialState, class: 'modal-lg modal-xl', ignoreBackdropClick: true });
  }

  infoContact(condoContact) {
    const initialState = {
      condo: this.condo,
      user: this.user,
      condoContact
    };
    this.modalService.show(ModalCondoContactDetailsComponent, { initialState, class: 'modal-lg modal-xl', ignoreBackdropClick: true });
  }

  banCondoContact(visitor: CondoContact) {
    swal({
      type: 'question',
      text: `Você deseja banir o ${this.condo.customLabels?.visitor?.singular || 'visitante'} ${visitor.firstName} do ${
        this.condo?.customLabels?.condo?.singular
      } ${this.condo.name}?`,
      input: 'textarea',
      inputPlaceholder: 'Digite o motivo do banimento',
      showCancelButton: true,
      confirmButtonText: 'Banir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: reason => {
        return this.condoContactService
          .banCondoContact(this.condo._id, visitor._id, reason)
          .toPromise()
          .then(res => res)
          .catch(error => {
            swal.showValidationError(`Não foi possível banir o ${this.condo.customLabels?.visitor?.singular || 'visitante'}`);
            console.log(error);
          });
      }
    }).then(
      () => {
        this.toastr.success(`${capitalize(this.condo.customLabels?.visitor?.singular || 'visitante')} banido com sucesso!`);
        this.onCondoContactUpdated();
      },
      error => console.log(error)
    );
  }

  unbanCondoContact(visitor: CondoContact) {
    let message = '';
    if (visitor.banReason) {
      message = ` O ${this.condo.customLabels?.visitor?.singular || 'visitante'} foi banido por ${
        visitor.bannedBy.firstName
      } pelo seguinte motivo:\n
      "${visitor.banReason}"`;
    }
    swal({
      type: 'question',
      text: `Você deseja desbanir o ${this.condo.customLabels?.visitor?.singular || 'visitante'} ${visitor.firstName} do ${
        this.condo?.customLabels?.condo?.singular
      } ${this.condo.name}?\n
      ${message}`,
      inputPlaceholder: 'Digite o motivo do banimento',
      showCancelButton: true,
      confirmButtonText: 'Desbanir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.condoContactService
          .unbanCondoContact(this.condo._id, visitor._id)
          .toPromise()
          .then(res => res)
          .catch(error => {
            swal.showValidationError(`Não foi possível desbanir o ${this.condo.customLabels?.visitor?.singular || 'visitante'}`);
            console.log(error);
          });
      }
    }).then(
      () => {
        this.toastr.success(`${capitalize(this.condo.customLabels?.visitor?.singular || 'visitante')} desbanido com sucesso!`);
        this.onCondoContactUpdated();
      },
      error => console.log(error)
    );
  }

  importIncontrolContacts() {
    const initialState = {
      condo: this.condo,
      user: this.user,
      callback: contact => this.onCondoContactCreated(contact)
    };
    this.modalService.show(ModalImportIntelbrasIncontrolVisitorsComponent, {
      initialState,
      class: 'modal-lg modal-xl',
      ignoreBackdropClick: true
    });
  }

  onCondoContactCreated(condoContact) {
    if (this.visitors.find(visitor => visitor._id === condoContact._id)) {
      this.visitors = this.visitors.map(visitor => {
        if (visitor._id === condoContact._id) {
          return condoContact;
        } else {
          return visitor;
        }
      });
    } else {
      this.visitors = [condoContact, ...this.visitors];
      this.totalVisitorsCount++;
    }
  }

  onCondoContactUpdated() {
    this.getData();
  }

  revealData(field: string, visitor) {
    visitor.isDataMasked[field] = false;

    let isFieldAlreadyUnmasked: boolean;

    if (field === 'ids') {
      isFieldAlreadyUnmasked = !visitor.ids
        .map(id => id.number)
        .toString()
        .includes('*');
    } else if (field === 'birthDate') {
      isFieldAlreadyUnmasked = !visitor.birthDate.toString().includes('3000');
    } else {
      isFieldAlreadyUnmasked = !visitor[field].toString().includes('*');
    }

    if (isFieldAlreadyUnmasked) {
      return;
    }

    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    if (field === 'ids') {
      query.$populate = [{ path: 'ids.pictures', select: 'url thumbnail' }];
    }

    let callback = null;

    if (field === 'ids') {
      callback = ({ data }) => (visitor[field] = data.map(id => new ContactID(id)));
    } else {
      callback = ({ data }) => (visitor[field] = data);
    }

    this.condoContactService
      .getCondoContactUnmaskedField(this.condo._id, visitor._id, field, query)
      .pipe(timeout(10000))
      .subscribe(callback);
  }

  openFilterModal() {
    const initialState = {
      filters: this.filters,
      initialQuery: { ...this.initialQuery },
      callback: ({ query, filters }) => {
        this.searchToken.setValue(undefined, { emitEvent: false });
        this.countActiveFilters(filters);
        this.filters = filters;
        this.customQuery = query;
        this.getData();
      }
    };
    this.modalService.show(ModalFilterComponent, { initialState, class: 'modal-md' });
  }

  countActiveFilters(filters: EcondosFilter[]) {
    this.numberOfActiveFilters = filters.reduce((accumulator, currentValue) => {
      if (currentValue.value) {
        return accumulator + 1;
      }

      return accumulator;
    }, 0);
  }

  removeIndividualFilter({ index }) {
    this.filters[index].value = '';
    this.filters[index].valueLabel = '';

    delete this.customQuery[this.filters[index].name];

    if (this.filters[index].name === 'name') {
      this.customQuery.$or = [];
    }

    this.numberOfActiveFilters--;
    this.getData();
  }

  clearFilters({ shouldReload = true } = {}) {
    this.filters = this.filters.map(filter => {
      return {
        ...filter,
        value: '',
        valueLabel: ''
      };
    });
    this.numberOfActiveFilters = 0;
    this.customQuery = { ...this.initialQuery };

    if (shouldReload) {
      this.getData();
    }
  }

  navigateToMergeContacts() {
    this.router.navigate(['gate', 'merge-contacts']);
  }

  hasGateKeeperAccess() {
    return (
      this.localUser &&
      (this.localUser.isGatekeeperOnCondo(this.condo._id) ||
        this.localUser.isAdminOnCondo(this.condo._id) ||
        this.localUser.isOwnerOnCondo(this.condo._id))
    );
  }
  handleRefreshData() {
    const { currentPage } = this.visitorsRegisteredTable.getCurrentState();
    this.getData({ page: currentPage - 1 });
  }
}
