import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { Residence } from '@api/model/interface/residence';
import { Note } from '@api/model/note';
import { Status } from '@api/model/status';
import { NoteService } from '@api/service/note.service';
import { beforeDateValidator, conditionalValidator } from '@api/util/validators';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';

type NoteForm = {
  noteText: FormControl<string>;
  isPermanent: FormControl<boolean>;
  startDate: FormControl<Date>;
  endDate: FormControl<Date>;
};

@Component({
  selector: 'app-modal-add-note',
  templateUrl: './modal-add-note.component.html',
  styleUrls: ['./modal-add-note.component.scss']
})
export class ModalAddNoteComponent implements OnInit, OnDestroy {
  note: Note;
  status: Status = new Status();

  protected noteForm = new FormGroup<NoteForm>({
    noteText: new FormControl('', Validators.required),
    isPermanent: new FormControl(true, Validators.required),
    startDate: new FormControl(new Date(), Validators.required),
    endDate: new FormControl(
      null,
      conditionalValidator(() => !!this.endDate.value, beforeDateValidator('startDate'))
    )
  });

  protected noteText = this.noteForm.controls.noteText;
  protected isPermanent = this.noteForm.controls.isPermanent;
  protected startDate = this.noteForm.controls.startDate;
  protected endDate = this.noteForm.controls.endDate;

  private unsubscribe$ = new Subject<void>();

  condo: Condo;
  residence: Residence;
  minDate = new Date();

  onSave: () => void = () => {};

  constructor(
    public bsModalRef: BsModalRef,
    private noteService: NoteService,
    private toastr: ToastrService
  ) {
    this.noteForm.controls.isPermanent.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this.isPermanent.value) {
        this.startDate.setValue(new Date());
        this.endDate.setValue(null);
      }
    });
  }

  ngOnInit() {
    this.populateNoteForm();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  populateNoteForm() {
    if (this.note) {
      const currentDate = moment().startOf('day');
      const afterCurrentDate = moment(this.note.startDate).isAfter(currentDate);

      this.noteText.setValue(this.note.text);

      if (this.note.startDate) {
        this.startDate.setValue(new Date(this.note.startDate));
      }

      if (this.note.endDate) {
        this.endDate.setValue(new Date(this.note.endDate));
      }

      this.isPermanent.setValue(afterCurrentDate || !this.note.endDate);
    }
  }

  submit() {
    if (this.noteForm.valid) {
      this.status.isProcessing();
      const note: Note = {
        text: this.noteText.value,
        residence: this.residence,
        startDate: moment(this.startDate.value).startOf('day').toISOString(),
        endDate: this.endDate.value ? moment(this.endDate.value).endOf('day').toISOString() : null
      };

      if (this.note) {
        this.updateNote(note);
      } else {
        this.createNote(note);
      }
    }
  }

  createNote(note: Note) {
    this.noteService.create(this.condo._id, note).subscribe(
      (res: any) => {
        this.onSave();
        this.bsModalRef.hide();
        this.toastr.success('Nota criada com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        this.toastr.error('Não foi possível criar a nota, tente novamente');
      }
    );
  }

  updateNote(note: Note) {
    this.noteService.update(this.condo._id, this.note._id, note).subscribe(
      (res: any) => {
        this.onSave();
        this.bsModalRef.hide();
        this.toastr.success('Nota atualizada com sucesso.');
      },
      err => {
        console.log(err);
        this.status.setAsError();
        this.toastr.error('Não foi possível atualizar a nota, tente novamente');
      }
    );
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
