import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { CondoService } from '@api/service/condo.service';
import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs/operators';
import swal from 'sweetalert2';
import { UtilService } from '../../../services/util.service';

type ParamConfig = {
  icon?: string;
  label: string;
  description?: string;
  true?: any;
  false?: any;
  value?: any;
  toggleValue?: any;
  originalValue?: any;
  paramName?: string;
  isSubSectionTitle?: boolean;
};

type ParamsSection = {
  title: string;
  icon: string;
  enabled?: boolean;
};

interface Superlogica {
  enabled: FormControl<boolean>;
  superlogicaAppToken: FormControl<string>;
  superlogicaAccessToken: FormControl<string>;
  condoCode: FormControl<string>;
  integrationType: FormControl<Condo['boleto']['integrationType'] | ''>;
}

interface Blindado {
  enabled: FormControl<boolean>;
  integrationType: FormControl<string>;
  token: FormControl<string>;
  cpf: FormControl<string>;
  cpfSearchEnabled: FormControl<boolean>;
}

@Component({
  selector: 'app-condo-integrations',
  templateUrl: 'condo-integrations.component.html'
})
export class CondoIntegrationsComponent {
  public condo = this.utilService.getLocalCondo();

  public isSubmitting = false;

  public CONDO_SECTION_PARAMS: Record<string, ParamsSection> = {
    superlogica: {
      title: 'Superlógica',
      icon: 'plus-square',
      enabled: true
    },
    blindado: {
      title: 'Blindado',
      icon: 'plus-square',
      enabled: true
    }
    // ahreas: {
    //   title: 'Ahreas',
    //   icon: 'plus-square',
    //   enabled: false
    // }
  };

  superlogicaForm = new FormGroup<Superlogica>({
    enabled: new FormControl(false, [Validators.required]),
    integrationType: new FormControl('', [Validators.required]),
    condoCode: new FormControl('', [Validators.required]),
    superlogicaAppToken: new FormControl('', [Validators.required]),
    superlogicaAccessToken: new FormControl('', [Validators.required])
  });

  blindadoForm = new FormGroup<Blindado>({
    enabled: new FormControl(false, [Validators.required]),
    integrationType: new FormControl('', [Validators.required]),
    token: new FormControl('', [Validators.required]),
    cpf: new FormControl(''),
    cpfSearchEnabled: new FormControl(false)
  });

  public selectedParamsSection: keyof typeof this.CONDO_SECTION_PARAMS = 'superlogica';

  constructor(
    private condoService: CondoService,
    private utilService: UtilService,
    private toastrService: ToastrService
  ) {
    this.getSuperLogicaIntegration();
    this.getBlindadoIntegration();
  }

  setParams(integration, params) {
    switch (integration.toLowerCase()) {
      case 'superlogica':
        this.superlogicaForm.patchValue(params);
        break;
      case 'blindado':
        this.blindadoForm.patchValue(params);
        break;
      // case 'Ahreas':
      //   this.ahreasForm.patchValue(integration);
      //   break;
    }
  }

  getSuperLogicaIntegration() {
    this.condoService
      .getSuperlogicaConfig(this.condo._id)
      .pipe(timeout(10_000))
      .subscribe({
        next: (response: Superlogica) => {
          this.setParams('superlogica', response);
        },
        error: () => {
          swal({
            type: 'error',
            text: 'Não foi possível carregar as configurações de integração com Superlógica, tente novamente.'
          });
        }
      });
  }

  getBlindadoIntegration() {
    this.condoService
      .getBlindadoConfig(this.condo._id)
      .pipe(timeout(10_000))
      .subscribe({
        next: (response: Blindado) => {
          this.setParams('blindado', response);
        },
        error: () => {
          swal({
            type: 'error',
            text: 'Não foi possível carregar as configurações de integração com Blindado, tente novamente.'
          });
        }
      });
  }

  handleSelectParamsSection(sectionKey: typeof this.selectedParamsSection) {
    this.selectedParamsSection = sectionKey;
  }

  handleSuperlogicaFormSubmit() {
    this.isSubmitting = true;
    this.condoService
      .testConnectionSuperlogica(this.condo._id, this.superlogicaForm.value)
      .pipe(timeout(10_000))
      .subscribe({
        next: response => {
          this.isSubmitting = false;
          this.toastrService.success(response['message']);
        },
        error: () => {
          this.isSubmitting = false;
          swal({
            type: 'error',
            text: 'Dados incorretos de integração, verifique e tente novamente.'
          });
        }
      });
  }

  handleBlindadoFormSubmit() {
    this.isSubmitting = true;

    if (this.blindadoForm.invalid) {
      return this.toastrService.error('Preencha todos os campos.');
    }

    this.condoService
      .testConnectionBlindado(this.condo._id, this.blindadoForm.value)
      .pipe(timeout(10_000))
      .subscribe({
        next: response => {
          this.isSubmitting = false;
          this.toastrService.success(response['message']);
          this.blindadoForm.get('cpf').setValue('');
        },
        error: () => {
          this.isSubmitting = false;
          swal({
            type: 'error',
            text: 'Dados da integração incorretos, verifique e tente novamente.'
          });
        }
      });
  }

  saveSuperlogica() {
    this.isSubmitting = true;

    if (this.superlogicaForm.value.enabled) this.superlogicaForm.patchValue({ integrationType: 'SUPERLOGICA' });

    this.condoService
      .saveSuperlogicaConfig(this.condo._id, this.superlogicaForm.value)
      .pipe(timeout(10_000))
      .subscribe({
        next: () => {
          this.isSubmitting = false;
          this.toastrService.success('Integração com Superlógica realizada com sucesso.');
        },
        error: () => {
          this.isSubmitting = false;
          swal({
            type: 'error',
            text: 'Não foi possível realizar a integração com Superlógica, tente novamente'
          });
        }
      });
  }

  saveBlindado() {
    this.isSubmitting = true;

    if (this.blindadoForm.value.enabled) this.blindadoForm.patchValue({ integrationType: 'BLINDADO' });

    if (this.blindadoForm.invalid) {
      this.isSubmitting = false;
      return this.toastrService.error('Preencha todos os campos.');
    }

    this.condoService
      .saveBlindadoConfig(this.condo._id, this.blindadoForm.value)
      .pipe(timeout(10_000))
      .subscribe({
        next: () => {
          this.isSubmitting = false;
          this.toastrService.success('Integração com o Condomínio Blindado realizada com sucesso.');
        },
        error: () => {
          this.isSubmitting = false;
          swal({
            type: 'error',
            text: 'Não foi possível realizar a integração com o condomínio Blindado, tente novamente'
          });
        }
      });
  }

  defaultSortFunction(): number {
    return 0;
  }
}
