/**
 * Created by Rafael on 28/11/2016.
 */
import { LoginPageComponent } from '../pages/login/login';
import { FeedPage } from '../pages/feed/feed';
import { ReservateLocalPageComponent } from '../pages/reservation/reservate.local';
import { CreateLocalPageComponent } from '../pages/reservation/create.local';
import { UserPage } from '../pages/user/user';
import { LoggedGuard } from '../guards/logged.guard';
import { ReservationHistoryComponent } from '../pages/reservation/history/reservation.history';
import { SignupGuard } from '../guards/signup.guard';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPage } from '../pages/error/not.found';
import { HasAdminAccessGuard } from '../guards/is.admin.or.owner.guard';
import { HasGatekeeperAccessGuard } from '../guards/is.gatekeeper.guard';
import { CondoPageComponent } from '../pages/condo/condo';
import { SignUpPage } from '../pages/signup/signup';
import { ResidencesPage } from '../pages/residences/residences';
import { ResetPasswordComponent } from '../pages/reset.password/reset.password';
import { UserActivation } from '../pages/user.activation/user.activation';
import { HasNotGatekeeperAccessGuard } from '../guards/is.not.gatekeeper.guard';
import { GatePage } from '../pages/gate/gate';
import { CondoResidentsComponent } from '../pages/condo/residents/condo.residents';
import { CondoResidencesComponent } from '../pages/condo/residences/condo.residences';
import { CondoVehiclesComponent } from '../pages/condo/vehicles/condo.vehicles';
import { CondoSuppliersComponent } from '../pages/condo/suppliers/condo.suppliers';
import { CondoListComponent } from '../pages/condo/condos/condo.list';
import { CondoConfigComponent } from '../pages/condo/config/condo.config';
import { SalePage } from '../pages/sale/sale';
import {
  AdFeatureGuard,
  DocumentsFeatureGuard,
  FinanceFeatureGuard,
  GateFeatureGuard,
  ReservationFeatureGuard
} from '../guards/feature.guard';
import { CondoPlansComponent } from '../pages/condo/plans/condo.plans';
import { HasSystemAdminAccessGuard } from '../guards/is.systemAdmin.guard';
import { SubscriptionPage } from '../pages/sale/subscription/subscription';
import { AccessControlComponent } from '../pages/gate/access.control/access.control';
import { CondoVisitorsComponent } from '../pages/gate/visitors/condo.visitors';
import { CondoKeysComponent } from '../pages/gate/keys/condo-keys';
import {
  AccessControlModuleGuard,
  AssetManagementModuleGuard,
  ClassificadosModuleGuard,
  CondoRatingModuleGuard,
  DeliveriesModuleGuard,
  DocumentsModuleGuard,
  KeysModuleGuard,
  LostAndFoundsModuleGuard,
  ReservationsModuleGuard,
  PetsModuleGuard,
  DependentsModuleGuard
} from '../guards/activated-module.guard';
import { GateDeliveryListPage } from '../pages/gate/gate.delivery.list/gate.delivery.list';
import { CreateGateDeliveryPage } from '../pages/gate/create.gate.delivery/create.gate.delivery';
import { GatePermissionsListPage } from '../pages/gate/gate.permissions.list/gate.permissions.list';
import { CreateReservationComponent } from '../pages/reservation/create-reservation/create.reservation';
import { CondoDependentsComponent } from '../pages/condo/dependents/condo-dependents';
import { CondoPetsComponent } from '../pages/condo/pets/condo-pets';
import { CondoIntegrationsComponent } from '../pages/condo/integrations/condo-integrations.component';
import { BlankPage } from '../pages/blank/blank';
import { FractionsComponent } from 'app/pages/condo/residences/residences-ideal-fractions-edit/residences-ideal-fractions-edit';
import { CondoParkingSpotComponent } from 'app/pages/condo/parking/condo-parking-spot';
import { CondoGroupsComponent } from '../pages/condo/groups/condo-groups';
import { MergeContactsComponent } from '../pages/gate/merge-contacts/merge-contacts.component';
import { FeaturesPageComponent } from 'app/pages/features/features';
import { MoreOptionsPageComponent } from 'app/pages/more-options/more-options';
import { FeaturesRoutesGuard } from 'app/guards/features-routes.guard';
import { IsSmartLockerEnabledGuard } from 'app/guards/is-smart-locker-enabled.guard';
import { IntercomComponent } from 'app/pages/intercom/intercom';
import { CondoConstructionsComponent } from '../pages/condo/condo-constructions/condo-constructions.component';
import { IsGatekeeperOrJanitorGuard } from '../guards/is.gatekeeper.or.janitor.guard';
import { FeedGuard } from '../guards/feed.guard';
import { HasAdminOrOwnerOrUserAccessGuard } from '../guards/is.admin.or.owner.or.user.guard';
import { HasGateAccessPermissionGuard } from '../guards/has-gate-access-permission.guard';
import { CamerasComponent } from '../camera/cameras.component';
import { PermittedGuard } from 'app/guards/permitted.guard';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { CondoSmartLockersComponent } from 'app/pages/condo/condo-smart-lockers/condo-smart-lockers';
import { HardwareRoutesGuard } from 'app/guards/hardware-routes.guard';
import { LocalGroupsPageComponent } from '../pages/reservation/local-groups.component/local-groups.component';
import { LogbooksComponent } from '../pages/features/logbooks/logbooks.component';
import { ExternalPersonInfoHistoryComponent } from '../pages/gate/external-person-info-history/external-person-info-history.component';

const routes: Routes = [
  // Página inicial
  { path: '', component: LoginPageComponent },
  { path: 'login', component: LoginPageComponent },

  // Feed
  { path: 'feed', component: FeedPage, canActivate: [LoggedGuard, FeedGuard] },
  { path: 'feed/:param', component: FeedPage, canActivate: [LoggedGuard], data: {} },

  // Dashboard
  {
    path: 'dashboard',
    loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [LoggedGuard, HasAdminAccessGuard]
  },

  // Reservas
  {
    path: 'reservations',
    component: ReservateLocalPageComponent,
    canActivate: [LoggedGuard, ReservationFeatureGuard, ReservationsModuleGuard]
  },
  {
    path: 'reservations/localGroups',
    component: LocalGroupsPageComponent,
    canActivate: [LoggedGuard, ReservationFeatureGuard, ReservationsModuleGuard]
  },
  {
    path: 'reservations/createLocal',
    component: CreateLocalPageComponent,
    canActivate: [LoggedGuard, HasAdminAccessGuard, ReservationFeatureGuard, ReservationsModuleGuard]
  },
  {
    path: 'reservations/editLocal/:localId',
    component: CreateLocalPageComponent,
    canActivate: [LoggedGuard, HasAdminAccessGuard, ReservationFeatureGuard, ReservationsModuleGuard],
    data: {}
  },
  {
    path: 'reservations/reservationHistory',
    component: ReservationHistoryComponent,
    canActivate: [LoggedGuard, ReservationFeatureGuard, ReservationsModuleGuard]
  },
  // TODO - Implementar a abertura da reserva com id específico, porque não está acontecendo
  {
    path: 'reservations/reservationHistory/:reservationId',
    component: ReservationHistoryComponent,
    canActivate: [LoggedGuard, HasNotGatekeeperAccessGuard, ReservationFeatureGuard, ReservationsModuleGuard]
  },
  {
    path: 'reservations/reserve/:localId',
    component: CreateReservationComponent
  },

  // Meus dados
  { path: 'me', component: UserPage, canActivate: [LoggedGuard] },

  // Portaria
  {
    path: 'gate',
    component: GatePage,
    canActivate: [LoggedGuard, HasGateAccessPermissionGuard, GateFeatureGuard],
    children: [
      { path: 'deliveries', component: GateDeliveryListPage },
      { path: 'deliveries/:id', component: GateDeliveryListPage },
      {
        path: 'permissions',
        component: GatePermissionsListPage,
        data: { permissions: [PERMISSIONS.accessLiberation.view] }
      },
      { path: 'permissions/:id', component: GatePermissionsListPage },
      {
        path: 'access',
        component: AccessControlComponent,
        canActivate: [AccessControlModuleGuard, PermittedGuard],
        data: { permissions: [PERMISSIONS.accessLiberation.view] }
      },
      {
        path: 'access/:accessId',
        component: AccessControlComponent,
        canActivate: [AccessControlModuleGuard, PermittedGuard],
        data: { permissions: [PERMISSIONS.accessLiberation.view] }
      },
      { path: 'visitants', component: CondoVisitorsComponent, canActivate: [AccessControlModuleGuard] },
      { path: 'visitants/:visitantId', component: CondoVisitorsComponent, canActivate: [AccessControlModuleGuard] },
      { path: 'keys', component: CondoKeysComponent, canActivate: [KeysModuleGuard] },
      { path: 'keys/:keysId', component: CondoKeysComponent, canActivate: [KeysModuleGuard] },
      {
        path: 'notebook',
        loadChildren: () => import('../pages/gate/gate-notebook/gate-notebook.module').then(m => m.GateNotebookModule)
      },
      {
        path: 'events',
        loadChildren: () => import('../pages/gate/gate-events/gate-events.module').then(m => m.GateEventsModule)
      },
      {
        path: 'active-visitors',
        loadChildren: () => import('../pages/gate/gate-active-access/gate-active-visitor.module').then(m => m.GateActiveVisitorModule)
      },
      {
        path: 'external-person-info',
        component: ExternalPersonInfoHistoryComponent,
        canActivate: [PermittedGuard],
        data: { permissions: [PERMISSIONS.externalPersonInfo.history.view] }
      }
    ]
  },
  {
    path: 'gate/delivery/new',
    component: CreateGateDeliveryPage,
    canActivate: [LoggedGuard, HasGateAccessPermissionGuard, GateFeatureGuard, DeliveriesModuleGuard]
  },
  {
    path: 'gate/delivery/:id',
    component: CreateGateDeliveryPage,
    canActivate: [LoggedGuard, HasGateAccessPermissionGuard, GateFeatureGuard, DeliveriesModuleGuard]
  },
  {
    path: 'gate/merge-contacts',
    component: MergeContactsComponent,
    canActivate: [LoggedGuard, HasGatekeeperAccessGuard]
  },
  {
    path: 'residences',
    component: ResidencesPage,
    canActivate: [LoggedGuard, HasAdminOrOwnerOrUserAccessGuard]
  },
  {
    path: 'visitor-liberation/:residenceId',
    loadComponent: () => import('../pages/visitor-liberation/visitor-liberation.component').then(m => m.VisitorLiberationComponent),
    canActivate: [LoggedGuard, HasAdminOrOwnerOrUserAccessGuard]
  },
  {
    path: 'features',
    component: FeaturesPageComponent,
    canActivate: [LoggedGuard, FeaturesRoutesGuard],
    children: [
      {
        path: 'classificados',
        loadChildren: () => import('../pages/features/classificados/classificados.module').then(m => m.ClassificadosModule),
        canActivate: [AdFeatureGuard, ClassificadosModuleGuard, HasNotGatekeeperAccessGuard]
      },
      {
        path: 'lost-and-founds',
        loadChildren: () => import('../pages/features/lost-and-found/lost-and-found.module').then(m => m.LostAndFoundModule),
        canActivate: [LostAndFoundsModuleGuard, LoggedGuard, PermittedGuard],
        data: { permissions: [PERMISSIONS.lostAndFounds.view] }
      },
      {
        path: 'documents',
        loadChildren: () => import('../pages/features/documents/documents.module').then(m => m.DocumentsModule),
        canActivate: [HasNotGatekeeperAccessGuard, DocumentsFeatureGuard, DocumentsModuleGuard]
      },
      {
        path: 'rating',
        loadChildren: () => import('../pages/features/rating/condo-rating.module').then(m => m.CondoRatingModule),
        canActivate: [CondoRatingModuleGuard]
      },
      {
        path: 'device-requests',
        loadChildren: () =>
          import('../pages/features/hardware-devices-requests/hardware-devices-request.module').then(m => m.HardwareDevicesRequestModule),
        canActivate: [HasGatekeeperAccessGuard]
      },
      {
        path: 'faq',
        loadChildren: () => import('../pages/features/faq/faq.module').then(m => m.FaqModule),
        canActivate: [LoggedGuard, PermittedGuard],
        data: { permissions: [PERMISSIONS.faq.view] }
      },
      {
        path: 'asset-management',
        loadChildren: () => import('../pages/features/asset-management/asset-management.module').then(m => m.AssetManagementModule),
        canActivate: [AssetManagementModuleGuard]
      },
      {
        path: 'statistics',
        loadChildren: () => import('../pages/features/statistics/statistics.module').then(m => m.StatisticsModule)
      },
      {
        path: 'event-announcement-board',
        loadChildren: () =>
          import('../pages/features/event-announcement-board/event-announcement-board.module').then(m => m.EventAnnouncementBoardModule),
        canActivate: [LoggedGuard, PermittedGuard],
        data: { permissions: [PERMISSIONS.eventAnnouncementBoard.view] }
      },
      {
        path: 'admin-tools',
        loadChildren: () => import('../pages/features/admin-tools/admin-tools.module').then(m => m.AdminToolsModule),
        canActivate: [LoggedGuard, PermittedGuard],
        data: { permissions: [PERMISSIONS.personDeletionProcess.view] }
      },
      {
        path: 'highlights',
        loadChildren: () => import('../pages/features/highlights/highlights.module').then(m => m.HighlightsModule),
        canActivate: [LoggedGuard, PermittedGuard],
        data: { permissions: [PERMISSIONS.highlights.view] }
      },
      {
        path: 'logbooks',
        component: LogbooksComponent,
        canActivate: [LoggedGuard, PermittedGuard],
        data: { permissions: [PERMISSIONS.logbooks.view] }
      }
    ]
  },

  {
    path: 'more-options',
    component: MoreOptionsPageComponent,
    canActivate: [LoggedGuard],
    children: [
      {
        path: '',
        redirectTo: 'data-security',
        pathMatch: 'full'
      },
      {
        path: 'downloads',
        loadChildren: () => import('../pages/more-options/downloads/downloads.module').then(m => m.DownloadsModule),
        canActivate: [LoggedGuard, IsGatekeeperOrJanitorGuard]
      },
      {
        path: 'data-security',
        loadChildren: () => import('../pages/more-options/data-security/data-security.module').then(m => m.DataSecurityModule)
      },
      {
        path: 'services-status',
        loadChildren: () => import('../pages/more-options/services-status/services-status.module').then(m => m.ServicesStatusModule),
        canActivate: [LoggedGuard, IsGatekeeperOrJanitorGuard]
      },
      {
        path: 'imports',
        loadChildren: () => import('../pages/more-options/imports/imports.module').then(m => m.ImportsModule),
        canActivate: [LoggedGuard, HasAdminAccessGuard]
      },
      {
        path: 'customize-theme',
        loadChildren: () => import('../pages/more-options/customize-theme/customize-theme.module').then(m => m.CustomizeThemeModule),
        canActivate: [LoggedGuard, HasSystemAdminAccessGuard]
      },
      {
        path: 'lpr-tester',
        loadComponent: () => import('../pages/more-options/lpr-debbuger/lpr-debugger.component').then(mod => mod.LprDebuggerPageComponent),
        canActivate: [LoggedGuard, IsGatekeeperOrJanitorGuard]
      },
      {
        path: 'device-tester',
        loadComponent: () =>
          import('../pages/more-options/hardware-debugger/hardware-debugger.component').then(mod => mod.HardwareDebuggerPageComponent),
        canActivate: [LoggedGuard, HasSystemAdminAccessGuard]
      }
    ]
  },

  {
    path: 'condo',
    component: CondoPageComponent,
    canActivate: [LoggedGuard],
    children: [
      { path: '', redirectTo: 'condos', pathMatch: 'full' },
      { path: 'condos', component: CondoListComponent },
      { path: 'residences', component: CondoResidencesComponent, canActivate: [HasGatekeeperAccessGuard] },
      {
        path: 'residences/fractions',
        loadChildren: () =>
          import('../pages/condo/residences/residences-ideal-fractions-edit/residences-ideal-fractions-edit.module').then(
            m => m.ResidencesIdealFractionEditModule
          ),
        component: FractionsComponent,
        canActivate: [HasGatekeeperAccessGuard]
      },
      {
        path: 'residences/smart-lockers',
        component: CondoSmartLockersComponent,
        canActivate: [HasGatekeeperAccessGuard, IsSmartLockerEnabledGuard]
      },
      {
        path: 'residences/residence-sync',
        loadComponent: () =>
          import('../pages/bravas-residences-sync/bravas-residences-sync.component').then(m => m.BravasResidencesSyncComponent),
        canActivate: [HasAdminAccessGuard]
      },
      { path: 'residences/:residenceId', component: CondoResidencesComponent, canActivate: [HasGatekeeperAccessGuard] },
      {
        path: 'residents',
        component: CondoResidentsComponent,
        canActivate: [PermittedGuard, HasGatekeeperAccessGuard],
        data: { permissions: [PERMISSIONS.users.view] }
      },
      {
        path: 'residents/groups',
        component: CondoGroupsComponent,
        canActivate: [PermittedGuard, HasGatekeeperAccessGuard],
        data: { permissions: [PERMISSIONS.users.view] }
      },
      {
        path: 'residents/register-facial-devices',
        loadComponent: () =>
          import('../pages/create-user-facial-devices/create-user-facial-devices.component').then(
            mod => mod.CreateUserFacialDevicesComponent
          ),
        canActivate: [PermittedGuard, HasGatekeeperAccessGuard],
        data: { permissions: [PERMISSIONS.users.view] }
      },
      {
        path: 'residents/:residentId',
        component: CondoResidentsComponent,
        canActivate: [PermittedGuard, HasGatekeeperAccessGuard],
        data: { permissions: [PERMISSIONS.users.view] }
      },
      {
        path: 'vehicles',
        component: CondoVehiclesComponent,
        canActivate: [HasGatekeeperAccessGuard]
      },
      {
        path: 'vehicles/register-plates',
        loadComponent: () =>
          import('../pages/create-vehicles-plate-devices/create-vehicles-plate-devices.component').then(
            mod => mod.CreateVehiclesPlateDevicesComponent
          ),
        canActivate: [HasAdminAccessGuard]
      },
      {
        path: 'vehicles/:vehicleId',
        component: CondoVehiclesComponent,
        canActivate: [HasGatekeeperAccessGuard]
      },
      { path: 'parking-spots', component: CondoParkingSpotComponent, canActivate: [HasGatekeeperAccessGuard] },
      {
        path: 'parking-spots/:parkingId',
        component: CondoParkingSpotComponent,
        canActivate: [HasGatekeeperAccessGuard]
      },
      { path: 'suppliers', component: CondoSuppliersComponent, canActivate: [HasAdminAccessGuard] },
      { path: 'suppliers/:supplierId', component: CondoSuppliersComponent, canActivate: [HasAdminAccessGuard] },
      { path: 'settings', component: CondoConfigComponent, canActivate: [HasAdminAccessGuard] },
      { path: 'plans', component: CondoPlansComponent, canActivate: [HasSystemAdminAccessGuard] },
      {
        path: 'dependents',
        component: CondoDependentsComponent,
        canActivate: [DependentsModuleGuard]
      },
      {
        path: 'dependents/register-facial-devices',
        loadComponent: () =>
          import('../pages/create-dependent-facial-devices/create-dependent-facial-devices.component').then(
            mod => mod.CreateDependentFacialDevicesComponent
          ),
        canActivate: [PermittedGuard, HasGatekeeperAccessGuard],
        data: { permissions: [PERMISSIONS.users.view] }
      },
      { path: 'pets', component: CondoPetsComponent, canActivate: [PetsModuleGuard] },
      { path: 'constructions', component: CondoConstructionsComponent, canActivate: [HasGatekeeperAccessGuard] },
      { path: 'integrations', component: CondoIntegrationsComponent, canActivate: [HasSystemAdminAccessGuard] }
    ]
  },

  // Financial
  {
    path: 'finance',
    loadChildren: () => import('../financial/financial.module').then(m => m.FinancialModule),
    canActivate: [LoggedGuard, FinanceFeatureGuard]
  },

  // Maintenance
  {
    path: 'maintenance',
    loadChildren: () => import('../maintenance/maintenance.module').then(m => m.MaintenanceModule),
    canActivate: [LoggedGuard]
  },

  {
    path: 'reports',
    loadChildren: () => import('../reports/reports.module').then(m => m.ReportsModule),
    canActivate: [LoggedGuard]
  },

  {
    path: 'hardware',
    loadChildren: () => import('../hardware/hardware.module').then(m => m.HardwareModule),
    canActivate: [LoggedGuard, HardwareRoutesGuard]
  },
  {
    path: 'changelog',
    loadChildren: () => import('../changelog/changelog.module').then(m => m.ChangeLogModule),
    canActivate: [LoggedGuard]
  },

  { path: 'notFound', component: NotFoundPage, canActivate: [LoggedGuard] },

  // Signup Pages
  { path: 'signup', component: SignUpPage, canActivate: [SignupGuard] },

  // Sale Page
  { path: 'sale', component: SalePage, canActivate: [LoggedGuard] },
  { path: 'sale/:feature', component: SalePage, canActivate: [LoggedGuard] },

  // Subscription
  { path: 'subscription', component: SubscriptionPage, canActivate: [LoggedGuard, HasAdminAccessGuard] },
  { path: 'subscription/:couponCode', component: SubscriptionPage, canActivate: [LoggedGuard, HasAdminAccessGuard] },

  // Reset password
  { path: 'passwordReset/activationKey/:activationKey', component: ResetPasswordComponent, data: {} },

  // Activate User
  { path: 'emailCheck/activationKey/:activationKey', component: UserActivation, data: {} },

  { path: 'cameras', component: CamerasComponent, canActivate: [LoggedGuard, HasGatekeeperAccessGuard] },

  { path: 'blank', component: BlankPage },

  {
    path: 'white-label-error',
    loadChildren: () => import('../pages/error/white-label-error/white-label-error.module').then(m => m.WhiteLabelErrorModule)
  },
  {
    path: 'intercom',
    component: IntercomComponent,
    canActivate: [LoggedGuard],
    children: [
      {
        path: 'sip-accounts',
        loadChildren: () => import('../pages/intercom/sip-accounts/sip-accounts.module').then(m => m.IntercomSipAccountsModule),
        canActivate: [HasAdminAccessGuard]
      },
      {
        path: 'extensions',
        loadChildren: () => import('../pages/intercom/extensions/extensions.module').then(m => m.IntercomExtensionsModule),
        canActivate: [HasAdminAccessGuard]
      },
      {
        path: 'calls-history',
        loadChildren: () => import('../pages/intercom/calls-history/calls-history.module').then(m => m.IntercomCallsHistoryModule)
      }
    ]
  },

  {
    path: 'custom-roles',
    canActivate: [HasAdminAccessGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('../pages/custom-roles/custom-roles.component').then(mod => mod.CustomRolesPageComponent)
      },
      {
        path: 'create',
        loadComponent: () =>
          import('../pages/custom-roles/create-custom-role/create-custom-role.component').then(mod => mod.CreateCustomRolePageComponent)
      },
      {
        path: 'edit/:customRoleId',
        loadComponent: () =>
          import('../pages/custom-roles/create-custom-role/create-custom-role.component').then(mod => mod.CreateCustomRolePageComponent)
      },
      {
        path: ':customRoleId',

        children: [
          {
            path: '',
            loadComponent: () =>
              import('../pages/custom-roles/custom-role-details/custom-role-details.component').then(
                mod => mod.CustomRoleDetailsPageComponent
              )
          },
          {
            path: 'users',
            loadComponent: () =>
              import('../pages/custom-roles/custom-role-users/custom-role-users.component').then(mod => mod.CustomRoleUsersPageComponent)
          }
        ]
      }
    ]
  },

  {
    path: 'no-permission-error',
    loadChildren: () => import('../pages/error/no-permission-error/no-permission-error.module').then(m => m.NoPermissionErrorModule)
  },
  {
    path: 'no-custom-role-ip-error',
    loadChildren: () =>
      import('../pages/error/no-custom-role-ip-error/no-custom-role-ip-error.module').then(m => m.NoCustomRoleIpErrorModule)
  },

  // Redirect for not found routes - SEMPRE DEVE SER A ÚLTIMA ROTA
  { path: '**', redirectTo: '/notFound' }
];

// - Updated Export
export const routing = RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' });
