import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Construction, CONSTRUCTION_STATUS_LABEL, ConstructionFormData, TECHNICAL_MANAGER_TYPES_LABEL } from '@api/model/construction';
import { Condo } from '@api/model/condo';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Status } from '@api/model/status';
import { KeyValue } from '@angular/common';
import { ConstructionService } from '@api/service/construction.service';
import { OccurrenceFileUploader } from '../../pages/occurrence.creator/occurrence.creator.file.list/files.scroll';
import * as moment from 'moment';
import { capitalize } from '@api/util/util';
import { Subject } from 'rxjs';
import { formatCnpj, formatCpf, formatPhone } from '@api/util/formatters';
import { filter, takeUntil } from 'rxjs/operators';
import { cnpjValidator, cpfValidator, emailValidator } from '@api/util/validators';
import { User } from '@api/model/user';
import { ResidenceService } from '@api/service/residence.service';
import { Residence } from '@api/model/interface/residence';

@Component({
  selector: 'app-modal-create-construction',
  templateUrl: './modal-create-construction.component.html',
  styleUrls: ['./modal-create-construction.component.scss']
})
export class ModalCreateConstructionComponent implements OnInit, OnDestroy {
  @ViewChild(OccurrenceFileUploader) occurrenceFileUploader: OccurrenceFileUploader;
  onCreate?: (construction: Construction) => void;
  onUpdate?: (construction: Construction) => void;

  construction?: Construction;

  condo: Condo;
  user: User;
  residence: Residence;

  STATUS = new Status();
  isUploading = false;
  isSubmiting: any;

  CONSTRUCTION_STATUS_LABEL = CONSTRUCTION_STATUS_LABEL;
  TECHNICAL_MANAGER_TYPES_LABEL = TECHNICAL_MANAGER_TYPES_LABEL;

  constructionForm = this.fb.group({
    residence: new FormControl<Residence>(undefined, Validators.required),
    status: new FormControl('', Validators.required),
    startDate: new FormControl('', Validators.required),
    duration: new FormControl<number>(undefined, Validators.required),
    local: new FormControl(''),
    description: new FormControl(''),
    noise: new FormControl(false, Validators.required)
  });
  technicalManagerForm = this.fb.group({
    name: new FormControl('', Validators.required),
    cpf: new FormControl('', cpfValidator),
    email: new FormControl('', emailValidator),
    phone: new FormControl('', Validators.compose([Validators.minLength(14), Validators.maxLength(15)])),
    profession: new FormControl('', Validators.required),
    crea: new FormControl('')
  });
  companyForm = this.fb.group({
    name: new FormControl('', Validators.required),
    cnpj: new FormControl('', cnpjValidator),
    email: new FormControl('', emailValidator),
    phone: new FormControl('', Validators.compose([Validators.minLength(14), Validators.maxLength(15)]))
  });

  generalAttachments = [];
  technicalAttachments = [];
  technicalAttachmentsError = null;

  isBusinessCondo = false;

  private unsubscribe$: Subject<void> = new Subject<void>();
  noResponsibleErrorMessage: string | null = null;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private constructionService: ConstructionService,
    private residenceService: ResidenceService,
    private toastr: ToastrService
  ) {
    this.companyForm
      .get('phone')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.companyForm.get('phone').setValue(formatPhone(value), { emitEvent: false }));

    this.technicalManagerForm
      .get('phone')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.technicalManagerForm.get('phone').setValue(formatPhone(value), { emitEvent: false }));

    this.technicalManagerForm
      .get('cpf')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        filter(v => !!v)
      )
      .subscribe(value => this.technicalManagerForm.get('cpf').setValue(formatCpf(value), { emitEvent: false }));

    this.companyForm
      .get('cnpj')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.companyForm.get('cnpj').setValue(formatCnpj(value), { emitEvent: false }));
  }

  ngOnInit(): void {
    if (this.construction) {
      this.constructionForm.get('residence').setValue(this.construction.residence);
      this.constructionForm.get('status').setValue(this.construction.status);
      this.constructionForm.get('startDate').setValue(moment(this.construction.startDate).format('YYYY-MM-DD'));
      this.constructionForm.get('duration').setValue(this.construction.duration);
      this.constructionForm.get('local').setValue(this.construction.local);
      this.constructionForm.get('description').setValue(this.construction.description);
      this.constructionForm.get('noise').setValue(this.construction.noise);
      if (this.construction.responsible) {
        const { company, technicalManager } = this.construction.responsible;
        if (company) {
          this.companyForm.get('name').setValue(company.name);
          this.companyForm.get('cnpj').setValue(company.cnpj);
          this.companyForm.get('email').setValue(company.email);
          this.companyForm.get('phone').setValue(company.phone);
        }
        if (technicalManager) {
          this.technicalManagerForm.get('name').setValue(technicalManager.name);
          this.technicalManagerForm.get('cpf').setValue(technicalManager.cpf);
          this.technicalManagerForm.get('email').setValue(technicalManager.email);
          this.technicalManagerForm.get('phone').setValue(technicalManager.phone);
          this.technicalManagerForm.get('profession').setValue(technicalManager.profession);
          this.technicalManagerForm.get('crea').setValue(technicalManager.crea);
        }
      }
      this.generalAttachments = this.construction.attachments.length > 0 ? this.construction.attachments : [];
      this.technicalAttachments = this.construction.technicalAttachments.length > 0 ? this.construction.technicalAttachments : [];
    }

    if (this.residence) {
      this.constructionForm.get('residence').setValue(this.residence);
    }

    this.isBusinessCondo = this.condo?.isBusinessCondo();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private areBothTechnicalAndCompanyFormsValid() {
    return (
      (this.technicalManagerForm.valid && this.companyForm.valid) ||
      (this.technicalManagerForm.valid && this.companyForm.invalid && this.companyForm.untouched) ||
      (this.companyForm.valid && this.technicalManagerForm.invalid && this.technicalManagerForm.untouched)
    );
  }

  submit() {
    this.technicalAttachmentsError = null;
    this.noResponsibleErrorMessage = null;

    if (this.constructionForm.valid && this.technicalAttachments.length > 0 && this.areBothTechnicalAndCompanyFormsValid()) {
      const responsible = { company: null, technicalManager: null };

      if (this.companyForm.valid) {
        responsible.company = this.companyForm.value;
      }
      if (this.technicalManagerForm.valid) {
        responsible.technicalManager = this.technicalManagerForm.value;
      }

      const { status, residence, description, duration, noise, startDate, local } = this.constructionForm.value;
      const construction: ConstructionFormData = {
        status,
        description,
        duration,
        noise,
        startDate: moment(startDate).startOf('day').toISOString(),
        local,
        technicalAttachments: this.technicalAttachments,
        attachments: this.generalAttachments,
        responsible,
        residence
      };

      this.STATUS.setAsProcessing();
      if (this.construction) {
        this.update(this.condo._id, this.construction._id, residence._id, construction);
      } else {
        this.create(this.condo._id, residence._id, construction);
      }
    } else {
      if (this.technicalAttachments.length < 1) {
        this.technicalAttachmentsError = 'É necessário anexar a ART, RRT ou algum outro documento técnico.';
      }

      for (const key of Object.keys(this.constructionForm.controls)) {
        this.constructionForm.get(key).markAsTouched();
      }

      for (const key of Object.keys(this.technicalManagerForm.controls)) {
        this.technicalManagerForm.get(key).markAsTouched();
      }

      for (const key of Object.keys(this.companyForm.controls)) {
        this.companyForm.get(key).markAsTouched();
      }

      if (!this.technicalManagerForm.valid && !this.companyForm.valid) {
        this.noResponsibleErrorMessage = '* É necessário informar pelo menos um responsável pela obra.';
      }

      this.toastr.warning('Preencha os campos obrigatórios.');
    }
  }

  private update(condoId: string, constructionId: string, residenceId: string, construction: ConstructionFormData) {
    this.residenceService.updateConstruction(condoId, residenceId, constructionId, construction).subscribe({
      next: () => {
        const updatedConstruction = new Construction({
          ...this.construction,
          ...construction
        });
        this.STATUS.setAsSuccess();
        this.onUpdate(updatedConstruction);
        this.bsModalRef.hide();
        this.toastr.success('Obra atualizada com sucesso.');
      },
      error: () => {
        this.STATUS.setAsError();
        this.toastr.error('Não foi possível atualizar a obra, tente novamente');
      }
    });
  }

  private create(condoId: string, residenceId: string, construction: ConstructionFormData) {
    this.residenceService.createConstruction(condoId, residenceId, construction).subscribe({
      next: response => {
        this.STATUS.setAsSuccess();
        const updatedConstruction = new Construction({ ...construction, _id: response._id, createdBy: this.user });
        if (this.onCreate) {
          this.onCreate(updatedConstruction);
        }
        this.bsModalRef.hide();
        this.toastr.success('Obra criada com sucesso.');
      },
      error: () => {
        this.STATUS.setAsError();
        this.toastr.error('Não foi possível cria a obra, tente novamente');
      }
    });
  }

  defaultSortFunction = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
    return 0;
  };

  uploadFile() {
    this.occurrenceFileUploader.pickFile();
  }

  capitalizeWord(word) {
    return capitalize(word);
  }

  get residenceControl(): FormControl {
    return this.constructionForm.get('residence') as FormControl;
  }
}
