import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FeedbackContainerComponent } from '../../../../../components/feedback-container/feedback-container.component';
import { CommonModule } from '@angular/common';
import { Status } from '@api/model/status';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupService } from '@api/serviceV2/group.service';
import { EcondosQuery } from '@api/model/query';
import { AutosizeModule } from 'ngx-autosize';
import { Group } from '@api/model/group';
import { ToastrService } from 'ngx-toastr';
import { LogbooksService } from '@api/serviceV3/logbooks.service';
import { Logbooks } from '@api/model/logbook';

@Component({
  selector: 'app-modal-create-logbook',
  templateUrl: './modal-create-logbook.component.html',
  styleUrls: ['./modal-create-logbook.component.scss'],
  imports: [FeedbackContainerComponent, CommonModule, FormsModule, ReactiveFormsModule, AutosizeModule],
  standalone: true
})
export class ModalCreateLogbookComponent implements OnInit {
  condoId: string;
  userId: string;
  logbook: Logbooks;

  callbacks: { success?: Function; error?: Function };

  groupData: Group[];

  status: Status = new Status();

  form: UntypedFormGroup;

  title: FormControl = new FormControl('');
  description: FormControl = new FormControl('');
  group: FormControl = new FormControl('');
  useChecklist: FormControl = new FormControl('');
  usePassPosition: FormControl = new FormControl('');

  query: EcondosQuery = {
    $select: 'name'
  };

  constructor(
    protected bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private toastrService: ToastrService,
    private groupService: GroupService,
    private logbooksService: LogbooksService
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      group: ['', Validators.required],
      useChecklist: [true, Validators.required],
      usePassPosition: [true, Validators.required]
    });

    this.title = this.form.get('title') as FormControl;
    this.description = this.form.get('description') as FormControl;
    this.group = this.form.get('group') as FormControl;
    this.useChecklist = this.form.get('useChecklist') as FormControl;
    this.usePassPosition = this.form.get('usePassPosition') as FormControl;
  }

  ngOnInit(): void {
    this.status.setAsProcessing();

    this.logbooksService.getGroups(this.condoId, this.query).subscribe({
      next: res => {
        this.groupData = res.groups;

        if (this.logbook) {
          const values = this.logbook;
          this.title.setValue(values.title || '');
          this.description.setValue(values.description);
          this.group.setValue(values.group || 'all');
          this.useChecklist.setValue(values.useChecklist);
          this.usePassPosition.setValue(values.usePassPosition);
        }

        this.status.setAsSuccess();
      },
      error: () => {
        this.status.setAsError();
      }
    });
  }

  save(value: Partial<Logbooks>): void {
    if (this.form.valid) {
      this.status.setAsCreating();

      value.condo = this.condoId;

      value.group = this.group.value === 'all' ? null : value.group;

      if (!this.logbook) {
        value.createdBy = this.userId;
      } else {
        value.updatedBy = this.userId;
      }

      if (!this.logbook) {
        this.logbooksService.create(this.condoId, value).subscribe({
          next: res => {
            this.status.setAsSuccess();
            this.bsModalRef.hide();
            this.callbacks.success?.(res);
          },
          error: () => {
            this.status.setAsSuccess();
            this.toastrService.error('Ocorreu um erro ao salvar');
          }
        });
      } else {
        this.logbooksService.update(this.condoId, this.logbook._id, value).subscribe({
          next: res => {
            this.status.setAsSuccess();
            this.bsModalRef.hide();
            this.callbacks.success?.(res);
          },
          error: () => {
            this.status.setAsSuccess();
            this.toastrService.error('Ocorreu um erro ao atualizar');
          }
        });
      }
    } else {
      this.toastrService.warning('Preencha todos os campos');
      for (const key of Object.keys(value)) {
        this.form.get(key).markAsTouched();
      }
    }
  }
}
