import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { File } from '@api/model/file';
import { Condo } from '@api/model/condo';
import { capitalize } from '@api/util/util';
import { SessionService } from '@api/service/session.service';
import { LogbookMessage } from '@api/model/logbook';

@Component({
  selector: 'app-logbook-messages',
  templateUrl: './logbook-messages.component.html',
  styleUrls: ['./logbook-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogbookMessagesComponent implements OnInit {
  @Input() logbookMessages: LogbookMessage; // Recebe mensagens de logbook do componente pai
  @Output() replyMessage = new EventEmitter<LogbookMessage | null>(); // Emite resposta ao pai

  audioVoice = null;
  files: File[] = [];
  condo: Condo;

  ROLES = {
    OWNER: 'Síndico',
    GATEKEEPER: 'Porteiro',
    USER: 'Condômino',
    REQUESTER: 'Solicitante',
    JANITOR: 'Zelador',
    ADMIN: 'Administrador',
    SYSTEM_ADMIN: 'Administrador eCondos'
  };
  PRIORITIES = {
    ROUTINE: { text: 'Rotina', badge: 'badge-subtle-sky' },
    LOW: { text: 'Baixa', badge: 'badge-subtle-green' },
    MODERATE: { text: 'Moderada', badge: 'badge-subtle-yellow' },
    HIGH: { text: 'Alta', badge: 'badge-subtle-danger' }
  };

  constructor(private sessionService: SessionService) {
    this.condo = this.sessionService.condoValue;

    this.ROLES.GATEKEEPER = capitalize(this.condo.customLabels.gatekeeper.singular) || 'Porteiro';
    this.ROLES.JANITOR = capitalize(this.condo.customLabels.janitor.singular) || 'Zelador';
    this.ROLES.ADMIN = capitalize(this.condo.customLabels.admin.singular) || 'Administrador';
    this.ROLES.OWNER = capitalize(this.condo.customLabels.owner.singular) || 'Síndico';
    this.ROLES.USER = capitalize(this.condo.customLabels.resident.singular) || 'Condômino';
  }

  ngOnInit() {
    if (Array.isArray(this.logbookMessages.files)) {
      this.files = (this.logbookMessages?.files?.length && this.logbookMessages?.files.map(f => new File(f))) || [];
      if (this.files.length > 0) {
        this.audioVoice = this.files[0].url;
      }
    }
  }

  handleReplyMessage(event: LogbookMessage | null) {
    this.replyMessage.emit(event);
  }
}
