import { Injectable } from '@angular/core';
import { Actuator } from '@api/model/hardware/actuator';
import { IntelbrasStandAloneSDK } from '@econdos/econdos-intelbras';
import { defer, forkJoin, from, merge, Observable, of } from 'rxjs';
import { catchError, map, scan, timeout } from 'rxjs/operators';
import { Device, DEVICE_TYPES } from '@api/model/hardware/device';
import * as moment from 'moment';
import { Timezone } from '@api/model/timezone';
import { EcondosDevice } from '@econdos/econdos-base-sdk';

@Injectable({ providedIn: 'root' })
export class IntelbrasStandAloneService {
  private intelbrasDevices = {};

  constructor() {}

  clearDeviceCache(actuatorId?: string) {
    if (actuatorId) {
      delete this.intelbrasDevices[actuatorId];
    } else {
      this.intelbrasDevices = {};
    }
  }

  public getDevices(actuators: Actuator | Actuator[]) {
    if (!Array.isArray(actuators)) {
      actuators = [actuators];
    }
    return actuators.map(a => this.getDevice(a));
  }

  public getDevice(actuator: Actuator) {
    if (!this.intelbrasDevices[actuator._id]) {
      this.intelbrasDevices[actuator._id] = new IntelbrasStandAloneSDK(actuator);
    }
    return this.intelbrasDevices[actuator._id];
  }

  trigger(actuator: Actuator) {
    const device = this.getDevice(actuator);
    return from(device.openDoor());
  }

  getTime(actuator: Actuator) {
    const device = this.getDevice(actuator);
    return from(device.getDateTime()).pipe(
      timeout(10000),
      map(({ datetime }) => ({ datetime }))
    );
  }

  public activateMonitor(actuator: Actuator) {
    const device = this.getDevice(actuator);
    return from(device.activateMonitor());
  }

  public disableUploadPicture(actuator: Actuator): Observable<{ success: boolean }> {
    const device = this.getDevice(actuator);
    return from(device.disableUploadPicture()).pipe(
      map(() => ({ success: true })),
      catchError(() => of({ success: false }))
    );
  }

  checkConnection(actuator: Actuator) {
    return this.getTime(actuator).pipe(
      timeout(10000),
      map(() => ({ _id: actuator._id, status: true })),
      catchError(() => of({ _id: actuator._id, status: false }))
    );
  }

  addFacial(device: Device, actuators: Actuator[]) {
    actuators = actuators || device.actuators || [];
    const hasAccessGroupWithActuators = (device.accessGroups || []).some(accessGroup => !!accessGroup.actuators?.length);
    // Verifica se existem acionadores para cadastrar, se não existir já retorna
    if (!actuators.length && !hasAccessGroupWithActuators) {
      return of([]);
    }
    let deviceUser: any = device.owner.user || device.owner.condoContact;
    if (!deviceUser) {
      if (device.owner && device.owner.userName) {
        deviceUser = { name: device.owner.userName };
      } else {
        console.error('User not found');
        throw new Error('User not found');
      }
    }
    let userType = 'RESIDENT';
    if (device.owner.condoContact) {
      userType = 'VISITOR';
    }
    let firstName = deviceUser.firstName;
    let lastName = deviceUser.lastName;
    if (deviceUser.name) {
      const names = deviceUser.name.split(' ');
      firstName = names.shift();
      lastName = names.join(' ');
    }
    const user: {
      _id: string;
      type: string;
      firstName: string;
      lastName: string;
      validUntil?: string;
      validFrom?: string;
    } = { _id: device._id, type: userType, firstName, lastName };
    if (device.validUntil) {
      user.validUntil = device.validUntil;
    }
    if (device.validFrom) {
      user.validFrom = device.validFrom;
    }
    const picture = device.owner?.picture?.url || deviceUser?.picture?.url;

    let observables = [];
    if (device.accessGroups?.length) {
      const accessGroups = device.accessGroups;
      for (const accessGroup of accessGroups) {
        const accessGroupActuators: Actuator[] = accessGroup.actuators as Actuator[];
        const timezoneId = accessGroup.timezone?.sequenceId;
        for (const actuator of accessGroupActuators) {
          const request$ = defer(() => this.getDevice(actuator).addUserWithFacial(user, picture, device._id, timezoneId)).pipe(
            map(({ success }) => ({ actuator, success })),
            catchError(e => {
              console.warn(e);
              return of({ actuator, success: false });
            })
          );
          observables.push(request$);
        }
      }
    } else {
      observables = actuators.map(actuator =>
        defer(() => this.getDevice(actuator).addUserWithFacial(user, picture, device._id)).pipe(
          map(({ success }) => ({ actuator, success })),
          catchError(e => {
            console.warn(e);
            return of({ actuator, success: false });
          })
        )
      );
    }

    return forkJoin(
      merge(...observables, 3).pipe(
        scan((acc, curr: any) => {
          acc = acc.concat({ ...curr });
          return acc;
        }, [])
      )
    ).pipe(map(([result]) => result));
  }

  addVisitorFacial(
    device: Device,
    actuators: Actuator[],
    timezone?: Partial<Timezone>,
    options?: Partial<{
      entranceDuration: number;
    }>
  ) {
    if (!device.owner?.condoContact) {
      throw new Error('Visitante não encontrado');
    }
    actuators = actuators || device.actuators || [];
    const { firstName, lastName, _id } = device.owner.condoContact;
    const credits = device.credits || device.condo?.hardwareParams?.visitorsFacialFromGatekeeperDefaultCredits || 1;

    const user: any = {
      firstName,
      lastName,
      _id,
      credits,
      type: 'VISITOR',
      validFrom: moment(device.validFrom || '')
        .startOf('d')
        .toISOString(),
      validUntil: device.validUntil ? moment(device.validUntil) : moment().add(1, 'd').toISOString()
    };

    if (options?.entranceDuration) {
      user.entranceDuration = options.entranceDuration;
    }

    const picture = device.owner?.picture?.url || device.owner?.condoContact?.picture?.url;

    const observables = actuators.map(actuator =>
      defer(() => this.getDevice(actuator).addUserWithFacial(user, picture, null, timezone?.sequenceId)).pipe(
        map(({ success }) => ({ actuator, success })),
        catchError(e => of({ actuator, success: false }))
      )
    );

    return forkJoin(
      merge(...observables, 3).pipe(
        scan((acc, curr: any) => {
          acc = acc.concat({ ...curr });
          return acc;
        }, [])
      )
    ).pipe(map(([result]) => result));
  }

  createDevice(device: Device, actuators?: Actuator[]) {
    switch (device.type) {
      case DEVICE_TYPES.FACIAL: {
        return this.addFacial(device, actuators);
      }
      default: {
        throw new Error('Invalid type');
      }
    }
  }

  deleteDevice(device: Device, actuators?: Actuator[]) {
    let actuatorsToDelete = [];
    if (actuators) {
      actuatorsToDelete = actuators;
    } else if (device.accessGroups?.length) {
      for (const accessGroup of device.accessGroups) {
        actuatorsToDelete = actuatorsToDelete.concat(accessGroup.actuators);
      }
    } else {
      actuatorsToDelete = device.actuators || [];
    }
    if (!actuatorsToDelete.length) {
      return of([]);
    }
    const observables = actuatorsToDelete.map(actuator => {
      if (device.accessType !== 'RESIDENT' && device.owner?.condoContact) {
        return defer(() => this.getDevice(actuator).removeUserByEcondosId(device.owner.condoContact._id)).pipe(
          map(({ success }) => ({ actuator, success })),
          catchError(e => {
            return of({ actuator, success: false });
          })
        );
      } else {
        return defer(() => this.getDevice(actuator).removeUserByEcondosId(device._id)).pipe(
          map(({ success }) => ({ actuator, success })),
          catchError(e => {
            return of({ actuator, success: false });
          })
        );
      }
    });

    return forkJoin(
      merge(...observables, 4).pipe(
        scan((acc, curr: any) => {
          acc = acc.concat({ ...curr });
          return acc;
        }, [])
      )
    ).pipe(map(([result]) => result));
  }

  public syncDevice(
    condoActuators: Actuator[],
    device: Device
  ): Observable<{
    actuator: Actuator;
    success: boolean;
    error: string;
  }>[] {
    condoActuators = condoActuators.filter(actuator => actuator.hardware === 'INTELBRAS_STAND_ALONE');
    let actuators;
    if (device.accessGroups && device.accessGroups.length) {
      actuators = (device.accessGroups.map(ag => ag.actuators) || []).reduce((acc, curr) => acc.concat(curr), []);
    } else {
      actuators = device.actuators || [];
    }

    let actuatorsToUpdate = [];
    let actuatorsToRemove = [];
    if (actuators.length) {
      const deviceActuatorKeys = actuators.reduce((keys, ac) => {
        keys[ac?._id || ac] = ac;
        return keys;
      }, {});
      actuatorsToUpdate = condoActuators.filter(condoAc => deviceActuatorKeys[condoAc._id]);
      actuatorsToRemove = condoActuators.filter(condoAc => !deviceActuatorKeys[condoAc._id]);
    } else {
      actuatorsToRemove = actuatorsToRemove.concat(condoActuators);
    }

    let observables = [];
    if (actuatorsToUpdate.length) {
      switch (device.type) {
        case DEVICE_TYPES.FACIAL:
          if (!device.accessType || device.accessType === 'RESIDENT') {
            const insert = this.addFacial(device, actuatorsToUpdate);
            observables = observables.concat(insert);
          } else {
            const insert = this.addVisitorFacial(device, actuatorsToUpdate);
            observables = observables.concat(insert);
          }
          break;
      }
    }

    if (actuatorsToRemove.length) {
      const remove = this.deleteDevice(device, actuatorsToRemove);
      observables = observables.concat(remove);
    }
    return observables;
  }

  async removeExpiredVisitors(actuators: Actuator[]) {
    const actuatorDevices = this.getDevices(actuators);
    return await Promise.all(
      actuatorDevices.map(device =>
        device
          .deleteExpiredVisitors()
          .then(() => ({
            success: true,
            actuator: {
              _id: device.actuatorId,
              name: device.actuatorName
            }
          }))
          .catch(e => {
            console.log(e);
            return {
              success: false,
              actuator: {
                _id: device.actuatorId,
                name: device.actuatorName
              }
            };
          })
      )
    );
  }

  addTimezone(actuator: Actuator, timezone: Timezone): Observable<{ success: boolean }> {
    const econdosSeqId = timezone.sequenceId;
    const daysAllowed = timezone.daysAllowed;
    return from(
      this.getDevice(actuator).addTimezone({ econdosSeqId, daysAllowed }) as Observable<{
        success: boolean;
      }>
    );
  }

  syncDateTime(actuator: Actuator) {
    const date = new Date();
    return from(this.getDevice(actuator).setDateTime(date));
  }

  reboot(actuator: Actuator) {
    const device = this.getDevice(actuator);
    return from(device.reboot()).pipe(
      timeout(10000),
      map(({ success }) => ({ success })),
      catchError(() => of({ success: false }))
    );
  }

  exportDevices(
    actuator: Actuator,
    deviceType: EcondosDevice['type'],
    accessType?: 'RESIDENT' | 'VISITOR'
  ): Observable<{
    data: EcondosDevice[];
    success: boolean;
  }> {
    const device = this.getDevice(actuator);
    return from(device.exportDevices(deviceType, accessType)).pipe(
      map(({ actuators, success }) => {
        const devices: EcondosDevice[] = actuators || [];
        return {
          data: devices,
          success
        };
      })
    );
  }
}
