import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { noop, Subject } from 'rxjs';
import { Status } from '@api/model/status';
import { Device } from '@api/model/hardware/device';
import { CondoContact } from '@api/model/contact/condo.contact';
import { distinctUntilChanged, filter, takeUntil, tap, timeout } from 'rxjs/operators';
import { CondoVehicle } from '@api/model/condo.vehicle';
import * as moment from 'moment';
import { ModalNewCondoContactComponent } from '../../pages/gate/access.control/new.condo.contact/new.condo.contact.modal';
import { Condo } from '@api/model/condo';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { SessionService } from '@api/service/session.service';

@Component({
  selector: 'app-modal-create-visitor-plate-device',
  templateUrl: 'modal-create-visitor-plate-device.component.html',
  styleUrls: ['modal-create-visitor-plate-device.component.scss']
})
export class ModalCreateVisitorPlateDeviceComponent implements OnInit, OnDestroy {
  public condo: Condo;

  status: Status = new Status();

  device: Device;

  form: UntypedFormGroup;

  condoVehicles: CondoVehicle[] = [];

  today = moment().set({ hour: 8, minute: 0 }).format('YYYY-MM-DDTHH:mm');
  maxDate = '';

  private lprParams: { enabled: boolean; gateKeeperCanRegisterVisitorPlate: boolean; deviceDuration: number };
  private callbacks: { success?: (arg) => void };
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly sessionService: SessionService,
    private readonly modalService: BsModalService,
    private readonly deviceService: HardwareDeviceService,
    private readonly toastrService: ToastrService
  ) {}

  private initForm(): void {
    this.maxDate = moment(this.today)
      .add(this.lprParams.deviceDuration, 'minutes')
      .set({
        hour: 17,
        minute: 0
      })
      .format('YYYY-MM-DDTHH:mm');

    this.form = this.formBuilder.group({
      type: ['VEHICLE_PLATE', [Validators.required]],
      hardware: ['ECONDOS', [Validators.required]],
      condoContact: [null, [Validators.required]],
      condoVehicle: [null, [Validators.required]],
      validFrom: [
        this.today,
        [Validators.required, control => (moment(control.value).isBefore(this.today, 'day') ? { invalidMinDate: true } : null)]
      ],
      validUntil: [
        this.maxDate,
        [
          Validators.required,
          control =>
            moment(control.value).isBefore(this.today, 'day') || moment(control.value).isAfter(this.maxDate, 'day')
              ? { invalidMinOrMaxDate: true }
              : null
        ]
      ],
      picture: [null, [Validators.required]],
      status: ['ACTIVE', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.condo = this.sessionService.condoValue;
    this.initForm();

    this.form
      .get('validFrom')
      .valueChanges.pipe(
        distinctUntilChanged(),
        filter(value => value),
        tap(date => {
          const expectedMaxDate = moment(date).add(this.lprParams.deviceDuration, 'minutes');
          const maxDate = moment(this.maxDate);
          if (!expectedMaxDate.isSame(maxDate, 'day')) {
            this.maxDate = expectedMaxDate.format('YYYY-MM-DDTHH:mm');
            this.form.get('validUntil').setValue(this.maxDate);
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(noop);

    this.form
      .get('validUntil')
      .valueChanges.pipe(
        distinctUntilChanged(),
        filter(value => value),
        tap(validUntil => {
          validUntil = moment(validUntil);
          const validFrom = moment(this.form.get('validFrom').value);
          if (validUntil.isBefore(validFrom)) {
            this.form.get('validUntil').setErrors({ isBeforeValidFrom: true });
          } else {
            const errors = this.form.get('validUntil').errors;
            delete errors?.isBeforeValidFrom;
            this.form.get('validUntil').setErrors(errors);
          }
          this.form.updateValueAndValidity();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(noop);

    this.form
      .get('condoContact')
      .valueChanges.pipe(
        tap(condoContact => {
          if (condoContact) {
            const { condoVehicles, picture } = condoContact;
            this.condoVehicles = condoVehicles || [];
            this.form.get('picture').setValue(picture || null);
          } else {
            this.condoVehicles = [];
            this.form.get('picture').setValue(null);
            this.form.get('condoVehicle').setValue(null);
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(noop);

    if (this.device) {
      const { condoContact, condoVehicle, picture } = this.device.owner || {};
      this.condoVehicles = [...condoContact?.condoVehicles];
      const value = {
        ...this.device,
        condoContact,
        picture: picture || condoContact.picture || null,
        condoVehicle: this.condoVehicles.find(vehicle => vehicle._id === condoVehicle._id),
        validUntil: moment(this.device.validUntil).format('YYYY-MM-DDTHH:mm'),
        validFrom: moment(this.device.validFrom).format('YYYY-MM-DDTHH:mm')
      };
      this.form.patchValue(value);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public setCondoContact(condoContact: CondoContact): void {
    this.form.get('condoContact').setValue(condoContact);
  }

  public createContact(nameOrId): void {
    const newContactInfo = { nameOrId };
    const initialState = {
      condo: this.condo,
      newContactInfo,
      callback: contact => {
        this.form.get('condoContact').setValue(contact);
      }
    };
    this.modalService.show(ModalNewCondoContactComponent, {
      initialState,
      class: 'modal-lg modal-xl',
      ignoreBackdropClick: true
    });
  }

  public createVehicleFromContact(): void {
    const initialState = {
      condo: this.condo,
      condoContact: this.form.get('condoContact').value,
      callback: ({ condoVehicles }) => {
        this.condoVehicles = condoVehicles;
        this.form.get('condoVehicle').setValue(condoVehicles?.[condoVehicles.length - 1] || null);
      }
    };
    this.modalService.show(ModalNewCondoContactComponent, {
      initialState,
      class: 'modal-lg modal-xl',
      ignoreBackdropClick: true
    });
  }

  private buildDeviceToBody() {
    const { condoContact, condoVehicle, picture, type, hardware, validFrom, validUntil, status } = this.form.value;
    const owner = {
      condoContact: condoContact?._id,
      condoVehicle: condoVehicle?._id,
      picture: picture?._id
    };
    const serial = condoVehicle?.plate;
    const approvedByName = this.sessionService.userValue?.fullName;
    if (this.device && this.device.owner?.residence) {
      owner['residence'] = this.device?.owner?.residence;
    }

    return {
      hardware,
      type,
      owner,
      serial,
      approvedByName,
      status,
      validFrom: moment(validFrom).toDate(),
      validUntil: moment(validUntil).toDate(),
      credits: 0,
      accessType: 'VISITOR'
    };
  }

  public save(): void {
    if (this.form.valid) {
      this.status.setAsProcessing();
      const device = this.buildDeviceToBody();
      let request;
      if (this.device) {
        request = this.deviceService.update(this.condo._id, this.device._id, device);
      } else {
        request = this.deviceService.create(this.condo._id, device);
      }
      request.pipe(timeout(10000)).subscribe(
        res => {
          const deviceToCallBack = {
            ...device,
            ...res,
            owner: {
              condoContact: this.form.get('condoContact').value,
              condoVehicle: this.form.get('condoVehicle').value,
              picture: this.form.get('picture').value,
              ...(this.device?.owner?.residence && { residence: this.device.owner.residence })
            },
            condoContactLabel: `${this.form.get('condoContact').value?.firstName} ${this.form.get('condoContact').value?.lastName}`,
            createdBy: this.device ? this.device.createdBy : this.sessionService.userValue
          };
          this.status.setAsSuccess();
          if (this.callbacks && this.callbacks.success) {
            this.callbacks.success(deviceToCallBack);
          }
          this.close();
        },
        () => {
          this.status.setAsError();
          swal({
            type: 'error',
            title: 'Ops...',
            text: `Não foi possível ${this.device ? 'editar' : 'criar'} o dispositivo, tente novamente...`
          });
        }
      );
    } else {
      this.form.markAllAsTouched();
      this.toastrService.warning('Preencha todos os campos corretamente!');
    }
  }

  public close(): void {
    this.bsModalRef.hide();
  }
}
