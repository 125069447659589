import { User } from '@api/model/user';

export type Note = {
  _id?: string;
  text: string;
  residence?: any;
  startDate?: string;
  endDate?: string;
  createdBy?: User | null;
  createdAt?: Date | null;
  updatedBy?: User | null;
  updatedAt?: Date | null;
};

export const BuildNote = (data: Partial<Note>): Note => {
  return {
    _id: typeof data === 'string' ? data : data._id,
    text: data.text || '',
    residence: data.residence || null,
    startDate: data.startDate || '',
    endDate: data.endDate || '',
    createdBy: data.createdBy ? new User(data.createdBy) : null,
    createdAt: data.createdAt ? new Date(data.createdAt) : null,
    updatedBy: data.updatedBy ? new User(data.updatedBy) : null,
    updatedAt: data.updatedAt ? new Date(data.updatedAt) : null
  };
};
