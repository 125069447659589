import { Component, OnDestroy, OnInit } from '@angular/core';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { ComponentsModule } from '../../../components/components.module';
import { HasPermissionDirective } from '../../../directives/has-permission.directive';
import { FormControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { SessionService } from '@api/service/session.service';
import { LogbooksService } from '@api/serviceV3/logbooks.service';
import { EcondosQuery } from '@api/model/query';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Status } from '@api/model/status';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalCreateLogbookComponent } from './components/modal-create-logbook/modal-create-logbook.component';
import { LogbookMessage, Logbooks } from '@api/model/logbook';
import swal from 'sweetalert2';
import { debounceTime, timeout } from 'rxjs/operators';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { LogbookComponent } from './components/logbook/logbook.component';
import { ToastrService } from 'ngx-toastr';
import { LogbookMessagesService } from '@api/service/logbook-messages.service';

@Component({
  selector: 'app-logbooks',
  templateUrl: './logbooks.component.html',
  styleUrls: ['./logbooks.component.scss'],
  imports: [ComponentsModule, HasPermissionDirective, ReactiveFormsModule, CommonModule, BsDropdownModule, TooltipModule, LogbookComponent],
  standalone: true
})
export class LogbooksComponent implements OnInit, OnDestroy {
  public user: User;
  public condo: Condo;

  logbooks: Logbooks[] = [];
  logbooksCount: number;

  selectedLogbook: Logbooks;

  selectedLogbookMessages: LogbookMessage[] = [];
  logbookMessagesCount: number = 0;

  searchTerm: FormControl = new FormControl('');

  status: Status = new Status();

  private subscriptions: Subscription = new Subscription();

  constructor(
    private sessionService: SessionService,
    private logbooksService: LogbooksService,
    private logbooksMessageService: LogbookMessagesService,
    private modalService: BsModalService,
    private toastrService: ToastrService
  ) {
    this.user = this.sessionService.userValue;
    this.condo = this.sessionService.condoValue;

    this.searchTerm = new UntypedFormControl();

    this.searchTerm.valueChanges.pipe(debounceTime(500)).subscribe({
      next: term => {
        this.getLogbooks(term);
      }
    });
  }

  ngOnInit(): void {
    this.getLogbooks();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getLogbooks(token?: string): void {
    this.status.setAsProcessing();

    const query: EcondosQuery = {
      $select: 'title description group useChecklist usePassPosition',
      memberId: this.user.id
    };

    let observable$: Observable<{ logbooks: Logbooks[] }>;

    if (token) {
      observable$ = this.logbooksService.getByToken(this.condo.id, token, query);
    } else {
      observable$ = this.logbooksService.get(this.condo.id, query);
    }

    const subscription = observable$.subscribe({
      next: (res: { logbooks: Logbooks[] }) => {
        this.logbooksCount = res.logbooks.length;
        this.logbooks = res.logbooks;

        this.status.setAsSuccess();
      },
      error: err => {
        this.status.setAsError();
        console.error(err);
      }
    });

    this.subscriptions.add(subscription);
  }

  handleCreateLogBook(): void {
    const initialState = {
      condoId: this.condo.id,
      userId: this.user.id,
      callbacks: {
        success: () => this.getLogbooks()
      }
    };
    this.modalService.show(ModalCreateLogbookComponent, { initialState, class: 'modal-md' });
  }

  handleEditLogBook(logbook: Logbooks): void {
    const initialState = {
      condoId: this.condo.id,
      userId: this.user.id,
      logbook,
      callbacks: {
        success: () => this.getLogbooks()
      }
    };
    this.modalService.show(ModalCreateLogbookComponent, { initialState, class: 'modal-md' });
  }

  handleDeleteLogBook(logbook: Logbooks): void {
    swal({
      type: 'question',
      title: 'Apagar livro de registro',
      text: 'Ao apagar esse livro todo o histórico de mensagens será perdido. Digite "apagar livro"',
      input: 'text',
      inputPlaceholder: 'Digite apagar livro',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async inputValue => {
        // Verifica se o texto inserido é "apagar livro"
        inputValue = (inputValue || '').toString().trim().toLowerCase();
        if (inputValue !== 'apagar livro') {
          return Promise.reject(`Digite "apagar livro" para confirmar sua ação`);
        }

        try {
          this.status.setAsProcessing();

          return await firstValueFrom(this.logbooksService.delete(this.condo.id, logbook?._id).pipe(timeout(10000)));
        } catch (err) {
          console.log(err);
          throw 'Não foi possível apagar este item, verifique sua conexão e tente novamente...';
        }
      }
    }).then(
      () => {
        if (this.searchTerm.value) {
          this.searchTerm.setValue(null);
        } else {
          this.getLogbooks();
        }
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  handleSelectLogbook(logbook: Logbooks): void {
    const hasPermission = this.user.getPermissionValue({
      condoId: this.condo._id,
      permission: PERMISSIONS.logbooks.sendMessage
    });

    if (!hasPermission) {
      this.toastrService.warning('Você não tem permissão para acessar esse livro.');
      return;
    }

    this.status.setAsProcessing();

    const query: EcondosQuery = {
      $select: 'messageType messageDescription priority files role createdBy createdAt checklists',
      $populate: [
        {
          path: 'createdBy',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail type name format' }
        },
        {
          path: 'files',
          select: 'name url thumbnail type format'
        },
        {
          path: 'replyTo',
          select: 'messageType messageDescription priority files role createdBy createdAt',
          populate: [
            {
              path: 'createdBy',
              select: 'firstName lastName picture',
              populate: { path: 'picture', select: 'url thumbnail type name format' }
            },
            { path: 'checklists' },
            {
              path: 'files',
              select: 'name url thumbnail type format'
            }
          ]
        }
      ],
      logbook: logbook._id,
      $limit: 10,
      $sort: '-createdAt'
    };

    this.logbooksMessageService.getMessages(this.condo.id, query).subscribe({
      next: res => {
        const sortedData = (res.logbookMessages || []).reverse();

        this.status.setAsSuccess();

        this.selectedLogbook = logbook;
        this.selectedLogbookMessages = sortedData;
        this.logbookMessagesCount = res.count;
      },
      error: err => {
        this.status.setAsSuccess();
        this.toastrService.error('Falha ao selecionar um livro. Tente novamente.');
        console.error(err);

        this.selectedLogbookMessages = null;
        this.selectedLogbook = null;
      }
    });
  }

  handleBack(): void {
    this.selectedLogbook = null;
    this.selectedLogbookMessages = null;
  }

  protected readonly PERMISSIONS = PERMISSIONS;
}
