import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { TableColumnDefinition, TableComponent, TableStatus } from '../../../components/table/table.component';
import { EcondosFilter } from '@api/model/filter';
import { User } from '@api/model/user';
import { SessionService } from '@api/service/session.service';
import { Condo } from '@api/model/condo';
import { ComponentsModule } from '../../../components/components.module';
import { PipesModule } from '../../../pipe/pipes.module';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalFilterComponent } from '../../../components/modal-filter/modal-filter.component';
import { EcondosQuery } from '@api/model/query';
import { Contact } from '@api/model/interface/contact';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ExternalPersonInfoService } from '@api/serviceV3/external-person-info.service';
import { SearchExternalPersonInfoModalComponent } from '../../modal/search-external-person-info.modal/search-external-person-info-modal.component';
import { EXTERNAL_TYPES, ExternalPersonInfo } from '@api/model/external-person-info';
import * as moment from 'moment';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { HasPermissionDirective } from '../../../directives/has-permission.directive';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '@environment';
import { ToastrService } from 'ngx-toastr';

interface TableData {
  _id: string;
  name: string;
  cpf: string;
  date: string;
  status: string;
  type: string;
}
const typeOptions = EXTERNAL_TYPES;
@Component({
  selector: 'app-external-person-info-history',
  templateUrl: 'external-person-info-history.component.html',
  styleUrls: ['external-person-info-history.component.scss'],
  imports: [
    ComponentsModule,
    PipesModule,
    FormsModule,
    NgIf,
    TooltipModule,
    ReactiveFormsModule,
    NgSwitch,
    NgSwitchCase,
    HasPermissionDirective
  ],
  standalone: true
})
export class ExternalPersonInfoHistoryComponent implements OnInit, OnDestroy {
  @ViewChild('cpfTemplate', { static: true }) cpfTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('externalPersonInfoTable', { static: true }) externalPersonInfoTable: TableComponent;

  searchInput = new UntypedFormControl('');
  user: User;
  condo: Condo;
  externalPersons: Array<TableData> = [];
  externalPersonsCount = 0;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<TableData>[] = [];

  sort: {
    field: string;
    order: 'asc' | 'desc' | '';
  } = { field: 'name', order: '' };

  filters: EcondosFilter[] = [];

  searchToken = new FormControl('');

  initialQuery: EcondosQuery = {
    $select: 'name cpf searchHistory.risk lastSearchTime lastProvider createdAt type',
    $and: [],
    $or: []
  };

  customQuery = { ...this.initialQuery };

  numberOfActiveFilters = 0;
  unsubscribe$ = new Subject();

  providerUrl: string;

  constructor(
    private sessionService: SessionService,
    private modalService: BsModalService,
    private externalPersonInfoService: ExternalPersonInfoService,
    private toastr: ToastrService
  ) {
    this.user = this.sessionService.userValue;
    this.condo = this.sessionService.condoValue;

    this.filters = [
      {
        element: 'select',
        elementSize: 'medium',
        name: 'status',
        selectOptions: [
          { value: '', label: 'Todos' },
          { value: 'VERDE', label: 'Seguro' },
          { value: 'AMARELO', label: 'Aviso' },
          { value: 'LARANJA', label: 'Suspeito' },
          { value: 'VERMELHO', label: 'Alto risco' }
        ],
        label: 'Status',
        searchType: 'match'
      },
      {
        element: 'input',
        elementSize: 'medium',
        elementType: 'date',
        name: 'lastSearchTime',
        label: 'Data da consulta',
        searchType: 'match'
      },

      {
        element: 'select',
        elementSize: 'medium',
        name: 'type',
        selectOptions: [{ value: '', label: 'Todos' }, ...typeOptions],
        label: 'Tipo de pessoa',
        searchType: 'match'
      }
    ];

    const provider = this.condo.externalPersonInfoData.integrationType;
    if (provider === 'BLINDADO') {
      this.providerUrl = environment.blindadoUrl;
    }

    this.searchToken.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(500)).subscribe(() => {
      this.getData();
    });
  }

  ngOnInit() {
    this.tableColumns = [
      { columnId: 'name', headerLabel: 'Nome', valueKey: 'name', sortable: true },
      { columnId: 'cpf', headerLabel: 'CPF', valueTemplate: this.cpfTemplate, sortable: true },
      { columnId: 'date', headerLabel: 'Data da consulta', valueKey: 'date', sortable: true },
      { columnId: 'status', headerLabel: 'Status', valueTemplate: this.statusTemplate, sortable: true },
      {
        columnId: 'type',
        headerLabel: 'Tipo de pessoa',
        valueFn: data => {
          return EXTERNAL_TYPES.find(type => type.value === data.type)?.label || 'Desconhecido';
        },
        sortable: true
      }
    ];

    this.getData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next('');
    this.unsubscribe$.complete();
  }

  getData({ page = 0, token = this.searchToken.value } = {}) {
    this.status = 'SUCCESS';
    const query: EcondosQuery = this.customQuery;

    const { pageSize, sortedColumn, sortOrder } = this.externalPersonInfoTable.getCurrentState();

    query.$page = page;
    query.$limit = pageSize;

    if (typeof sortedColumn === 'string') {
      switch (sortedColumn) {
        case 'date':
          query.$sort = `${sortOrder === 'desc' ? '-' : ''}lastSearchTime`;
          break;
        case 'status':
          query.$sort = `${sortOrder === 'desc' ? '-' : ''}searchHistory.risk`;
          break;
        default:
          query.$sort = `${sortOrder === 'desc' ? '-' : ''}${sortedColumn}`;
          break;
      }
    }

    let observable;
    if (token) {
      observable = this.externalPersonInfoService.getExternalPersonInfoSearchToken(this.condo._id, { ...query }, token);
    } else {
      observable = this.externalPersonInfoService.getExternalPersonInfo(this.condo._id, query);
    }

    observable.subscribe({
      next: (res: { count: number; externalPersonInfos: ExternalPersonInfo[] }) => {
        this.externalPersons = res.externalPersonInfos.map(person => ({
          _id: person._id,
          name: person.name,
          cpf: person.cpf,
          date: moment(person?.lastSearchTime || person?.createdAt)
            .utc()
            .format('DD/MM/YYYY - HH:mm'),
          type: person.type,
          status: person.searchHistory[0]?.risk || 'PROCESSING'
        }));
        this.externalPersonsCount = res.count;
        this.status = 'SUCCESS';
      },
      error: () => {
        this.status = 'ERROR';
      }
    });
  }

  handleRefreshData() {
    const { currentPage } = this.externalPersonInfoTable.getCurrentState();
    this.getData({ page: currentPage - 1 });
  }

  openFilterModal() {
    const initialState = {
      filters: this.filters,
      initialQuery: { ...this.initialQuery },
      callback: ({ query, filters }) => {
        this.countActiveFilters(filters);
        this.filters = filters;
        if (query.status) {
          query['searchHistory.risk'] = query.status;
          delete query.status;
        }
        if (query.lastSearchTime) {
          const date = moment(query.lastSearchTime).utc();
          query.lastSearchTime = { $gte: date.startOf('day').toISOString(), $lte: date.endOf('day').toISOString() };
        }
        this.customQuery = query;
        this.getData();
      }
    };
    this.modalService.show(ModalFilterComponent, { initialState, class: 'modal-md' });
  }

  countActiveFilters(filters: EcondosFilter[]) {
    this.numberOfActiveFilters = filters.reduce((accumulator, currentValue) => {
      if (currentValue.value) {
        return accumulator + 1;
      }

      return accumulator;
    }, 0);
  }

  removeIndividualFilter({ index }) {
    this.filters[index].value = '';
    this.filters[index].valueLabel = '';
    if (this.filters[index].name === 'status') {
      delete this.customQuery['searchHistory.risk'];
    } else {
      delete this.customQuery[this.filters[index].name];
    }

    this.numberOfActiveFilters--;
    this.getData();
  }

  clearFilters({ shouldReload = true } = {}) {
    this.filters = this.filters.map(filter => {
      return {
        ...filter,
        value: '',
        valueLabel: ''
      };
    });
    this.numberOfActiveFilters = 0;
    this.customQuery = { ...this.initialQuery };

    if (shouldReload) {
      this.getData();
    }
  }

  openSearchCPFModal() {
    const initialState = {
      callback: () => {
        this.getData();
      }
    };
    this.modalService.show(SearchExternalPersonInfoModalComponent, {
      initialState,
      class: 'modal-md',
      ignoreBackdropClick: true
    });
  }

  unmaskCPF(externalPersonInfoId: string) {
    this.externalPersonInfoService.getCPFUnmasked(this.condo._id, externalPersonInfoId).subscribe({
      next: res => {
        this.externalPersons = this.externalPersons.map(externalPerson => {
          if (externalPerson._id === externalPersonInfoId) {
            externalPerson.cpf = res.cpf;
          }
          return externalPerson;
        });
      },
      error: err => {
        let message = err?.message || 'Erro ao tentar desmascarar o CPF.';
        this.toastr.error(message);
      }
    });
  }

  clearSearchToken() {
    this.searchToken.setValue('');
  }

  protected readonly PERMISSIONS = PERMISSIONS;
}
