import { CondoService } from '@api/service/condo.service';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Condo } from '@api/model/condo';
import { ContactID } from '@api/model/contact/contact.id';
import { catchError, map } from 'rxjs/operators';

export class AsyncValidators {
  static validateDocumentWithEmail(
    condoService: CondoService,
    condoId: Condo['_id'],
    type: ContactID['type'],
    errorString: string
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const email = control.parent.get('email').value;
      if (!control.value) return of({ [errorString]: true });
      return condoService.validateDocumentWithEmail(condoId, email, control.value, type).pipe(
        map(({ valid }) => (!valid ? { [errorString]: true } : null)),
        catchError(() => of({ [errorString]: true }))
      );
    };
  }
}
