import { Component, OnDestroy } from '@angular/core';
import { User } from '@api/model/user';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { parkingIcon } from 'assets/svg/custom-icons';
import { SessionService } from '@api/service/session.service';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';

@Component({
  templateUrl: 'condo.html',
  selector: 'condo-tabs'
})
export class CondoPageComponent implements OnDestroy {
  public condo;
  public user: User;
  public selectedTabIndex;
  public TABS;

  private unsubscribe: Subject<void> = new Subject();

  isBusinessCondo = false;

  public parkingIcon = parkingIcon;

  constructor(
    private router: Router,
    private sessionService: SessionService
  ) {
    const user = this.sessionService.userValue;
    this.user = user;
    this.condo = this.sessionService.condoValue;
    this.isBusinessCondo = this.condo?.isBusinessCondo();
    this.defineTabs();

    this.sessionService.user$.pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      // mode will be null the first time it is created, so you need to igonore it when null
      if (user !== null) {
        this.user = user;
        this.condo = this.user.defaultCondo;
        this.defineTabs();
      }
    });

    this.setSelectedTabBasedOnURL();
    // Listen router events and update tabs selection
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe)
      )
      .subscribe((event: any) => {
        this.setSelectedTabBasedOnURL(event.url);
      });
  }

  defineTabs() {
    this.TABS = {
      CONDO: {
        index: 0,
        enabled: true
      },
      RESIDENCES: {
        index: 1,
        enabled: this.user.isGatekeeper() || this.user.isOwner() || this.user.isAdmin()
      },
      RESIDENTS: {
        index: 2,
        enabled:
          (this.user.isGatekeeper() || this.user.isOwner() || this.user.isAdmin()) &&
          this.user.getPermissionValue({ condoId: this.condo._id, permission: PERMISSIONS.users.view })
      },
      VEHICLES: {
        index: 3,
        enabled: this.user.isGatekeeper() || this.user.isOwner() || this.user.isAdmin()
      },
      PARKINGS: {
        index: 4,
        enabled: this.user.isGatekeeper() || this.user.isOwner() || this.user.isAdmin()
      },
      DEPENDENTS: {
        index: 5,
        enabled: !this.condo.isDependentsDisabled() && (this.user.isGatekeeper() || this.user.isOwner() || this.user.isAdmin())
      },
      PETS: {
        index: 6,
        enabled: !this.condo.isPetsDisabled() && (this.user.isGatekeeper() || this.user.isOwner() || this.user.isAdmin())
      },
      SUPPLIERS: {
        index: 7,
        enabled: this.user.isOwner() || this.user.isAdmin()
      },
      CONSTRUCTIONS: {
        index: 8,
        enabled: !this.condo.isConstructionsDisabled() && (this.user.isGatekeeper() || this.user.isOwner() || this.user.isAdmin())
      },
      CONFIG: {
        index: 9,
        enabled: (this.user.isOwner() || this.user.isAdmin()) && this.condo.selfConfig === 'ENABLED'
      },
      PLANS: {
        index: 10,
        enabled: this.user.isSystemAdmin
      },
      INTEGRATIONS: {
        index: 11,
        enabled: this.user.isSystemAdmin
      }
    };
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setSelectedTabBasedOnURL(url = this.router.url) {
    if (url.includes('residences')) {
      this.selectedTabIndex = this.TABS.RESIDENCES.index;
    } else if (url.includes('residents')) {
      this.selectedTabIndex = this.TABS.RESIDENTS.index;
    } else if (url.includes('suppliers')) {
      this.selectedTabIndex = this.TABS.SUPPLIERS.index;
    } else if (url.includes('dependents')) {
      this.selectedTabIndex = this.TABS.DEPENDENTS.index;
    } else if (url.includes('pets')) {
      this.selectedTabIndex = this.TABS.PETS.index;
    } else if (url.includes('constructions')) {
      this.selectedTabIndex = this.TABS.CONSTRUCTIONS.index;
    } else if (url.includes('vehicles')) {
      this.selectedTabIndex = this.TABS.VEHICLES.index;
    } else if (url.includes('parking-spots')) {
      this.selectedTabIndex = this.TABS.PARKINGS.index;
    } else if (url.includes('import')) {
      this.selectedTabIndex = this.TABS.IMPORT.index;
    } else if (url.includes('settings')) {
      this.selectedTabIndex = this.TABS.CONFIG.index;
    } else if (url.includes('plans')) {
      this.selectedTabIndex = this.TABS.PLANS.index;
    } else if (url.includes('integrations')) {
      this.selectedTabIndex = this.TABS.INTEGRATIONS.index;
    } else {
      this.selectedTabIndex = this.TABS.CONDO.index;
    }
  }

  navigate(route) {
    if (!this.router.url.includes(route)) {
      this.router.navigate([`condo/${route}`]);
    }
  }

  onTabSelected(event) {
    this.selectedTabIndex = parseInt(this.selectedTabIndex, 10);

    switch (this.selectedTabIndex) {
      case this.TABS.CONDO.index:
        this.navigate('condos');
        break;
      case this.TABS.RESIDENCES.index:
        this.navigate('residences');
        break;
      case this.TABS.RESIDENTS.index:
        this.navigate('residents');
        break;
      case this.TABS.VEHICLES.index:
        this.navigate('vehicles');
        break;
      case this.TABS.PARKINGS.index:
        this.navigate('parking-spots');
        break;
      case this.TABS.DEPENDENTS.index:
        this.navigate('dependents');
        break;
      case this.TABS.CONSTRUCTIONS.index:
        this.navigate('constructions');
        break;
      case this.TABS.PETS.index:
        this.navigate('pets');
        break;
      case this.TABS.SUPPLIERS.index:
        this.navigate('suppliers');
        break;
      case this.TABS.CONFIG.index:
        this.navigate('settings');
        break;
      case this.TABS.PLANS.index:
        this.navigate('plans');
        break;
      case this.TABS.INTEGRATIONS.index:
        this.navigate('integrations');
        break;
    }
  }
}
