import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Subscription, from, lastValueFrom, Subject, forkJoin, of, switchMap } from 'rxjs';
import { Residence } from '@api/model/interface/residence';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { Device, DEVICE_STATUS } from '@api/model/hardware/device';
import swal from 'sweetalert2';
import { timeout, retry, tap, takeUntil, debounceTime, map } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { ModalAddHardwareDeviceComponent } from '../../hardware/modal-add-hardware-device/modal-add-hardware-device.component';
import { ModalCreateControlIdDeviceComponent } from 'app/hardware/modal-create-controlid-device/modal-create-controlid-device.component';
import { ModalCreateHikvisionDeviceComponent } from 'app/hardware/modal-create-hikvision-device/modal-create-hikvision-device.component';
import { HardwareCreateService } from 'app/services/hardware-create.service';
import { ModalCreateUtechDeviceComponent } from '../../hardware/modal-create-utech-device/modal-create-utech-device.component';
import { ModalCreateIntelbrasIncontrolDeviceComponent } from '../../hardware/modal-create-intelbras-incontrol-device/modal-create-intelbras-incontrol-device.component';
import { IntelbrasIncontrolService } from '@api/service/hardware/intelbras-incontrol.service';
import { Router } from '@angular/router';
import { ModalCreateAlphadigiDeviceComponent } from '../../hardware/modal-create-alphadigi-device/modal-create-alphadigi-device.component';
import { ModalCreateNiceControllerDeviceComponent } from '../../hardware/modal-create-nice-controller-device/modal-create-nice-controller-device.component';
import { ModalCreateIntelbrasStandAloneDeviceComponent } from '../../hardware/modal-create-intelbras-stand-alone-device/modal-create-intelbras-stand-alone-device.component';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { ModalCreateZktecoDeviceComponent } from '../../hardware/modal-create-zkteco-device/modal-create-zkteco-device.component';
import { ModalCreateDeviceComponent } from '../../hardware/modal-create-device/modal-create-device.component';
import { ModalCreateGarenDeviceComponent } from '../../hardware/modal-create-garen-device/modal-create-garen-device.component';
import { DEVICE_TYPES_LABEL } from '@api/model/hardware/hardware-constants';
import { TableColumnDefinition, TableComponent, TableStatus } from '../table/table.component';
import { FormControl } from '@angular/forms';
import { removeAccents, replaceVowelsToAccentedVowels } from '@api/util/util';
import { CondoService } from '@api/service/condo.service';
import { DependentService } from '@api/service/dependent.service';
import { UserLocalSettingsService } from '@api/serviceV2/user-local-settings.service';

@Component({
  selector: 'app-device-list',
  templateUrl: 'device-list.component.html',
  styleUrls: ['device-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceListComponent implements OnInit, OnChanges, OnDestroy {
  // residence faz busca por unidade e device user faz busca por usuario
  @Input()
  residence: Residence;
  @Input()
  condo: Condo;
  @Input()
  user: User;
  @Input()
  selectedUser: User;
  @Input()
  container = 'body';
  @Input()
  isVisibleHeader = true;
  @Input()
  isVisibleSearchInput = true;

  @ViewChild('devicesTemplate', { static: true }) devicesTemplate: TemplateRef<Device>;
  @ViewChild('headerButtomTemplate', { static: true }) headerButtomTemplate: TemplateRef<Device>;
  @ViewChild('devicesTable', { static: true }) devicesTable: TableComponent;

  status: TableStatus = 'LOADING';
  openIntelbrasModal;

  devices: Device[] = [];
  DEVICE_TYPES_LABEL = DEVICE_TYPES_LABEL;

  subscription: Subscription = new Subscription();
  public unsubscribe$ = new Subject();
  public searchToken = new FormControl('');
  tableColumns: TableColumnDefinition<Device>[] = [];
  countData: number = 0;
  permissions = {
    canRegister: false,
    canEdit: false,
    canDelete: false
  };

  constructor(
    private toastr: ToastrService,
    private routerCtrl: Router,
    private deviceService: HardwareDeviceService,
    private hardwareCreateService: HardwareCreateService,
    private cdr: ChangeDetectorRef,
    private intelbrasIncontrolService: IntelbrasIncontrolService,
    private modalService: BsModalService,
    private condoService: CondoService,
    private dependentService: DependentService,
    private userLocalSettingsService: UserLocalSettingsService
  ) {
    this.searchToken.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(500)).subscribe(token => {
      this.getData(this.condo, this.residence || null, null, { token });
    });
  }

  ngOnInit() {
    this.tableColumns = [
      {
        columnId: 'device',
        headerTemplate: this.headerButtomTemplate,
        valueTemplate: this.devicesTemplate,
        sortable: false
      }
    ];
    this.subscription = this.hardwareCreateService.onDeviceCreated.subscribe(async (device: Device) => {
      const deviceUpdated = await this.getSingleDevice(device._id);
      this.devices = [].concat(deviceUpdated, this.devices);
      this.countData = +this.countData + 1;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condo && changes.user) {
      const user: User = changes.user.currentValue;
      const condo = changes.condo.currentValue;
      const isAdminOrOwner = user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id);
      const isGatekeeper = user.isGatekeeperOnCondo(condo._id);
      this.updatePermissions(isAdminOrOwner, isGatekeeper);
    }
    if (changes.condo?.currentValue) {
      // verifica se tem deviceUser ou residence - os dois não serão usados no mesmo momento
      if (changes.residence?.currentValue) {
        this.getData(changes.condo.currentValue, changes.residence.currentValue);
      } else if (changes.selectedUser?.currentValue) {
        this.getData(changes.condo.currentValue, null, changes.selectedUser.currentValue);
      }
    } else if (!changes.condo?.currentValue && changes.selectedUser?.currentValue) {
      this.getData(this.condo, null, changes.selectedUser.currentValue);
    }
  }

  updatePermissions(isAdminOrOwner, isGatekeeper) {
    if (isAdminOrOwner) {
      this.permissions = { canRegister: true, canEdit: true, canDelete: true };
    } else if (isGatekeeper) {
      this.permissions = {
        canRegister: this.condo.hardwareParams.gatekeeperCanRegisterDevice,
        canEdit: this.condo.hardwareParams.gatekeeperCanEditDevice,
        canDelete: this.condo.hardwareParams.gatekeeperCanDeleteDevice
      };
    } else {
      this.permissions = { canRegister: false, canDelete: false, canEdit: false };
    }
  }

  getData(condo, residence, user = null, { page = 0, token = this.searchToken.value } = {}) {
    this.status = 'LOADING';
    this.cdr.detectChanges();
    let observable$ = of([]);
    const { pageSize } = this.devicesTable.getCurrentState();
    const deviceQuery: EcondosQuery = {
      $populate: [
        {
          path: 'owner.user',
          select: 'firstName lastName',
          populate: [{ path: 'picture', select: 'url thumbnail type format name' }]
        },
        { path: 'owner.dependent', select: 'name' },
        { path: 'owner.condoVehicle', select: 'plate model color brand' },
        { path: 'owner.picture', select: 'url thumbnail type format name' },
        { path: 'owner.residence', select: 'identification type block lot number' },
        { path: 'actuators', select: 'name username password host port host2 port2' },
        { path: 'accessGroups', select: 'name' },
        { path: 'owner', select: 'userName' }
      ],
      $sort: '-createdAt',
      $page: page,
      $limit: pageSize,
      accessType: 'RESIDENT'
    };
    if (residence) {
      deviceQuery['owner.residence'] = residence._id;
    }
    if (user) {
      deviceQuery['owner.user'] = user._id;
    }
    if (token) {
      const terms = token
        .split(' ')
        .map(word => removeAccents(word))
        .map(word => replaceVowelsToAccentedVowels(word));

      const dependentName = [];
      terms.forEach(term => {
        dependentName.push({
          $or: [{ name: { $regex: term, $options: 'i' } }]
        });
      });

      const residentName = [];
      terms.forEach(term => {
        residentName.push({
          $or: [{ firstName: { $regex: term, $options: 'i' } }, { lastName: { $regex: term, $options: 'i' } }]
        });
      });

      let usersQuery: EcondosQuery = {
        $populate: [
          { path: 'picture', select: 'thumbnail url type' },
          { path: 'residencesUser', select: 'identification' }
        ],
        $limit: pageSize,
        residencesUser: this.residence._id
      };
      usersQuery.$and = residentName;

      let dependentQS: EcondosQuery = {
        $populate: [
          { path: 'picture', select: 'thumbnail url type' },
          { path: 'residence', select: 'identification' }
        ],
        $limit: pageSize,
        residence: this.residence._id
      };

      dependentQS.$and = dependentName;
      observable$ = forkJoin([
        this.condoService.getCondoResidents(this.condo._id, usersQuery),
        this.dependentService.getDependents(this.condo._id, dependentQS)
      ]).pipe(
        tap(([usersResponse, dependentsResponse]) => {
          const { users } = usersResponse;
          const { dependents } = dependentsResponse;
          const usersResult = users.map(user => user._id);
          const dependentsResult = dependents.map(user => user._id);
          if (usersResult.length || dependentsResult.length) {
            if (!deviceQuery.$or) {
              deviceQuery.$or = [];
            }
            if (usersResult.length) {
              deviceQuery.$or.push({ 'owner.user': { $in: usersResult } });
            }
            if (dependentsResult.length) {
              deviceQuery.$or.push({ 'owner.dependent': { $in: dependentsResult } });
            }
          }
        })
      );
    }
    observable$
      .pipe(
        switchMap(() => {
          return this.deviceService.get(condo._id, deviceQuery).pipe(timeout(10000));
        })
      )
      .subscribe(
        devicesResponse => {
          this.devices = devicesResponse.devices || [];
          this.status = 'SUCCESS';
          this.countData = devicesResponse.count;
          this.cdr.detectChanges();
        },
        err => {
          this.status = 'ERROR';
          this.cdr.detectChanges();
          console.log(err);
        }
      );
  }

  retry() {
    if (this.condo && this.residence) {
      this.getData(this.condo, this.residence);
    }
  }

  create() {
    this.hardwareCreateService.createDevice(this.selectedUser, this.residence);
  }

  delete(device) {
    let text;
    if (device.type === 'SN') {
      text = `Deseja remover senha?`;
    } else {
      text = `Deseja remover o dispositivo de serial ${device.serial} ?`;
    }
    let swalConfig;
    if (device.status !== 'SYNCED') {
      swalConfig = {
        type: 'warning',
        title: 'Tem certeza que deseja excluir esse dispositivo?',
        text: 'Excluir um dispositivo não sincronizado pode causar inconsistências nos equipamentos, o dispositivo deve ser sincronizado antes de ser excluido. Digite "excluir dispositivo" para confirmar sua ação.',
        showCancelButton: true,
        input: 'text',
        inputPlaceholder: 'excluir dispositivo',
        confirmButtonText: 'Excluir dispositivo',
        confirmButtonColor: '#f53d3d',
        cancelButtonClass: 'btn-outline-danger',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async input => {
          input = (input || '').toString().trim().toLowerCase();
          if (!input || input !== 'excluir dispositivo') {
            return Promise.reject(`Digite "excluir dispositivo" para confirmar sua ação`);
          }
          return this.deleteDevice(device);
        }
      };
    } else {
      swalConfig = {
        text,
        type: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          return this.deleteDevice(device);
        }
      };
    }
    swal(swalConfig).then(
      result => {
        if (result) {
          this.toastr.success('Dispositivo removido com sucesso');
          const index = this.devices.findIndex(d => d._id === device._id);
          this.devices.splice(index, 1);
          this.devices = [].concat(this.devices);
          this.countData = this.countData - 1;
          this.cdr.detectChanges();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  deleteDevice(device: Device) {
    return lastValueFrom(this.deviceService.delete(this.condo._id, device).pipe(timeout(10000))).catch(err => {
      console.log(err);
      let message: string;
      if (typeof err === 'string') {
        message = err;
      } else {
        message = err?.message || 'Não foi possível excluir o dispositivo, tente novamente...';
      }
      return Promise.reject(message);
    });
  }

  askToSync(device: Device) {
    let title;
    if (device.type === 'SN') {
      title = `Sincronizar senha?`;
    } else {
      title = `Sincronizar dispositivo ${device.serial}`;
    }
    swal({
      title,
      type: 'question',
      text: `Deseja realmente sincronizar este dispositivo?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.deviceService
          .sync(this.condo._id, device._id)
          .pipe(
            timeout(30000),
            tap((syncedDevice: Device) => {
              let index = this.devices.findIndex(d => d._id === device._id);
              this.devices[index] = { ...this.devices[index], status: syncedDevice.status };
              this.devices = [].concat(this.devices);
              this.cdr.detectChanges();
            })
          )
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível sincronizar o dispositivo, tente novamente...');
          });
      }
    }).then(
      () => {},
      error => {
        console.log(error);
      }
    );
  }

  editDevice(device: Device) {
    const callback = async updatedDevice => {
      updatedDevice = await this.getSingleDevice(device._id);
      const index = this.devices.findIndex(d => d._id === updatedDevice._id);
      this.devices[index] = new Device({ ...updatedDevice, status: DEVICE_STATUS.UNSYNCED });
      this.devices = [].concat(this.devices);
      this.status = 'SUCCESS';
      this.cdr.detectChanges();
    };
    const initialState = {
      condo: this.condo,
      device,
      callbacks: {
        success: callback
      }
    };
    switch (device.hardware) {
      case 'LINEAR': {
        this.modalService.show(ModalAddHardwareDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case 'CONTROL_ID': {
        this.modalService.show(ModalCreateControlIdDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case 'HIKVISION': {
        const hasAccessGroups = !!device.accessGroups?.length;
        const useDeprecatedHikvision = this.userLocalSettingsService.getSetting('useDeprecatedHikvision');

        const openDeprecatedHikvisionModal = () => {
          const initialState = {
            device,
            condo: this.condo,
            callbacks: {
              success: () => {
                callback;
              },
              onUseNewHikvisionButtonClick: () => {
                openNewHikvisionModal();
              }
            }
          };
          this.modalService.show(ModalCreateHikvisionDeviceComponent, {
            initialState,
            class: 'modal-lg',
            ignoreBackdropClick: true
          });
        };

        const openNewHikvisionModal = () => {
          this.modalService.show(ModalCreateDeviceComponent, {
            initialState: {
              device,
              callback: () => callback,
              onUseDeprecatedHikvisionButtonClick: () => {
                openDeprecatedHikvisionModal();
              }
            },
            class: 'modal-lg',
            ignoreBackdropClick: true
          });
        };

        if (hasAccessGroups && useDeprecatedHikvision) {
          openNewHikvisionModal();
        } else {
          openDeprecatedHikvisionModal();
        }
        break;
      }
      case 'UTECH': {
        this.modalService.show(ModalCreateUtechDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case 'INTELBRAS': {
        this.openIntelbrasModal = 'LOADING';
        if (this.intelbrasIncontrolService.connected) {
          from(this.intelbrasIncontrolService.getGroups())
            .pipe(timeout(10000))
            .subscribe(
              res => {
                initialState['groups'] = res;
                this.openIntelbrasModal = 'SUCCESS';
                this.cdr.detectChanges();
                this.modalService.show(ModalCreateIntelbrasIncontrolDeviceComponent, {
                  initialState,
                  class: 'modal-lg',
                  ignoreBackdropClick: true
                });
              },
              err => {
                this.incontrolError();
              }
            );
        } else {
          this.incontrolError();
        }
        break;
      }
      case 'ALPHADIGI':
      case 'ALPHADIGI_LPR': {
        this.modalService.show(ModalCreateAlphadigiDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case 'NICE_CONTROLLER': {
        this.modalService.show(ModalCreateNiceControllerDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case 'INTELBRAS_STAND_ALONE': {
        this.modalService.show(ModalCreateIntelbrasStandAloneDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case HARDWARES.GAREN: {
        this.modalService.show(ModalCreateGarenDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case HARDWARES.ZKTECO: {
        this.modalService.show(ModalCreateZktecoDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case HARDWARES.ANY:
      default: {
        this.modalService.show(ModalCreateDeviceComponent, {
          initialState: { device, callback },
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
    }
  }

  incontrolError() {
    this.openIntelbrasModal = 'ERROR';
    this.cdr.detectChanges();
    swal({
      type: 'error',
      showCancelButton: true,
      cancelButtonText: 'Ver configurações',
      cancelButtonColor: 'var(--green-500)',
      title: `Ops...`,
      text: `Não foi possível conectar ao servidor Incontrol. Verifique as informações na tela de configurações do(a) ${this.condo?.customLabels?.condo?.singular} e tente novamente`
    })
      .then(() => {})
      .catch(err => {
        if (err === 'cancel') {
          this.routerCtrl.navigate(['hardware', 'config']);
        }
      });
  }

  async getSingleDevice(deviceId: string): Promise<Device> {
    const qs: EcondosQuery = {
      $populate: [
        'hardwareParams',
        {
          path: 'owner.residence',
          select: 'identification type block lot number users',
          populate: [{ path: 'users', select: 'firstName lastName name' }]
        },
        {
          path: 'owner.user',
          select: 'firstName lastName hardwareParams id',
          populate: [{ path: 'picture', select: 'url thumbnail type format name' }]
        },
        { path: 'owner.dependent', select: 'name firstName lastName id' },
        { path: 'owner.condoVehicle', select: 'plate model color brand' },
        { path: 'owner.picture', select: 'url thumbnail type format name' },
        { path: 'actuators', select: 'name username password host port host2 port2 hardware condo' },
        {
          path: 'accessGroups',
          select: 'name actuators timezone',
          populate: [
            { path: 'actuators', select: 'name type username password host port host2 port2 hardware condo' },
            { path: 'timezone', select: 'name sequenceId' }
          ]
        }
      ]
    };

    const device: Device = await this.deviceService.getById(this.condo._id, deviceId, qs).pipe(timeout(10000), retry(3)).toPromise();
    return device;
  }
}
