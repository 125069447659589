import { NgModule } from '@angular/core';
import { AppSuggestionComponent } from './app.suggestion.panel/app.suggestion.panel';
import { UploadButton } from './upload-button';
import { OccurrenceFileUploader } from '../pages/occurrence.creator/occurrence.creator.file.list/files.scroll';
import { LightboxComponent } from './lightbox/lightbox';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChipComponent } from './chip/chip.component';
import { TagInputComponent } from './tag-input/tag-input.component';
import { CondoContactPickerComponent } from './condo-contact-picker/condo-contact-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './spinner/spinner.component';
import { GlobalSearchComponent } from './global-search/global-search';
import { DeviceSelectComponent } from './device-select/device-select.component';
import { ImagePickerComponent } from './image-picker/image-picker';
import { DependentListComponent } from './dependent-list/dependent-list';
import { PetListComponent } from './pet-list/pet-list';
import { VehicleListComponent } from './vehicle-list/vehicle-list';
import { MomentModule } from 'ngx-moment';
import { ActuatorsPanelComponent } from './actuators-panel/actuators-panel.component';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { ResidencesAutocompleteComponent } from '../pages/occurrence.creator/private/residence.autocomplete';
import { ModalWebCam } from '../pages/modal/webcam/webcam.modal';
import { ResidenceDocumentsListComponent } from './residence-documents-list/residence-documents-list.component';
import { ThemeModule } from '../theme';
import { WebcamModule } from 'ngx-webcam';
import { ModalPreviewWebcamComponent } from './image-picker/modal-preview-webcam/modal-preview-webcam.component';
import { ModalPreviewCCTVComponent } from './image-picker/modal-preview-cctv/modal-preview-cctv.component';
import { ModalEditUserComponent } from './modal-edit-user/modal-edit-user.component';
import { DragAndDropFileDirective } from './drag-and-drop-file-directive/drag-and-drop-file.directive';
import { FileExplorerComponent } from './file-explorer/file-explorer.component';
import { ContextMenuModule, ContextMenuService } from '@perfectmemory/ngx-contextmenu';
import { DeviceListComponent } from './device-list/device-list.component';
import { SelectResidenceInputComponent } from './select-residence-input/select-residence-input.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalActuateActuatorComponent } from './modal-actuate-actuator/modal-actuate-actuator.component';
import { ModalActuateActuatorsRouteComponent } from './modal-actuate-actuator-routes/modal-actuate-actuators-routes.component';
import { ResidentAutoCompleteComponent } from './resident-auto-complete/resident-auto-complete.component';
import { VehicleBrandAutoCompleteComponent } from './vehicle-brand-auto-complete/vehicle-brand-auto-complete.component';
import { DisableHtmlFormDirective } from './disable-html-form-directive/disable-html-form.directive';
import { ModalSelectUserComponent } from './modal-select-user/modal-select-user.component';
import { ModalSelectAccessGroup } from './modal-select-access-group/modal-select-access-group.component';
import { PipesModule } from 'app/pipe/pipes.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ResidenceAutoCompleteComponent } from './residence-auto-complete/residence-auto-complete.component';
import { ResidenceAutocompleteComponent } from './residence-autocomplete/residence-autocomplete.component';
import { ModalFilterComponent } from './modal-filter/modal-filter.component';
import { VisitorPickerComponent } from './visitor-picker/visitor-picker';
import { VehiclesPickerComponent } from './vehicles-picker/vehicles-picker.component';
import { DirectivesModule } from 'app/directives/directives.module';
import { ActiveFiltersComponent } from './active-filters/active-filters.component';
import { ModalCreateDevicesRequestComponent } from './modal-create-devices-request/modal-create-devices-request.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ModalGenerateQrcodeComponent } from './modal-generate-qrcode/modal-generate-qrcode.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ModalCreateParkingSpot } from './modal-create-parking-spot/modal-create-parking-spot';
import { ModalCondoCustomLabelsComponent } from './modal-condo-custom-labels/modal-condo-custom-labels.component';
import { ModalDeviceSyncInformationComponent } from './modal-device-sync-information/modal-device-sync-information.component';
import { GroupsPickerComponent } from './groups-picker/groups-picker.component';
import { ClipboardModule } from 'ngx-clipboard';
import { SelectAccessGroupActuatorsComponent } from './select-access-group-actuators/select-access-group-actuators.component';
import { ModalSelectCondoContactComponent } from './modal-select-condo-contact/modal-select-condo-contact.components';
import { ModalShowDataViewersComponent } from './modal-show-data-viewers/modal-show-data-viewers.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ResidenceEmergencyContactList } from './residence-emergency-contact-list/residence-emergency-contact-list';
import { ModalCreateEmergencyContact } from './modal-create-emergency-contact/modal-create-emergency-contact';
import { ButtonSelectComponent } from './button-select/button-select.component';
import { DependentAutoCompleteComponent } from './dependent-auto-complete/dependent-auto-complete.component';
import { MultiSelectEditorComponent } from './multi-select-renderer/multi-select-editor.component';
import { ModalUseTermsComponent } from './modal-terms-of-service/modal-use-terms.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FilePreviewOverlayComponent } from './file-preview/file-preview-overlay.component';
import { FilePreviewOverlayToolbarComponent } from './file-preview/file-preview-overlay-toolbar/file-preview-overlay-toolbar.component';
import { HttpCameraViewerComponent } from './camera-viewer/http-camera-viewer/http-camera-viewer.component';
import { RtspCameraViewerComponent } from './camera-viewer/rtsp-camera-viewer/rtsp-camera-viewer.component';
import { CameraViewerComponent } from './camera-viewer/camera-viewer.component';
import { ModalImageCropperComponent } from './modal-image-cropper/modal-image-cropper.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { FeedbackContainerComponent } from './feedback-container/feedback-container.component';
import { ConstructionResponsibleFieldComponent } from './responsible-field/construction-responsible-field.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ResidenceConstructionsListComponent } from './residence-constructions-list/residence-constructions-list.component';
import { AuthorizedPeopleListComponent } from './authorized-people-list/authorized-people-list.component';
import { ModalCreateAuthorizedPersonComponent } from './modal-create-authorized-person/modal-create-authorized-person.component';
import { DaysAllowedPickerComponent } from './days-allowed-picker/days-allowed-picker.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AuthorizedPersonAutoCompleteComponent } from './modal-create-authorized-person/authorized-person-auto-complete/authorized-person-auto-complete.component';
import { ModalSelectDuplicatedCondoContactComponent } from './modal-select-duplicated-condo-contact/modal-select-duplicated-condo-contact.component';
import { DahuaRtspCameraViewerComponent } from './camera-viewer/dahua-rtsp-camera-viewer/dahua-rtsp-camera-viewer.component';
import { VehicleColorAutoCompleteComponent } from './vehicle-color-auto-complete/vehicle-color-auto-complete.component';
import { ModalGateNotebookChecklistComponent } from '../pages/gate/gate-notebook/modal-gate-notebook-checklist/modal-gate-notebook-checklist.component';
import { PersonAutoCompleteComponent } from './person-auto-complete/person-auto-complete.component';
import { TitleComponent } from './title/title.component';
import { RouterModule } from '@angular/router';
import { ModalBulkAccessGroupAssignmentComponent } from './modal-bulk-access-group-assignment/modal-bulk-access-group-assignment.component';
import { AppInputComponent } from './app-input/app-input.component';
import { CalendarReservationLinksComponent } from './app-calendar-reservation-links/app-calendar-reservation-links.component';
import { TableComponent } from './table/table.component';
import { ModalVisitorPlatesEditorComponent } from './modal-visitor-plates-editor/modal-visitor-plates-editor.component';
import { AppliedFiltersComponent } from './applied-filters/applied-filters.component';
import { EcameraCameraViewerComponent } from './camera-viewer/ecamera-camera-viewer/ecamera-camera-viewer.component';
import { ReceiptListComponent } from './receipt-list/receipt-list.component';
import { ModalPermissionDetailsComponent } from './modal-permission-details/modal-permission-details.component';
import { ModalShareLinkComponent } from './modal-share-link/modal-share-link';
import { NoteListComponent } from './note-list/note-list';
import { VehicleAutoCompleteComponent } from './vehicle-auto-complete/vehicle-auto-complete.component';
import { ResidentListComponent } from './resident-list/resident-list';
import { IconifyComponent } from '../web-components/iconify/iconify.component';
import { HasPermissionDirective } from '../directives/has-permission.directive';
import { ModalMultiSelectionComponent } from './modal-multi-selection/modal-multi-selection.component';
import { InQueueReservationsListModalComponent } from '../pages/reservation/history/in-queue-reservations-list-modal/in-queue-reservations-list-modal.component';
import { ModalAddNoteComponent } from './modal-add-note/modal-add-note.component';

@NgModule({
  declarations: [
    AppSuggestionComponent,
    UploadButton,
    OccurrenceFileUploader,
    LightboxComponent,
    ChipComponent,
    ActiveFiltersComponent,
    TagInputComponent,
    CondoContactPickerComponent,
    VisitorPickerComponent,
    SpinnerComponent,
    GlobalSearchComponent,
    DeviceSelectComponent,
    ModalPreviewWebcamComponent,
    ModalPreviewCCTVComponent,
    ImagePickerComponent,
    DependentListComponent,
    ResidentListComponent,
    PetListComponent,
    VehicleListComponent,
    ModalCreateParkingSpot,
    ModalCreateEmergencyContact,
    ResidenceDocumentsListComponent,
    ResidenceEmergencyContactList,
    ResidencesAutocompleteComponent,
    ModalWebCam,
    ActuatorsPanelComponent,
    ModalEditUserComponent,
    DragAndDropFileDirective,
    FileExplorerComponent,
    DeviceListComponent,
    ModalActuateActuatorComponent,
    ModalActuateActuatorsRouteComponent,
    SelectResidenceInputComponent,
    ResidentAutoCompleteComponent,
    ResidenceAutoCompleteComponent,
    VehicleBrandAutoCompleteComponent,
    DisableHtmlFormDirective,
    ModalSelectUserComponent,
    ModalSelectAccessGroup,
    ModalCondoCustomLabelsComponent,
    VehiclesPickerComponent,
    GroupsPickerComponent,
    ModalFilterComponent,
    ModalCreateDevicesRequestComponent,
    ModalGenerateQrcodeComponent,
    ModalDeviceSyncInformationComponent,
    SelectAccessGroupActuatorsComponent,
    ModalSelectCondoContactComponent,
    ModalSelectDuplicatedCondoContactComponent,
    ModalShowDataViewersComponent,
    ButtonSelectComponent,
    MultiSelectEditorComponent,
    ButtonSelectComponent,
    DependentAutoCompleteComponent,
    ModalUseTermsComponent,
    FilePreviewOverlayComponent,
    FilePreviewOverlayToolbarComponent,
    HttpCameraViewerComponent,
    RtspCameraViewerComponent,
    DahuaRtspCameraViewerComponent,
    EcameraCameraViewerComponent,
    CameraViewerComponent,
    ModalImageCropperComponent,
    AuthorizedPeopleListComponent,
    ModalShareLinkComponent,
    ModalCreateAuthorizedPersonComponent,
    DaysAllowedPickerComponent,
    AuthorizedPersonAutoCompleteComponent,
    ResidenceConstructionsListComponent,
    ConstructionResponsibleFieldComponent,
    VehicleColorAutoCompleteComponent,
    PersonAutoCompleteComponent,
    VehicleAutoCompleteComponent,
    TitleComponent,
    ResidenceAutocompleteComponent,
    ModalBulkAccessGroupAssignmentComponent,
    AppInputComponent,
    CalendarReservationLinksComponent,
    ModalVisitorPlatesEditorComponent,
    ModalGateNotebookChecklistComponent,
    ReceiptListComponent,
    ModalPermissionDetailsComponent,
    NoteListComponent,
    InQueueReservationsListModalComponent,
    ModalAddNoteComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    TypeaheadModule,
    TooltipModule,
    BsDropdownModule,
    ButtonsModule,
    MomentModule,
    ThemeModule,
    DragDropModule,
    WebcamModule,
    ContextMenuModule,
    PipesModule,
    ImageCropperModule,
    DirectivesModule,
    QRCodeModule,
    ClipboardModule,
    NgxPaginationModule,
    TabsModule,
    AngularSvgIconModule,
    FontAwesomeModule,
    PopoverModule,
    TimepickerModule,
    BsDatepickerModule,
    RouterModule,
    TableComponent,
    FeedbackContainerComponent,
    SkeletonComponent,
    AppliedFiltersComponent,
    NgOptimizedImage,
    IconifyComponent,
    HasPermissionDirective,
    ModalMultiSelectionComponent
  ],
  exports: [
    EcameraCameraViewerComponent,
    AppSuggestionComponent,
    UploadButton,
    OccurrenceFileUploader,
    LightboxComponent,
    ChipComponent,
    ActiveFiltersComponent,
    TagInputComponent,
    CondoContactPickerComponent,
    VisitorPickerComponent,
    SpinnerComponent,
    GlobalSearchComponent,
    DeviceSelectComponent,
    ImagePickerComponent,
    DependentListComponent,
    ResidentListComponent,
    PetListComponent,
    VehicleListComponent,
    ResidenceDocumentsListComponent,
    ResidenceEmergencyContactList,
    ResidencesAutocompleteComponent,
    ModalWebCam,
    ActuatorsPanelComponent,
    ModalCondoCustomLabelsComponent,
    DragAndDropFileDirective,
    FileExplorerComponent,
    DeviceListComponent,
    SelectResidenceInputComponent,
    ModalActuateActuatorComponent,
    ModalActuateActuatorsRouteComponent,
    ResidentAutoCompleteComponent,
    ResidenceAutoCompleteComponent,
    VehicleBrandAutoCompleteComponent,
    DisableHtmlFormDirective,
    ModalSelectUserComponent,
    ModalSelectAccessGroup,
    VehiclesPickerComponent,
    GroupsPickerComponent,
    ModalDeviceSyncInformationComponent,
    SelectAccessGroupActuatorsComponent,
    ModalSelectCondoContactComponent,
    ModalSelectDuplicatedCondoContactComponent,
    ModalShowDataViewersComponent,
    ButtonSelectComponent,
    DependentAutoCompleteComponent,
    FilePreviewOverlayComponent,
    CameraViewerComponent,
    ModalImageCropperComponent,
    SkeletonComponent,
    FeedbackContainerComponent,
    ResidenceConstructionsListComponent,
    ConstructionResponsibleFieldComponent,
    AuthorizedPeopleListComponent,
    ModalCreateAuthorizedPersonComponent,
    DaysAllowedPickerComponent,
    VehicleColorAutoCompleteComponent,
    PersonAutoCompleteComponent,
    VehicleAutoCompleteComponent,
    TitleComponent,
    ResidenceAutocompleteComponent,
    ModalBulkAccessGroupAssignmentComponent,
    AppInputComponent,
    CalendarReservationLinksComponent,
    TableComponent,
    ModalVisitorPlatesEditorComponent,
    AppliedFiltersComponent,
    ModalPermissionDetailsComponent,
    ReceiptListComponent,
    NoteListComponent,
    IconifyComponent,
    ModalMultiSelectionComponent,
    InQueueReservationsListModalComponent
  ],
  providers: [ActuatorService, ContextMenuService]
})
export class ComponentsModule {}
