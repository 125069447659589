import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Status } from '@api/model/status';
import { Condo } from '@api/model/condo';
import { GateNotebookService } from '@api/service/gate-notebook.service';
import { User } from '@api/model/user';
import { CondoService } from '@api/service/condo.service';
import { File } from '@api/model/file';
import { ChecklistTemplate } from '@api/model/checklistTemplate';
import { Observable } from 'rxjs';
import { LogbookMessagesService } from '@api/service/logbook-messages.service';
import { Error } from '@api/model/error/error';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../../../components/components.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { AutosizeModule } from 'ngx-autosize';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ThemeModule } from '../../../../theme';

type ChecklistTopic = {
  name: string;
  items: TopicItem[];
};

type TopicItem = {
  status: boolean;
  obs: string;
  name: string;
};

@Component({
  selector: 'app-modal-passagem-de-posto.component',
  templateUrl: 'modal-passagem-de-posto.component.html',
  styleUrls: ['modal-passagem-de-posto.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ModalModule,
    ComponentsModule,
    ButtonsModule,
    AutosizeModule,
    TypeaheadModule,
    ThemeModule
  ],
  standalone: true
})
export class ModalPassagemDePostoComponent implements OnInit {
  TYPES = {
    ENTRANCE: 'POST_SHIFT_ENTRANCE',
    EXIT: 'POST_SHIFT_EXIT'
  };

  condo: Condo;
  user: User;
  checklistsTemplates: ChecklistTemplate[] = [];
  logbookId: string;

  condoGatekeepers: string[] = [];

  form: UntypedFormGroup;
  type: AbstractControl;
  from: AbstractControl;
  to: AbstractControl;
  description: AbstractControl;
  isUploading = false;

  files: File[] = [];

  callbacks: { success?: Function; error?: Function };

  status: Status = new Status();

  checklists: { expanded: boolean; name: string; items: { obs: string; name: string; status: Boolean }[] }[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private gateNotebookService: GateNotebookService,
    private logbookMessagesService: LogbookMessagesService,
    public bsModalRef: BsModalRef,
    private condoService: CondoService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.getData(this.condo);
  }

  private initializeForm() {
    const checklistFormArray = this.checklistsTemplates.map(template =>
      this.addTopic({
        name: template.name,
        items: template.items.map(item => ({ status: false, obs: '', name: item }))
      })
    );

    this.form = this.fb.group({
      type: ['', Validators.required],
      from: [this.user ? this.user.fullName : '', Validators.required],
      to: ['', Validators.required],
      description: ['Sem novidades', Validators.required],
      checklist: this.fb.array(checklistFormArray)
    });
    this.type = this.form.get('type');
    this.from = this.form.get('from');
    this.to = this.form.get('to');
    this.description = this.form.get('description');
  }

  get checklistFormArray() {
    return this.form.get('checklist') as UntypedFormArray;
  }

  getData(condo) {
    this.status.setAsDownloading();
    this.condoService.getUsersFromCondo(this.condo._id, `?condosGatekeeper=${condo._id}`).subscribe(
      gatekeepers => {
        this.condoGatekeepers = gatekeepers.map(u => u.fullName);
        this.status.setAsSuccess();
      },
      err => {
        console.log(err);
        this.condoGatekeepers = [];
        this.checklists = [];
        this.status.setAsError();
      }
    );
  }

  save(value) {
    if (this.form.valid) {
      this.toastrService.success('Passagem registrada com sucesso');

      let message = '';
      if (this.type.value === this.TYPES.ENTRANCE) {
        message += `Eu <b>${this.from.value}</b> recebi o posto de <b>${this.to.value}</b> nas seguintes condições:
      <p style="margin-top: 10px">${this.description.value}</p>`;
      } else {
        message += `Eu <b>${this.from.value}</b> passei o posto para <b>${this.to.value}</b> nas seguintes condições:
      ${this.description.value}`;
      }
      const files = (this.files?.length && this.files?.map(f => f._id)) || [];

      const everyStatus = [];
      const checklist = value.checklist.map(({ name, items }) => {
        everyStatus.push(items.every(i => i.status === true));
        return {
          name,
          items: items.map(item => ({ obs: item.obs, status: item.status, item: item.name }))
        };
      });

      const checklists = { checklist, status: everyStatus.every(s => s === true) };
      this.status.setAsProcessing();

      let observable$: Observable<Object | Error>;

      if (this.logbookId) {
        observable$ = this.logbookMessagesService.sendMessage(this.condo.id, {
          messageDescription: message,
          messageType: this.type.value,
          priority: 'ROUTINE',
          logbook: this.logbookId,
          files,
          checklists
        });
      } else {
        observable$ = this.gateNotebookService.create(this.condo._id, {
          description: message,
          type: this.type.value,
          priority: 'ROUTINE',
          files,
          checklists
        });
      }

      observable$.subscribe({
        next: registered => {
          this.toastrService.success('Passagem registrada com sucesso');
          if (this.callbacks && this.callbacks.success) {
            this.callbacks.success({ ...registered, checklists });
          }
          this.bsModalRef.hide();
        },
        error: err => {
          console.log(err);
          this.status.setAsError();
          this.toastrService.error('Não foi possível a passagem de posto, tente novamente');
        }
      });
    } else {
      this.toastrService.warning('Preencha todos os campos obrigatórios');
      for (const key of Object.keys(value)) {
        this.form.get(key).markAsTouched();
      }

      for (const group of this.checklistFormArray.controls) {
        group.markAsTouched();
        (group as UntypedFormGroup).controls.items.markAllAsTouched();
      }
    }
  }

  addTopic({ name, items }: ChecklistTopic) {
    return this.fb.group({
      expanded: false,
      name: name,
      items: this.fb.array(items.map(item => this.addItem(item)))
    });
  }

  addItem({ name, obs, status }: TopicItem) {
    const validation = (formGroup: UntypedFormGroup) => {
      const obsFormControl = formGroup.get('obs') as UntypedFormControl;
      const statusFormControl = formGroup.get('status') as UntypedFormControl;
      if (statusFormControl.value === false && !obsFormControl.value) {
        return { falseWithoutObs: true };
      } else {
        return {};
      }
    };

    return this.fb.group({ name, obs, status }, { validators: validation });
  }

  toggleNestedProperty(control: AbstractControl, propertyName: string) {
    const propertyControl = control.get(propertyName);
    propertyControl.setValue(!propertyControl.value);
  }
}
