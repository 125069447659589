import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AbstractControl, FormArray, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CondoService } from '@api/service/condo.service';
import { Residence } from '@api/model/interface/residence';
import { UtilService } from '../../services/util.service';
import { OccurrenceServiceV2 } from '@api/serviceV2/occurrence.service';
import { Condo } from '@api/model/condo';
import { Occurrence } from '@api/model/interface/occurrence';
import { User } from '@api/model/user';
import * as moment from 'moment';
import { conditionalValidator, minLengthArray } from '@api/util/validators';
import { CondoContactServiceV2 } from '@api/serviceV2/condo.contact.service';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { CondoContact } from '@api/model/contact/condo.contact';
import { ModalVisitorPlatesEditorComponent } from '../modal-visitor-plates-editor/modal-visitor-plates-editor.component';
import swal from 'sweetalert2';
import { validatePlate } from '@api/util/util';
import { ModalNewCondoContactComponent } from '../../pages/gate/access.control/new.condo.contact/new.condo.contact.modal';
import { lastValueFrom, Observable, of, Subject } from 'rxjs';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { ModalSelectCondoContactComponent } from '../modal-select-condo-contact/modal-select-condo-contact.components';
import { VehicleService } from '@api/service/vehicle.service';
import { AccessGroupService } from '@api/service/access-group.service';
import { EcondosQuery } from '@api/model/query';
import { SessionService } from '@api/service/session.service';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';

interface DayAllowed {
  day: string;
  startTime: Date | string;
  endTime: Date | string;
}
@Component({
  selector: 'app-modal-create-authorized-person',
  templateUrl: 'modal-create-authorized-person.component.html',
  styleUrls: ['./modal-create-authorized-person.component.scss']
})
export class ModalCreateAuthorizedPersonComponent implements OnInit, OnDestroy {
  condo: Condo;
  user: User;
  residence: Residence;
  isAdmin = false;
  isResident = false;
  isSubmited = false;
  occurrence: GateOccurrence;
  form: UntypedFormGroup;
  selectedResidence: FormControl<Residence>;
  public visitorsList: CondoContact[] = [];
  selectedDays: DayAllowed[] = [];
  maxDate;
  minDate;

  availableFields;
  visitorAccessGroups;
  residentCanAccessRoute: boolean;

  selectedAccessGroup;

  unsubscribe$: Subject<void> = new Subject();

  processing = false;
  residences: Residence[] = [];
  showResidencePicker = false;
  callback: (args?) => void;
  defaultStartHour: number;
  defaultStartMinute: number;
  defaultEndHour: number;
  defaultEndMinute: number;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private condoService: CondoService,
    private occurrenceService: OccurrenceServiceV2,
    private condoContactService: CondoContactServiceV2,
    private modalService: BsModalService,
    private utilService: UtilService,
    private toastr: ToastrService,
    private sessionService: SessionService,
    private vehicleService: VehicleService,
    private accessGroupService: AccessGroupService
  ) {
    this.form = this.fb.group(
      {
        condoContact: [null],
        startDate: [new Date(), Validators.required],
        endDate: [new Date(), Validators.required],
        shouldCall: [false, Validators.required],
        daysAllowed: [[], Validators.required],
        description: [''],
        residence: [null, Validators.required],
        title: [null, Validators.required],
        accessGroups: []
      },
      {
        validators: [
          (form: AbstractControl) => {
            const startDate = form.get('startDate');
            const endDate = form.get('endDate');
            const error = { invalidRange: true };
            if (startDate.hasError('required') || endDate.hasError('required')) {
              return error;
            }
            if (!startDate.value || !endDate.value) {
              return null;
            }
            if (moment(endDate.value).endOf('day').isSameOrAfter(moment(startDate.value))) {
              return null;
            }
            return error;
          },
          (form: AbstractControl) => {
            // Validação de horário de início e fim dos dias permitidos
            const daysAllowed = form.get('daysAllowed');
            const error = { invalidTime: true };

            if (daysAllowed.hasError('minLengthArray')) {
              return error; // Dias permitidos não podem ser vazios
            }

            if (!daysAllowed.value || daysAllowed.value.length === 0) {
              return null;
            }

            const invalidDays = daysAllowed.value.filter(day => {
              const startTime = moment(day.startTime, 'HH:mm');
              const endTime = moment(day.endTime, 'HH:mm');
              return startTime.isSameOrAfter(endTime);
            });

            return invalidDays.length > 0 ? error : null;
          }
        ]
      }
    );
    this.selectedResidence = this.form.get('residence') as FormControl<Residence>;
    this.user = this.utilService.getLocalUser();
    this.isAdmin = this.user.isAdmin() || this.user.isOwner() || this.user.isGatekeeper();
    this.isResident = this.user.isUser();
    if (!this.isAdmin) {
      this.residences = this.user.getResidences();
      if (this.residences.length === 1) {
        this.selectedResidence.setValue(this.residences[0]);
      }
    }
    this.condo = this.utilService.getLocalCondo();
  }

  ngOnInit() {
    this.availableFields = this.condo?.generalParams?.accessLiberation?.availableFields?.accessGroups;

    if (this.availableFields?.available) {
      const params: EcondosQuery = {
        $select: 'name type enabledOnAccessLiberationForResident',
        type: 'VISITOR',
        enabledOnAccessLiberation: true
      };

      this.accessGroupService.get(this.condo.id, params).subscribe({
        next: ({ accessGroups }) => {
          if (this.isResident) {
            this.visitorAccessGroups = accessGroups.filter(group => group.enabledOnAccessLiberationForResident);
          } else {
            this.visitorAccessGroups = accessGroups;
          }
          if (this.visitorAccessGroups.length === 1) {
            this.form.get('accessGroups').setValue(this.visitorAccessGroups[0].name);
            this.selectedAccessGroup = this.visitorAccessGroups[0];
            this.residentCanAccessRoute = this.visitorAccessGroups[0].enabledOnAccessLiberationForResident;
          }
          if (this.visitorAccessGroups.length > 1) {
            this.form
              .get('accessGroups')
              .setValidators([conditionalValidator(() => this.availableFields.required, Validators.required), this.validAccessGroups]);
            this.form.get('accessGroups').updateValueAndValidity();
            this.residentCanAccessRoute = this.visitorAccessGroups.some(group => group.enabledOnAccessLiberationForResident);
          }
        },
        error: err => {
          this.toastr.error('Falha ao buscar os grupos de acesso');
          console.log(err);
        }
      });
    }

    const { visitorAccessLiberationDefaultTime } = this.condo.generalParams?.accessLiberation;

    const [hours, minutes] = visitorAccessLiberationDefaultTime?.startTime
      ? visitorAccessLiberationDefaultTime.startTime.split(':')
      : ['08', '00'];
    const [hoursEnd, minutesEnd] = visitorAccessLiberationDefaultTime?.endTime
      ? visitorAccessLiberationDefaultTime.endTime.split(':')
      : ['17', '00'];
    this.defaultStartHour = parseInt(hours);
    this.defaultStartMinute = parseInt(minutes);
    this.defaultEndHour = parseInt(hoursEnd);
    this.defaultEndMinute = parseInt(minutesEnd);

    const accessLiberationMaxDays = this.condo?.generalParams?.accessLiberation?.maxDays || 180;
    this.maxDate = moment().add(accessLiberationMaxDays, 'days').toDate();
    this.minDate = moment().subtract(1, 'd').toDate();

    if (this.occurrence) {
      this.showResidencePicker = true;
      this.selectedResidence.setValue(this.occurrence?.residence || null);
      this.visitorsList = this.occurrence?.condoContacts || [];
      this.form.get('startDate').setValue(new Date(this.occurrence.startDate));
      this.form.get('endDate').setValue(new Date(this.occurrence.endDate));
      this.form.get('shouldCall').setValue(this.occurrence.shouldCall || false);
      this.form.get('description').setValue(this.occurrence.description || '');
      this.form.get('title').setValue(this.occurrence?.title || '');
      this.form.get('daysAllowed').setValue(this.occurrence.daysAllowed);
      if (this.occurrence?.accessGroups.length > 0) {
        this.form.get('accessGroups').setValue(this.occurrence.accessGroups[0].name);
        this.selectedAccessGroup = this.occurrence.accessGroups[0];
      }
    } else {
      // Se a unidade for "passada" via initial state nós não mostramos a opção da escolha da unidade
      if (!this.residence) {
        this.showResidencePicker = true;
      }
      if (this.residence) {
        this.selectedResidence.setValue(this.residence);
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$?.next();
    this.unsubscribe$?.complete();
  }

  selectAccessGroup(event) {
    this.selectedAccessGroup = event?.item;
  }

  onResidenceSelect(residence) {
    this.selectedResidence.setValue(residence);
  }

  validAccessGroups = c => {
    if (!c.value) {
      return null;
    }
    const falseObj = {
      validAccessGroups: {
        valid: false
      }
    };

    const names = this.visitorAccessGroups.map((group: any) => group.name);

    if (!names.includes(c.value)) {
      return falseObj;
    } else {
      return null;
    }
  };

  submit() {
    if (this.form.valid) {
      this.processing = true;
      const values = this.form.value;
      const data = {
        title: values.title,
        type: Occurrence.GATE_TYPE,
        subType: 'ACCESS',
        shouldCall: values.shouldCall,
        residence: values.residence._id,
        startDate: moment(values.startDate).startOf('day').toISOString(),
        endDate: moment(values.endDate).endOf('day').toISOString(),
        condoContacts: this.visitorsList || [],
        description: values.description,
        descriptionHtml: values.description,
        daysAllowed: values.daysAllowed,
        tags: [],
        accessGroups: this.selectedAccessGroup?._id ? [this.selectedAccessGroup._id] : []
      };

      let subscription: Observable<any>;
      if (this.occurrence) {
        data['status'] = this.occurrence.status;
        subscription = this.occurrenceService.updateOcurrence(this.condo._id, this.occurrence._id, data);
      } else {
        subscription = this.occurrenceService.createOccurrence(this.condo._id, data);
      }
      subscription.subscribe({
        next: (res: { _id: string }) => {
          if (this.callback) {
            if (this.occurrence) {
              const occurrenceEdited = Object.assign(this.occurrence, data);
              occurrenceEdited.residence = values.residence;
              occurrenceEdited.accessGroups = this.selectedAccessGroup ? [this.selectedAccessGroup] : [];
              occurrenceEdited.updatedAt = moment(new Date()).toISOString();
              this.callback(occurrenceEdited);
            } else {
              data['_id'] = res._id;
              this.callback(data);
            }
          }
          this.processing = false;
          this.bsModalRef.hide();
          this.toastr.success(`Liberação ${this.occurrence ? 'atualizada' : 'criada'} com sucesso.`);
        },
        error: err => {
          console.log(err);
          this.toastr.error('Não foi possível realizar o cadastro, tente novamente');
          this.processing = false;
        }
      });
    } else {
      for (const key of Object.keys(this.form.controls)) {
        this.form.get(key).markAsTouched();
      }
      this.toastr.warning('Preencha os campos obrigatórios.');
    }
  }
  getVisitorVehiclesList(visitor: CondoContact): string {
    if (visitor?.condoVehicles?.length) {
      return visitor.condoVehicles.map(vehicle => vehicle.plate).join(', ');
    } else {
      return 'Nenhum veículo';
    }
  }
  editVisitorVehicles(visitor: CondoContact): void {
    const initialState = {
      condoContact: visitor,
      condo: this.condo,
      user: this.user
    };

    this.modalService.show(ModalVisitorPlatesEditorComponent, { initialState });
  }
  async addVisitorVehicle(visitor: CondoContact, index: number) {
    if (this.user.isUserOnCondo(this.condo._id)) {
      swal({
        title: `Cadastrar veículo para ${this.condo.customLabels?.visitor?.singular || 'visitante'}`,
        text: `Digite a placa do novo veículo para ${visitor.fullName}. Utilize apenas dígitos e letras, evite traços.`,
        input: 'text',
        confirmButtonText: 'Salvar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        inputPlaceholder: 'Ex: XXX9999',
        inputAutoTrim: true,
        showLoaderOnConfirm: true,
        preConfirm: async plate => {
          if (validatePlate(plate)) {
            return this.createVisitorVehicle(plate, visitor);
          } else {
            return Promise.reject(`Placa inválida: ${plate || 'vazia'}, digite uma placa válida no fomato ABC1E23 ou ABC1234`);
          }
        }
      })
        .then(message => {
          this.toastr.success(message);
        })
        .catch(swal.noop);
    } else {
      const callback = (updatedVisitor: CondoContact) => {
        this.visitorsList[index] = updatedVisitor;
      };

      const initialState = {
        condo: this.condo,
        user: this.user,
        condoContact: visitor,
        callback
      };

      this.modalService.show(ModalNewCondoContactComponent, { initialState, class: 'modal-lg modal-xl', ignoreBackdropClick: true });
    }
  }
  async createVisitorVehicle(plate: string, visitor: CondoContact) {
    if (plate) {
      const visitorVehiclesPlates = visitor.condoVehicles.map(condoVehicle => condoVehicle.plate.replace(/[^a-zA-Z0-9]/gm, ''));

      const leanPlate = plate.replace(/[^a-zA-Z0-9]/gm, '');

      if (visitorVehiclesPlates.indexOf(leanPlate) >= 0) {
        return Promise.reject(`Esse ${this.condo.customLabels?.visitor?.singular || 'visitante'} já possui um veículo com esta placa.`);
      }

      const data = {
        plate: leanPlate,
        condo: this.condo._id,
        createdBy: this.user._id
      };

      let vehicleId: string;

      try {
        const query = { plate: leanPlate };

        const [vehicle] = await lastValueFrom(this.vehicleService.getVehicles(this.condo._id, query).pipe(map(({ vehicles }) => vehicles)));

        if (vehicle) {
          vehicleId = vehicle._id;
        } else {
          const response: any = await lastValueFrom(this.vehicleService.createVehicle(this.condo._id, data));
          vehicleId = response._id;
        }

        const newVehicle = new CondoVehicle({ _id: vehicleId, ...data });

        const visitorData = { condoVehicles: [...visitor.condoVehicles, newVehicle] };

        await lastValueFrom(this.condoContactService.updateCondoContact(this.condo._id, visitor._id, visitorData));

        visitor.condoVehicles.push(newVehicle);

        return Promise.resolve('Veículo cadastrado com sucesso');
      } catch (err) {
        console.error(err);
        const message = err.originalError?.message || 'Ocorreu um erro ao salvar o veículo. Verifique sua conexão e tente novamente.';
        return Promise.reject(message);
      }
    }
  }
  showCondoContactSelectionModal() {
    this.isSubmited = false;
    const finishCondoContactSelectionCallback = (selectedContacts: CondoContact[]) => {
      if (selectedContacts.length) {
        this.visitorsList = selectedContacts;
      }
    };

    const config: ModalOptions = {
      class: 'modal-lg',
      initialState: {
        condo: this.condo,
        user: this.user,
        selectedContacts: [...this.visitorsList],
        finishCondoContactSelectionCallback
      },
      ignoreBackdropClick: true
    } as ModalOptions;
    if (!(this.user.isGatekeeper() || this.user.isAdmin() || this.user.isOwner())) {
      this.condoService
        .getCondoById(this.condo._id, '?$select=hardwareParams')
        .pipe(
          catchError(() => of(this.condo)),
          tap(condo => {
            const localCondo = this.utilService.getLocalCondo();
            if (this.condo._id === localCondo._id) {
              localCondo.hardwareParams.visitorsPlateAddedByResident = condo.hardwareParams.visitorsPlateAddedByResident;
              this.utilService.saveLocalCondo(localCondo);
              this.condo = localCondo;
            }
            const localUser = this.utilService.getLocalUser();
            localUser.defaultCondo.hardwareParams.visitorsPlateAddedByResident = condo.hardwareParams.visitorsPlateAddedByResident;
            this.sessionService.setUser(localUser);
            this.user = localUser;
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe({
          next: () => {
            this.modalService.show(ModalSelectCondoContactComponent, config);
          },
          error: err => {
            console.error(err);
          }
        });
    } else {
      this.modalService.show(ModalSelectCondoContactComponent, config);
    }
  }

  compareResidenceId(residence1: Residence, residence2: Residence) {
    return residence1?._id === residence2?._id;
  }
}
