import * as moment from 'moment';
import { KeyValue } from '@angular/common';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import swal from 'sweetalert2';

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

export function capitalize(word) {
  if (!word) {
    return '';
  }
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
export function removeAccents(s = '') {
  let r = s.toLowerCase();
  r = r.replace(new RegExp('\\s', 'g'), '');
  r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
  r = r.replace(new RegExp('æ', 'g'), 'ae');
  r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
  r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
  r = r.replace(new RegExp('ñ', 'g'), 'n');
  r = r.replace(new RegExp('[òóôõö]', 'g'), 'o');
  r = r.replace(new RegExp('œ', 'g'), 'oe');
  r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
  r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
  // r = r.replace(/ç/g, 'c');
  // r = r.replace(new RegExp('\\W', 'g'), '');
  return r;
}

export function removeNotNumbers(s = '') {
  return s.toString().replace(/\D/g, ''); // Remove tudo o que não é dígito
}

export const sortFunction = (a: any, b: any): number => {
  if (a === null || typeof a === 'undefined') {
    a = 0;
  }
  if (b === null || typeof b === 'undefined') {
    b = 0;
  }
  if (a instanceof Date && b instanceof Date) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
  }
  const momentFormats = [moment.ISO_8601, 'DD/MM/YYYY', 'DD/MM/YY', 'HH:mm:ss'];
  const aMomentDate = moment(a, momentFormats, true);
  const bMomentDate = moment(b, momentFormats, true);
  if (aMomentDate.isValid() && bMomentDate.isValid()) {
    const aISOString = aMomentDate.toISOString();
    const bISoString = bMomentDate.toISOString();
    if (aISOString > bISoString) {
      return 1;
    } else if (aISOString < bISoString) {
      return -1;
    } else {
      return 0;
    }
  } else if (isNaN(parseFloat(a)) || !isFinite(a) || isNaN(parseFloat(b)) || !isFinite(b)) {
    // Convert to string in case of a=0 or b=0
    a = String(a);
    b = String(b);
    // Isn't a number so lowercase the string to properly compare

    const collator = new Intl.Collator('pt-br', {
      numeric: true,
      sensitivity: 'base',
      caseFirst: 'false',
      usage: 'sort'
    });
    return collator.compare(a, b);
  } else {
    // Parse strings as numbers to compare properly
    if (parseFloat(a) < parseFloat(b)) {
      return -1;
    }
    if (parseFloat(a) > parseFloat(b)) {
      return 1;
    }
  }

  // equal each other
  return 0;
};

export const createImageFromBlob = (image: Blob): Promise<any> => {
  if (!image) {
    return Promise.reject('No data detected');
  }

  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false
    );
    reader.readAsDataURL(image);
  });
};

export const generateRandomHexString = len => {
  let output = '';
  for (let i = 0; i < len; ++i) {
    output += Math.floor(Math.random() * 16).toString(16);
  }
  return output;
};

export const convertLocalHourToUTCHour = (localHour: number) => {
  return moment().hour(localHour).add(new Date().getTimezoneOffset(), 'm').hour();
};

export const convertUTCHourToLocalHour = (utcHour: number) => {
  return moment().hour(utcHour).subtract(new Date().getTimezoneOffset(), 'm').hour();
};

// Solution to search words with accents
// https://tech.rgou.net/en/php/pesquisas-nao-sensiveis-ao-caso-e-acento-no-mongodb-e-php/
// https://stackoverflow.com/questions/36647244/mongodb-how-to-find-documents-ignoring-case-sensitive-accents-and-percent-like/36647710
export function replaceVowelsToAccentedVowels(word) {
  return word
    .replace(/a/g, '[a,á,à,ä,ã,â]')
    .replace(/e/g, '[e,é,ë,ê]')
    .replace(/i/g, '[i,í,ï]')
    .replace(/o/g, '[o,ó,ö,ò,õ,ô]')
    .replace(/u/g, '[u,ü,ú,ù]');
}

export const getImageBase64Dimensions = (base64): Promise<{ width: number; height: number }> => {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = function () {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };
    img.src = base64;
  });
};

export const rotateImageBase64 = (imageBase64, degrees): Promise<string> => {
  return new Promise(resolve => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      ctx.translate(image.width / 2, image.height / 2);
      ctx.rotate((degrees * Math.PI) / 180);
      ctx.drawImage(image, -image.width / 2, -image.height / 2);
      const rotatedBase64 = canvas.toDataURL('image/png', 100);
      resolve(rotatedBase64);
    };
    image.src = imageBase64;
  });
};

export const rotateBase64Image90Deg = (base64Image: string, isClockwise = true): Promise<string> => {
  return new Promise(resolve => {
    // create Image
    const image = new Image();
    image.crossOrigin = 'Anonymous';

    image.onload = () => {
      // create an off-screen canvas
      const offScreenCanvas = document.createElement('canvas');
      const offScreenCanvasCtx = offScreenCanvas.getContext('2d');

      // set its dimension to rotated size
      offScreenCanvas.height = image.width;
      offScreenCanvas.width = image.height;

      // rotate and draw source image into the off-screen canvas:
      if (isClockwise) {
        offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
        offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
      } else {
        offScreenCanvasCtx.rotate((-90 * Math.PI) / 180);
        offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
      }
      offScreenCanvasCtx.drawImage(image, 0, 0);

      // encode image to data-uri with base64
      resolve(offScreenCanvas.toDataURL('image/jpeg', 100));
    };

    image.src = base64Image;
  });
};

export const readFileAsDataURL = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = fileReaderEvent => {
      const fileContent = fileReaderEvent.target?.result.toString();

      if (fileContent) {
        resolve(fileContent);
      }
    };

    reader.onerror = errorEvent => reject(errorEvent);

    reader.readAsDataURL(file);
  });
};

export const defaultSortFunction = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => 0;

export const validatePlate = plate => {
  plate = plate.toUpperCase();
  const regexPlacaMercosul = /^[A-Z]{3}\d[A-Z]\d{2}$/;
  const regexPlacaBrasileira = /^[A-Z]{2,3}\d{3,4}$/;
  return plate.length <= 7 && (regexPlacaMercosul.test(plate) || regexPlacaBrasileira.test(plate));
};

// cnpj validator reference by https://gist.github.com/alexbruno/6623b5afa847f891de9cb6f704d86d02
export const validateCnpj = value => {
  if (!value) {
    return false;
  }

  // Guarda um array com todos os dígitos do valor
  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  // Valida a quantidade de dígitos
  // eslint-disable-next-line curly
  if (numbers.length !== 14) {
    return false;
  }

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)];
  // eslint-disable-next-line curly
  if (items.length === 1) {
    return false;
  }

  // Cálculo validador
  const calc = x => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      // eslint-disable-next-line curly
      if (factor < 2) {
        factor = 9;
      }
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12);

  // Valida 1o. dígito verificador
  const digit0 = calc(12);
  // eslint-disable-next-line curly
  if (digit0 !== digits[0]) {
    return false;
  }

  // Valida 2o. dígito verificador
  const digit1 = calc(13);
  return digit1 === digits[1];
};

// Source: https://stackoverflow.com/a/62742520
export const urlValidator: ValidatorFn = (control: AbstractControl) => {
  let validUrl = true;

  try {
    new URL(control.value);
  } catch {
    validUrl = false;
  }

  return validUrl ? null : { invalidUrl: true };
};

// Mistura o array usando shuffle para uma melhor aleatoriedade.
export const shuffleArray = <T>(array: T[]): T[] => {
  const shuffled = array.slice(); // Faz uma cópia para evitar mutações no original
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

// Mescla dois arrays, inserindo elementos do segundo array em posições aleatórias do primeiro.
export const mergeRandomly = <T>(arr1: T[], arr2: T[]): T[] => {
  const result = arr1.slice(); // Copia para não mutar o original
  arr2.forEach(item => {
    const randomIndex = Math.floor(Math.random() * (result.length + 1));
    result.splice(randomIndex, 0, item);
  });
  return result;
};

export const beforeDateValidator = (dateToCompareFieldName: string): ValidatorFn => {
  return (control: AbstractControl) => {
    const dateToCompare = control.root.get(dateToCompareFieldName);

    if (!dateToCompare) {
      return null;
    }

    const controlDate = moment(control.value).startOf('day');
    const compareDate = moment(dateToCompare.value).startOf('day');

    return controlDate.isBefore(compareDate) ? { isBeforeDate: true } : null;
  };
};

export function getCurrentLocation() {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      position => {
        this.latitude.setValue(position.coords.latitude);
        this.longitude.setValue(position.coords.longitude);
      },
      err => {
        if (err.code === err.PERMISSION_DENIED) {
          swal({
            title: 'Localização não disponível',
            text: 'Você precisa permitir que o sistema acesse sua localização.',
            type: 'error'
          });
        } else {
          this.toastrService.error('Não foi possivel pegar sua localização atual!');
        }
      },
      { enableHighAccuracy: true }
    );
  } else {
    swal({
      type: 'info',
      text: 'O serviço de geolocalização não esta disponivel no navegador atual.\nTente atualizar seu navegador ou troque por outro navegador'
    });
  }
}
