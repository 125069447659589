import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Condo } from '@api/model/condo';
import { Actuator } from '@api/model/hardware/actuator';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { User } from '@api/model/user';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { ProxyService } from '@api/service/proxy.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, noop, of, Subject } from 'rxjs';
import { catchError, exhaustMap, filter, takeUntil, tap, timeout } from 'rxjs/operators';
import swal from 'sweetalert2';

@Component({
  templateUrl: 'modal-actuate-actuator.component.html',
  styleUrls: ['modal-actuate-actuator.component.scss']
})
export class ModalActuateActuatorComponent implements OnInit, OnDestroy {
  condo: Condo;
  user: User;
  actuator: Actuator;
  actuationStatus;

  defaultImage = 'assets/img/no-image.jpg';

  showInstructions = false;

  isElectron = false;

  actuate$: Subject<{ actuator: Actuator; params: any }> = new Subject();

  private unsubscribe: Subject<void> = new Subject();

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.actuationStatus !== 'PROCESSING' && event && (event.key === 'Enter' || event.keyCode === 13)) {
      this.actuate(this.actuator, this.condo);
    }
  }

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private http: HttpClient,
    private toastrService: ToastrService,
    private actuatorService: ActuatorService,
    private proxyService: ProxyService
  ) {
    this.isElectron = this.userAgentIsElectron();
  }

  ngOnInit() {
    this.actuate$
      .asObservable()
      .pipe(
        filter(data => !!data.actuator),
        tap(data => (this.actuationStatus = 'PROCESSING')),
        exhaustMap(data => {
          let observable;
          let isTriggerCommand = false;

          if (data.params?.command) {
            const command = data.params.command;
            observable = command === 'LOCK' ? this.actuatorService.lock(data.actuator) : this.actuatorService.unlock(data.actuator);
          } else {
            observable = this.actuatorService.trigger(data.actuator, data.params);
            isTriggerCommand = true;
          }

          return observable.pipe(
            timeout(10000),
            catchError(async error => {
              let failedToSendCommand = true;

              const isLinearHardware = data.actuator.hardware === HARDWARES.LINEAR;
              const isLinearServerModeEnabledOnCondo = !!data.actuator.condo.linear.serverMode.enabled;

              if (isTriggerCommand && isLinearHardware && isLinearServerModeEnabledOnCondo) {
                try {
                  await firstValueFrom(this.proxyService.linearServerMode.trigger(data.actuator, data.actuator.condo.linear.serverMode));
                  failedToSendCommand = false;
                } catch {
                  failedToSendCommand = true;
                }
              }

              if (failedToSendCommand) {
                swal({
                  type: 'error',
                  text: this.getTextFromHardware(data.actuator, error)
                });

                this.actuationStatus = 'ERROR';
                return of(new Error());
              }

              return of();
            }),
            tap((value: any) => {
              const isErrored = value instanceof Error || value.status === 'ERROR';

              if (!isErrored) {
                this.toastrService.success('Comando enviado');
                this.actuationStatus = 'SUCCESS';

                if (!this.actuator.camera) {
                  this.hide();
                }
              } else {
                swal({
                  type: 'error',
                  text: this.getTextFromHardware(data.actuator, value)
                });

                this.actuationStatus = 'ERROR';
              }
            })
          );
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(noop);
  }

  getTextFromHardware(actuator: Actuator, error: any) {
    let text = 'Não foi possível enviar o comando para o';

    switch (actuator.hardware) {
      case 'LINEAR':
        text = text + ' módulo guarita';
        break;
      case 'CONTROL_ID':
        text = text + ' Control ID';
        break;
      case 'HIKVISION':
        text = text + ' Hikvision';
        break;
      case HARDWARES.BRAVAS:
        text = text + ' equipamento';
        break;
      case HARDWARES.INTELBRAS_ALARM_CENTRAL:
        let errorMessage = Array.isArray(error?.error?.message) ? error?.error?.message[1] : error?.error?.message;

        if (errorMessage === 'Alarm central client not found') {
          errorMessage = 'Central de alarme não encontrada';
        }

        text = errorMessage || 'Não foi possível processar a requisição';
        break;
      default:
        text = 'Não foi possível processar a requisição';
    }
    return text;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  checkIfProxyIsRunning() {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    this.http
      .get('http://localhost:5050/api.econdos.com.br', { headers, responseType: 'text' })
      .pipe(timeout(8000))
      .subscribe(
        res => {
          swal({
            type: 'success',
            text: 'O eCondos câmera proxy está funcionando corretamente, verifique se as câmeras estão configuradas corretamente e tente novamente'
          });
        },
        err => {
          console.log(err);
          swal({
            type: 'error',
            text: 'Parece que o eCondos câmera proxy não está em execução, verifique as instruções para executá-lo e tente novamente'
          });
        }
      );
  }

  actuate(actuator, condo, params = {}) {
    actuator.condo = condo;

    try {
      if (actuator.hardware !== HARDWARES.INTELBRAS_ALARM_CENTRAL) {
        this.actuatorService.registerTriggerEvent(actuator).subscribe({ next: noop, error: noop });
      }
    } catch (e) {
      console.log(e);
    }

    this.actuate$.next({ actuator, params });
  }

  // actuateViaServerMode(actuator, condo) {
  //   const user = this.sessionService.user;
  //   this.actuationStatus = 'PROCESSING';
  //   let input;
  //   let command;
  //   // If output is bigger than 4, it should used advanced trigger command to actuate auxiliars output
  //   if (actuator.output > 4) {
  //     input = {
  //       tipo_disp: actuator.type,
  //       num_disp: actuator.number,
  //       gera_evt: true,
  //       rele: actuator.output,
  //       tempo: 1,
  //     };
  //     command = 'triggerReceiverAdvanced';
  //   } else {
  //     command = 'triggerReceiver';
  //     input = {
  //       tipo_disp: actuator.type,
  //       num_disp: actuator.number,
  //       num_saida: actuator.output,
  //       gera_evt: true
  //     };
  //   }
  //
  //   this.hardwareSocketService.sendCommandViaServerMode(condo._id, {
  //     command, input,
  //     createdBy: user
  //   }).pipe(timeout(10000)).subscribe(res => {
  //     this.toastrService.success('Comando enviado');
  //     this.actuationStatus = 'SUCCESS';
  //     if (!actuator.camera) {
  //       this.hide();
  //     }
  //   }, err => {
  //     swal({
  //       type: 'error',
  //       text: 'Não foi possível enviar o comando para o módulo guarita'
  //     });
  //     this.actuationStatus = 'ERROR';
  //     console.log(err);
  //   });
  //
  // }

  userAgentIsElectron() {
    const userAgent = window && window.navigator && window.navigator.userAgent;
    return userAgent.toLowerCase().includes('electron');
  }

  hide() {
    this.bsModalRef.hide();
  }
}
