import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Condo } from '../../../api/model/condo';
import { UtilService } from '../../../services/util.service';
import { CommentService } from '../../../api/service/comment.service';
import { User } from '../../../api/model/user';
import { timeout } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { Occurrence } from '../../../api/model/interface/occurrence';
import { OccurrenceFileUploader } from '../../occurrence.creator/occurrence.creator.file.list/files.scroll';
import { File } from '@api/model/file';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalEditCommentComponent } from '../modal-edit-comment/modal-edit-comment.component';
import { Comment } from '@api/model/comment';

@Component({
  selector: 'app-feed-comment',
  templateUrl: './feed-comment.component.html',
  styleUrls: ['./feed-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedCommentComponent implements OnChanges {
  @ViewChild('filePicker', { static: true }) occurrenceFileUploader: OccurrenceFileUploader;

  @Input()
  occurrence: Occurrence;

  @Input()
  condo: Condo;

  @Input()
  user: User;

  @Input()
  showAsMessages = false;

  @Input()
  enableCommentFiles = false;

  @Input()
  dataTesteIdIndex: number;

  @Output()
  commentCreated: EventEmitter<Comment> = new EventEmitter<Comment>();

  @Output()
  commentDeleted: EventEmitter<Comment> = new EventEmitter<Comment>();

  isUploading = false;
  files: Array<File> = new Array<File>();

  userPicture;
  commentsTotal;
  comments: {
    _id: string;
    editing: boolean;
    pictures: File[];
    residences: string;
    description: string;
    createdAt: string;
    createdBy: User;
  }[] = [];

  loadingComments;
  hasMoreComments = true;
  placeholder = 'Escreva um comentário';
  numberOfCommentsPerPage = 10;
  pageNumber = 0;

  newComment: UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.required, Validators.minLength(1)]));

  constructor(
    private utilService: UtilService,
    private commentService: CommentService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.userPicture =
      (changes.user.currentValue.picture && changes.user.currentValue.picture.thumbnail) || 'assets/img/empty-user-picture.png';
    this.commentsTotal = changes.occurrence.currentValue.commentsTotal;
    this.comments = this.convertComments(changes.occurrence.currentValue.comments);
    this.placeholder = changes.showAsMessages && changes.showAsMessages.currentValue ? 'Escreva uma mensagem' : 'Escreva um comentário';
  }

  convertComments(comments) {
    return comments.map(c => ({
      _id: c._id,
      editing: false,
      pictures: c.pictures,
      residences: c.createdBy.residencesIdentification.join(' - '),
      description: c.description,
      createdAt: c.createdAt,
      createdBy: c.createdBy
    }));
  }

  loadComments() {
    if (this.commentsTotal <= 3 || !this.hasMoreComments) {
      return;
    }
    this.loadingComments = true;
    const qs = [];
    let populateIndex = 0;
    if (this.enableCommentFiles) {
      qs[qs.length] = `$populate[${populateIndex}][path]=pictures`;
      qs[qs.length] = `$populate[${populateIndex++}][select]=url thumbnail type format`;
    }
    qs[qs.length] = `$populate[${populateIndex}][path]=createdBy`;
    qs[qs.length] = `$populate[${populateIndex}][select]=firstName lastName picture`;
    qs[qs.length] = `$populate[${populateIndex}][populate][path]=picture`;
    qs[qs.length] = `$populate[${populateIndex}][populate][select]=url publicId thumbnail`;
    qs[qs.length] = `$sort=-createdAt`;
    qs[qs.length] = `$limit=${this.numberOfCommentsPerPage}`;
    qs[qs.length] = `$page=${this.pageNumber}`;

    this.commentService
      .getCommentsFromOccurrence(this.condo._id, this.occurrence._id, `?${qs.join('&')}`)
      .pipe(timeout(10000))
      .subscribe(
        comments => {
          if (comments?.length == this.numberOfCommentsPerPage) {
            this.hasMoreComments = true;
          } else {
            this.hasMoreComments = false;
          }
          if (this.comments?.length <= 3 && comments?.length > 3) {
            this.comments = [];
          }
          comments.reverse();
          const keepingCurrentComments = this.pageNumber > 0;
          this.occurrence.comments = [...comments, ...(keepingCurrentComments ? this.comments : [])];
          this.comments = [...this.convertComments(comments), ...(keepingCurrentComments ? this.comments : [])];

          this.loadingComments = false;
          this.cdr.detectChanges();
          this.pageNumber += 1;
        },
        err => {
          console.log(err);
          this.loadingComments = false;
          this.toastr.error('Não foi possível carregar os comentários, verifique sua conexão e tente novamente...');
        }
      );
  }

  addComment() {
    if (this.newComment.valid) {
      const condoId = this.condo._id ? this.condo._id : this.condo;
      const comment: any = {
        description: this.newComment.value
      };
      if (this.files.length && this.enableCommentFiles) {
        comment.pictures = this.files;
      }
      this.newComment.disable();
      this.commentService
        .createComment(condoId.toString(), this.occurrence._id, comment)
        .pipe(timeout(10000))
        .subscribe(
          (data: any) => {
            this.newComment.setValue('');
            comment._id = data._id;
            comment.createdAt = new Date();
            comment.createdBy = this.user;
            this.occurrence.comments?.push(comment);
            this.occurrence.commentsTotal++;
            this.commentsTotal++;
            this.comments = this.convertComments(this.occurrence.comments);
            this.newComment.enable();
            this.newComment.markAsUntouched();
            this.commentCreated.emit(comment);
            this.files = new Array<File>();
            this.cdr.detectChanges();
          },
          err => {
            console.log(err);
            this.newComment.enable();
            this.toastr.error('Não foi possível criar o comentário, tente novamente...');
          }
        );
    } else {
      this.toastr.warning('Insira algo no comentário...');
    }
  }

  deleteComment(comment) {
    swal({
      type: 'question',
      title: 'Deletar comentário',
      text: 'Tem certeza que deseja excluir esse comentário?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.commentService
          .deleteComment(this.condo._id, this.occurrence._id, comment._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível apagar este comentário, tente novamente.');
          });
      }
    }).then(
      () => {
        const commentIndex = this.occurrence.comments.findIndex(c => c._id === comment._id);
        this.occurrence.comments.splice(commentIndex, 1);
        this.comments = this.convertComments(this.occurrence.comments);
        this.cdr.detectChanges();
        this.commentDeleted.emit(comment);
        this.toastr.success('Comentário excluído com sucesso');
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  editComment(comment) {
    const initialState = {
      condo: this.condo,
      occurrence: this.occurrence,
      comment: comment,
      enableCommentFiles: this.enableCommentFiles,
      showAsMessages: this.showAsMessages,
      callbacks: {
        success: value => {
          const index = this.comments.findIndex(k => k._id === value._id);
          this.comments[index].description = value.description;
          this.comments[index].pictures = value.pictures;
          this.cdr.detectChanges();
        }
      }
    };
    this.modalService.show(ModalEditCommentComponent, { initialState, class: 'modal-md' });
  }

  uploadFile() {
    this.occurrenceFileUploader.pickFile();
  }
}
