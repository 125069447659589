import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { EcondosQuery } from '@api/model/query';
import { Logbooks } from '@api/model/logbook';
import { buildGroup, Group } from '@api/model/group';

@Injectable()
export class LogbooksService {
  protected endPoint: string;

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService
  ) {
    this.endPoint = `${this.constantService.getV3Endpoint()}condos/`;
  }

  get(condoId: string, query: EcondosQuery = {}): Observable<{ logbooks: Logbooks[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });
    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}${condoId}/logbooks`, options).pipe(
      map((res: any) => {
        return {
          logbooks: res.body
        };
      })
    );
  }

  getGroups(condoId: string, params: EcondosQuery): Observable<{ count: number; groups: Group[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/logbooks/groups`, options).pipe(
      map((res: any) => {
        return {
          count: res.headers.get('count'),
          groups: res.body.map(gr => buildGroup(gr))
        };
      })
    );
  }

  getByToken(condoId: string, token: string, query: EcondosQuery = {}): Observable<{ logbooks: Logbooks[] }> {
    query.token = token;
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/logbooks/searchToken`, { params }).pipe(
      map((res: any) => ({
        logbooks: res.body
      }))
    );
  }

  create(condoId: string, group: EcondosQuery = {}) {
    return this.http.post(`${this.endPoint}${condoId}/logbooks`, group);
  }

  update(condoId: string, logbookId: string, logbook: Partial<Logbooks>) {
    return this.http.put(`${this.endPoint}${condoId}/logbooks/${logbookId}`, logbook);
  }

  delete(condoId: string, logbookId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/logbooks/${logbookId}`);
  }
}
