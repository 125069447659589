import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LogbookMessage } from '@api/model/logbook';

@Component({
  selector: 'app-logbook-message-reply',
  templateUrl: './logbook-message-reply.component.html',
  styleUrls: ['./logbook-message-reply.component.scss']
})
export class LogbookMessageReplyComponent implements OnInit {
  @Input() logbookMessage: LogbookMessage;
  @Output() replyMessage = new EventEmitter<LogbookMessage | null>();

  event: LogbookMessage;

  constructor() {}

  ngOnInit() {
    this.event = { ...this.logbookMessage, replyTo: null };
  }

  handleReplyMessage(event: LogbookMessage | null) {
    this.replyMessage.emit(event);
  }
}
