import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { EcondosQuery } from '../model/query';
import * as qs from 'qs';
import { BuildCondoRequest, CondoRequest } from '@api/model/condo-request';
import { User } from '@api/model/user';

@Injectable({ providedIn: 'root' })
export class CondoRequestService {
  private endPoint;

  constructor(
    private http: HttpService,
    private constantService: ConstantService
  ) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  get(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; condoRequests: CondoRequest[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/requests`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({ count: res.headers.get('count'), condoRequests: res.body.map(data => BuildCondoRequest(data)) })),
        catchError((e: any) => {
          console.log(e);
          return throwError(e);
        })
      );
  }

  getUsers(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; pendingRequests: User[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/requests`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({ count: res.headers.get('count'), pendingRequests: res.body.map(data => new User(data.user)) })),
        catchError((e: any) => {
          console.log(e);
          return throwError(e);
        })
      );
  }

  create(condoId: string, condoRequest: CondoRequest): Observable<{ condoRequestId: string }> {
    return this.http.post(`${this.endPoint}${condoId}/requests`, condoRequest).pipe(
      map((condoRequest: any) => ({
        condoRequestId: condoRequest._id
      }))
    );
  }

  update(condoId: string, condoRequestId: string, condoRequest: Pick<CondoRequest, 'residence' | 'role'>) {
    return this.http.put(`${this.endPoint}${condoId}/requests/${condoRequestId}`, condoRequest);
  }

  getById(condoId: string, condoRequestId: string): Observable<CondoRequest> {
    return this.http.get(`${this.endPoint}${condoId}/requests/${condoRequestId}`) as Observable<CondoRequest>;
  }

  reject(condoId: string, condoRequestId: string) {
    return this.http.put(`${this.endPoint}${condoId}/requests/${condoRequestId}/reject`, {});
  }

  approve(condoId: string, condoRequestId: string) {
    return this.http.put(`${this.endPoint}${condoId}/requests/${condoRequestId}/approve`, {});
  }
}
