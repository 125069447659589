import { Injectable } from '@angular/core';
import { Condo } from '@api/model/condo';
import { Device, DEVICE_STATUS } from '@api/model/hardware/device';
import { Residence } from '@api/model/interface/residence';
import { User } from '@api/model/user';
import { ModalAddHardwareDeviceComponent } from 'app/hardware/modal-add-hardware-device/modal-add-hardware-device.component';
import { ModalCreateControlIdDeviceComponent } from 'app/hardware/modal-create-controlid-device/modal-create-controlid-device.component';
import { ModalCreateHikvisionDeviceComponent } from 'app/hardware/modal-create-hikvision-device/modal-create-hikvision-device.component';
import { ModalShowManufacturerChoiceComponent } from 'app/hardware/modal-show-manufacturer-choice/modal-show-manufacturer-choice.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UtilService } from './util.service';
import swal from 'sweetalert2';
import { EMPTY, forkJoin, iif, Observable, Subject } from 'rxjs';
import { IntelbrasIncontrolService } from '@api/service/hardware/intelbras-incontrol.service';
import { ModalCreateIntelbrasIncontrolDeviceComponent } from 'app/hardware/modal-create-intelbras-incontrol-device/modal-create-intelbras-incontrol-device.component';
import { ModalCreateUtechDeviceComponent } from '../hardware/modal-create-utech-device/modal-create-utech-device.component';
import { ModalCreateAlphadigiDeviceComponent } from '../hardware/modal-create-alphadigi-device/modal-create-alphadigi-device.component';
import { ModalCreateNiceControllerDeviceComponent } from '../hardware/modal-create-nice-controller-device/modal-create-nice-controller-device.component';
import { ModalCreateIntelbrasStandAloneDeviceComponent } from '../hardware/modal-create-intelbras-stand-alone-device/modal-create-intelbras-stand-alone-device.component';
import { Router } from '@angular/router';
import { ModalCreateGarenDeviceComponent } from '../hardware/modal-create-garen-device/modal-create-garen-device.component';
import { HikvisionService } from '@api/service/hardware/hikvision.service';
import { expand, map, reduce } from 'rxjs/operators';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { ToastrService } from 'ngx-toastr';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { EcondosQuery } from '@api/model/query';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { ModalCreateZktecoDeviceComponent } from '../hardware/modal-create-zkteco-device/modal-create-zkteco-device.component';
import { ModalCreateDeviceComponent } from '../hardware/modal-create-device/modal-create-device.component';
import { Actuator } from '@api/model/hardware/actuator';
import { UserLocalSettingsService } from '@api/serviceV2/user-local-settings.service';

@Injectable({
  providedIn: 'root'
})
export class HardwareCreateService {
  condo: Condo;
  hardwareManufacturers: { value: string; label: string; enabled: boolean }[];
  idDownloadingIncontrolGroups = false;

  hasMultipleHardwareManufacturers = false;

  private _onDeviceCreated: Subject<any> = new Subject();
  onDeviceCreated: Observable<any> = this._onDeviceCreated.asObservable();

  constructor(
    private utilService: UtilService,
    private modalService: BsModalService,
    private intelbrasIncontrolService: IntelbrasIncontrolService,
    private routerCtrl: Router,
    private hikvisionService: HikvisionService,
    private deviceService: HardwareDeviceService,
    private toastrService: ToastrService,
    private actuatorService: ActuatorService,
    private userLocalSettingsService: UserLocalSettingsService
  ) {}

  createDevice(user, residence) {
    this.condo = this.utilService.getLocalCondo();
    this.hardwareManufacturers = [
      { value: 'LINEAR', label: 'Linear', enabled: this.condo.isLinearEnabled() },
      { value: 'CONTROL_ID', label: 'Control ID', enabled: this.condo.isControlIdEnabled() },
      { value: 'HIKVISION', label: 'Hikvision', enabled: this.condo.isHikvisionEnabled() },
      { value: 'UTECH', label: 'uTech', enabled: this.condo.isUtechEnabled() },
      { value: 'INTELBRAS', label: 'Intelbras InControl', enabled: this.condo.isIntelbrasEnabled() },
      { value: 'ALPHADIGI', label: 'Alphadigi', enabled: this.condo.isAlphaDigiEnabled() },
      { value: HARDWARES.GAREN, label: 'Garen', enabled: this.condo.isGarenEnabled() },
      { value: 'NICE_CONTROLLER', label: 'Controladora da Nice', enabled: this.condo.isNiceControllerEnabled() },
      { value: 'INTELBRAS_STAND_ALONE', label: 'Intelbras', enabled: this.condo.isIntelbrasStandAloneEnabled() },
      { value: HARDWARES.ZKTECO, label: 'ZKTeco', enabled: this.condo.isZktecoEnabled() },
      { value: HARDWARES.BRAVAS, label: 'ZKTeco', enabled: this.condo.isBravasEnabled() },
      { value: HARDWARES.MIP1000IP, label: 'MIP1000IP', enabled: this.condo.isMIP1000IPEnabled() }
    ];
    this.hasMultipleHardwareManufacturers = this.hardwareManufacturers.filter(hw => hw.enabled).length > 1;
    if (this.hasMultipleHardwareManufacturers) {
      const initialState = {
        callbacks: {
          success: manufacturer => {
            this.openSelectedManufacturerModal(manufacturer, user, residence);
          }
        }
      };
      this.modalService.show(ModalShowManufacturerChoiceComponent, {
        initialState,
        class: 'modal-md',
        ignoreBackdropClick: true
      });
    } else {
      const hw = this.hardwareManufacturers.find(h => h.enabled === true);
      if (hw) {
        const hardwareManufacturer = hw.value;
        this.openSelectedManufacturerModal(hardwareManufacturer, user, residence);
      }
    }
  }

  async openSelectedManufacturerModal(manufacturer, user?, residence?) {
    const initialState = {
      condo: this.condo
    };
    if (user) {
      initialState['storageUser'] = {
        ...initialState,
        user
      };
    }
    if (residence) {
      initialState['storageUser'] = {
        ...initialState['storageUser'],
        residence
      };
    }
    switch (manufacturer) {
      case 'LINEAR': {
        initialState['callbacks'] = {
          success: (device: Device) => {
            this._onDeviceCreated.next(device);
          }
        };
        this.modalService.show(ModalAddHardwareDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case 'CONTROL_ID': {
        initialState['callbacks'] = {
          success: (device: Device) => {
            this._onDeviceCreated.next(device);
          }
        };
        this.modalService.show(ModalCreateControlIdDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case 'HIKVISION': {
        const useDeprecatedHikvision = this.userLocalSettingsService.getSetting('useDeprecatedHikvision');

        const openDeprecatedHikvisionModal = () => {
          initialState['callbacks'] = {
            success: async (device: Device) => {
              await this.syncDevice(device);
              this._onDeviceCreated.next(device);
            },
            onUseNewHikvisionButtonClick: () => {
              openNewHikvisionModal();
            }
          };

          this.modalService.show(ModalCreateHikvisionDeviceComponent, {
            initialState,
            class: 'modal-lg',
            ignoreBackdropClick: true
          });
        };

        const openNewHikvisionModal = () => {
          initialState['callback'] = (device: Device) => this._onDeviceCreated.next(device);
          initialState['onUseDeprecatedHikvisionButtonClick'] = () => {
            openDeprecatedHikvisionModal();
          };

          this.modalService.show(ModalCreateDeviceComponent, {
            initialState,
            class: 'modal-lg',
            ignoreBackdropClick: true
          });
        };

        if (useDeprecatedHikvision) {
          openDeprecatedHikvisionModal();
        } else {
          openNewHikvisionModal();
        }
        break;
      }
      case 'INTELBRAS': {
        if (this.intelbrasIncontrolService.connected) {
          let groups;
          this.idDownloadingIncontrolGroups = true;
          this.intelbrasIncontrolService
            .getGroups()
            .then(res => {
              this.idDownloadingIncontrolGroups = false;
              groups = res;
              initialState['groups'] = groups;
              initialState['residenceSelected'] = residence;
              initialState['callbacks'] = {
                success: (device: Device) => {
                  this._onDeviceCreated.next(device);
                }
              };
              this.modalService.show(ModalCreateIntelbrasIncontrolDeviceComponent, {
                initialState,
                class: 'modal-lg',
                ignoreBackdropClick: true
              });
            })
            .catch(err => {
              this.idDownloadingIncontrolGroups = false;
              this.incontrolError();
            });
        } else {
          this.idDownloadingIncontrolGroups = false;
          this.incontrolError();
        }

        break;
      }
      case 'UTECH': {
        initialState['callbacks'] = {
          success: (device: Device) => {
            this._onDeviceCreated.next(device);
          }
        };
        this.modalService.show(ModalCreateUtechDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case HARDWARES.GAREN: {
        initialState['callbacks'] = {
          success: (device: Device) => {
            this._onDeviceCreated.next(device);
          }
        };
        this.modalService.show(ModalCreateGarenDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case 'ALPHADIGI': {
        initialState['callbacks'] = {
          success: (device: Device) => {
            this._onDeviceCreated.next(device);
          }
        };
        this.modalService.show(ModalCreateAlphadigiDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case 'NICE_CONTROLLER': {
        initialState['callbacks'] = {
          success: (device: Device) => {
            this._onDeviceCreated.next(device);
          }
        };
        //checar qual é
        this.modalService.show(ModalCreateNiceControllerDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case 'INTELBRAS_STAND_ALONE': {
        initialState['callbacks'] = {
          success: (device: Device) => {
            this._onDeviceCreated.next(device);
          }
        };
        this.modalService.show(ModalCreateIntelbrasStandAloneDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case HARDWARES.ZKTECO: {
        initialState['callbacks'] = {
          success: (device: Device) => {
            this._onDeviceCreated.next(device);
          }
        };
        this.modalService.show(ModalCreateZktecoDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case HARDWARES.BRAVAS: {
        initialState['callback'] = (device: Device) => this._onDeviceCreated.next(device);
        this.modalService.show(ModalCreateDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
      case HARDWARES.MIP1000IP: {
        initialState['callback'] = (device: Device) => this._onDeviceCreated.next(device);
        this.modalService.show(ModalCreateDeviceComponent, {
          initialState,
          class: 'modal-lg',
          ignoreBackdropClick: true
        });
        break;
      }
    }
  }

  askToCreateDeviceToUser(user: User, residence: Residence) {
    swal({
      type: 'question',
      title: 'Cadastrar dispositivo',
      text: `Gostaria de cadastrar um dispositivo para o usuário ${user.firstName} ${user.lastName}?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then(
      res => {
        this.createDevice(user, residence);
      },
      err => {
        // Clicked cancel
      }
    );
  }

  incontrolError() {
    swal({
      type: 'error',
      showCancelButton: true,
      cancelButtonText: 'Ver configurações',
      cancelButtonColor: 'var(--green-500)',
      title: `Ops...`,
      text: `Não foi possível conectar ao servidor Incontrol. Verifique as informações na tela de configurações do(a) ${this.condo?.customLabels?.condo?.singular} e tente novamente`
    })
      .then(() => {})
      .catch(err => {
        if (err === 'cancel') {
          this.routerCtrl.navigate(['hardware', 'config']);
        }
      });
  }

  private async syncDevice(device: Device) {
    const hardware = device.hardware;
    if (['HIKVISION'].includes(hardware)) {
      const observables: Observable<[{ actuator: Actuator; ok: boolean }]>[] = [];
      switch (hardware) {
        case 'HIKVISION': {
          let page = 0;
          const limit = 500;
          const eQuery = (): EcondosQuery => {
            const query = {
              hardware: 'HIKVISION',
              $limit: limit,
              $page: page
            };
            page++;
            return query;
          };
          const hardwareActuators = await this.actuatorService
            .getActuators(this.condo._id, eQuery())
            .pipe(
              expand(response =>
                iif(() => response.actuators.length === limit, this.actuatorService.getActuators(this.condo._id, eQuery()), EMPTY)
              ),
              reduce((acc, current) => acc.concat(current.actuators), [])
            )
            .toPromise();
          const deviceActuatorsId = device.actuators.map(a => a._id || a);
          const actuatorsToDelete = hardwareActuators.filter(a => a.type === 'FACIAL' && !deviceActuatorsId.includes(a._id));
          if (actuatorsToDelete.length) {
            observables.push(this.hikvisionService.delete(device, actuatorsToDelete));
          }
          observables.push(this.hikvisionService.update(device, device.actuators));
          break;
        }
        default:
          break;
      }
      forkJoin(observables)
        .pipe(map(([a, b]) => ({ device, results: [].concat(a, b).filter(v => v) })))
        .subscribe(data => {
          if (data.results && data.results.every(res => res.ok || res.success)) {
            this.deviceService.update(this.condo._id, data.device._id, { status: 'SYNCED' }).subscribe(
              () => {
                device.status = DEVICE_STATUS.SYNCED;
                this.toastrService.success('Dispositivos sincronizado com sucesso');
              },
              () => {
                device.status = DEVICE_STATUS.UNSYNCED;
                this.toastrService.warning('Não foi possível sincronizar o dispositivo nos acionadores selecionados. Tente novamente.');
              }
            );
          } else {
            const devicesWithErrorMsg = (data?.results || [])
              .filter(d => !d.ok && !d.success)
              .map(res => res?.actuator?.name || '')
              .join(', ');
            swal({
              type: 'error',
              title: 'Ops...',
              html: `Não foi possível sincronizar o dispositivo nos seguintes equipamentos: ${devicesWithErrorMsg}`
            });
            this.deviceService.update(this.condo._id, data.device._id, { status: 'UNSYNCED' }).subscribe(
              () => {
                device.status = DEVICE_STATUS.UNSYNCED;
                this.toastrService.warning('Não foi possível sincronizar o dispositivo nos acionadores selecionados. Tente novamente.');
              },
              () => {
                device.status = DEVICE_STATUS.UNSYNCED;
                this.toastrService.warning('Não foi possível sincronizar o dispositivo aos acionadores selecionados, Tente novamente.');
              }
            );
          }
        });
    }
  }
}
