import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Injectable } from '@angular/core';
import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs';
import { LogbookMessage } from '@api/model/logbook';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { map } from 'rxjs/operators';

@Injectable()
export class LogbookMessagesService {
  protected endPoint: string;

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService
  ) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  getMessages(condoId: string, query: EcondosQuery = {}): Observable<{ count: number; logbookMessages: LogbookMessage[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/logbook-messages`, { params }).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        logbookMessages: res.body
      }))
    );
  }

  sendMessage(condoId: string, logbookMessage: Partial<LogbookMessage>) {
    return this.http.post(`${this.endPoint}${condoId}/logbook-messages`, logbookMessage);
  }
}
