import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';

import { EmailValidator } from './email.validator';
// Services
import { UtilService } from '../../services/util.service';
import { UserService } from '../../api/service/user.service';
import { AuthService } from '../../services/auth.service';
import { NavbarService } from '../navbar/navbar.service';
import { ParamsService } from '../../api/service/params.service';
import swal from 'sweetalert2';
import { Error } from '../../api/model/error/error';
import { User } from '../../api/model/user';
import { FacebookService, InitParams } from 'ngx-facebook';
import { AdminCondoPickerModal } from './admin.condo.picker.modal/admin.condo.picker.modal';
import { CondoService } from '../../api/service/condo.service';
import { Condo } from '../../api/model/condo';
import { retry, switchMap, takeUntil, tap, timeout, timeoutWith } from 'rxjs/operators';
import { ErrorBuilder } from '../../api/model/error/error.builder';
import { Status } from '@api/model/status';
import { ThemeService } from '../../theme';
import { of, Subject } from 'rxjs';
import { ModalPendingCondoApprovalComponent } from '../signup/modal-pending-condo-approval/modal-pending-condo-approval';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UseTermsService } from '@api/service/use-terms.service';
import { UseTerms } from '@api/model/useTerms';
import { ModalUseTermsComponent } from '../../components/modal-terms-of-service/modal-use-terms.component';
import { AcceptedTermsService } from '@api/service/accepted-terms.service';
import { SessionService } from '@api/service/session.service';
import { environment } from '@environment';

import { environment as localEnvironment } from '../../../environments/environment.local';
import { environment as devEnvironment } from '../../../environments/environment.dev';
import { environment as prodEnvironment } from '../../../environments/environment.prod';
import { environment as mrEnvironment } from '../../../environments/environment.merge-request';
import { mr1Environment } from '../../../environments/environment.merge-request';
import { mr2Environment } from '../../../environments/environment.merge-request';
import { mr3Environment } from '../../../environments/environment.merge-request';
import { environment as latitudeDevEnviroment } from '../../../environments/environment.dev-latitude';
import { environment as latitudeQAEnviroment } from '../../../environments/environment.qa-latitude';

import { ConstantService } from '../../services/constant.service';
import { EcondosQuery } from '@api/model/query';
import { CondoRequest } from '@api/model/condo-request';

const { version: APP_VERSION } = require('../../../../package.json');

@Component({
  selector: 'app-login-page',
  templateUrl: './login.html',
  styleUrls: ['./login.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  loginForm;
  username: AbstractControl;
  password: AbstractControl;
  passwordConfirm: AbstractControl;
  isSigningUp: boolean;
  isSigningIn: boolean;
  agreed = false;
  triedToSignUp = false;

  redirect: string;

  showPassword = false;
  showPasswordConfirm = false;

  signupStatus: Status = new Status();

  logoUrl = '';
  isWhiteLabel = false;

  useTerms: UseTerms | null = null;

  env = environment;

  condoResquests: CondoRequest[] = [];

  private _destroy$ = new Subject();

  environments = {
    production: prodEnvironment,
    dev: devEnvironment,
    local: localEnvironment,
    mr0: mrEnvironment,
    mr1: mr1Environment,
    mr2: mr2Environment,
    mr3: mr3Environment,
    latitude_dev: latitudeDevEnviroment,
    latitude_qa: latitudeQAEnviroment
  };

  selectedEnvironment: FormControl<typeof environment>;

  protected readonly APP_VERSION = APP_VERSION;

  constructor(
    private utilService: UtilService,
    private userService: UserService,
    private condoService: CondoService,
    private authService: AuthService,
    private facebookService: FacebookService,
    private themeService: ThemeService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private sessionService: SessionService,
    private navbarService: NavbarService,
    private modalService: BsModalService,
    private constantsService: ConstantService,
    private paramsService: ParamsService,
    private useTermsService: UseTermsService,
    private acceptedTermsService: AcceptedTermsService
  ) {
    const initParams: InitParams = {
      appId: '279593022462005',
      xfbml: true,
      version: 'v2.9'
    };
    facebookService.init(initParams);

    this.isSigningUp = false;

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required, Validators.minLength(4), EmailValidator])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      passwordConfirm: ['']
    });
    this.username = this.loginForm.get('username');
    this.password = this.loginForm.get('password');
    this.passwordConfirm = this.loginForm.get('passwordConfirm');
    this.redirect = this.route.snapshot.params['redirect'];

    this.themeService
      .getActiveTheme()
      .pipe(
        takeUntil(this._destroy$),
        tap(theme => {
          this.isWhiteLabel = theme.name !== 'econdos';
          if (theme?.logo) {
            this.logoUrl = theme?.logo;
          } else {
            this.logoUrl = 'assets/img/logomarca.png';
          }
        }),
        switchMap(() => this.useTermsService.getActive())
      )
      .subscribe((terms: UseTerms) => {
        if (terms.enabled) {
          this.useTerms = terms;
        }
      });

    this.selectedEnvironment = new FormControl(this.constantsService.dynamicEnvironment || null);
    this.selectedEnvironment.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(env => this.constantsService.setEnvironment(env));
  }

  ngOnInit() {
    const user = this.sessionService.userValue;
    if (user) {
      this.continueLogin(user);
    } else {
      this.navbarService.hideNavBar();
    }
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  signin() {
    if (!this.loginForm.valid) {
      this.username.markAsTouched();
      this.password.markAsTouched();
      return;
    }

    this.authService.unregisterSession();

    const login = this.username.value.trim().toLowerCase();
    const password = this.password.value;
    this.isSigningIn = true;

    this.authService
      .login(login, password)
      .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
      .subscribe(
        (user: User) => {
          this.authService.registerSession(user, login, password);
          user.password = password;
          if (user.isSystemAdmin) {
            this.logAsSystemAdmin(user);
          } else {
            this.continueLogin(user);
          }
        },
        (err: Error) => {
          console.log(err);
          this.isSigningIn = false;
          if (err.type == Error.TYPES.INACTIVE_USER) {
            this.askToActivateUser(login);
            return;
          }
          swal({
            type: 'error',
            title: err.messageTitle || 'Ops...',
            text: err.message || 'Não foi possível efetuar login.\nVerifique sua conexão e seus dados de acessos e tente novamente.'
          });
        }
      );
  }

  logAsSystemAdmin(user: User) {
    const callback = (condo: Condo) => {
      this.condoService.getCondoById(condo.id).subscribe(
        (c: Condo) => {
          c.pictures = condo.pictures;
          user.defaultCondo = c;
          this.sessionService.setUser(user);
          this.sessionService.setCondo(c);
          this.userService.updateUser(user._id, user.createBackObject()).subscribe();
          this.router.navigate(['dashboard']);
        },
        err => {
          user.defaultCondo = condo;
          this.sessionService.setUser(user);
          this.sessionService.setCondo(condo);
          this.userService.updateUser(user._id, user.createBackObject()).subscribe();
          this.router.navigate(['dashboard']);
        }
      );
    };
    this.modalService.show(AdminCondoPickerModal, {
      initialState: {
        callback,
        defaultCondo: user.defaultCondo
      }
    });
    this.isSigningIn = false;
  }

  continueLogin(user: User) {
    // User com cadastro incompleto
    if (user.isOnSignUpStepOne()) {
      user.signupStep = 1;
      this.paramsService.add('userData', user);
      this.router.navigate(['signup']);
      return;
    }

    const query: EcondosQuery = {
      $select: 'user residence condo role status residence ',
      $populate: [
        { path: 'condo', select: 'name pictures', populate: { path: 'pictures', select: 'url' } },
        { path: 'residence', select: 'identification condo' }
      ],
      user: user._id,
      status: 'PENDING'
    };
    this.userService.getCondoRequests(user._id, query).subscribe(({ condoRequests }) => {
      this.condoResquests = condoRequests;
      // User sem condomínio aprovado
      if (!user.hasApprovedCondo()) {
        // User pendente aprovacao em algum condomínio
        if (this.condoResquests.length) {
          const initialState = {
            condoRequests,
            user
          };
          this.modalService.show(ModalPendingCondoApprovalComponent, { initialState, class: 'modal-md' });
          this.isSigningIn = false;
          this.authService.unregisterSession();
          // User sem condominio at all
        } else {
          this.paramsService.add('userData', user);
          this.router.navigate(['signup']);
        }
        // User com cadastro completo
      } else {
        // Trata casos onde defaultCondo esta como pending approval
        // const allApprovedCondos = [].concat(user.condos, user.condosAdmin, user.condosGatekeeper, user.condosOwner);
        const allApprovedCondos = [].concat(...user.approvedCondos);
        if (this.condoResquests.find(condoRequest => user.defaultCondo._id == condoRequest.condo._id)) {
          user.defaultCondo = allApprovedCondos[0];
          this.sessionService.setCondo(allApprovedCondos[0]);
          this.userService.updateUser(user._id, user).subscribe();
        }

        this.sessionService.setUser(this.sessionService.userValue);
        if (this.redirect && this.validateRedirect(this.redirect)) {
          this.router.navigate([this.redirect]);
        } else {
          // Vai para dashboard
          this.goToHomePage(user);
        }
      }
    });
  }

  goToHomePage(user: User) {
    if (user.isAdmin() || user.isOwner()) {
      this.router.navigate(['dashboard']);
    } else {
      this.router.navigate(['feed']);
    }
  }

  validateRedirect(redirectPath = ''): boolean {
    const isDomainRegex = new RegExp(
      '^(?:(?:(?:[a-zA-z\\-]+)\\:\\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\\-\\.]){1,61}(?:\\.[a-zA-Z]{2,})+|\\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\\]|(?:(?:[0-9]{1,3})(?:\\.[0-9]{1,3}){3}))(?:\\:[0-9]{1,5})?$'
    );
    const isDomain = isDomainRegex.test(redirectPath);
    // Checa se o redirect é um domínio
    if (isDomain) {
      // Se for domínio, faz a validação para garantir que não redireciona para fora do domínio da aplicação
      const hostname = window.location.hostname;
      const isValid = redirectPath.includes(hostname);
      return isValid;
    } else {
      return true;
    }
  }

  signUp() {
    this.isSigningUp = true;
    this.passwordConfirm.setValue('');

    this.passwordConfirm.setValidators(
      Validators.compose([
        Validators.required,
        (c: UntypedFormControl) => {
          if (c.value == this.password.value) {
            return null;
          }
          return { validatorPassword: { valid: false } };
        }
      ])
    );
  }

  submitSignUpForm() {
    if (!this.loginForm.valid) {
      this.username.markAsTouched();
      this.passwordConfirm.markAsTouched();
      this.password.markAsTouched();
      return;
    }

    if (!this.agreed) {
      this.triedToSignUp = true;
      return;
    }

    this.signupStatus.setAsProcessing();
    this.authService.unregisterSession();

    const serverData: any = {};
    serverData.email = this.username.value.trim().toLowerCase();
    serverData.password = this.password.value;
    serverData.signupStep = 1;
    const user = new User();
    this.userService
      .createUser(serverData)
      .pipe(
        timeoutWith(10000, ErrorBuilder.throwTimeoutError()),
        tap((userId: any) => {
          this.signupStatus.setAsSuccess();
          user.id = userId._id;
          user.email = serverData.email;
          user.password = serverData.password;
          user.signupStep = serverData.signupStep;
          this.utilService.saveLocalAuthorizationHeader(user.email, user.password);
          this.paramsService.add('userData', user);
        }),
        switchMap(() => {
          if (this.useTerms && this.useTerms.enabled) {
            return this.acceptedTermsService.acceptUseTerms(this.useTerms._id).pipe(timeout(10000), retry(3));
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        () => {
          this.router.navigate(['/signup']);
        },
        (err: Error) => {
          this.signupStatus.setAsError();
          let errorMessage = '';
          if (err.originalErrorMessage?.includes('duplicate')) {
            errorMessage = 'Já existe um cadastro para este e-mail.\nTente um e-mail diferente.';
          } else {
            errorMessage = err.message;
          }

          swal({
            type: 'error',
            title: err.messageTitle || `Problema ao cadastrar`,
            text:
              errorMessage ||
              'Não foi possível realizar seu cadastro, tente novamente.' + '\nSe o problema persistir entre em contato com a administração',
            cancelButtonColor: '#f53d3d'
          });
        }
      );
  }

  backToLogin() {
    this.isSigningUp = false;
    this.password.setValue('');
    this.passwordConfirm.setValue('');
    this.loginForm.controls['passwordConfirm'].clearValidators();
    this.loginForm.controls['passwordConfirm'].updateValueAndValidity();
  }

  resetPassword(username) {
    swal({
      type: 'info',
      title: `Recuperar senha`,
      text: 'Insira seu e-mail de usuário no campo abaixo e clique em recuperar minha senha',
      showCloseButton: true,
      confirmButtonText: 'Recuperar minha senha',
      confirmButtonColor: 'var(--green-500)',
      showLoaderOnConfirm: true,
      input: 'text',
      inputPlaceholder: 'Seu e-mail',
      inputValue: username || '',
      preConfirm: email => {
        if (!email || !email.trim().length) {
          return Promise.reject(`Insira seu e-mail`);
        } else {
          email = email.trim().toLowerCase();
          if (this.utilService.validEmail(email)) {
            return this.userService
              .resetUserPassword(email)
              .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
              .toPromise()
              .catch((err: Error) =>
                Promise.reject(
                  'Não foi possível enviar o e-mail para você, verifique se você digitou seu e-mail corretamente, verifique sua conexão e tente novamente....'
                )
              );
          } else {
            return Promise.reject(`Endereço de e-mail inválido!`);
          }
        }
      }
    })
      .then(() => {
        swal({
          type: 'success',
          title: 'Solicitação recebida!',
          text: `Um e-mail foi enviado para você, clique no link encontrado neste e-mail e altere sua senha.`
        });
      })
      .catch(swal.noop);
  }

  askToActivateUser(login) {
    swal({
      title: 'Ativar usuário',
      text: 'Seu usuário ainda não foi ativado, para continuar usando o eCondos clique no botão ativar e um email será enviado para você contendo o link de ativação.',
      showCancelButton: true,
      confirmButtonText: 'Ativar',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.userService
          .sendActivationEmail(login)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('O envio do email falhou. Tente novamente');
          });
      }
    }).then(
      residence => {
        console.log('response = ', residence);
        swal({
          type: 'success',
          text: 'Email enviado com sucesso!'
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  loginWithFacebook() {
    this.authService.unregisterSession();
    this.facebookService
      .login()
      .then((res: any) => {
        console.log('Logged into Facebook!', res);
        this.userService
          .loginWithSocial('facebook', res.authResponse.accessToken)
          .pipe(timeoutWith(10000, ErrorBuilder.throwTimeoutError()))
          .subscribe(
            (user: User) => {
              this.authService.registerSession(user);
              if (user.isSystemAdmin) {
                this.logAsSystemAdmin(user);
              } else {
                this.continueLogin(user);
              }
            },
            (err: Error) => {
              swal({
                type: 'error',
                title: err.messageTitle || 'Ops...',
                text: err.message || 'Não foi possível entrar com o facebook, tente novamente.'
              });
            }
          );
      })
      .catch(e => {
        console.log('Error logging into Facebook', e);
        if (e && e.errorCode === '4201') {
          return;
        }
        swal({
          type: 'error',
          title: 'Falha',
          text: 'Não foi possível entrar com o facebook, tente novamente.'
        });
      });
  }

  openModal(defaultPage = 0) {
    this.modalService.show(ModalUseTermsComponent, {
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {
        readOnly: true,
        useTerms: this.useTerms,
        defaultPageIndex: defaultPage
      }
    });
  }

  protected readonly environment = environment;
}
