import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable, of } from 'rxjs';
import { map, retry, tap, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { EcondosQuery } from '../model/query';
import { WhiteLabel } from '../model/white-label';
import { UtilService } from 'app/services/util.service';

@Injectable({ providedIn: 'root' })
export class WhiteLabelService {
  private endPoint;
  private _whiteLabels: WhiteLabel[] = [];
  private _eCondosWhiteLabel: WhiteLabel;

  constructor(
    private http: HttpService,
    private constantService: ConstantService,
    private utilService: UtilService
  ) {
    this.endPoint = `${this.constantService.getEndpoint()}white-labels/`;
  }

  get(params: EcondosQuery = {}): Observable<{ count: number; whiteLabels: WhiteLabel[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(this.endPoint, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          whiteLabels: res.body as WhiteLabel[]
        })),
        tap(res => {
          this.whiteLabels = res.whiteLabels;
        })
      );
  }

  getEcondosWhiteLabel() {
    if (this.eCondosWhiteLabel) return of(this.eCondosWhiteLabel);
    return this.get({ name: { $regex: 'eCondos', $options: 'i' }, $select: 'name' }).pipe(
      map(res => {
        this.eCondosWhiteLabel = res.whiteLabels[0];
        return this.eCondosWhiteLabel;
      })
    );
  }

  get whiteLabels(): WhiteLabel[] {
    return this._whiteLabels;
  }

  set whiteLabels(value: WhiteLabel[]) {
    this._whiteLabels = value;
  }

  get eCondosWhiteLabel(): WhiteLabel {
    return this._eCondosWhiteLabel;
  }

  set eCondosWhiteLabel(value: WhiteLabel) {
    this._eCondosWhiteLabel = value;
  }

  isEcondosWhiteLabel(): boolean {
    return (
      this.whiteLabels[0]?.companyName?.toLowerCase().includes('econdos') ||
      this.whiteLabels[0]?._id === this.eCondosWhiteLabel?._id ||
      window.location.href.includes('econdos')
    );
  }
}
