import { CustomRole } from './custom-role';

type FlattenKeys<T, Prefix extends string = ''> = {
  [K in keyof T]: T[K] extends boolean
    ? `${Prefix & string}${Prefix extends '' ? '' : '.'}${K & string}`
    : FlattenKeys<T[K], `${Prefix & string}${Prefix extends '' ? '' : '.'}${K & string}`>;
}[keyof T];

type FlattenAllKeys<T, Prefix extends string = ''> = {
  [K in keyof T]: T[K] extends boolean
    ? `${Prefix & string}${Prefix extends '' ? '' : '.'}${K & string}`
    : K extends string
      ?
          | `${Prefix & string}${Prefix extends '' ? '' : '.'}${K & string}`
          | FlattenAllKeys<T[K], `${Prefix & string}${Prefix extends '' ? '' : '.'}${K & string}`>
      : never;
}[keyof T];

type ConvertKeyValueType<T, TFrom, TTo> = {
  [K in keyof T]: T[K] extends TFrom ? TTo : ConvertKeyValueType<T[K], TFrom, TTo>;
};

export type Permissions = CustomRole['permissions'];
export type PermissionKey = FlattenKeys<Permissions>;
export type DeepPermissionKey = FlattenAllKeys<Permissions>;

export const PERMISSIONS: ConvertKeyValueType<Permissions, boolean, PermissionKey> = {
  faq: {
    view: 'faq.view',
    create: 'faq.create',
    update: 'faq.update',
    delete: 'faq.delete'
  },
  lostAndFounds: {
    view: 'lostAndFounds.view',
    create: 'lostAndFounds.create',
    update: 'lostAndFounds.update',
    delete: 'lostAndFounds.delete'
  },
  logbooks: {
    view: 'logbooks.view',
    create: 'logbooks.create',
    update: 'logbooks.update',
    delete: 'logbooks.delete',
    sendMessage: 'logbooks.sendMessage'
  },
  eventAnnouncementBoard: {
    view: 'eventAnnouncementBoard.view'
  },
  actuators: {
    view: 'actuators.view',
    create: 'actuators.create',
    update: 'actuators.update',
    delete: 'actuators.delete'
  },
  actuatorsPanel: {
    trigger: 'actuatorsPanel.trigger',
    changeOrder: 'actuatorsPanel.changeOrder'
  },
  alarmCentrals: {
    view: 'alarmCentrals.view',
    create: 'alarmCentrals.create',
    update: 'alarmCentrals.update',
    delete: 'alarmCentrals.delete'
  },

  maintenanceTickets: {
    view: 'maintenanceTickets.view',
    create: 'maintenanceTickets.create',
    update: 'maintenanceTickets.update',
    updateTicketStatus: 'maintenanceTickets.updateTicketStatus',
    viewAllUsersTickets: 'maintenanceTickets.viewAllUsersTickets'
  },
  maintenance: {
    view: 'maintenance.view',
    create: 'maintenance.create',
    update: 'maintenance.update',
    delete: 'maintenance.delete',
    execute: 'maintenance.execute'
  },
  personDeletionProcess: {
    view: 'personDeletionProcess.view',
    complete: 'personDeletionProcess.complete'
  },
  accessLiberation: {
    view: 'accessLiberation.view'
  },
  userResidences: {
    dependents: {
      view: 'userResidences.dependents.view',
      add: 'userResidences.dependents.add',
      update: 'userResidences.dependents.update',
      remove: 'userResidences.dependents.remove'
    },
    vehicles: {
      view: 'userResidences.vehicles.view',
      create: 'userResidences.vehicles.create',
      remove: 'userResidences.vehicles.remove',
      update: 'userResidences.vehicles.update'
    }
  },
  externalLinks: {
    view: 'externalLinks.view',
    create: 'externalLinks.create',
    update: 'externalLinks.update',
    delete: 'externalLinks.delete',
    access: 'externalLinks.access'
  },
  highlights: {
    view: 'highlights.view',
    create: 'highlights.create',
    update: 'highlights.update',
    delete: 'highlights.delete',
    carousel: 'highlights.carousel'
  },
  users: {
    view: 'users.view',
    create: 'users.create',
    update: 'users.update',
    delete: 'users.delete',
    convertUserToDependent: 'users.convertUserToDependent',
    resetPassword: 'users.resetPassword'
  },
  userDevices: {
    view: 'userDevices.view',
    create: 'userDevices.create',
    update: 'userDevices.update',
    delete: 'userDevices.delete'
  },
  visitorDevices: {
    view: 'visitorDevices.view',
    create: 'visitorDevices.create',
    update: 'visitorDevices.update',
    delete: 'visitorDevices.delete'
  }
};

export const PERMISSIONS_RELATIONS: Record<string, { hook: boolean; value: boolean; requiredPermission: DeepPermissionKey }[]> = {
  'users.convertUserToDependent': [
    {
      hook: true,
      value: true,
      requiredPermission: 'users.delete'
    }
  ],
  'users.delete': [
    {
      hook: false,
      value: false,
      requiredPermission: 'users.convertUserToDependent'
    }
  ]
};

export const PERMISSIONS_LABELS: Record<DeepPermissionKey, string> = {
  faq: 'Perguntas Frequentes',
  'faq.view': 'Visualizar perguntas frequentes',
  'faq.create': 'Cadastrar perguntas frequentes',
  'faq.update': 'Editar perguntas frequentes',
  'faq.delete': 'Excluir perguntas frequentes',

  lostAndFounds: 'Achados e perdidos',
  'lostAndFounds.view': 'Visualizar achados e perdidos',
  'lostAndFounds.create': 'Cadastrar achados e perdidos',
  'lostAndFounds.update': 'Editar achados e perdidos',
  'lostAndFounds.delete': 'Excluir achados e perdidos',

  logbooks: 'Livros de registros',
  'logbooks.view': 'Visualizar livros de registros',
  'logbooks.create': 'Cadastrar livros de registros',
  'logbooks.update': 'Editar livros de registros',
  'logbooks.delete': 'Excluir livros de registros',
  'logbooks.sendMessage': 'Enviar mensagem aos usuários dos livros de registros',

  eventAnnouncementBoard: 'Quadro de anúncio de eventos',
  'eventAnnouncementBoard.view': 'Vizualizar quadro de anúncio de eventos',

  actuators: 'Acionadores (somente no Monitoramento)',
  'actuators.view': 'Visualizar acionadores (somente no Monitoramento)',
  'actuators.create': 'Cadastrar acionadores (somente no Monitoramento)',
  'actuators.update': 'Editar acionadores (somente no Monitoramento para equipamentos Control ID e Módulo Guarita)',
  'actuators.delete': 'Excluir acionadores (somente no Monitoramento)',

  actuatorsPanel: 'Painel da Botoeira',
  'actuatorsPanel.trigger': 'Realizar acionamentos pela Botoeira',
  'actuatorsPanel.changeOrder': 'Alterar a ordem dos acionadores na Botoeira (somente no Monitoramento)',

  alarmCentrals: 'Centrais de alarme',
  'alarmCentrals.view': 'Visualizar centrais de alarme',
  'alarmCentrals.create': 'Cadastrar centrais de alarme',
  'alarmCentrals.update': 'Editar centrais de alarme',
  'alarmCentrals.delete': 'Excluir centrais de alarme',

  maintenanceTickets: 'Chamados de manutenção',
  'maintenanceTickets.view': 'Visualizar chamados de manutenção',
  'maintenanceTickets.create': 'Cadastrar chamados de manutenção',
  'maintenanceTickets.update': 'Editar chamados de manutenção',
  'maintenanceTickets.updateTicketStatus': 'Atualizar status de chamados de manutenção',
  'maintenanceTickets.viewAllUsersTickets': 'Visualizar chamados de manutenção de todos os usuários',

  maintenance: 'Manutenção',
  'maintenance.view': 'Visualizar planos, ações e tarefas de manutenção',
  'maintenance.create': 'Cadastrar planos e ações de manutenção',
  'maintenance.update': 'Editar planos e ações de manutenção',
  'maintenance.delete': 'Excluir planos, ações e tarefas de manutenção',
  'maintenance.execute': 'Criar uma execução de manutenção',

  personDeletionProcess: 'Processos de exclusão de pessoas',
  'personDeletionProcess.view': 'Visualizar processos de exclusão de pessoas',
  'personDeletionProcess.complete': 'Encerrar processos de exclusão de pessoas',

  accessLiberation: 'Liberação de visitantes',
  'accessLiberation.view': 'Visualizar liberações de visitantes',

  userResidences: 'Minhas unidades',
  'userResidences.dependents': 'Dependentes da unidade',
  'userResidences.dependents.view': 'Visualizar dependentes da unidade',
  'userResidences.dependents.add': 'Criar dependentes na unidade',
  'userResidences.dependents.update': 'Editar dependentes da unidade',
  'userResidences.dependents.remove': 'Excluir dependentes da unidade',

  'userResidences.vehicles': 'Veículos da unidade',
  'userResidences.vehicles.view': 'Visualizar veículos da unidade',
  'userResidences.vehicles.create': 'Criar veículos na unidade',
  'userResidences.vehicles.update': 'Editar veículos da unidade',
  'userResidences.vehicles.remove': 'Deletar veículos da unidade',

  externalLinks: 'Links externos',
  'externalLinks.view': 'Visualizar links externos',
  'externalLinks.create': 'Criar links externos',
  'externalLinks.update': 'Editar links externos',
  'externalLinks.delete': 'Excluir links externos',
  'externalLinks.access': 'Acessar links externos',

  highlights: 'Destaques e informativos',
  'highlights.view': 'Visualizar destaques e informativos',
  'highlights.create': 'Cadastrar destaques e informativos',
  'highlights.update': 'Editar destaques e informativos',
  'highlights.delete': 'Excluir destaques e informativos',
  'highlights.carousel': 'Visualizar e interagir com o carrossel de destaques e informativos',

  users: 'Tela de Usuários (Funcionalidade não compatível com zeladores e moradores)',
  'users.view': 'Visualizar usuários',
  'users.create': 'Criar usuários',
  'users.update': 'Editar usuários',
  'users.delete': 'Excluir usuários',
  'users.convertUserToDependent': 'Converter usuários em dependentes',
  'users.resetPassword': 'Redefinir senha dos usuários',

  userDevices: 'Dispositivos de usuários',
  'userDevices.view': 'Visualizar dispositivos de usuários',
  'userDevices.create': 'Cadastrar dispositivos de usuários',
  'userDevices.update': 'Editar dispositivos de usuários',
  'userDevices.delete': 'Excluir dispositivos de usuários',

  visitorDevices: 'Dispositivos de visitantes',
  'visitorDevices.view': 'Visualizar dispositivos de visitantes',
  'visitorDevices.create': 'Cadastrar dispositivos de visitantes',
  'visitorDevices.update': 'Editar dispositivos de visitantes',
  'visitorDevices.delete': 'Excluir dispositivos de visitantes'
};
