import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { UtilService } from '../../services/util.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';

interface Tab {
  link: string;
  label: string;
  icon?: string;
  enabled: boolean;
}

@Component({
  templateUrl: 'features.html',
  styleUrls: ['features.scss'],
  selector: 'app-features-tabs'
})
export class FeaturesPageComponent implements OnDestroy {
  public TABS: Tab[] = [];
  public selectedTab: string;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private utilService: UtilService
  ) {
    const condo = this.utilService.getLocalCondo();
    const user = this.utilService.getLocalUser();

    this.TABS = this.buildFeaturesTabs(user, condo);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe)
      )
      .subscribe((event: any) => {
        const url = event.url;
        if (url === '/features' && this.TABS.length) {
          const firstAvailableRoute = this.TABS[0].link;
          this.router.navigate([firstAvailableRoute]);
          this.selectedTab = url;
        } else if (url !== '/features' && url.includes('features')) {
          if (url.includes('classificados?')) {
            this.selectedTab = '/features/classificados';
            return;
          }
          // Corrige quando a funcionalidade possui sub-rotas e a url selecionada fica diferente da mapeada pela funcionalidade
          const featureUrl = url.split('/').slice(0, 3).join('/');
          this.selectedTab = featureUrl;
        } else {
          this.router.navigate(['notFound']);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  navigate(route = this.selectedTab): void {
    if (!this.router.url.includes(route)) {
      this.router.navigate([route]);
    }
  }

  buildFeaturesTabs(user: User, condo: Condo) {
    const isAdmin = user.isAdminOnCondo(condo._id) || user.isOwnerOnCondo(condo._id);
    const isGatekeeper = user.isGatekeeperOnCondo(condo._id);
    const isJanitor = user.isJanitorOnCondo(condo._id);
    const isUser = user.isUserOnCondo(condo._id) && !isJanitor;

    const allTabs: Tab[] = [
      {
        link: '/features/device-requests',
        label: 'Solicitações de dispositivos',
        icon: 'fa-microchip',
        enabled: !condo.isDeviceRequestDisabled() && (isAdmin || isGatekeeper)
      },
      {
        link: '/features/documents',
        label: 'Documentos',
        icon: 'fa-folder',
        enabled: !condo.isDocumentsDisabled() && condo.hasDocumentsAccess() && (isAdmin || isUser)
      },
      {
        link: '/features/faq',
        label: 'Perguntas frequentes',
        icon: 'fa-question-circle',
        enabled: !condo.isCondoFaqDisabled() && user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.faq.view })
      },
      {
        link: '/features/classificados',
        label: 'Classificados',
        icon: 'fa-shopping-cart',
        enabled: !condo.isClassificadosDisabled() && condo.hasAdAccess() && (isAdmin || isUser)
      },
      {
        link: '/features/rating',
        label: 'Avaliações',
        icon: 'fa-star',
        enabled: !condo.isCondoRatingDisabled() && (isAdmin || isGatekeeper || isUser)
      },
      {
        link: '/features/lost-and-founds',
        label: 'Achados e perdidos',
        icon: 'fa-search',
        enabled:
          !condo.isLostAndFoundsDisabled() && user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.lostAndFounds.view })
      },
      {
        link: '/features/asset-management',
        label: 'Controle de ativos',
        icon: 'fa-archive',
        enabled: condo.isAssetManagementEnabled() && (isAdmin || isGatekeeper || isJanitor)
      },
      {
        link: '/features/statistics',
        label: 'Estatísticas',
        icon: 'fa-line-chart',
        enabled: isAdmin
      },
      {
        link: '/features/event-announcement-board',
        label: 'Quadro de anúncios de eventos',
        icon: 'fa-desktop',
        enabled:
          condo.isEventAnnouncementBoardEnabled() &&
          user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.eventAnnouncementBoard.view })
      },
      {
        link: '/features/admin-tools',
        label: 'Ferramentas administrativas',
        icon: 'fa-shield',
        enabled: user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.personDeletionProcess.view })
      },
      {
        link: '/features/highlights',
        label: 'Destaques e informativos',
        icon: 'fa-bookmark',
        enabled: user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.highlights.view })
      },
      {
        link: '/features/logbooks',
        label: 'Livros de registros',
        icon: 'bi:book',
        enabled: condo.isLogbooksEnabled() && user.getPermissionValue({ condoId: condo._id, permission: PERMISSIONS.logbooks.view })
      }
    ];

    return allTabs.filter(tab => tab.enabled);
  }
}
