import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { AdviceOccurrence } from '@api/model/occurrence/occurrence.advice';
import { FeedCommentComponent } from '../feed-comment/feed-comment.component';
import { FeedLikersComponent } from '../feed-likers/feed-likers.component';

@Component({
  selector: 'app-advice-card',
  templateUrl: './advice-card.component.html',
  styleUrls: ['./advice-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdviceCardComponent implements OnChanges {
  @ViewChild(FeedCommentComponent) commentsComponent;

  @ViewChild(FeedLikersComponent) likersComponent;

  @Input()
  advice: AdviceOccurrence;

  @Input()
  dataTesteIdIndex: number;

  @Input()
  condo: Condo;

  @Input()
  user: User;

  @Output()
  delete: EventEmitter<AdviceOccurrence> = new EventEmitter<AdviceOccurrence>();

  @Output()
  edit: EventEmitter<AdviceOccurrence> = new EventEmitter<AdviceOccurrence>();

  @Output()
  disableComments: EventEmitter<AdviceOccurrence> = new EventEmitter<AdviceOccurrence>();

  @Output()
  enableComments: EventEmitter<AdviceOccurrence> = new EventEmitter<AdviceOccurrence>();

  @Output()
  pin: EventEmitter<AdviceOccurrence> = new EventEmitter<AdviceOccurrence>();
  @Output()
  unpin: EventEmitter<AdviceOccurrence> = new EventEmitter<AdviceOccurrence>();
  @Output()
  archive: EventEmitter<AdviceOccurrence> = new EventEmitter<AdviceOccurrence>();
  @Output()
  unarchive: EventEmitter<AdviceOccurrence> = new EventEmitter<AdviceOccurrence>();

  isAdmin;
  isGatekeeperOrJanitor;
  createdBy;
  createdAt;
  title;
  description;
  groups;
  userPictureThumbnail;
  userPicture;
  timeAgo;
  protocol;

  commentsTotal;
  commentsEnabled;
  isLiked = false;

  isPinned;
  isArchived;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this.isAdmin = changes.user?.currentValue?.isAdmin() || changes.user?.currentValue?.isOwner();
    this.isGatekeeperOrJanitor = changes.user?.currentValue?.isGatekeeper() || changes.user?.currentValue?.isJanitor();
    this.createdBy = changes.advice?.currentValue?.createdBy?.firstName + ' - Administração';
    this.createdAt = changes.advice?.currentValue?.createdAt;
    this.title = changes.advice?.currentValue?.title;
    this.description = changes.advice?.currentValue?.descriptionHtml || changes.advice?.currentValue?.description;
    this.groups = changes.advice?.currentValue?.groups;
    this.userPicture = changes.advice?.currentValue?.createdBy?.picture?.url || 'assets/img/empty-user-picture.png';
    this.userPictureThumbnail = changes.advice?.currentValue?.createdBy?.picture?.thumbnail || 'assets/img/empty-user-picture.png';
    this.timeAgo = changes.advice?.currentValue?.title;
    this.protocol = changes.advice?.currentValue?.protocol;
    this.commentsTotal = changes.advice?.currentValue?.commentsTotal;

    this.isLiked = changes.advice?.currentValue?.likers?.find(liker => this.user?._id == liker._id);

    this.commentsEnabled = this.isCommentsEnabled(changes.advice?.currentValue, changes.condo?.currentValue);

    this.isPinned = changes.advice?.currentValue?.pin;
    this.isArchived = changes.advice?.currentValue?.archived;
  }

  isCommentsEnabled(advice, condo) {
    let moderation;
    if (condo && (!advice.params?.commentsModeration || advice.params?.commentsModeration === 'CONDO_DEFAULT')) {
      moderation = condo.params.commentsModeration;
    } else {
      moderation = advice.params.commentsModeration;
    }
    return moderation === 'NONE';
  }

  loadComments() {
    this.commentsComponent.loadComments();
  }

  onCommentCreated() {
    this.commentsTotal++;
    this.cdr.detectChanges();
  }

  onCommentDeleted() {
    this.commentsTotal--;
    this.cdr.detectChanges();
  }

  like() {
    this.likersComponent.likeSubject.next('');
  }
}
