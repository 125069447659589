import { Injectable } from '@angular/core';
import { LinearServerModeService } from '@api/service/linear-server-mode.service';

@Injectable({ providedIn: 'root' })
export class ProxyService {
  constructor(private linearServerModeService: LinearServerModeService) {}

  public get linearServerMode() {
    return this.linearServerModeService;
  }
}
