import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../../theme';
import { ComponentsModule } from '../../../components/components.module';
import { SearchExternalPersonInfoModalComponent } from './search-external-person-info-modal.component';

@NgModule({
  imports: [CommonModule, ThemeModule, ComponentsModule, SearchExternalPersonInfoModalComponent],
  declarations: []
})
export class SearchExternalPersonInfoModalModule {}
