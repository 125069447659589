import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UtilService } from '../../../app/services/util.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';

interface Tab {
  name: string;
  label: string;
  icon?: string;
}

const { version: APP_VERSION } = require('../../../../package.json');

@Component({
  templateUrl: 'more-options.html',
  styleUrls: ['more-options.scss'],
  selector: 'app-more-options-tabs'
})
export class MoreOptionsPageComponent {
  public TABS: Tab[] = [];
  public selectedTab: string;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private utilService: UtilService
  ) {
    const condo = this.utilService.getLocalCondo();
    const user = this.utilService.getLocalUser();

    this.TABS = this.buildFeaturesTabs(user, condo);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe)
      )
      .subscribe((event: any) => {
        this.setSelectedTabBasedOnURL(event.url);
      });
  }

  buildFeaturesTabs(user: User, condo: Condo): Tab[] {
    const tabs: Tab[] = [{ name: 'data-security', label: 'Proteção de dados', icon: 'shield' }];

    if (user.isOwner() || user.isAdmin() || user.isGatekeeper() || user.isJanitor()) {
      const downloads = { name: 'downloads', label: 'Downloads', icon: 'download' };
      const systemStatus = { name: 'services-status', label: 'Diagnóstico do sistema', icon: 'signal' };
      const lprDebugger = { name: 'lpr-tester', label: 'Testador LPR alphadigi', icon: 'car' };
      tabs.push(downloads, systemStatus, lprDebugger);
    }

    if (user.isOwner() || user.isAdmin()) {
      tabs.push({ name: 'imports', label: 'Importações', icon: 'upload' });
    }

    if (user.isSystemAdmin) {
      const deviceTester = { name: 'device-tester', label: 'Testador de dispositivos', icon: 'microchip' };
      tabs.push(deviceTester, { name: 'customize-theme', label: 'Customizar tema', icon: 'paint-brush' });
      tabs.push({ name: 'customize-theme', label: 'Customizar tema', icon: 'paint-brush' });
    }

    this.selectedTab = tabs[0].name;

    return tabs;
  }

  setSelectedTabBasedOnURL(url = this.router.url): void {
    const tabsRoutes = this.TABS.map(tab => tab.name);

    tabsRoutes.forEach(route => {
      if (url.includes(route)) {
        this.selectedTab = route;
      }
    });
  }

  navigate(route = this.selectedTab): void {
    if (!this.router.url.includes(route)) {
      this.router.navigate([`more-options/${route}`]);
    }
  }
}
