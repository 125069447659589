/**
 * Created by Rafael on 02/03/2017.
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { UtilService } from '../../../services/util.service';
import swal from 'sweetalert2';
import { User } from '../../../api/model/user';
import { UserService } from '../../../api/service/user.service';
import { timeout } from 'rxjs/operators';
import { Condo } from '@api/model/condo';

@Component({
  templateUrl: 'modal-notification-manager.html',
  styleUrls: ['modal-notification-manager.scss']
})
export class ModalNotificationManagerComponent implements OnInit {
  @ViewChild('notificationManagerModal') notificationManagerModal: ModalDirective;
  user: User;
  userRole: string;
  ROLES = User.ROLES;
  condo: Condo;
  public isSubmiting = false;

  notificationsConfig;

  notificationsConfigKeys;
  filteredNotificationKeysByRole = [];

  constructor(
    private utilService: UtilService,
    private userService: UserService,
    public bsModalRef: BsModalRef
  ) {
    this.condo = utilService.getLocalCondo();
    this.notificationsConfig = {
      'NEW-PRIVATE-OCCURRENCE': {
        label: 'Nova ocorrência',
        description: 'Notificação enviada quando houver uma nova ocorrência que você tenha acesso.',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER]
      },
      'NEW-ADVICE-OCCURRENCE': {
        label: 'Novo aviso',
        description: 'Notificação enviada quando houver um novo aviso cadastrado.',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER, User.ROLES.JANITOR]
      },
      'NEW-DELIVERY-OCCURRENCE': {
        label: 'Nova encomenda',
        description: 'Notificação enviada quando houver uma nova encomenda cadastrada.',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER]
      },
      'NEW-VOTING-OCCURRENCE': {
        label: 'Nova enquete',
        description: 'Notificação enviada quando houver uma nova enquete cadastrada.',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER]
      },
      'NEW-ACCESS-OCCURRENCE': {
        label: 'Nova liberação de acesso',
        description: 'Notificação enviada quando houver uma nova liberação de acesso cadastrada.',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER]
      },
      'COMMENT-NEW': {
        label: 'Novo comentário',
        description:
          'Notificação enviada quando houver um novo comentário em alguma ocorrência, aviso, enquete ou encomenda que você tenha acesso',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER]
      },
      'OCCURRENCE-LIKE': {
        label: 'Novo like',
        description: 'Notificação enviada quando houver um novo like em uma enquete ou aviso criado por você',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER]
      },
      'OCCURRENCE-VOTE': {
        label: 'Novo voto',
        description: 'Notificação enviada quando há um novo voto em alguma enquete',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER]
      },
      'ADVERTISE-NEW': {
        label: 'Novo classificado',
        description: 'Notificação enviada quando um classficado é criado',
        push: false,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER]
      },
      'LOST-AND-FOUND-NEW': {
        label: 'Novo achado e perdido',
        description: 'Notificação enviada quando um novo objeto é registrado no achados e perdidos',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER]
      },
      'USER-NEW-CONDO-REQUESTER': {
        label: `Solicitação de acesso no(a) ${this.condo?.customLabels?.condo?.singular}`,
        description: `Notificação enviada para ${this.condo?.customLabels?.admin?.plural || 'administradores'} de ${
          this.condo?.customLabels?.condo?.singular
        } quando um usuário pede acesso no(a) ${this.condo?.customLabels?.condo?.singular}`,
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER]
      },
      'USER-NEW-CONDO-USER': {
        label:
          'Novo ' + (this.condo?.customLabels?.resident?.singular || 'condômino') + ' no(a) ' + this.condo?.customLabels?.condo?.singular,
        description:
          'Notificação enviada quando um novo ' +
          (this.condo?.customLabels?.resident?.singular || 'condômino') +
          ' é adicionado em seu(sua) ' +
          this.condo?.customLabels?.condo?.singular,
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER]
      },
      'USER-NEW-CONDO-ADMIN': {
        label: 'Novo ' + (this.condo?.customLabels?.admin?.singular || 'administrador'),
        description:
          'Notificação enviada quando um novo ' +
          (this.condo?.customLabels?.admin?.singular || 'administrador') +
          ' é adicionado em seu(sua) ' +
          this.condo?.customLabels?.condo?.singular,
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER, User.ROLES.JANITOR]
      },
      'USER-NEW-CONDO-OWNER': {
        label: 'Novo ' + (this.condo?.customLabels?.owner?.singular || 'síndico'),
        description:
          'Notificação enviada quando há um novo ' +
          (this.condo?.customLabels?.owner?.singular || 'síndico') +
          ' em seu(sua) ' +
          this.condo?.customLabels?.condo?.singular,
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER, User.ROLES.JANITOR]
      },
      'USER-NEW-CONDO-GATEKEEPER': {
        label: 'Novo ' + (this.condo?.customLabels?.gatekeeper?.singular || 'porteiro'),
        description:
          'Notificação enviada quando há um novo ' +
          (this.condo?.customLabels?.gatekeeper?.singular || 'porteiro') +
          ' em seu(sua) ' +
          this.condo?.customLabels?.condo?.singular,
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER, User.ROLES.JANITOR]
      },
      'USER-NEW-RESIDENCE-REQUESTER': {
        label: `Solicitação em ${this.condo?.customLabels?.residence?.singular || 'unidade'}`,
        description:
          'Notificação enviada quando um ' +
            (this.condo?.customLabels?.resident?.singular || 'condômino') +
            ' solicita acesso num(a) ' +
            this.condo?.customLabels?.residence?.singular || 'unidade',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER]
      },
      'USER-NEW-RESIDENCE-USER': {
        label:
          'Novo ' +
            (this.condo?.customLabels?.resident?.singular || 'condômino') +
            ' da(do) ' +
            this.condo?.customLabels?.residence?.singular || 'unidade',
        description:
          'Notificação enviada quando um novo ' +
            (this.condo?.customLabels?.resident?.singular || 'condômino') +
            ' é adicionado num(a) ' +
            this.condo?.customLabels?.residence?.singular || 'unidade',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER]
      },
      'USER-NEW-RESIDENCE-VOTER': {
        label: 'Novo ' + (this.condo?.customLabels?.voter?.singular || 'proprietário'),
        description:
          'Notificação enviada quando o ' +
            (this.condo?.customLabels?.voter?.singular || 'proprietário') +
            ' de um(a) ' +
            this.condo?.customLabels?.residence?.singular || 'unidade' + ' é alterada',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER]
      },
      'USER-REMOVED': {
        label: 'Usuário removido',
        description: 'Notificação enviada quando um usuário é removido do(a) ' + this.condo?.customLabels?.condo?.singular,
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER]
      },
      'USER-REMOVED-CONDO-ADMIN': {
        label: 'Remoção de ' + (this.condo?.customLabels?.admin?.singular || 'administrador'),
        description: `Notificação enviada quando um ${
          this.condo?.customLabels?.admin?.singular || 'administrador'
        } é removido de seu(sua) ${this.condo?.customLabels?.condo?.singular}`,
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER, User.ROLES.JANITOR]
      },
      'USER-REMOVED-CONDO-GATEKEEPER': {
        label: 'Remoção de ' + (this.condo?.customLabels?.gatekeeper?.singular || 'porteiro'),
        description: `Notificação enviada quando um ${
          this.condo?.customLabels?.gatekeeper?.singular || 'porteiro'
        } é removido de seu(sua) ${this.condo?.customLabels?.condo?.singular}`,
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER, User.ROLES.JANITOR]
      },
      'RESERVATION-CREATED': {
        label: 'Criação de reserva',
        description: 'Notificação enviada quando uma reserva é criada',
        push: false,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.GATEKEEPER]
      },
      'RESERVATION-CANCELED': {
        label: 'Cancelamento de reserva',
        description: 'Notificação enviada quando uma reserva é cancelada',
        push: false,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER]
      },
      'RESERVATION-APPROVED': {
        label: 'Aprovação de reserva',
        description: 'Notificação enviada quando uma reserva é aprovada',
        push: false,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER]
      },
      'RESERVATION-REJECTED': {
        label: 'Rejeição de reserva',
        description: 'Notificação enviada quando uma reserva é rejeitada',
        push: false,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.USER, User.ROLES.GATEKEEPER]
      },
      'GATE-NOTEBOOK-MESSAGE-NEW': {
        label: 'Registro no livro da portaria',
        description: 'Notificação enviada quando um novo registro é adicionado no livro de ocorrências da portaria',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.GATEKEEPER]
      },
      'MAINTENANCE-TICKET-NEW': {
        label: 'Novo chamado de manutenção',
        description: 'Notificação enviada quando um novo chamado de manutenção for criado',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.GATEKEEPER, User.ROLES.JANITOR]
      },
      'MAINTENANCE-ACTION-EXECUTION-ALERT': {
        label: 'Execução de ação de manutenção próxima',
        description: 'Notificação enviada quando houver alguma ação de manutenção a ser realizadas nos próximos dias',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.JANITOR]
      },
      'DEPENDENT-NEW': {
        label: `Novo ${this.condo.customLabels.dependent.plural || (this.condo.isBusinessCondo() ? 'funcionário' : 'dependente')}`,
        description:
          'Notificação enviada quando um ' +
            (this.condo?.customLabels?.resident?.singular || 'condômino') +
            ' cadastra um novo' +
            (this.condo.customLabels.dependent.plural || (this.condo.isBusinessCondo() ? 'funcionários' : 'dependentes')) +
            ' em sua(seu) ' +
            this.condo?.customLabels?.residence?.singular || 'unidade',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER]
      },
      'VEHICLE-NEW': {
        label: 'Novo veículo',
        description:
          'Notificação enviada quando um ' +
            (this.condo?.customLabels?.resident?.singular || 'condômino') +
            ' cadastra um novo veículo em sua(seu) ' +
            this.condo?.customLabels?.residence?.singular || 'unidade',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER]
      },
      'PET-NEW': {
        label: 'Novo pet',
        description:
          'Notificação enviada quando um ' +
            (this.condo?.customLabels?.resident?.singular || 'condômino') +
            ' cadastra um novo pet em sua(seu) ' +
            this.condo?.customLabels?.residence?.singular || 'unidade',
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER]
      },
      'CONDO-REQUEST-APPROVED': {
        label: 'Solicitação de acesso aprovada',
        description: 'Notificação enviada quando um usuário é aprovado no(a)' + this.condo.customLabels.condo.singular,
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.GATEKEEPER]
      },
      'CONDO-REQUEST-REJECTED': {
        label: 'Solicitação de acesso rejeitada',
        description: 'Notificação enviada quando um usuário é rejeitado no(a) ' + this.condo.customLabels.condo.singular,
        push: true,
        email: false,
        roles: [User.ROLES.ADMIN, User.ROLES.OWNER, User.ROLES.GATEKEEPER]
      }
    };
    this.notificationsConfigKeys = Object.keys(this.notificationsConfig);
  }

  ngOnInit(): void {
    if (this.user) {
      if (this.user.isOwner()) {
        this.userRole = User.ROLES.OWNER;
      } else if (this.user.isAdmin()) {
        this.userRole = User.ROLES.ADMIN;
      } else if (this.user.isGatekeeper()) {
        this.userRole = User.ROLES.GATEKEEPER;
      } else if (this.user.isJanitor()) {
        this.userRole = User.ROLES.JANITOR;
      } else if (this.user.isUser()) {
        this.userRole = User.ROLES.USER;
      }
    }
    this.readUserNotifications(this.user);
    this.filteredNotificationKeysByRole = this.notificationsConfigKeys.filter(key =>
      this.notificationsConfig[key].roles.some(role => role === this.userRole)
    );
  }

  private readUserNotifications(user) {
    if (user && user.params && user.params.notifications) {
      const userKeys = Object.keys(user.params.notifications);
      for (const key of userKeys) {
        if (this.notificationsConfig[key]) {
          this.notificationsConfig[key].push = user.params.notifications[key].push;
          this.notificationsConfig[key].email = user.params.notifications[key].email;
        }
      }
    }
  }

  updateUserNotifications() {
    this.isSubmiting = true;
    const notificationsSettings: any = {};
    for (const key of this.notificationsConfigKeys) {
      notificationsSettings[key] = {
        push: this.notificationsConfig[key].push,
        email: this.notificationsConfig[key].email
      };
    }
    const user: any = {
      params: {
        notifications: notificationsSettings
      }
    };
    this.userService
      .updateUser(this.user.id, user)
      .pipe(timeout(10000))
      .subscribe(
        () => {
          this.user.params = {
            notifications: user.params.notifications
          };
          this.utilService.saveLocalUser(this.user);
          this.isSubmiting = false;
          this.bsModalRef.hide();
        },
        err => {
          this.isSubmiting = false;
          swal({
            text: 'Não foi possível atualizar suas definições de notificações, tente novamente.'
          });
        }
      );
  }
}
