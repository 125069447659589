import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlarmCentral, buildAlarmCentral } from '@api/model/hardware/alarm-central';
import { EcondosQuery } from '@api/model/query';
import { ConstantService } from 'app/services/constant.service';
import { HttpService } from 'app/services/http.service';
import * as qs from 'qs';
import { map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

export interface GetAlarmCentralsResponse {
  count: number;
  alarmCentrals: AlarmCentral[];
}

@Injectable({ providedIn: 'root' })
export class AlarmCentralService {
  protected endPoint = `${this.constantService.getV2Endpoint()}condos/`;

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService
  ) {}

  getAlarmCentrals(condoId: string, query: EcondosQuery = {}): Observable<GetAlarmCentralsResponse> {
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/alarm-centrals`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        alarmCentrals: res.body.map(alarmCentral => buildAlarmCentral(alarmCentral))
      }))
    );
  }

  createAlarmCentral(condoId: string, alarmCentral: Omit<AlarmCentral, '_id'>) {
    return this.http.post(`${this.endPoint}${condoId}/alarm-centrals`, alarmCentral);
  }

  updateAlarmCentral(condoId: string, alarmCentralId: string, alarmCentral: Partial<AlarmCentral>) {
    return this.http.put(`${this.endPoint}${condoId}/alarm-centrals/${alarmCentralId}`, alarmCentral);
  }

  deleteAlarmCentral(condoId: string, alarmCentralId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/alarm-centrals/${alarmCentralId}`);
  }
}
