import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Marketplace } from '@api/model/marketplace';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';

@Injectable()
export class MarketplaceService {
  private endPoint;

  constructor(
    private http: HttpService,
    private constantService: ConstantService
  ) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}`;
  }

  getMarketplaces(params: EcondosQuery): Observable<Marketplace[]> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .get(`${this.endPoint}marketplaces`, { params: httpParams })
      .pipe(map((marketplaces: any) => marketplaces.map(marketplace => <Marketplace>marketplace)));
  }
}
