import { Condo } from '@api/model/condo';

export enum PROVIDERS_TYPE {
  BLINDADO = 'BLINDADO',
  ECONDOS = 'ECONDOS'
}

export enum RISK_COLLORS {
  VERDE = 'VERDE',
  AMARELO = 'AMARELO',
  LARANJA = 'LARANJA',
  VERMELHO = 'VERMELHO'
}

export enum RISK_COLORS_LABELS {
  VERDE = 'Seguro',
  AMARELO = 'Mediano',
  LARANJA = 'Merece atenção',
  VERMELHO = 'Perigoso'
}

export const EXTERNAL_TYPES = [
  { value: 'RESIDENT', label: 'Condômino' },
  { value: 'DEPENDENT', label: 'Dependente' },
  { value: 'VISITOR', label: 'Visitante' },
  { value: 'PROVIDER', label: 'Prestador' },
  { value: 'SUPPLIER', label: 'Fornecedor' },
  { value: 'DELIVERYMAN', label: 'Entregador' },
  { value: 'PUBLIC_AGENT', label: 'Agente público' },
  { value: 'DRIVER', label: 'Motorista (Aplicativos/Taxi)' },
  { value: 'EMPLOYEE', label: 'Funcionário' },
  { value: 'RELATIVE', label: 'Parente' },
  { value: 'AIRBNB', label: 'Airbnb' },
  { value: 'TENANT', label: 'Locatário' },
  { value: 'BROKER', label: 'Corretor' },
  { value: 'OTHER', label: 'Outros' }
];

export interface ExternalPersonInfo {
  _id: string;
  condo: Condo;
  cpf: string;
  createdAt?: string;
  type?: string;
  rg?: string;
  address?: string;
  name: string;
  birthDate?: string;
  lastSearchId?: string;
  lastProvider?: PROVIDERS_TYPE;
  lastSearchTime?: string;
  searchHistory?: {
    startSearchTime: string;
    endSearchTime: string;
    searchId: string;
    provider: PROVIDERS_TYPE;
    risk: RISK_COLLORS;
    resume: string;
    hasWarrant: boolean;
    warrantDetails: string;
    hasProcess: boolean;
    processDetails: string;
  }[];
}

export type ExternalPersonInfoOnlyWithId = Pick<ExternalPersonInfo, '_id'>;

export const BuildExternalPersonInfo = (
  externalPersonInfo: Partial<ExternalPersonInfo>
): ExternalPersonInfoOnlyWithId | ExternalPersonInfo => {
  if (typeof externalPersonInfo == 'string') {
    return {
      _id: externalPersonInfo
    };
  }

  if (externalPersonInfo) {
    return {
      _id: externalPersonInfo._id || '',
      condo: externalPersonInfo.condo ? new Condo(externalPersonInfo.condo) : ({} as Condo),
      type: externalPersonInfo.type || 'VISITOR',
      cpf: externalPersonInfo.cpf || '',
      rg: externalPersonInfo.rg || '',
      address: externalPersonInfo.address || '',
      name: externalPersonInfo.name || '',
      birthDate: externalPersonInfo.birthDate || '',
      lastSearchId: externalPersonInfo.lastSearchId || '',
      lastProvider: externalPersonInfo.lastProvider ? PROVIDERS_TYPE[externalPersonInfo.lastProvider] : null,
      lastSearchTime: externalPersonInfo.lastSearchTime || '',
      createdAt: externalPersonInfo.createdAt || '',
      searchHistory: externalPersonInfo.searchHistory?.map(searchHistory => ({
        startSearchTime: searchHistory.startSearchTime || '',
        endSearchTime: searchHistory.endSearchTime || '',
        searchId: searchHistory.searchId || '',
        provider: searchHistory.provider ? PROVIDERS_TYPE[searchHistory.provider] : null,
        risk: searchHistory.risk ? RISK_COLLORS[searchHistory.risk] : null,
        resume: searchHistory.resume || '',
        hasWarrant: searchHistory.hasWarrant || false,
        warrantDetails: searchHistory.warrantDetails || '',
        hasProcess: searchHistory.hasProcess || false,
        processDetails: searchHistory.processDetails || ''
      }))
    };
  }
};
