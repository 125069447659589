import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Condo } from '@api/model/condo';
import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { catchError, expand, last, map, switchMap, takeWhile } from 'rxjs/operators';
import { BuildExternalPersonInfo, ExternalPersonInfo, PROVIDERS_TYPE } from '@api/model/external-person-info';
import { of, throwError, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExternalPersonInfoService {
  protected endPoint = `${this.constantService.getV3Endpoint()}condos/`;
  private readonly MAX_RETRIES = 5; // Número máximo de tentativas
  private readonly INITIAL_DELAY = 15000; // Tempo inicial (15s)
  private readonly MAX_DELAY = 120000; // Tempo máximo de espera (120s)

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService
  ) {}

  public searchCpf(condoId: string, cpf: string, provider = PROVIDERS_TYPE.ECONDOS) {
    const params = new HttpParams({
      fromObject: {
        cpf,
        provider
      }
    });

    return this.http.get(`${this.endPoint}${condoId}/external-person-info/consultarCPF`, { params });
  }

  getExternalPersonInfo(
    condoId: Condo['_id'],
    query: EcondosQuery = {}
  ): Observable<{
    count: number;
    externalPersonInfos: ExternalPersonInfo[];
  }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/external-person-info`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        externalPersonInfos: res.body.map(res => BuildExternalPersonInfo(res))
      }))
    );
  }

  getExternalPersonInfoSearchToken(
    condoId: string,
    query: EcondosQuery,
    token = ''
  ): Observable<{
    count: number;
    externalPersonInfos: ExternalPersonInfo[];
  }> {
    query.token = token;
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = {
      headers: new HttpHeaders(),
      params,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/external-person-info/searchToken`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        externalPersonInfos: res.body.map(res => BuildExternalPersonInfo(res))
      }))
    );
  }

  postSearchPersonInfoInProvider(
    condoId: string,
    data: {
      data: {
        cpf: string;
        nome: string;
        novaConsulta: boolean;
      };
      provider: string;
    }
  ): Observable<{ _id: string }> {
    return this.http.post(`${this.endPoint}${condoId}/external-person-info/consulta`, data).pipe(
      map((res: any) => ({
        _id: res._id
      }))
    );
  }

  getSearchPersonInfoInProvider(
    condoId: string,
    id: string,
    provider = PROVIDERS_TYPE.BLINDADO
  ): Observable<{ externalPersonInfo: ExternalPersonInfo }> {
    const params = new HttpParams({ fromString: qs.stringify({ id, provider }) });
    const options = {
      headers: new HttpHeaders(),
      params,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/external-person-info/consulta`, options).pipe(
      map((res: any) => ({
        externalPersonInfo: BuildExternalPersonInfo(res.body) as ExternalPersonInfo
      }))
    );
  }

  //Função para fazer tentativas de busca dos dados da pessoa no provedor a cada x segundos sempre dobrando o tempo entre as requisições
  waitProviderResponse(condoId: string, id: string, provider: PROVIDERS_TYPE = PROVIDERS_TYPE.BLINDADO) {
    let attempt = 0;
    return timer(this.INITIAL_DELAY).pipe(
      // Começa a primeira tentativa após 15s
      switchMap(() =>
        this.getSearchPersonInfoInProvider(condoId, id, provider).pipe(
          expand(response => {
            attempt++;

            if (attempt >= this.MAX_RETRIES) {
              return throwError(() => new Error('Número máximo de tentativas atingido.'));
            }

            if (!response || !response.externalPersonInfo.searchHistory?.length) {
              const delayTime = Math.min(this.INITIAL_DELAY * Math.pow(2, attempt - 1), this.MAX_DELAY);
              return timer(delayTime).pipe(switchMap(() => this.getSearchPersonInfoInProvider(condoId, id, provider)));
            }

            return of(response);
          }),
          takeWhile(response => !response?.externalPersonInfo.searchHistory?.length, true), // Continua até o campo `searchHistory` existir
          catchError(error => {
            return throwError(() => error);
          }),
          last()
        )
      )
    );
  }

  getCPFUnmasked(condoId: string, externalPersonInfoId: string): Observable<{ cpf: string }> {
    return this.http.get(`${this.endPoint}${condoId}/external-person-info/${externalPersonInfoId}/unmask`, {}).pipe(
      map((res: any) => ({
        cpf: res.cpf
      }))
    );
  }
}
