import { ConstantService } from '../../services/constant.service';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isElectron } from '@api/utils';

@Injectable({ providedIn: 'any' })
export class RemoteCheckService {
  private readonly endPoint: string;

  constructor(
    private http: HttpClient,
    private constantService: ConstantService
  ) {
    this.endPoint = `${this.constantService.getV3Endpoint()}condos/`;
  }

  public getRemoteCheckStatus(host: string, port: number): Observable<boolean> {
    let request$;
    if (isElectron()) {
      request$ = this.http.get(`http://${host}:${port}/api/v1/health-check`, { responseType: 'text' });
    } else {
      request$ = this.http.get(`http://localhost:5050/http://${host}:${port}/api/v1/health-check`, { responseType: 'text' });
    }
    return request$.pipe(
      timeout(10000),
      map(() => {
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  public sendConfigToRemoteCheck(host: string, port: number, actuatorId: string): Observable<boolean> {
    const data = { customIp: host };
    let request$;
    if (isElectron()) {
      request$ = this.http.post(`http://${host}:${port}/api/v1/actuators/${actuatorId}/config`, data);
    } else {
      request$ = this.http.post(`http://localhost:5050/http://${host}:${port}/api/v1/actuators/${actuatorId}/config`, data);
    }
    return request$.pipe(
      timeout(10000),
      map(() => {
        return true;
      })
    );
  }

  public checkRemoteCheckConnection(accessToken: string, condoId: string): Observable<{ isConnected: boolean }> {
    return this.http.get(`${this.endPoint}${condoId}/offline/${accessToken}/checkConnection`).pipe(
      map((res: { isConnected: boolean }) => {
        return res;
      })
    );
  }
}
