import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { ActiveVisitor, buildActiveVisitor } from '@api/model/active-visitor';

@Injectable()
export class ActiveVisitorService {
  protected endPoint;

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService
  ) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  getActiveVisitors(condoId: string, query: EcondosQuery = {}): Observable<{ count: number; activeVisitors: ActiveVisitor[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });

    return this.http.getWithFullResponse(`${this.endPoint}${condoId}/active-visitor`, { params }).pipe(
      map((res: any) => {
        if (query.$count) {
          return {
            count: res.body[0],
            activeVisitors: []
          };
        } else {
          return {
            count: parseInt(res.headers.get('count')),
            activeVisitors: res.body.map(activeVisitor => buildActiveVisitor(activeVisitor))
          };
        }
      })
    );
  }

  getActiveVisitorsWithToken(condoId: string, query: EcondosQuery, token = ''): Observable<{ activeVisitors: ActiveVisitor[] }> {
    query.token = token;
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = {
      headers: new HttpHeaders(),
      params,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/active-visitor/searchToken`, options).pipe(
      map((res: any) => ({
        activeVisitors: res.body.map(activeVisitor => buildActiveVisitor(activeVisitor))
      }))
    );
  }
}
