import { File } from './file';
import { BackObject } from './interface/back.object';
import { ContactID } from './contact/contact.id';
import { Condo } from './condo';
import { ResidenceBuilder } from './residence/residence.builder';
import { Residence } from './interface/residence';
import { MARITAL_STATUS } from './constants';
import { BuildCustomRole, CustomRole } from './custom-role/custom-role';
import { get as _get } from 'lodash';
import { PermissionKey, Permissions } from './custom-role/custom-role-permissions';
import { capitalize } from '@api/util/util';

interface CondoResidenceAttribute {
  condo: string;
  residence: string;
  userLabel: string;
}

export class User implements BackObject {
  static ROLES = {
    OWNER: 'OWNER',
    GATEKEEPER: 'GATEKEEPER',
    USER: 'USER',
    ADMIN: 'ADMIN',
    REQUESTER: 'REQUESTER',
    JANITOR: 'JANITOR'
  };

  static ROLES_LABEL = {
    OWNER: 'Síndico',
    GATEKEEPER: 'Porteiro',
    USER: 'Condômino',
    ADMIN: 'Administrador',
    REQUESTER: 'Solicitante',
    JANITOR: 'Zelador'
  };

  public _id = '';
  public firstName = '';
  public lastName = '';
  public maritalStatus: MARITAL_STATUS;
  public occupation: string;
  public specialNeeds: boolean;
  public specialNeedsDetails: string;
  public panicWord: string;
  private _phones: Array<any> = [];
  private _ids: Array<ContactID> = new Array<ContactID>();
  public tempPass = false;
  public birthDate: Date | string = null;
  public signupStep: number = null;
  public activationKey = '';
  private _picture: any = null;
  private _defaultCondo: any = null;
  private _defaultResidence: any = null;
  private _condos: any = [];
  private _condosAdmin: any = [];
  private _condosOwner: any = [];
  private _condosJanitor: any = [];
  private _condosRequester: any = [];
  private _condosGatekeeper: any = [];
  private _residencesUser: any = [];
  private _residencesRequester: any = [];
  private _residencesVoter: any = [];
  public email = '';
  public emails: Array<string> = [];
  public password: string = null;
  public createdBy: any = null;
  public createdAt: Date | string = null;
  public updatedBy: any = null;
  public language = 'pt';
  public role: string[] = [];
  public params: any = null;
  public isEfoneEnabled: boolean = false;
  public efone: {
    enabled: boolean;
    userId: number;
  };
  public activated: boolean;
  public token = '';
  public isSystemAdmin;
  public isVendor;
  public vendorCode: string;
  public indicationCode: string;
  public residenceRequester: {
    residence: string;
    role: 'OWNER_AND_RESIDENT' | 'OWNER_ONLY' | 'RESIDENT_ONLY';
    condo: string;
  };
  public isDataMasked: {
    phones: boolean;
    email: boolean;
    emails: boolean;
    birthDate: boolean;
    ids: boolean;
  };
  public hardwareParams: {
    intelbras: {
      id: number;
      pessoaId: number;
      deletedAt: Date;
    };
  };
  public condoResidenceAttributes: CondoResidenceAttribute[];
  private _userTermsOfService: [{ termsOfService: any; acceptedVersion: Number }];
  public permissions: Record<Condo['_id'], Permissions> = {};
  public customRoles: CustomRole[] = [];

  constructor(user?) {
    if (user) {
      this._id = user._id || user;
      this.firstName = user.firstName || '';
      this.lastName = user.lastName || '';
      this._phones = user.phones || [];
      this.tempPass = user.tempPass;
      this.birthDate = user.birthDate || '';
      this.occupation = user.occupation || '';
      this.maritalStatus = user.maritalStatus || '';
      this.panicWord = user.panicWord || '';
      this.specialNeeds = user.specialNeeds || false;
      this.specialNeedsDetails = user.specialNeedsDetails || '';
      this.signupStep = user.signupStep || 0;
      this.activationKey = user.activationKey || '';
      this._picture = user.picture ? new File(user.picture) : null;
      this._defaultResidence = user.defaultResidence || null;
      this._condos = user.condos ? user.condos.map(condo => new Condo(condo)) : [];
      this._condosAdmin = user.condosAdmin ? user.condosAdmin.map(condo => new Condo(condo)) : [];
      this._condosOwner = user.condosOwner ? user.condosOwner.map(condo => new Condo(condo)) : [];
      this._condosRequester = user.condosRequester ? user.condosRequester.map(condo => new Condo(condo)) : [];
      this._condosGatekeeper = user.condosGatekeeper ? user.condosGatekeeper.map(condo => new Condo(condo)) : [];
      this._condosJanitor = user.condosJanitor ? user.condosJanitor.map(condo => new Condo(condo)) : [];
      this._residencesUser = user.residencesUser || [];
      this._residencesRequester = user.residencesRequester || [];
      this._residencesVoter = user.residencesVoter || [];
      this.isEfoneEnabled = user.isEfoneEnabled || false;
      this.email = user.email || '';
      this.emails = user.emails || [];
      this.password = user.password || '';
      this.createdBy = user.createdBy || {};
      this.createdAt = user.createdAt || '';
      this.updatedBy = user.updatedBy || {};
      this.language = user.language || 'pt';
      this._ids = user.ids ? user.ids.map(id => new ContactID(id)) : [];
      this.params = user.params || null;
      this.activated = user.activated;
      this.token = user.token || '';
      this.isSystemAdmin = user.isSystemAdmin;
      this.isVendor = user.isVendor || false;
      this.vendorCode = user.vendorCode || '';
      this.indicationCode = user.indicationCode || '';
      this.residenceRequester = user.residenceRequester || {};
      this.isDataMasked = {
        phones: user.phones?.some(phone => phone?.includes('*')),
        emails: user.emails?.some(email => email?.includes('*')),
        email: user.email?.includes('*'),
        birthDate: user.birthDate?.includes('3000'),
        ids: user.ids?.some(id => id.number?.includes('*'))
      };
      this.hardwareParams = user.hardwareParams || {};
      this.condoResidenceAttributes = user.condoResidenceAttributes || [];
      this._userTermsOfService = user.userTermsOfService || [];
      this.efone = user.efone || null;
      this.permissions = user.permissions || {};
      this.customRoles = user.customRoles?.length ? user.customRoles.map(customRole => BuildCustomRole(customRole)) : [];

      // Sets default condo
      if (this.isSystemAdmin) {
        this.defaultCondo = user.defaultCondo ? new Condo(user.defaultCondo) : null;
      } else {
        const allUserCondo = [].concat(
          this.condosOwner,
          this.condosAdmin,
          this.condosGatekeeper,
          this.condos,
          this.condosRequester,
          this.condosJanitor
        );
        if (user.defaultCondo) {
          const condo = allUserCondo.find(c => c.id === user.defaultCondo._id);
          this.defaultCondo = condo ? new Condo(user.defaultCondo) : null;
        }

        if (!this.defaultCondo && allUserCondo.length) {
          this.defaultCondo = allUserCondo[0];
        }
      }

      if (this.defaultCondo) {
        this.setDefaultResidence();
        this.role = this.setUserRole();
      } else {
        this.role = user.role || [];
      }
    }
  }

  isAdmin() {
    return this.role.includes(User.ROLES.ADMIN);
  }

  isGatekeeper() {
    return this.role.includes(User.ROLES.GATEKEEPER);
  }

  isJanitor() {
    return this.role.includes(User.ROLES.JANITOR);
  }

  isOwner() {
    return this.role.includes(User.ROLES.OWNER) || this.isSystemAdmin;
  }

  isUser() {
    return this.role.includes(User.ROLES.USER);
  }

  isRequester() {
    return this.role.includes(User.ROLES.REQUESTER);
  }

  isAdminOnCondo(condoId: String) {
    return this.condosAdmin.some(condo => {
      return condo._id == condoId || condo == condoId;
    });
  }

  isGatekeeperOnCondo(condoId: String) {
    return this.condosGatekeeper.some(condo => {
      return condo._id == condoId || condo == condoId;
    });
  }

  isOwnerOnCondo(condoId: String) {
    if (this.isSystemAdmin) {
      return true;
    }

    return this.condosOwner.some(condo => {
      return condo._id == condoId || condo == condoId;
    });
  }

  isRequesterOnCondo(condoId: String) {
    return this.condosRequester.some(condo => {
      return condo._id == condoId || condo == condoId;
    });
  }

  isUserOnCondo(condoId: String) {
    return this.condos.some(condo => {
      return condo._id == condoId || condo == condoId;
    });
  }

  isJanitorOnCondo(condoId: String) {
    return this.condosJanitor.some(condo => condo._id == condoId || condo == condoId);
  }

  isOnlyJanitor() {
    return this.isJanitor() && !this.isGatekeeper() && !this.isOwner() && !this.isAdmin();
  }

  isApprovedOnCondo(condoId: string) {
    return (
      this.isUserOnCondo(condoId) ||
      this.isGatekeeperOnCondo(condoId) ||
      this.isJanitorOnCondo(condoId) ||
      this.isOwnerOnCondo(condoId) ||
      this.isAdminOnCondo(condoId)
    );
  }

  // Filtra as residências de acordo com o condominio atual do usuário
  filterUserResidencesByCondo() {
    const condoId = this.defaultCondo ? this.defaultCondo._id : undefined;
    if (!condoId) {
      return;
    }
    let toRemove = [];

    // Removendo residencias que nao sao do condominio default
    this.residencesUser.forEach((res, i) => {
      if (res.condo != condoId) {
        toRemove.push(i);
      }
    });

    for (let i = toRemove.length - 1; i >= 0; i--) {
      this.residencesUser.splice(toRemove[i], 1);
    }

    toRemove = [];
    this.residencesVoter.forEach((res, i) => {
      if (res.condo != condoId) {
        toRemove.push(i);
      }
    });
    for (let i = toRemove.length - 1; i >= 0; i--) {
      this.residencesVoter.splice(toRemove[i], 1);
    }
  }

  // Define a residência default do usuário
  setDefaultResidence() {
    const condoId = this.defaultCondo._id;
    if (!condoId) {
      return;
    }
    if (this.defaultResidence && this.defaultResidence.condo == condoId) {
      return;
    }

    [].concat(this.residencesUser, this.residencesVoter).forEach(res => {
      if (res.condo == condoId) {
        this.defaultResidence = res;
        return;
      }
    });
  }

  setUserRole() {
    const condoId = this.defaultCondo ? this.defaultCondo._id : '';
    const roles = [];
    if (this.condosOwner.find(condo => condo == condoId || condo._id == condoId)) {
      roles.push(User.ROLES.OWNER);
    }

    if (
      this.condosAdmin.find(condo => {
        return condo == condoId || condo._id == condoId;
      })
    ) {
      roles.push(User.ROLES.ADMIN);
    }

    if (this.condosGatekeeper.find(condo => condo == condoId || condo._id == condoId)) {
      roles.push(User.ROLES.GATEKEEPER);
    }

    if (this.condos.find(condo => condo == condoId || condo._id == condoId)) {
      roles.push(User.ROLES.USER);
    }

    if (this.condosJanitor.find(condo => condo == condoId || condo._id == condoId)) {
      roles.push(User.ROLES.JANITOR);
    }

    if (this.condosRequester.find(condo => condo == condoId || condo._id == condoId)) {
      roles.push(User.ROLES.REQUESTER);
    }
    return roles;
  }

  getResidences(): Array<Residence> {
    const residences = this.residencesUser ? this.residencesUser.slice() : [];
    this.residencesVoter.forEach(resVoter => {
      if (!residences.find(resUser => resUser._id == resVoter._id)) {
        residences.push(resVoter);
      }
    });
    return residences.map(res => ResidenceBuilder.build(res));
  }

  hasResidence() {
    return !!this.getResidences().length;
  }

  get residencesIdentification(): Array<string> {
    return this.getResidences().map(res => {
      return res.identification;
    });
  }

  removeResidence(residence) {
    const userIndex = this.residencesUser.findIndex(res => {
      return res._id == residence._id;
    });
    const voterIndex = this.residencesVoter.findIndex(res => {
      return res._id == residence._id;
    });
    if (userIndex > -1) {
      this.residencesUser.splice(userIndex, 1);
    }
    if (voterIndex > -1) {
      this.residencesVoter.splice(voterIndex, 1);
    }
  }

  removeResidenceRequest(residence) {
    const requestIndex = this.residencesRequester.findIndex(res => {
      return res._id == residence._id;
    });
    if (requestIndex > -1) {
      this.residencesRequester.splice(requestIndex, 1);
    }
  }

  getUserRoleLabel(condo: Condo) {
    if (!condo && this.defaultCondo) {
      condo = this.defaultCondo;
    }

    let rolesLabels: string[] = [];

    if (this.isSystemAdmin) {
      rolesLabels.push('System Admin');
    }

    if (this.isOwnerOnCondo(condo._id)) {
      const ownerLabel = capitalize(condo?.customLabels?.owner?.singular) || 'Síndico';
      rolesLabels.push(ownerLabel);
    }

    if (this.isAdminOnCondo(condo._id)) {
      const adminLabel = capitalize(condo?.customLabels?.admin?.singular) || 'Administrador';
      rolesLabels.push(adminLabel);
    }

    if (this.isGatekeeperOnCondo(condo._id)) {
      const gatekeeperLabel = capitalize(condo?.customLabels?.gatekeeper?.singular) || 'Porteiro';
      rolesLabels.push(gatekeeperLabel);
    }

    if (this.isUserOnCondo(condo._id)) {
      const userLabel = capitalize(condo?.customLabels?.resident?.singular) || 'Condômino';
      rolesLabels.push(userLabel);
    }

    if (this.isJanitorOnCondo(condo._id)) {
      const janitorLabel = capitalize(condo?.customLabels?.janitor?.singular) || 'Zelador';
      rolesLabels.push(janitorLabel);
    }

    if (this.isRequesterOnCondo(condo._id)) {
      rolesLabels.push('Solicitante de acesso');
    }

    return rolesLabels;
  }

  /*
   Cria um JSON somente com os atributos desta classe. Não faz cópia das funções.
   A finalidade inicial desse método foi guardar as propriedades do objeto para armazenar no local storage
   */
  createBackObject() {
    const user: any = {
      _id: this._id,
      firstName: this.firstName,
      lastName: this.lastName,
      occupation: this.occupation,
      specialNeeds: this.specialNeeds,
      panicWord: this.panicWord,
      specialNeedsDetails: this.specialNeedsDetails,
      phones: this.phones,
      ids: this.ids.map(id => id.createBackObject()),
      tempPass: this.tempPass,
      birthDate: this.birthDate,
      signupStep: this.signupStep,
      activationKey: this.activationKey,
      picture: this.picture ? (this.picture.createBackObject ? this.picture.createBackObject() : this.picture) : null,
      defaultCondo: this.defaultCondo
        ? this.defaultCondo.createBackObject
          ? this.defaultCondo.createBackObject()
          : this.defaultCondo
        : null,
      defaultResidence: this.defaultResidence,
      condos: this.condos ? this.condos.map(condo => (condo.createBackObject ? condo.createBackObject() : condo)) : [],
      condosAdmin: this.condosAdmin ? this.condosAdmin.map(condo => (condo.createBackObject ? condo.createBackObject() : condo)) : [],
      condosOwner: this.condosOwner ? this.condosOwner.map(condo => (condo.createBackObject ? condo.createBackObject() : condo)) : [],
      condosRequester: this.condosRequester
        ? this.condosRequester.map(condo => (condo.createBackObject ? condo.createBackObject() : condo))
        : [],
      condosGatekeeper: this.condosGatekeeper
        ? this.condosGatekeeper.map(condo => (condo.createBackObject ? condo.createBackObject() : condo))
        : [],
      condosJanitor: this.condosJanitor ? this.condosJanitor.map(condo => (condo.createBackObject ? condo.createBackObject() : condo)) : [],
      residencesUser: this.residencesUser,
      residencesRequester: this.residencesRequester,
      residencesVoter: this.residencesVoter,
      email: this.email,
      password: this.password,
      params: this.params,
      isSystemAdmin: this.isSystemAdmin,
      isVendor: this.isVendor,
      vendorCode: this.vendorCode,
      token: this.token,
      indicationCode: this.indicationCode,
      residenceRequester: this.residenceRequester || {},
      userTermsOfService: this._userTermsOfService || [],
      permissions: this.permissions || {},
      customRoles: this.customRoles || []
    };
    if (this.maritalStatus) {
      user.maritalStatus = this.maritalStatus;
    }
    return user;
  }

  formatUserPhone(phone = '') {
    phone = phone.toString();
    phone = phone.replace(/[^*\d]/g, ''); // Remove tudo o que não é dígito exceto o asterisco
    phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2'); // Coloca parênteses em volta dos dois primeiros dígitos
    phone = phone.replace(/(\d)(\d{4})$/, '$1-$2'); // Coloca hífen entre o quarto e o quinto dígitos
    return phone;
  }

  // Obtém o id cadastrado como CPF
  get cpf() {
    if (!this.ids) {
      return null;
    }
    const cpf = this.ids.find(id => id.type == ContactID.TYPES.CPF);
    return cpf || null;
  }

  get formattedCpf() {
    const cpf = this.cpf;
    if (cpf && cpf.number) {
      let cpfNumber = cpf.number;
      cpfNumber = cpfNumber.toString();
      cpfNumber = cpfNumber.replace(/[^*\d]/g, ''); // Remove tudo o que não é dígito exceto o asterisco
      cpfNumber = cpfNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4'); // Formata para o padrão de CPF
      return cpfNumber;
    }
    return '';
  }

  set cpf(value: ContactID) {
    const index = this.ids.findIndex(id => id.type === ContactID.TYPES.CPF);

    if (index > -1) {
      this.ids[index] = value;
    } else {
      this.ids.push(value);
    }
  }

  get rg() {
    if (!this.ids) {
      return null;
    }
    const rg = this.ids.find(id => id.type == ContactID.TYPES.RG);
    return rg || null;
  }

  set rg(value: ContactID) {
    const index = this.ids.findIndex(id => id.type === ContactID.TYPES.RG);

    if (index > -1) {
      this.ids[index] = value;
    } else {
      this.ids.push(value);
    }
  }

  set passport(value: ContactID) {
    const index = this.ids.findIndex(id => id.type === ContactID.TYPES.PASSPORT);

    if (index > -1) {
      this.ids[index] = value;
    } else {
      this.ids.push(value);
    }
  }

  get passport() {
    if (!this.ids) {
      return null;
    }
    const passport = this.ids.find(id => id.type == ContactID.TYPES.PASSPORT);
    return passport || null;
  }

  set ra(value: ContactID) {
    const index = this.ids.findIndex(id => id.type === ContactID.TYPES.RA);

    if (index > -1) {
      this.ids[index] = value;
    } else {
      this.ids.push(value);
    }
  }

  get ra() {
    if (!this.ids) {
      return null;
    }
    const ra = this.ids.find(id => id.type == ContactID.TYPES.RA);
    return ra || null;
  }

  get cnpj() {
    if (!this.ids) {
      return null;
    }
    const cnpj = this.ids.find(id => id.type == ContactID.TYPES.CNPJ);
    return cnpj || null;
  }

  set cnpj(value: ContactID) {
    const index = this.ids.findIndex(id => id.type === ContactID.TYPES.CNPJ);

    if (index > -1) {
      this.ids[index] = value;
    } else {
      this.ids.push(value);
    }
  }

  get formattedCnpj() {
    const cnpj = this.cnpj;
    if (cnpj && cnpj.number) {
      let cnpjNumber = cnpj.number;
      cnpjNumber = cnpjNumber.replace(/[^*\d]/g, ''); // Remove tudo o que não é dígito e asterisco
      cnpjNumber = cnpjNumber.replace(/^([0-9*]{2})([0-9*])/, '$1.$2');
      cnpjNumber = cnpjNumber.replace(/^([0-9*]{2})\.([0-9*]{3})([0-9*])/, '$1.$2.$3');
      cnpjNumber = cnpjNumber.replace(/\.([0-9*]{3})([0-9*])/, '.$1/$2');
      cnpjNumber = cnpjNumber.replace(/([0-9*]{4})([0-9*])/, '$1-$2');
      return cnpjNumber;
    }
    return '';
  }

  filterResidencesByDefaultCondo() {
    if (!this.defaultCondo || !this.defaultCondo._id) {
      const condos = this.approvedCondos;
      if (condos.length) {
        this.defaultCondo = condos[0];
      }
    }

    if (this.defaultCondo && this.defaultCondo._id) {
      const condoId = this.defaultCondo._id;
      if (!condoId) {
        return;
      }
      // Removendo residencias que nao sao do condominio default
      this.residencesUser = this.residencesUser.filter(res => res.condo == condoId);
      this.residencesVoter = this.residencesVoter.filter(res => res.condo == condoId);
      this.residencesRequester = this.residencesRequester.filter(res => res.condo == condoId);
    }
  }

  getAllCondos() {
    return [].concat(this.condos, this.condosOwner, this.condosAdmin, this.condosGatekeeper, this.condosJanitor, this.condosRequester);
  }

  isOnSignUpStepOne() {
    return !(this.firstName && this.lastName && this.ids.length && this.birthDate && this.phones.length);
  }

  isOnSignUpStepTwo() {
    // return (!this.isOnSignUpStepOne()) && (this.signupStep == 2 || !this.getAllCondos().length);
    return !this.isOnSignUpStepOne() && !this.getAllCondos().length;
  }

  isOnVendorStep() {
    return !this.vendorCode || !this.isVendor;
  }

  hasApprovedCondo() {
    return this.approvedCondos.length > 0;
  }

  isFullyRegistered() {
    return !this.isOnSignUpStepOne() && !this.isOnSignUpStepTwo();
  }
  getAcceptedTermsOfUser(id: string) {
    return this._userTermsOfService.find(t => (t.termsOfService._id || t.termsOfService) === id);
  }

  getPermissionValue = ({ condoId, permission }: { condoId: Condo['_id']; permission: PermissionKey }) => {
    if (this.isSystemAdmin) {
      return true;
    }

    const permissionPath = `${condoId}.${permission}`;
    return _get(this.permissions, permissionPath, false) as boolean;
  };

  //----------------GETTERS AND SETTERS----------------\\

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get phones(): Array<any> {
    return this._phones;
  }

  set phones(value: Array<any>) {
    this._phones = value;
  }

  get ids(): Array<ContactID> {
    return this._ids;
  }

  set ids(value: Array<ContactID>) {
    this._ids = value;
  }

  get picture(): any {
    return this._picture;
  }

  set picture(value: any) {
    this._picture = value;
  }

  get defaultCondo(): any {
    return this._defaultCondo;
  }

  set defaultCondo(value: any) {
    this._defaultCondo = value;
  }

  get defaultResidence(): any {
    return this._defaultResidence;
  }

  set defaultResidence(value: any) {
    this._defaultResidence = value;
  }

  get condos(): any {
    return this._condos;
  }

  set condos(value: any) {
    this._condos = value;
  }

  get condosAdmin(): any {
    return this._condosAdmin;
  }

  set condosAdmin(value: any) {
    this._condosAdmin = value;
  }

  get condosOwner(): any {
    return this._condosOwner;
  }

  set condosOwner(value: any) {
    this._condosOwner = value;
  }

  get condosRequester(): any {
    return this._condosRequester;
  }

  set condosRequester(value: any) {
    this._condosRequester = value;
  }

  get condosGatekeeper(): any {
    return this._condosGatekeeper;
  }

  set condosGatekeeper(value: any) {
    this._condosGatekeeper = value;
  }

  get condosJanitor(): any {
    return this._condosJanitor;
  }

  set condosJanitor(value: any) {
    this._condosJanitor = value;
  }

  get residencesUser(): any {
    return this._residencesUser;
  }

  set residencesUser(value: any) {
    this._residencesUser = value;
  }

  get residencesRequester(): any {
    return this._residencesRequester;
  }

  set residencesRequester(value: any) {
    this._residencesRequester = value;
  }

  get residencesVoter(): any {
    return this._residencesVoter;
  }

  set residencesVoter(value: any) {
    this._residencesVoter = value;
  }

  get approvedCondos(): Condo[] {
    return [].concat(this.condos, this.condosOwner, this.condosAdmin, this.condosGatekeeper, this.condosJanitor);
  }

  get roleLabel(): string {
    return User.ROLES_LABEL[this.role[0]];
  }

  get fullName(): string {
    return ''.concat(this.firstName, ' ', this.lastName);
  }

  get shortFullName(): string {
    return ''.concat(this.firstName, ' ', this.lastName.split(' ').pop());
  }

  get phone() {
    return this.phones && this.phones.length ? this.phones[0] : null;
  }
}
