import { File } from './file';
import { BackObject } from './interface/back.object';
import * as moment from 'moment';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { AccessGroup } from '@api/model/hardware/access-group';

export interface LinearInterface {
  multiple?: boolean;
  name?: string;
  uuid?: string;
  enabled?: boolean;
  monitorUnread?: boolean;
  serverMode?: {
    host: string;
    port: string;
    enabled: boolean;
    password: string;
  };
  licence?: string;
  receivers?: {
    name: string;
    outputs: { name: string }[];
  }[];
  residentQrCode?: boolean;
  residentQrCodeCredits?: number;
  residentQrCodeTime?: number;
  residentPasswordFormat?: string;
}

export class Condo implements BackObject {
  static PARAMS = {
    NEW_USERS: {
      KEY: 'newUsers',
      NEED_REQUEST: 'NEED_REQUEST',
      NO_NEED_REQUEST: 'NO_NEED_REQUEST'
    },
    PUBLIC_OCCURRENCE_CREATION: {
      KEY: 'publicOccurrencesCreation',
      ONLY_ADMINS: 'ONLY_ADMINS',
      ANYONE: 'ANYONE'
    },
    PRIVATE_OCCURRENCE_CREATION: {
      KEY: 'privateOccurrencesCreation',
      ONLY_ADMINS: 'ONLY_ADMINS',
      ANYONE: 'ANYONE'
    },
    RESIDENTS_OCCURRENCES_CREATION: {
      KEY: 'residentsOccurrencesCreation',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    COMMENTS_MODERATION: {
      KEY: 'commentsModeration',
      NONE: 'NONE',
      REQUIRED: 'REQUIRED'
    },
    RESIDENCE_CREATION: {
      KEY: 'residenceCreation',
      PERMITTED: 'PERMITTED',
      PROHIBITED: 'PROHIBITED'
    },
    FINANCE: {
      KEY: 'finance',
      ONLY_ADMINS: 'ONLY_ADMINS',
      ANYONE: 'ANYONE'
    },
    FINANCE_MODULE: {
      KEY: 'financeModule',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    ACCESS_CONTROL: {
      KEY: 'accessControl',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    ACCESS_LIBERATION: {
      KEY: 'accessLiberation',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    DEVICE_ACCESS_REQUEST: {
      KEY: 'deviceAccessRequest',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    DELIVERIES: {
      KEY: 'deliveries',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    DELIVERIES_TRACKING: {
      KEY: 'deliveriesTracking',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    KEYS: {
      KEY: 'keys',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    GATE_NOTEBOOK: {
      KEY: 'gateNotebook',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    VOTING: {
      KEY: 'voting',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    RESERVATIONS: {
      KEY: 'reservations',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    DOCUMENTS: {
      KEY: 'condoDocuments',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    ADS: {
      KEY: 'ads',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    MAINTENANCE: {
      KEY: 'maintenance',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    MAINTENANCE_TICKETS: {
      KEY: 'maintenanceTickets',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    MAINTENANCE_TICKETS_VISIBILITY: {
      KEY: 'maintenanceTicketsVisibility',
      RESTRICTED: 'RESTRICTED',
      ANYONE: 'ANYONE'
    },
    LOST_AND_FOUNDS: {
      KEY: 'lostAndFounds',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    CONDO_RATING: {
      KEY: 'condoRating',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    CONDO_FAQ: {
      KEY: 'condoFaq',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    ADVICES: {
      KEY: 'advices',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    OCCURRENCES: {
      KEY: 'occurrences',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    VIRTUAL_KEY: {
      KEY: 'virtualKey',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    CAMERAS: {
      KEY: 'cameras',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    SEARCHABLE: {
      KEY: 'searchable',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    PETS: {
      KEY: 'pets',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    PANIC_BUTTON: {
      KEY: 'panicButton',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    ALARM_CENTRALS: {
      KEY: 'alarmCentrals',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    INTERCOM: {
      KEY: 'intercom',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    GATE_CHAT: {
      KEY: 'gateChat',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    RESIDENCE_HARDWARE_EVENTS_HISTORY: {
      KEY: 'residenceHardwareEventsHistory',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    ASSET_MANAGEMENT: {
      KEY: 'assetManagement',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    DEPENDENTS: {
      KEY: 'dependents',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    CONSTRUCTIONS: {
      KEY: 'constructions',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    DEPENDENTS_ACCESS_NOTIFICATION: {
      KEY: 'dependentsAccessNotification',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    EVENT_ANNOUNCEMENT_BOARD: {
      KEY: 'eventAnnouncementBoard',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    VEHICLE_HISTORY_REPORT: {
      KEY: 'vehicleHistoryReport',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    },
    NAVIGATE_TABS: {
      KEY: 'navigateTabs',
      ENABLED: 'ENABLED',
      DISABLED: 'DISABLED'
    }
  };

  static condoTypes = {
    APARTMENT: {
      type: 'APARTMENT',
      label: 'Apartamentos'
    },
    HOUSE: {
      type: 'HOUSE',
      label: 'Casas'
    },
    BUSINESS: {
      type: 'BUSINESS',
      label: 'Comercial'
    }
    // OTHER: {
    //   type: 'OTHER',
    //   label: 'Outros'
    // }
  };

  static TYPES = {
    APARTMENT: 'APARTMENT',
    HOUSE: 'HOUSE',
    BUSINESS: 'BUSINESS'
  };

  public _id = '';
  public name = '';
  public type = '';
  private _pictures: File[] = [];
  private _documents: File[] = [];
  public address = '';
  public number = '';
  public extensionLine = '';
  public extensionLines = [];
  public selfConfig = '';
  private _params: any = {
    [Condo.PARAMS.NEW_USERS.KEY]: Condo.PARAMS.NEW_USERS.NEED_REQUEST,
    [Condo.PARAMS.PUBLIC_OCCURRENCE_CREATION.KEY]: Condo.PARAMS.PUBLIC_OCCURRENCE_CREATION.ONLY_ADMINS,
    [Condo.PARAMS.PRIVATE_OCCURRENCE_CREATION.KEY]: Condo.PARAMS.PRIVATE_OCCURRENCE_CREATION.ONLY_ADMINS,
    [Condo.PARAMS.RESIDENTS_OCCURRENCES_CREATION.KEY]: Condo.PARAMS.RESIDENTS_OCCURRENCES_CREATION.ENABLED,
    [Condo.PARAMS.COMMENTS_MODERATION.KEY]: Condo.PARAMS.COMMENTS_MODERATION.NONE,
    [Condo.PARAMS.RESIDENCE_CREATION.KEY]: Condo.PARAMS.RESIDENCE_CREATION.PROHIBITED,
    [Condo.PARAMS.FINANCE.KEY]: Condo.PARAMS.FINANCE.ONLY_ADMINS,
    [Condo.PARAMS.FINANCE_MODULE.KEY]: Condo.PARAMS.FINANCE_MODULE.ENABLED,
    [Condo.PARAMS.ACCESS_LIBERATION.KEY]: Condo.PARAMS.ACCESS_LIBERATION.ENABLED,
    [Condo.PARAMS.DEVICE_ACCESS_REQUEST.KEY]: Condo.PARAMS.DEVICE_ACCESS_REQUEST.ENABLED,
    [Condo.PARAMS.DELIVERIES.KEY]: Condo.PARAMS.DELIVERIES.ENABLED,
    [Condo.PARAMS.DELIVERIES_TRACKING.KEY]: Condo.PARAMS.DELIVERIES_TRACKING.DISABLED,
    [Condo.PARAMS.KEYS.KEY]: Condo.PARAMS.KEYS.ENABLED,
    [Condo.PARAMS.VOTING.KEY]: Condo.PARAMS.VOTING.ENABLED,
    [Condo.PARAMS.RESERVATIONS.KEY]: Condo.PARAMS.RESERVATIONS.ENABLED,
    [Condo.PARAMS.DOCUMENTS.KEY]: Condo.PARAMS.DOCUMENTS.ENABLED,
    [Condo.PARAMS.ADS.KEY]: Condo.PARAMS.ADS.ENABLED,
    [Condo.PARAMS.MAINTENANCE.KEY]: Condo.PARAMS.MAINTENANCE.ENABLED,
    [Condo.PARAMS.MAINTENANCE_TICKETS.KEY]: Condo.PARAMS.MAINTENANCE_TICKETS.ENABLED,
    [Condo.PARAMS.MAINTENANCE_TICKETS_VISIBILITY.KEY]: Condo.PARAMS.MAINTENANCE_TICKETS_VISIBILITY.RESTRICTED,
    [Condo.PARAMS.LOST_AND_FOUNDS.KEY]: Condo.PARAMS.LOST_AND_FOUNDS.ENABLED,
    [Condo.PARAMS.CONDO_RATING.KEY]: Condo.PARAMS.CONDO_RATING.ENABLED,
    [Condo.PARAMS.CONDO_FAQ.KEY]: Condo.PARAMS.CONDO_FAQ.ENABLED,
    [Condo.PARAMS.ADVICES.KEY]: Condo.PARAMS.ADVICES.ENABLED,
    [Condo.PARAMS.OCCURRENCES.KEY]: Condo.PARAMS.OCCURRENCES.ENABLED,
    [Condo.PARAMS.VIRTUAL_KEY.KEY]: Condo.PARAMS.VIRTUAL_KEY.DISABLED,
    [Condo.PARAMS.ALARM_CENTRALS.KEY]: Condo.PARAMS.ALARM_CENTRALS.DISABLED,
    [Condo.PARAMS.CAMERAS.KEY]: Condo.PARAMS.CAMERAS.DISABLED,
    [Condo.PARAMS.PETS.KEY]: Condo.PARAMS.PETS.ENABLED,
    [Condo.PARAMS.PANIC_BUTTON.KEY]: Condo.PARAMS.PANIC_BUTTON.ENABLED,
    [Condo.PARAMS.INTERCOM.KEY]: Condo.PARAMS.INTERCOM.ENABLED,
    [Condo.PARAMS.GATE_CHAT.KEY]: Condo.PARAMS.GATE_CHAT.DISABLED,
    [Condo.PARAMS.RESIDENCE_HARDWARE_EVENTS_HISTORY.KEY]: Condo.PARAMS.RESIDENCE_HARDWARE_EVENTS_HISTORY.DISABLED,
    [Condo.PARAMS.ASSET_MANAGEMENT.KEY]: Condo.PARAMS.ASSET_MANAGEMENT.DISABLED,
    [Condo.PARAMS.DEPENDENTS.KEY]: Condo.PARAMS.DEPENDENTS.ENABLED,
    [Condo.PARAMS.CONSTRUCTIONS.KEY]: Condo.PARAMS.CONSTRUCTIONS.ENABLED,
    [Condo.PARAMS.EVENT_ANNOUNCEMENT_BOARD.KEY]: Condo.PARAMS.EVENT_ANNOUNCEMENT_BOARD.DISABLED,
    [Condo.PARAMS.NAVIGATE_TABS.KEY]: Condo.PARAMS.NAVIGATE_TABS.ENABLED,
    [Condo.PARAMS.VEHICLE_HISTORY_REPORT.KEY]: Condo.PARAMS.VEHICLE_HISTORY_REPORT.DISABLED
  };
  private _reservationParams = {};
  public city = '';
  public state = '';
  public zipCode = '';
  public latitude = '';
  public longitude = '';
  public country = '';
  public cnpj: number = null;
  private _phones: Array<number> = [];
  private _plan: { name: string; features: Array<string>; camerasLimit: number };
  public daysOfTrial = 0;
  private _blockNames: string[] = [];
  public customLabels: {
    voter: {
      singular: string;
      plural: string;
    };
    dependent: {
      singular: string;
      plural: string;
    };
    resident: {
      singular: string;
      plural: string;
    };
    residence: {
      singular: string;
      plural: string;
    };
    janitor: {
      singular: string;
      plural: string;
    };
    gatekeeper: {
      singular: string;
      plural: string;
    };
    admin: {
      singular: string;
      plural: string;
    };
    owner: {
      singular: string;
      plural: string;
    };
    condo: {
      singular: string;
      plural: string;
    };
    block: {
      singular: string;
      plural: string;
    };
    visitor: {
      singular: string;
      plural: string;
    };
  } = {
    voter: { singular: 'proprietário', plural: 'proprietários' },
    dependent: { singular: 'dependente', plural: 'dependente' },
    resident: { singular: 'condômino', plural: 'condôminos' },
    residence: { singular: 'unidade', plural: 'unidades' },
    janitor: { singular: 'zelador', plural: 'zeladores' },
    gatekeeper: { singular: 'porteiro', plural: 'porteiros' },
    admin: { singular: 'administrador', plural: 'administradores' },
    owner: { singular: 'síndico', plural: 'síndicos' },
    condo: { singular: 'condomínio', plural: 'condomínios' },
    block: { singular: 'bloco', plural: 'blocos' },
    visitor: { singular: 'visitante', plural: 'visitantes' }
  };

  private _location: any = null;
  private _googlePlaceId = '';
  public neighborhood = '';
  public createdBy: any = null;
  public createdAt: any = null;
  public updatedBy: any = null;
  private _sale;
  private _generalInfo: Array<{ label: string; content: string }> = [];
  public linear: LinearInterface = {
    licence: '',
    name: '',
    uuid: '',
    monitorUnread: false,
    serverMode: {
      host: '',
      port: '',
      enabled: false,
      password: ''
    },
    enabled: false,
    receivers: [],
    residentQrCode: false,
    residentQrCodeCredits: 1,
    residentQrCodeTime: 5,
    residentPasswordFormat: 'ONLY-PASS',
    multiple: false
  };
  public lineares = [];

  public hardwareParams: {
    gatekeeperCanRegisterActuator: boolean;
    gatekeeperCanEditActuator: boolean;
    gatekeeperCanDeleteActuator: boolean;
    gatekeeperCanRegisterDevice: boolean;
    gatekeeperCanEditDevice: boolean;
    gatekeeperCanDeleteDevice: boolean;
    visitorsQRCodeFromUser?: string;
    visitorsQRCodeFromGatekeeper?: string;
    visitorsQRCodeFromGatekeeperDefaultCredits?: number;
    visitorsQRCodeFromGatekeeperDefaultTime?: number;
    visitorsPasswordFromGatekeeper?: string;
    visitorsPasswordFromGatekeeperDefaultCredits?: number;
    visitorsPasswordFromGatekeeperDefaultTime?: number;
    visitorsFacialFromGatekeeper?: string;
    visitorsVirtualKeyFromUser?: string;
    visitorsFacialFromGatekeeperDefaultCredits?: number;
    visitorsFacialFromGatekeeperDefaultEntranceDuration?: number;
    visitorsFacialFromGatekeeperDefaultTime?: number;
    residentPrintedQrCode: boolean;
    visitorPrintedQrCode: boolean;
    visitorPasswordFromGatekeeperOnlyToLeave: boolean;
    visitorPrintedQrCodeUniquePersonUse: boolean;
    visitorsPasswordFromGatekeeperMaxLength: number;
    visitorsLprPlateFromGatekeeperDefaultTime: number;
    visitorsCardFromGatekeeper?: string;
    visitorsCardFromGatekeeperDefaultCredits?: number;
    visitorsCardFromGatekeeperDefaultTime?: number;
    visitorsLprFromUser?: string;
    condosSharingHardware?: string[];
    visitorsDoubleAuthenticationWithLpr?: boolean;
    visitorsPlateAddedByResident?: boolean;
    visitorsFacialFromUser?: string;
    activeVisitorExpireAfterHours?: number;
    selfAddFacial: 'ENABLED' | 'DISABLED';
    selfAddResidentFacialDefaultAccessGroup?: AccessGroup;
    selfAddDependentFacialDefaultAccessGroup?: AccessGroup;
  };
  public generalParams: {
    accessLiberation: {
      isAbleToEdit: boolean;
      allowRegisteringGenericDocument: boolean;
      maxDays: number;
      residentMustRegisterVisitorDocument: boolean;
      gateKeeperMustRegisterVisitorDocument: boolean;
      gateKeeperMustRegisterResidence: boolean;
      useShouldCall: boolean;
      visitorSelfRegistration: boolean;
      visitorSelfRegistrationFromQrCode: boolean;
      selfAttendanceForAutonomousConcierges: boolean;
      residentCanSearchAllVisitors: boolean;
      residentCanRegisterAccessLiberation: boolean;
      visitorAccessLiberationDefaultTime: {
        startTime: string;
        endTime: string;
      };
      availableFields: {
        [key: string]: { required: boolean; available: boolean };
      };
      gateKeeperCanRegisterVisitorPermission: boolean;
      gateKeeperCanShareVisitorPermissionLink: boolean;
      availableVisitorTypes: {
        VISITOR: boolean;
        RESIDENT: boolean;
        PROVIDER: boolean;
        SUPPLIER: boolean;
        DELIVERYMAN: boolean;
        PUBLIC_AGENT: boolean;
        DRIVER: boolean;
        EMPLOYEE: boolean;
        RELATIVE: boolean;
        AIRBNB: boolean;
        TENANT: boolean;
        OTHER: boolean;
      };
      visitorCanCreateMultipleVisitors: boolean;
      residentCanCreateVisitorIdentificationLiberation: boolean;
    };
    alprParams: {
      enabled: boolean;
      residentEnabled: boolean;
      visitorEnabled: boolean;
      residentParkingControl: boolean;
      visitorParkingControl: boolean;
      triggerActuatorForResident: boolean;
      triggerActuatorForVisitor: boolean;
      registerResidentVehicleOffline: boolean;
      gateKeeperCanRegisterVisitorPlate: boolean;
      selfAddLPR: boolean;
      residentParkingControlBypass: boolean;
      residentParkingControlByDevice: boolean;
    };
    faceIsRequiredOnFileUpload: boolean;
    virtualKey: {
      useUserGroups: boolean;
      useGeolocation: boolean;
    };
    residences: {
      residentCanViewVoter: boolean;
      voterCanAddResidenceUser: boolean;
    };
  };
  public controlId: {
    enabled: boolean;
  };
  public intelbras: {
    enabled: boolean;
    host: string;
    port: number;
    username: string;
    password: string;
    useHttps: boolean;
  };
  public intelbrasStandAlone: {
    enabled: boolean;
  };
  public hikvision: {
    enabled: boolean;
  };
  public bravas: {
    enabled: boolean;
  };
  public utech: {
    enabled: boolean;
  };
  public niceController: {
    enabled: boolean;
  };
  public tasmota: {
    enabled: boolean;
  };
  public alphadigi: {
    enabled: boolean;
  };
  public garen: {
    enabled: boolean;
  };
  public xpe: {
    enabled: boolean;
  };
  public zkteco: {
    enabled: boolean;
    host: string;
    port: string;
  };
  public mip1000ip: {
    enabled: boolean;
    host: string;
    port: string;
  };
  public avantia: {
    enabled: boolean;
  };
  public cliqueRetire: {
    enabled: boolean;
  };
  public lockin: {
    enabled: boolean;
  };

  public handover: {
    enabled: boolean;
  };

  public remoteCheck: {
    enabled: boolean;
    https: boolean;
    host: string;
    port: number;
  };

  public timeZone: string;
  public whiteLabel: any = null;
  public boleto: {
    enabled: boolean;
    url?: string;
    integrationType: 'AHREAS' | 'SUPERLOGICA';
    condoCode?: string;
    superlogicaAppToken?: string;
    superlogicaAccessToken?: string;
  };

  constructor(condo?) {
    if (typeof condo == 'string') {
      this._id = condo;
    } else if (condo) {
      this._id = condo._id || this._id;
      this.name = condo.name || this.name;
      this.type = condo.type || this.type;
      this.customLabels = condo.customLabels || this.customLabels;
      this._pictures = condo.pictures ? condo.pictures.map(file => new File(file)) : this._pictures;
      this._documents = condo.documents || this._documents;
      this.address = condo.address || this.address;
      this.number = condo.number || this.number;
      this.extensionLine = condo.extensionLine || this.extensionLine;
      this.extensionLines = condo.extensionLines || this.extensionLines;
      this.selfConfig = condo.selfConfig;
      this._params = condo.params || this._params;
      this._reservationParams = condo.reservationParams || this._reservationParams;
      this.city = condo.city || this.city;
      this.state = condo.state || this.state;
      this.zipCode = condo.zipCode || this.zipCode;
      this.latitude = condo.latitude || this.latitude;
      this.longitude = condo.longitude || this.longitude;
      this.country = condo.country || this.country;
      this.cnpj = condo.cnpj || this.cnpj;
      this._phones = condo.phones || this._phones;
      this._plan = condo.plan || { name: 'FREE', features: [], camerasLimit: 5 };
      this.daysOfTrial = condo.daysOfTrial || 0;
      this._blockNames = condo.blockNames || this._blockNames;
      this._location = condo.location || this._location;
      this._googlePlaceId = condo.googlePlaceId || this._googlePlaceId;
      this.neighborhood = condo.neighborhood || this.neighborhood;
      this.createdBy = condo.createdBy || this.createdBy;
      this.createdAt = condo.createdAt || this.createdAt;
      this.updatedBy = condo.updatedBy || this.updatedBy;
      this._sale = condo.sale;
      this._generalInfo = condo.generalInfo;
      this.generalParams = condo.generalParams || {};
      this.linear = condo.linear || {
        licence: '',
        enabled: false,
        receivers: [
          {
            name: 'Rec. 1',
            outputs: [{ name: 'Saída 1' }, { name: 'Saída 2' }, { name: 'Saída 3' }, { name: 'Saída 4' }]
          },
          {
            name: 'Rec. 2',
            outputs: [{ name: 'Saída 1' }, { name: 'Saída 2' }, { name: 'Saída 3' }, { name: 'Saída 4' }]
          },
          {
            name: 'Rec. 3',
            outputs: [{ name: 'Saída 1' }, { name: 'Saída 2' }, { name: 'Saída 3' }, { name: 'Saída 4' }]
          },
          {
            name: 'Rec. 4',
            outputs: [{ name: 'Saída 1' }, { name: 'Saída 2' }, { name: 'Saída 3' }, { name: 'Saída 4' }]
          },
          {
            name: 'Rec. 5',
            outputs: [{ name: 'Saída 1' }, { name: 'Saída 2' }, { name: 'Saída 3' }, { name: 'Saída 4' }]
          },
          {
            name: 'Rec. 6',
            outputs: [{ name: 'Saída 1' }, { name: 'Saída 2' }, { name: 'Saída 3' }, { name: 'Saída 4' }]
          },
          {
            name: 'Rec. 7',
            outputs: [{ name: 'Saída 1' }, { name: 'Saída 2' }, { name: 'Saída 3' }, { name: 'Saída 4' }]
          },
          {
            name: 'Rec. 8',
            outputs: [{ name: 'Saída 1' }, { name: 'Saída 2' }, { name: 'Saída 3' }, { name: 'Saída 4' }]
          }
        ],
        residentQrCode: false,
        residentQrCodeCredits: 1,
        residentQrCodeTime: 5,
        residentPasswordFormat: 'ONLY-PASS',
        multiple: false
      };
      this.lineares = condo.lineares || [];
      this.hardwareParams = condo.hardwareParams || {
        gatekeeperCanRegisterActuator: true,
        gatekeeperCanEditActuator: true,
        gatekeeperCanDeleteActuator: true,
        gatekeeperCanRegisterDevice: false,
        gatekeeperCanEditDevice: false,
        gatekeeperCanDeleteDevice: false,
        visitorsQRCodeFromUser: 'DISABLED',
        visitorsQRCodeFromGatekeeper: 'DISABLED',
        visitorsQRCodeFromGatekeeperDefaultCredits: 2,
        visitorsQRCodeFromGatekeeperDefaultTime: 1440,
        visitorsPasswordFromGatekeeper: 'DISABLED',
        visitorsPasswordFromGatekeeperDefaultCredits: 2,
        visitorsPasswordFromGatekeeperDefaultTime: 1440,
        visitorsFacialFromGatekeeper: 'DISABLED',
        visitorsFacialFromGatekeeperDefaultEntranceDuration: 5,
        visitorsFacialFromGatekeeperDefaultCredits: 1,
        visitorsFacialFromGatekeeperDefaultTime: 1440,
        residentPrintedQrCode: false,
        visitorPrintedQrCode: false,
        visitorPasswordFromGatekeeperOnlyToLeave: false,
        visitorPrintedQrCodeUniquePersonUse: false,
        visitorsPasswordFromGatekeeperMaxLength: 10,
        visitorsLprPlateFromGatekeeperDefaultTime: false,
        visitorsLprFromUser: 'DISABLED',
        visitorsFacialFromUser: 'DISABLED',
        condosSharingHardware: [],
        visitorsDoubleAuthenticationWithLpr: false,
        visitorsPlateAddedByResident: false,
        activeVisitorExpireAfterHours: 72,
        selfAddFacial: 'DISABLED',
        selfAddFacialDefaultAccessGroups: {}
      };
      this.controlId = condo.controlId || { enabled: false };
      this.hikvision = condo.hikvision || { enabled: false };
      this.intelbras = condo.intelbras || { enabled: false };
      this.bravas = condo.bravas || { enabled: false };
      this.intelbrasStandAlone = condo.intelbrasStandAlone || { enabled: false };
      this.utech = condo.utech || { enabled: false };
      this.niceController = condo.niceController || { enabled: false };
      this.alphadigi = condo.alphadigi || { enabled: false };
      this.garen = condo.garen || { enabled: false };
      this.zkteco = condo.zkteco || { enabled: false, host: '', port: '' };
      this.mip1000ip = condo.mip1000ip || { enabled: false, host: 'localhost', port: '5054' };
      this.avantia = condo.avantia || { enabled: false };
      this.cliqueRetire = condo.cliqueRetire || { enabled: false };
      this.lockin = condo.lockin || { enabled: false };
      this.handover = condo.handover || { enabled: false };
      this.tasmota = condo.tasmota || { enabled: false };
      this.remoteCheck = condo.remoteCheck || { enabled: false, https: false, host: '', port: '' };
      this.xpe = condo.xpe || { enabled: false };
      this.timeZone = condo.timeZone || null;
      this.whiteLabel = condo.whiteLabel || null;
      this.boleto = condo.boleto || {
        enabled: false
      };
    }
  }

  createBackObject() {
    const backObject = {
      _id: this._id,
      name: this.name,
      type: this.type,
      customLabels: this.customLabels,
      pictures: this.pictures.map(pic => pic.createBackObject()),
      documents: this.documents,
      address: this.address,
      number: this.number,
      selfConfig: this.selfConfig,
      extensionLine: this.extensionLine,
      extensionLines: this.extensionLines,
      params: this.params,
      reservationParams: this.reservationParams,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      latitude: this.latitude,
      longitude: this.longitude,
      country: this.country,
      cnpj: this.cnpj,
      phones: this.phones,
      plan: this.plan,
      daysOfTrial: this.daysOfTrial,
      blockNames: this.blockNames,
      location: this.location,
      googlePlaceId: this.googlePlaceId,
      neighborhood: this.neighborhood,
      sale: this.sale,
      linear: {
        name: this.linear.name || '',
        licence: this.linear.licence || '',
        enabled: this.linear.enabled || false,
        receivers: this.linear.receivers || [],
        residentQrCode: this.linear.residentQrCode || false,
        residentQrCodeCredits: this.linear.residentQrCodeCredits,
        residentQrCodeTime: this.linear.residentQrCodeTime,
        residentPasswordFormat: this.linear.residentPasswordFormat || 'ONLY-PASS',
        serverMode: this.linear.serverMode || {},
        multiple: this.linear.multiple || false
      },
      lineares: this.lineares || [],
      controlId: {
        enabled: this.controlId.enabled || false
      },
      utech: {
        enabled: this.utech.enabled || false
      },
      bravas: {
        enabled: this.bravas.enabled || false
      },
      intelbrasStandAlone: {
        enabled: this.intelbrasStandAlone.enabled || false
      },
      intelbras: {
        enabled: this.intelbras.enabled || false,
        host: this.intelbras.host || '',
        port: this.intelbras.port || '',
        username: this.intelbras.username || '',
        password: this.intelbras.password || '',
        useHttps: this.intelbras.useHttps || false
      },
      tasmota: {
        enabled: this.tasmota.enabled || false
      },
      hikvision: {
        enabled: this.hikvision.enabled || false
      },
      niceController: {
        enabled: this.niceController.enabled || false
      },
      alphadigi: {
        enabled: this.alphadigi.enabled || false
      },
      garen: {
        enabled: this.garen.enabled || false
      },
      zkteco: {
        enabled: this.zkteco.enabled || false,
        host: this.zkteco.host || '',
        port: this.zkteco.port || ''
      },
      mip1000ip: {
        enabled: this.mip1000ip.enabled || false,
        host: this.mip1000ip.host || '',
        port: this.mip1000ip.port || ''
      },
      avantia: {
        enabled: this.avantia.enabled || false
      },
      cliqueRetire: {
        enabled: this.cliqueRetire.enabled || false
      },
      lockin: {
        enabled: this.lockin.enabled || false
      },
      handover: {
        enabled: this.handover.enabled || false
      },
      remoteCheck: {
        enabled: this.remoteCheck.enabled || false,
        https: this.remoteCheck.https || false,
        host: this.remoteCheck.host || '',
        port: this.remoteCheck.port || ''
      },
      xpe: {
        enabled: this.xpe.enabled || false
      },
      hardwareParams: this.hardwareParams,
      generalParams: this.generalParams,
      generalInfo: this.generalInfo,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      createdAt: this.createdAt,
      timeZone: this.timeZone,
      whiteLabel: this.whiteLabel,
      boleto: this.boleto
    };
    return backObject;
  }

  isApartmentCondo() {
    return this.type === Condo.TYPES.APARTMENT;
  }

  isHouseCondo() {
    return this.type === Condo.TYPES.HOUSE;
  }

  isBusinessCondo() {
    return this.type === Condo.TYPES.BUSINESS;
  }

  needAccessRequest() {
    return this.params && this.params.newUsers && this.params.newUsers == Condo.PARAMS.NEW_USERS.NO_NEED_REQUEST ? false : true;
  }

  canCreatePublicOccurrence() {
    return (
      this.params &&
      this.params.publicOccurrencesCreation &&
      this.params.publicOccurrencesCreation == Condo.PARAMS.PUBLIC_OCCURRENCE_CREATION.ANYONE
    );
  }

  canCreatePrivateOccurrence() {
    return (
      this.params &&
      this.params.privateOccurrencesCreation &&
      this.params.privateOccurrencesCreation == Condo.PARAMS.PRIVATE_OCCURRENCE_CREATION.ANYONE
    );
  }

  canResidentsCreateOccurrence() {
    return (
      this.params &&
      this.params.residentsOccurrencesCreation &&
      this.params.residentsOccurrencesCreation == Condo.PARAMS.RESIDENTS_OCCURRENCES_CREATION.ENABLED
    );
  }

  hasModeratedComments() {
    return !(this.params && this.params.commentsModeration && this.params.commentsModeration == Condo.PARAMS.COMMENTS_MODERATION.NONE);
  }

  canCreateResidence() {
    return this.params && this.params.residenceCreation && this.params.residenceCreation == Condo.PARAMS.RESIDENCE_CREATION.PERMITTED;
  }

  anyoneViewFinance() {
    return this.params && this.params.finance && this.params.finance == Condo.PARAMS.FINANCE.ANYONE;
  }

  isAdvicesDisabled() {
    return this.params && this.params.advices && this.params.advices == Condo.PARAMS.ADVICES.DISABLED;
  }

  isOccurrencesDisabled() {
    return this.params && this.params.occurrences && this.params.occurrences == Condo.PARAMS.OCCURRENCES.DISABLED;
  }

  isAccessControlDisabled() {
    return this.params && this.params.accessControl && this.params.accessControl == Condo.PARAMS.ACCESS_CONTROL.DISABLED;
  }

  isAccessLiberationDisabled() {
    return this.params && this.params.accessLiberation && this.params.accessLiberation == Condo.PARAMS.ACCESS_LIBERATION.DISABLED;
  }

  isDeviceRequestDisabled() {
    return this.params && this.params.deviceAccessRequest && this.params.deviceAccessRequest == Condo.PARAMS.DEVICE_ACCESS_REQUEST.DISABLED;
  }

  isDeliveriesDisabled() {
    return this.params && this.params.deliveries && this.params.deliveries == Condo.PARAMS.DELIVERIES.DISABLED;
  }

  isDeliveriesTrackingDisabled() {
    return this.params && this.params.deliveriesTracking && this.params.deliveriesTracking == Condo.PARAMS.DELIVERIES_TRACKING.DISABLED;
  }

  isKeysDisabled() {
    return this.params && this.params.keys && this.params.keys == Condo.PARAMS.KEYS.DISABLED;
  }

  isVotingDisabled() {
    return this.params && this.params.voting && this.params.voting == Condo.PARAMS.VOTING.DISABLED;
  }

  isReservationsDisabled() {
    return this.params && this.params.reservations && this.params.reservations == Condo.PARAMS.RESERVATIONS.DISABLED;
  }

  isDocumentsDisabled() {
    return this.params && this.params.condoDocuments && this.params.condoDocuments == Condo.PARAMS.DOCUMENTS.DISABLED;
  }

  isClassificadosDisabled() {
    return this.params && this.params.ads && this.params.ads == Condo.PARAMS.ADS.DISABLED;
  }

  isMaintenanceDisabled() {
    return this.params && this.params.maintenance && this.params.maintenance == Condo.PARAMS.MAINTENANCE.DISABLED;
  }

  isMaintenanceTicketsDisabled() {
    return this.params && this.params.maintenanceTickets && this.params.maintenanceTickets == Condo.PARAMS.MAINTENANCE_TICKETS.DISABLED;
  }

  isLostAndFoundsDisabled() {
    return this.params && this.params.lostAndFounds && this.params.lostAndFounds == Condo.PARAMS.LOST_AND_FOUNDS.DISABLED;
  }

  isGateNotebookDisabled() {
    return this.params && this.params.gateNotebook && this.params.gateNotebook == Condo.PARAMS.GATE_NOTEBOOK.DISABLED;
  }

  isCondoRatingDisabled() {
    return this.params && this.params.condoRating && this.params.condoRating == Condo.PARAMS.CONDO_RATING.DISABLED;
  }

  isCondoFaqDisabled() {
    return this.params && this.params.condoFaq && this.params.condoFaq == Condo.PARAMS.CONDO_FAQ.DISABLED;
  }

  isVirtualKeyDisabled() {
    return this.params && this.params.virtualKey && this.params.virtualKey == Condo.PARAMS.VIRTUAL_KEY.DISABLED;
  }

  isAlarmCentralDisabled() {
    return this.params && this.params.alarmCentrals && this.params.alarmCentrals == Condo.PARAMS.ALARM_CENTRALS.DISABLED;
  }

  isCamerasDisabled() {
    return this.params && this.params.cameras && this.params.cameras == Condo.PARAMS.CAMERAS.DISABLED;
  }

  isPetsDisabled() {
    return this.params && this.params.pets && this.params.pets == Condo.PARAMS.PETS.DISABLED;
  }

  isFinanceDisabled() {
    return this.params && this.params.financeModule && this.params.financeModule == Condo.PARAMS.FINANCE_MODULE.DISABLED;
  }

  isPanicButtonDisabled() {
    return this.params && this.params.panicButton && this.params.panicButton == Condo.PARAMS.PANIC_BUTTON.DISABLED;
  }

  isGateChatDisabled() {
    return this.params && this.params.gateChat && this.params.gateChat == Condo.PARAMS.GATE_CHAT.DISABLED;
  }

  isResidenceHardwareEventsHistoryDisabled() {
    return (
      this.params &&
      this.params.residenceHardwareEventsHistory &&
      this.params.residenceHardwareEventsHistory == Condo.PARAMS.RESIDENCE_HARDWARE_EVENTS_HISTORY.DISABLED
    );
  }

  isDependentsDisabled() {
    return this.params && this.params.dependents && this.params.dependents == Condo.PARAMS.DEPENDENTS.DISABLED;
  }

  isConstructionsDisabled() {
    return this.params && this.params.constructions && this.params.constructions === Condo.PARAMS.CONSTRUCTIONS.DISABLED;
  }

  isBoletoEnabled() {
    return this.boleto && this.boleto.enabled;
  }

  isCreatedByUser(userId): boolean {
    return this.createdBy == userId || (this.createdBy && this.createdBy._id == userId);
  }

  hasGateAccess() {
    return this.plan && this.plan.features && this.plan.features.indexOf('GATE') > -1;
  }

  hasReservationAccess() {
    return this.plan && this.plan.features && this.plan.features.indexOf('RESERVATIONS') > -1;
  }

  hasAdAccess() {
    return this.plan && this.plan.features && this.plan.features.indexOf('ADS') > -1;
  }

  hasDocumentsAccess() {
    return this.plan && this.plan.features && this.plan.features.indexOf('DOCUMENTS') > -1;
  }

  hasFinanceAccess() {
    return this.plan && this.plan.features && this.plan.features.indexOf('FINANCE') > -1;
  }

  hasVotingAccess() {
    return this.plan && this.plan.features && this.plan.features.indexOf('VOTING') > -1;
  }

  isLinearEnabled() {
    return this.linear && this.linear.enabled;
  }

  isControlIdEnabled() {
    return this.controlId && this.controlId.enabled;
  }

  isHikvisionEnabled() {
    return this.hikvision && this.hikvision.enabled;
  }

  isIntelbrasEnabled() {
    return this.intelbras && this.intelbras.enabled;
  }

  isIntelbrasStandAloneEnabled() {
    return this.intelbrasStandAlone && this.intelbrasStandAlone.enabled;
  }

  isUtechEnabled() {
    return this.utech && this.utech.enabled;
  }

  isBravasEnabled() {
    return this.bravas && this.bravas.enabled;
  }

  isTasmotaEnabled() {
    // Sonoff
    return this.tasmota && this.tasmota.enabled;
  }

  isNiceControllerEnabled() {
    return this.niceController && this.niceController.enabled;
  }

  isAlphaDigiEnabled() {
    return this.alphadigi && this.alphadigi.enabled;
  }

  isGarenEnabled() {
    return this.garen && this.garen.enabled;
  }

  isXPEEnabled() {
    return this.xpe && this.xpe.enabled;
  }

  isLprEnabled() {
    return this.generalParams && this.generalParams.alprParams && this.generalParams.alprParams.enabled;
  }

  isMIP1000IPEnabled() {
    return this.mip1000ip && this.mip1000ip.enabled;
  }

  isZktecoEnabled() {
    return this.zkteco?.enabled;
  }

  isAvantiaEnabled() {
    return this.avantia?.enabled;
  }

  isCliqueRetireEnabled() {
    return this.cliqueRetire?.enabled;
  }

  isLockinEnabled() {
    return this.lockin?.enabled;
  }

  isHandoverEnabled() {
    return this.handover?.enabled;
  }

  isRemoteCheckEnabled() {
    return this.remoteCheck?.enabled || false;
  }

  isAnySmartLockerEnabled() {
    return this.isCliqueRetireEnabled() || this.isLockinEnabled() || this.isHandoverEnabled();
  }

  isAssetManagementEnabled() {
    return this.params?.assetManagement == Condo.PARAMS.ASSET_MANAGEMENT.ENABLED;
  }

  isEventAnnouncementBoardEnabled() {
    return this.params?.eventAnnouncementBoard === Condo.PARAMS.EVENT_ANNOUNCEMENT_BOARD.ENABLED;
  }

  isVehicleHistoryEnabled() {
    return this.params?.vehicleHistoryReport == Condo.PARAMS.VEHICLE_HISTORY_REPORT.ENABLED;
  }

  isHardwareEnabled() {
    return (
      this.isLinearEnabled() ||
      this.isControlIdEnabled() ||
      this.isHikvisionEnabled() ||
      this.isIntelbrasEnabled() ||
      this.isIntelbrasStandAloneEnabled() ||
      this.isUtechEnabled() ||
      this.isTasmotaEnabled() ||
      this.isNiceControllerEnabled() ||
      this.isLprEnabled() ||
      this.isAlphaDigiEnabled() ||
      this.isZktecoEnabled() ||
      this.isBravasEnabled() ||
      this.isAvantiaEnabled() ||
      this.isMIP1000IPEnabled()
    );
  }

  isAccessLiberationEditDisabled() {
    return !this.generalParams?.accessLiberation?.isAbleToEdit;
  }

  getEnabledHardwares(): string[] {
    const hardwares = [];
    if (this.isLinearEnabled()) {
      hardwares.push(HARDWARES.LINEAR);
    }
    if (this.isControlIdEnabled()) {
      hardwares.push(HARDWARES.CONTROL_ID);
    }
    if (this.isHikvisionEnabled()) {
      hardwares.push(HARDWARES.HIKVISION);
    }
    if (this.isIntelbrasEnabled()) {
      hardwares.push(HARDWARES.INTELBRAS);
    }
    if (this.isIntelbrasStandAloneEnabled()) {
      hardwares.push(HARDWARES.INTELBRAS_STAND_ALONE);
    }
    if (this.isUtechEnabled()) {
      hardwares.push(HARDWARES.UTECH);
    }
    if (this.isTasmotaEnabled()) {
      hardwares.push(HARDWARES.TASMOTA);
    }
    if (this.isNiceControllerEnabled()) {
      hardwares.push(HARDWARES.NICE_CONTROLLER);
    }
    if (this.isLprEnabled()) {
      hardwares.push(HARDWARES.ALPHADIGI_LPR);
    }
    if (this.isAlphaDigiEnabled()) {
      hardwares.push(HARDWARES.ALPHADIGI);
    }
    if (this.isZktecoEnabled()) {
      hardwares.push(HARDWARES.ZKTECO);
    }
    if (this.isBravasEnabled()) {
      hardwares.push(HARDWARES.BRAVAS);
    }
    if (this.isAvantiaEnabled()) {
      hardwares.push(HARDWARES.AVANTIA);
    }
    if (this.isXPEEnabled()) {
      hardwares.push(HARDWARES.XPE);
    }
    if (this.isMIP1000IPEnabled()) {
      hardwares.push(HARDWARES.MIP1000IP);
    }
    return hardwares;
  }
  isOnTrial() {
    if (!this.plan || !this.createdAt || this.plan.name === 'TRIAL') {
      return true;
    } else {
      return false;
    }
  }

  isOnFreePlan() {
    if (!this.plan || !this.createdAt || this.plan.name === 'TRIAL' || this.plan.name === 'FREE') {
      return true;
    } else {
      return false;
    }
  }

  get remainingDaysOfTrial() {
    const daysOfTrial = moment(this.createdAt)
      .add(this.daysOfTrial || 0, 'days')
      .diff(moment(), 'days');
    return !daysOfTrial || daysOfTrial < 0 ? 0 : daysOfTrial;
  }

  // TODO Futuramente remover esses get de phone únicos, por hora, só existe o uso de um telefone, eles foram criados para auxiliar nisso
  get phone(): number {
    return this._phones[0] || null;
  }

  set phone(value: number) {
    this._phones = [value];
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get pictures(): File[] {
    return this._pictures;
  }

  set pictures(value: File[]) {
    this._pictures = value;
  }

  get documents(): File[] {
    return this._documents;
  }

  set documents(value: File[]) {
    this._documents = value;
  }

  get params(): any {
    return this._params;
  }

  set params(value: any) {
    this._params = value;
  }

  get blockNames(): string[] {
    return this._blockNames;
  }

  set blockNames(value: string[]) {
    this._blockNames = value;
  }

  get location(): any {
    return this._location;
  }

  set location(value: any) {
    this._location = value;
  }

  get googlePlaceId(): string {
    return this._googlePlaceId;
  }

  set googlePlaceId(value: string) {
    this._googlePlaceId = value;
  }

  get phones(): Array<number> {
    return this._phones;
  }

  set phones(value: Array<number>) {
    this._phones = value;
  }

  get plan(): { name: string; features: Array<string>; camerasLimit: number } {
    return this._plan;
  }

  set plan(value: { name: string; features: Array<string>; camerasLimit: number }) {
    this._plan = value;
  }

  get sale() {
    return this._sale;
  }

  get reservationParams(): {} {
    return this._reservationParams;
  }

  set reservationParams(value: {}) {
    this._reservationParams = value;
  }

  get generalInfo(): Array<{ label: string; content: string }> {
    return this._generalInfo;
  }

  set generalInfo(value: Array<{ label: string; content: string }>) {
    this._generalInfo = value;
  }
}
