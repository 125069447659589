import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Condo } from '@api/model/condo';
import { PrintContentService } from '../../services/print-content.service';

@Component({
  selector: 'app-modal-generate-qrcode',
  templateUrl: 'modal-generate-qrcode.component.html',
  styleUrls: ['modal-generate-qrcode.component.scss']
})
export class ModalGenerateQrcodeComponent implements OnInit, AfterViewInit {
  condo: Condo;
  modalTitle: string;
  modalBodyText: string;

  qrData: string = null;

  constructor(public bsModalRef: BsModalRef, private printContentService: PrintContentService) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.print('qrcode-print');
    }, 500);
  }

  print(elementId) {
    const printContents = document.getElementById(elementId).innerHTML;
    this.printContentService.print(printContents);
  }
}
