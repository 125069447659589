import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { validateCnpj } from '@api/util/util';
import * as moment from 'moment';

export function cpfValidator(c) {
  const validateCpf = cpf => {
    {
      cpf = cpf.toString();
      cpf = cpf.replace(/[^\d]+/g, '');
      if (cpf === '') {
        return false;
      }
      // Elimina CPFs invalidos conhecidos
      if (
        cpf.length !== 11 ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999'
      ) {
        return false;
      }
      // Valida 1o digito
      let add = 0;
      for (let i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
      }
      let rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) {
        rev = 0;
      }
      if (rev !== parseInt(cpf.charAt(9))) {
        return false;
      }
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i++) {
        add += parseInt(cpf.charAt(i)) * (11 - i);
      }
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) {
        rev = 0;
      }
      if (rev !== parseInt(cpf.charAt(10))) {
        return false;
      }
      return true;
    }
  };

  return c.value && validateCpf(c.value) ? null : { validCpf: { valid: false } };
}

export function emptyOrValidCpfValidator(c: UntypedFormControl) {
  const cpf = c.value;
  if (!cpf) {
    return null;
  }
  return cpfValidator(c);
}

export function cnpjValidator(c) {
  return c.value && validateCnpj(c.value) ? null : { validCnpj: { valid: false } };
}

export function emailValidator(c) {
  const falseObj = {
    emailValidator: {
      valid: false
    }
  };

  const email = c.value;

  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!email || re.test(email)) {
    return null;
  } else {
    return falseObj;
  }
}

export function phoneValidator(c) {
  const falseObj = {
    phoneValidator: {
      valid: false
    }
  };
  const phone = c.value;
  const reg = /(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/g;
  if (!phone || reg.test(phone)) {
    return null;
  } else {
    return falseObj;
  }
}

export function dateValidator(c) {
  const falseObj = {
    dateValidator: {
      valid: false
    }
  };
  const date = c.value;
  const reg = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;
  if (!date || reg.test(date)) {
    return null;
  } else {
    return falseObj;
  }
}

export function personBirthDateValidator(c) {
  const dateString = c.value;
  if (!dateString) {
    return null;
  }
  const reg = /^\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$/i;
  if (reg.test(dateString)) {
    const date = Date.parse(c.value);
    // Data mínima de nascimento é 110 anos atrás
    const minValidDate = new Date().setFullYear(new Date().getFullYear() - 110);
    const now = new Date().getTime();
    if (date < minValidDate) {
      return {
        minDateErrorValidator: {
          valid: false
        }
      };
    }
    if (date > now) {
      return {
        maxDateErrorValidator: {
          valid: false
        }
      };
    }
    return null;
  }
  return {
    personBirthDateValidator: {
      valid: false
    }
  };
}

export function enDateValidator(date) {
  // const pattern = /^\d{4}-\d{2}-\d{2}$/;
  const pattern = /^\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$/i;

  if (!date || !date.value || date.value.match(pattern)) {
    return null;
  } else {
    return {
      date: {
        valid: false
      }
    };
  }
}

export function maxDateValidator(maxDate: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const inputValue = control.value;

    if (!inputValue) {
      // Valor vazio, ignorar validação
      return null;
    }

    const inputDate = new Date(inputValue);
    const maxAllowedDate = new Date(maxDate);
    if (isNaN(inputDate.getTime())) {
      // Valor inválido, não é uma data
      return { invalidDate: true };
    }

    if (inputDate > maxAllowedDate) {
      // Data excede o limite máximo
      return { maxDateExceeded: true };
    }

    // Data válida
    return null;
  };
}

export function minDateValidator(minDate: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const inputValue = control.value;

    if (!inputValue) {
      // Valor vazio, ignorar validação
      return null;
    }

    const inputDate = new Date(inputValue);
    const minDateAllowedDate = new Date(minDate);
    if (isNaN(inputDate.getTime())) {
      // Valor inválido, não é uma data
      return { invalidDate: true };
    }

    if (inputDate < minDateAllowedDate) {
      // Data excede o limite máximo
      return { minDateDateExceeded: true };
    }

    // Data válida
    return null;
  };
}

export function couponCodeValidator(code) {
  const letters = code.value
    .toString()
    .toUpperCase()
    .replace(/[^A-Z]/g, '');
  const numbers = code.value.toString().toUpperCase().replace(/[^\d]/g, '');
  if (!code || (letters.length > 6 && numbers.length > 1)) {
    return null;
  } else {
    const falseObj = {
      couponCodeValidator: {
        valid: false
      }
    };
    return falseObj;
  }
}

export interface BooleanFn {
  (): boolean;
}

/**
 * A conditional validator generator. Assigns a validator to the form control if the predicate function returns true on the moment of validation
 * @example
 * Here if the myCheckbox is set to true, the myEmailField will be required and also the text will have to have the word 'mason' in the end.
 * If it doesn't satisfy these requirements, the errors will placed to the dedicated `illuminatiError` namespace.
 * Also the myEmailField will always have `maxLength`, `minLength` and `pattern` validators.
 * ngOnInit() {
 *   this.myForm = this.fb.group({
 *    myCheckbox: [''],
 *    myEmailField: ['', [
 *       Validators.maxLength(250),
 *       Validators.minLength(5),
 *       Validators.pattern(/.+@.+\..+/),
 *       conditionalValidator(() => this.myForm.get('myCheckbox').value,
 *                            Validators.compose([
 *                            Validators.required,
 *                            Validators.pattern(/.*mason/)
 *         ]),
 *        'illuminatiError')
 *        ]]
 *     })
 * }
 * @param predicate
 * @param validator
 * @param errorNamespace optional argument that creates own namespace for the validation error
 */
export function conditionalValidator(predicate: BooleanFn, validator: ValidatorFn, errorNamespace?: string): ValidatorFn {
  return formControl => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (predicate()) {
      error = validator(formControl);
    }
    if (errorNamespace && error) {
      const customError = {};
      customError[errorNamespace] = error;
      error = customError;
    }
    return error;
  };
}

export const beforeDateValidator = (dateToCompareFieldName: string): ValidatorFn => {
  return (control: AbstractControl) => {
    const dateToCompare = control.root.get(dateToCompareFieldName);

    if (!dateToCompare) {
      return null;
    }

    const controlDate = moment(control.value).startOf('day');
    const compareDate = moment(dateToCompare.value).startOf('day');

    return controlDate.isBefore(compareDate) ? { IsBefore: true } : null;
  };
};

export function fullNameValidator(name: AbstractControl) {
  const falseObj = {
    nameValidator: {
      valid: false
    }
  };

  const fullName = name.value;
  const isValid = fullName.split(' ').filter(v => v).length > 1;

  if (isValid) {
    return null;
  }

  return falseObj;
}

export function passwordCharactersValidator(control: AbstractControl) {
  const falseObj = {
    passwordCharactersValidator: {
      valid: false
    }
  };
  const password = control.value;
  const reg = /([@:#\s])/g;
  if (!password || !reg.test(password)) {
    return null;
  } else {
    return falseObj;
  }
}

export function minLengthArray(min: number) {
  return (c: AbstractControl): { [key: string]: any } => {
    if (c.value.length >= min) {
      return null;
    }

    return { minLengthArray: { valid: false } };
  };
}
