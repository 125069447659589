import { Condo } from '../condo';
import { User } from '../user';

export type BasePermissionJson = Partial<Record<'view' | 'create' | 'update' | 'delete', boolean | null>>;

export type CustomRole = {
  _id: string;
  condo?: Condo;
  name?: string;
  permissions?: {
    faq?: BasePermissionJson;
    lostAndFounds?: BasePermissionJson;
    logbooks?: BasePermissionJson & Partial<Record<'sendMessage', boolean>>;
    accessLiberation?: Pick<BasePermissionJson, 'view'>;
    eventAnnouncementBoard?: Pick<BasePermissionJson, 'view'>;
    alarmCentrals?: BasePermissionJson;
    actuators?: BasePermissionJson;
    actuatorsPanel?: {
      trigger?: boolean;
      changeOrder?: boolean;
    };
    maintenanceTickets?: Pick<BasePermissionJson, 'view' | 'create' | 'update'> & {
      updateTicketStatus?: boolean;
      viewAllUsersTickets?: boolean;
    };
    maintenance?: BasePermissionJson & {
      execute?: boolean;
    };
    personDeletionProcess?: Pick<BasePermissionJson, 'view'> & {
      complete?: boolean;
    };
    userResidences?: {
      dependents?: Pick<BasePermissionJson, 'view' | 'update'> & { add?: boolean; remove?: boolean };
      vehicles?: Pick<BasePermissionJson, 'view' | 'update' | 'create'> & { remove?: boolean };
    };
    externalLinks?: BasePermissionJson & Partial<Record<'access', boolean>>;
    highlights?: BasePermissionJson & Partial<Record<'carousel', boolean>>;
    users?: BasePermissionJson & { resetPassword?: boolean; convertUserToDependent?: boolean };
    userDevices?: BasePermissionJson;
    visitorDevices?: BasePermissionJson;
    externalPersonInfo?: {
      search?: {
        cpf?: boolean;
      };
      history?: Pick<BasePermissionJson, 'view'> & { unmask?: boolean; action?: boolean };
    };
  };
  allowedIPs?: string[];
  createdAt?: string;
  createdBy?: User;
  updatedAt?: string;
  updatedBy?: User;
};

export const BuildCustomRole = (customRole: Partial<CustomRole>): CustomRole => {
  if (typeof customRole === 'string') {
    return {
      _id: customRole
    };
  }

  if (customRole) {
    return {
      _id: customRole._id || '',
      condo: customRole.condo ? new Condo(customRole.condo) : null,
      name: customRole.name || '',
      permissions: customRole.permissions || ({} as CustomRole['permissions']),
      allowedIPs: customRole.allowedIPs || [],
      createdBy: customRole.createdBy ? new User(customRole.createdBy) : null,
      createdAt: customRole.createdAt || null,
      updatedBy: customRole.updatedBy ? new User(customRole.updatedBy) : null,
      updatedAt: customRole.updatedAt || null
    };
  }
};
