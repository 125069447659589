export const environment = {
  production: false,
  name: 'LOCAL',
  backendUrl: 'http://127.0.0.1:8080/api/v1/',
  backendUrlV2: 'http://127.0.0.1:8080/api/v2/',
  backendUrlV3: 'http://127.0.0.1:8080/api/v3/',
  reportsUrl: 'http://127.0.0.1:8080/api/v1/',
  reportsUrlV2: 'http://127.0.0.1:8080/api/v2/',
  visitorUrl: 'http://127.0.0.1:4220',
  socketUrl: 'ws://127.0.0.1:8080',
  blindadoUrl: 'https://condominioblindado.azurewebsites.net/'
};
// export const environment = {
//   production: false,
//   name: 'LOCAL',
//   backendUrl: 'http://10.42.0.18:8080/api/v1/',
//   backendUrlV2: 'http://10.42.0.18:8080/api/v2/',
//   socketUrl: 'ws://10.42.0.18:8080'
// };
