import * as moment from 'moment';

type CountDaysExcludingWeekdaysParms = {
  startDate: Date;
  endDate: Date;
  excludedDays: number[];
};

export function countDaysExcludingWeekdays({ startDate, endDate, excludedDays }: CountDaysExcludingWeekdaysParms) {
  let start = moment(startDate);
  let end = moment(endDate);
  let count = 0;

  while (start.isSameOrBefore(end, 'day')) {
    if (!excludedDays.includes(start.weekday())) {
      count++;
    }
    start.add(1, 'day');
  }

  return count;
}
