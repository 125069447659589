import { Condo } from '@api/model/condo';
import { User } from '../user';

export enum ALARM_CENTRAL_MANUFACTURER_LABEL {
  INTELBRAS = 'INTELBRAS'
}

export interface AlarmCentral {
  _id?: string;
  condo?: Condo;
  name: string;
  manufacturer: keyof typeof ALARM_CENTRAL_MANUFACTURER_LABEL;
  channel?: string;
  account: string;
  password: string;
  partialMacAddress: string;
  keepAlive?: boolean;
  keepAliveAlertInterval?: number;
  location?: string;
  observation?: string;
  createdAt?: Date;
  createdBy?: User;
}

export const buildAlarmCentral = (alarmCentral: Partial<AlarmCentral>): AlarmCentral => {
  return {
    _id: typeof alarmCentral === 'string' ? alarmCentral : alarmCentral._id || '',
    condo: alarmCentral.condo ? new Condo(alarmCentral.condo) : null,
    name: alarmCentral.name,
    manufacturer: alarmCentral.manufacturer,
    channel: alarmCentral.channel,
    account: alarmCentral.account,
    password: alarmCentral.password,
    partialMacAddress: alarmCentral.partialMacAddress,
    keepAlive: alarmCentral.keepAlive,
    keepAliveAlertInterval: alarmCentral.keepAliveAlertInterval,
    location: alarmCentral.location,
    observation: alarmCentral.observation,
    createdAt: alarmCentral.createdAt ? new Date(alarmCentral.createdAt) : null,
    createdBy: alarmCentral.createdBy ? new User(alarmCentral.createdBy) : null
  };
};
