import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { File } from '../../api/model/file';
import { ThemeService } from '../../theme';
import { getContrast } from 'polished';

@Component({
  selector: 'lightbox',
  templateUrl: 'lightbox.html',
  styleUrls: ['lightbox.scss']
})
export class LightboxComponent implements OnInit {
  @ViewChild('lightboxModal', { static: true }) public lightboxModal: ModalDirective;
  @ViewChild('videoPlayer') public videoPlayer;
  @ViewChild('audioPlayer') public audioPlayer;

  public files = [];

  public selectedFile;
  public selectedIndex = 0;

  iconColor = '#CCC';

  constructor(private themeService: ThemeService) {
    const primaryColor = this.themeService.activeTheme.cssVariables['--primary'];
    if (getContrast(primaryColor, '#000000') >= 3) {
      this.iconColor = primaryColor;
    }
  }

  ngOnInit() {
    this.selectedFile = this.files[0] || null;
  }

  closeModal(): void {
    this.files = [];
    this.selectedIndex = 0;
    this.selectedFile = null;
    this.lightboxModal.hide();
  }

  showImagesViewer(images: any[], index?) {
    this.files = images.map(file => new File(file));
    this.selectedIndex = index || 0;
    this.setSelectedFile();
    this.showModal();
  }

  private setSelectedFile(index?) {
    if (index || index == 0) this.selectedIndex = index;
    this.selectedFile = this.files[this.selectedIndex];
    this.loadIfVideo();
    this.loadIfAudio();
  }

  showModal() {
    this.lightboxModal.show();
  }

  public nextSlide() {
    if (this.selectedIndex >= this.files.length - 1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex++;
    }
    this.setSelectedFile();
  }

  public previousSlide() {
    if (this.selectedIndex <= 0) {
      this.selectedIndex = this.files.length - 1;
    } else {
      this.selectedIndex--;
    }
    this.setSelectedFile();
  }

  private loadIfVideo() {
    if (this.selectedFile.isVideo()) {
      setTimeout(() => {
        this.videoPlayer.nativeElement.load();
      }, 100);
    }
  }

  private loadIfAudio() {
    if (this.selectedFile.isAudio()) {
      setTimeout(() => {
        this.audioPlayer.nativeElement.load();
      }, 100);
    }
  }
}
