/**
 * Created by Rafael on 20/01/2017.
 */
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CondoContactService } from '@api/service/condo.contact.service';
import swal from 'sweetalert2';
import { FileService } from '@api/service/file.service';
import { UtilService } from '../../../../services/util.service';
import { ContactID } from '@api/model/contact/contact.id';
import { Vehicle } from '@api/model/vehicle';
import { BlindadoIntegration, Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { CondoContact } from '@api/model/contact/condo.contact';
import { VehicleCreateModal } from '../../../modal/vehicle.create.modal/vehicle.create.modal';
import { CondoVehicle } from '@api/model/condo.vehicle';
import * as moment from 'moment';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, timeout } from 'rxjs/operators';
import {
  conditionalValidator,
  emailValidator,
  emptyOrValidCpfValidator,
  personBirthDateValidator,
  phoneValidator
} from '@api/util/validators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { from, Subject, Subscription, throwError } from 'rxjs';
import { EcondosQuery } from '@api/model/query';
import { ToastrService } from 'ngx-toastr';
import { Status } from '@api/model/status';
import { CamerasGroupsService, GetCamerasGroupsResponse } from '@api/serviceV2/cameras-group.service';
import { CamerasGroup } from '@api/model/cameras-group';
import { UserLocalSettingsService } from '@api/serviceV2/user-local-settings.service';
import { Camera } from '@api/model/camera';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { ModalSelectDuplicatedCondoContactComponent } from 'app/components/modal-select-duplicated-condo-contact/modal-select-duplicated-condo-contact.component';
import { formatCnpj, formatCpf } from '@api/util/formatters';
import { SdkService } from '@api/service/hardware/sdk.service';
import { CondoService } from '@api/service/condo.service';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { ExternalPersonInfoService } from '@api/serviceV3/external-person-info.service';
import { ExternalPersonInfo, PROVIDERS_TYPE, RISK_COLLORS, RISK_COLORS_LABELS } from '@api/model/external-person-info';

interface CamerasGroupCameras {
  faceCamera: Camera;
  docFrontCamera: Camera;
  docBackCamera: Camera;
}

interface SavedCamerasGroupSnapshots {
  FACE?: string | null;
  DOC_FRONT?: string | null;
  DOC_BACK?: string | null;
}

@Component({
  selector: 'app-modal-new-condo-contact',
  templateUrl: 'new.condo.contact.modal.html',
  styleUrls: ['new.condo.contact.modal.scss']
})
export class ModalNewCondoContactComponent implements OnInit, OnDestroy {
  @ViewChild('vehicleCreateModal', { static: true }) vehicleCreateModal: VehicleCreateModal;

  @Input() condo: Condo;
  @Input() user: User;

  callback: (condoContact) => void;

  form: UntypedFormGroup;
  picture: AbstractControl;
  name: AbstractControl;
  birthDate: AbstractControl;
  type: AbstractControl;
  phone: AbstractControl;
  email: AbstractControl;
  company: AbstractControl;
  service: AbstractControl;
  documentType: AbstractControl;
  isForeigner: FormControl<boolean>;
  documentNumber: AbstractControl;
  documentFrontPicture: AbstractControl;
  documentBackPicture: AbstractControl;
  specialNeeds: AbstractControl;
  specialNeedsDetails: AbstractControl;
  obs: AbstractControl;

  vehicles: AbstractControl;

  condoContact: CondoContact;
  isSubmitting = false;

  loadStatus = false;

  showObs = false;

  ID_TYPES = Object.keys(ContactID.TYPES);
  ID_LABELS = ContactID.TYPES_LABEL;
  VEHICLE_TYPES = Vehicle.TYPES;

  // Vehicle vars
  onVehicleSelectedCallback: Function;

  contactTypes: { key: string; value: string }[] = [];

  newContactInfo;

  subscriptions: Subscription = new Subscription();
  private unsubscribe: Subject<void> = new Subject();

  editField = {
    email: false,
    id: false,
    phone: false,
    birthDate: false
  };

  public selectedCamerasGroupId: string = null;
  public camerasGroupsLoadingStatus = new Status();
  public camerasGroups: CamerasGroup[] = [];
  public savedCamerasGroupSnapshots: SavedCamerasGroupSnapshots = {};

  private blindadoIntegration: BlindadoIntegration;
  public lastSearchDate: string;
  public lastSearchRisk: RISK_COLLORS;
  public lastSearchRiskLabels = RISK_COLORS_LABELS;

  protected readonly PERMISSIONS = PERMISSIONS;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private bsModalRef: BsModalRef,
    private fileService: FileService,
    private toaster: ToastrService,
    private condoContactService: CondoContactService,
    private camerasGroupsService: CamerasGroupsService,
    private userLocalSettingsService: UserLocalSettingsService,
    private actuatorService: ActuatorService,
    private sdkService: SdkService,
    private modalService: BsModalService,
    private condoService: CondoService,
    private externalPersonInfoService: ExternalPersonInfoService
  ) {}

  ngOnInit(): void {
    if (!this.user) {
      this.user = this.utilService.getLocalUser();
    }

    const isGatekeeperOrAdmin = this.user.isGatekeeper() || this.user.isAdmin() || this.user.isOwner();

    const { availableVisitorTypes } = this.condo.generalParams.accessLiberation;
    if (isGatekeeperOrAdmin) {
      this.contactTypes = Object.keys(availableVisitorTypes).map(key => ({
        key,
        value: CondoContact.TYPES_LABEL[key]
      }));
    } else {
      this.contactTypes = Object.keys(availableVisitorTypes)
        .filter(key => availableVisitorTypes[key])
        .map(key => ({ key, value: CondoContact.TYPES_LABEL[key] }));
    }

    this.initializeForm();
    this.initializeCallbacks();

    let condoContact;
    if (this.condoContact) {
      condoContact = cloneDeep(this.condoContact);
      this.updateFormValues(condoContact);
      this.condoContact = condoContact;
    } else {
      this.form.addControl('email', this.email);
      this.form.addControl('phone', this.phone);
      this.form.addControl('birthDate', this.birthDate);
      this.form.addControl('documentNumber', this.documentNumber);
      this.form.updateValueAndValidity();

      if (this.newContactInfo?.nameOrId) {
        try {
          // Remove all numeric to check if it is a name or a document number
          const value = this.newContactInfo.nameOrId.replace(/[^0-9]/g, '');
          // Check if value contains digit, assume that name does not have number on it
          if (!value || isNaN(value as any)) {
            // Capitalize names that length is greater than 2 letters
            const names = this.newContactInfo.nameOrId.split(' ').map(name => {
              if (name.length > 2) {
                return name.charAt(0).toUpperCase() + name.slice(1);
              } else {
                return name;
              }
            });
            // Set contact name
            this.name.setValue(names.join(' '));
          } else {
            // Set value to be passed to create document component
            this.documentNumber.setValue(this.newContactInfo.nameOrId);
          }
        } catch (e) {}
      }

      if (this.newContactInfo?.type) {
        this.type.setValue(this.newContactInfo.type);
      }

      if (this.newContactInfo?.service) {
        this.service.setValue(this.newContactInfo.service);
      }
    }

    this.getCamerasGroups();

    this.subscriptions.add(
      this.isForeigner.valueChanges.subscribe({
        next: value => {
          const currentType = this.documentType.value;
          const newType = value ? ContactID.TYPES.OTHER : '';
          if (currentType !== newType) {
            this.documentType.setValue(newType, { emitEvent: false });
          }
        }
      })
    );

    this.getBlindadoIntegration();

    this.subscriptions.add(
      this.documentNumber.valueChanges
        .pipe(
          distinctUntilChanged(),
          filter(value => !!value)
        )
        .subscribe(documentNumber => {
          if (documentNumber && this.documentNumber.valid) {
            this.handleSearchCpf();
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();

    this.subscriptions.unsubscribe();
  }

  private getCamerasGroups(): void {
    const query: EcondosQuery = {
      $select: ['name', 'cameras'].join(' '),
      $populate: [
        {
          path: 'cameras.camera',
          select: [
            '_id',
            'name',
            'host',
            'port',
            'protocol',
            'rtspPort',
            'manufacturer',
            'user',
            'password',
            'channel',
            'subtype',
            'use',
            'directUrl',
            'snapshotUrl',
            'rtspUrl',
            'public',
            'resolution',
            'quality'
          ].join(' ')
        }
      ],
      $sort: 'name'
    };

    this.camerasGroupsLoadingStatus.setAsDownloading();

    const condoId = this.condo._id;

    const successCallback = (response: GetCamerasGroupsResponse) => {
      this.camerasGroups = response.camerasGroups;
      this.camerasGroupsLoadingStatus.setAsSuccess();

      if (this.camerasGroups.length) {
        if (this.defaultCamerasGroup) {
          const userDefaultCamerasGroupStillExists = this.camerasGroups.some(cameraGroup => cameraGroup._id === this.defaultCamerasGroup);

          if (userDefaultCamerasGroupStillExists) {
            this.selectedCamerasGroupId = this.defaultCamerasGroup;
          } else {
            this.userLocalSettingsService.saveSetting({ key: 'defaultCamerasGroup', value: '' });
            this.selectedCamerasGroupId = this.camerasGroups[0]._id;
          }
        } else {
          this.selectedCamerasGroupId = this.camerasGroups[0]._id;
        }
      }
    };

    const errorCallback = error => {
      console.error(error);
      this.camerasGroupsLoadingStatus.setAsError();
    };

    this.camerasGroupsService
      .getCamerasGroups(condoId, query)
      .pipe(timeout(10_000), takeUntil(this.unsubscribe))
      .subscribe(successCallback, errorCallback);
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      picture: [null],
      name: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      type: ['', Validators.compose([Validators.required])],
      company: [''],
      service: [''],
      documentType: [ContactID.TYPES.RG],
      isForeigner: [false],
      documentFrontPicture: [null],
      documentBackPicture: [null],
      specialNeeds: [false],
      specialNeedsDetails: [''],
      obs: [''],
      vehicles: [[]]
    });

    this.picture = this.form.get('picture');
    this.name = this.form.get('name');
    this.birthDate = new UntypedFormControl('', [Validators.minLength(10), Validators.maxLength(10), personBirthDateValidator]);
    this.type = this.form.get('type');
    this.phone = new UntypedFormControl('', [phoneValidator]);
    this.email = new UntypedFormControl('', [emailValidator]);
    this.company = this.form.get('company');
    this.service = this.form.get('service');
    this.vehicles = this.form.get('vehicles');
    this.documentType = this.form.get('documentType');
    this.isForeigner = this.form.get('isForeigner') as FormControl<boolean>;
    this.documentNumber = new UntypedFormControl('', [
      conditionalValidator(() => !!(this.documentFrontPicture.value || this.documentBackPicture.value), Validators.required)
    ]);
    this.documentFrontPicture = this.form.get('documentFrontPicture');
    this.documentBackPicture = this.form.get('documentBackPicture');
    this.specialNeeds = this.form.get('specialNeeds');
    this.specialNeedsDetails = this.form.get('specialNeedsDetails');
    this.specialNeeds.valueChanges.pipe(filter(v => !v)).subscribe(() => this.specialNeedsDetails.setValue(''));
    this.obs = this.form.get('obs');

    this.documentFrontPicture.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!this.documentNumber.value) {
        this.enableFieldToEdit('id');
      }
      this.documentNumber.setValidators([
        conditionalValidator(() => !!(value || this.documentBackPicture.value), Validators.required),
        conditionalValidator(
          () => value === 'CPF',
          Validators.compose([emptyOrValidCpfValidator, Validators.minLength(14), Validators.maxLength(14)])
        )
      ]);
      this.documentNumber.updateValueAndValidity();
    });
    this.documentBackPicture.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!this.documentNumber.value) {
        this.enableFieldToEdit('id');
      }
      this.documentNumber.setValidators([
        conditionalValidator(() => !!(value || this.documentBackPicture.value), Validators.required),
        conditionalValidator(
          () => value === 'CPF',
          Validators.compose([emptyOrValidCpfValidator, Validators.minLength(14), Validators.maxLength(14)])
        )
      ]);
      this.documentNumber.updateValueAndValidity();
    });

    this.documentType.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.documentNumber.setValidators([
        conditionalValidator(() => !!(this.documentFrontPicture.value || this.documentBackPicture.value), Validators.required),
        conditionalValidator(
          () => value === 'CPF',
          Validators.compose([emptyOrValidCpfValidator, Validators.minLength(14), Validators.maxLength(14)])
        )
      ]);
      this.documentNumber.updateValueAndValidity();
    });

    this.documentNumber.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        filter(() => ['CPF', 'CNPJ'].includes(this.documentType.value))
      )
      .subscribe(value => {
        if (this.documentType.value == 'CPF') {
          value = value.substring(0, 14);
          const formatted = formatCpf(value);
          this.documentNumber.setValue(formatted, { emitEvent: false });
        } else {
          value = value.substring(0, 18);
          const formatted = formatCnpj(value);
          this.documentNumber.setValue(formatted, { emitEvent: false });
        }
      });
  }

  updateFormValues(contact) {
    this.form.removeControl('email');
    this.form.removeControl('phone');
    this.form.removeControl('birthDate');
    this.form.removeControl('documentNumber');
    this.form.updateValueAndValidity();

    const phone = contact.phones && contact.phones[0] ? this.utilService.formatPhoneToShow(contact.phones[0]) : '';
    const email = contact.emails && contact.emails[0] ? contact.emails[0] : '';
    this.name.setValue((contact.firstName || '') + ' ' + (contact.lastName || ''));
    this.birthDate.setValue(contact.birthDate ? moment(contact.birthDate).format('YYYY-MM-DD') : '', { emitEvent: false });
    this.type.setValue(contact.type || '');
    this.phone.setValue(phone || '');
    this.email.setValue(email || '');
    this.isForeigner.setValue(contact.isForeigner || false);
    this.company.setValue(contact.company || '');
    this.service.setValue(contact.service || '');
    this.vehicles.setValue(contact.condoVehicles || []);
    this.specialNeeds.setValue(contact.specialNeeds);
    this.specialNeedsDetails.setValue(contact.specialNeedsDetails);
    this.obs.setValue(contact.obs || '');
    if (contact.obs) {
      this.showObs = true;
    }
    if (contact.picture) {
      this.picture.setValue(contact.picture);
    }
    if (contact.ids && contact.ids.length) {
      const contactId = contact.ids.find(id => id.type === ContactID.TYPES.RG) || contact.ids[0];
      this.documentType.setValue(contactId.type);

      this.documentNumber.setValue(contactId.number);
      if (contactId.pictures && contactId.pictures.length) {
        this.documentFrontPicture.setValue(contactId.pictures[0] || null);
        this.documentBackPicture.setValue(contactId.pictures[1] || null);
      }
    }
  }

  initializeCallbacks() {
    this.onVehicleSelectedCallback = vehicle => {
      const index = this.vehicles.value.findIndex(obj => vehicle._id == obj._id);

      if (index > -1) {
        this.vehicles.value[index] = vehicle;
      } else {
        this.vehicles.value.push(vehicle);
      }

      if (this.condoContact) {
        this.condoContact.condoVehicles = this.vehicles.value;
      }
    };
  }

  formatPhone() {
    let phoneNumber = this.phone.value;
    phoneNumber = this.utilService.formatPhone(phoneNumber);
    this.phone.setValue(phoneNumber);
  }

  handleCamerasGroupSnapshot(base64Image: string | null, type: 'FACE' | 'DOC_FRONT' | 'DOC_BACK') {
    this.savedCamerasGroupSnapshots[type] = base64Image;
  }

  uploadBase64Image(base64image: string): Promise<File> {
    return new Promise((resolve, reject) => {
      this.fileService.uploadBase64(base64image).subscribe(
        ([img]: [File]) => resolve(img),
        err => reject(err)
      );
    });
  }

  async uploadDocImagesFromCamerasGroup(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!!this.savedCamerasGroupSnapshots.DOC_FRONT) {
        try {
          const docFrontImg = await this.uploadBase64Image(this.savedCamerasGroupSnapshots.DOC_FRONT);
          this.documentFrontPicture.setValue(docFrontImg);
        } catch (err) {
          reject(err);
        }
      } else if (this.savedCamerasGroupSnapshots.DOC_FRONT === null) {
        this.documentFrontPicture.setValue(null);
      }

      if (!!this.savedCamerasGroupSnapshots.DOC_BACK) {
        try {
          const docBackImg = await this.uploadBase64Image(this.savedCamerasGroupSnapshots.DOC_BACK);
          this.documentBackPicture.setValue(docBackImg);
        } catch (err) {
          reject(err);
        }
      } else if (this.savedCamerasGroupSnapshots.DOC_BACK === null) {
        this.documentBackPicture.setValue(null);
      }

      resolve();
    });
  }

  uploadUserPictureFromCamerasGroup(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!!this.savedCamerasGroupSnapshots.FACE) {
        try {
          const userPicture = await this.uploadBase64Image(this.savedCamerasGroupSnapshots.FACE);
          this.picture.setValue(userPicture);
        } catch (err) {
          reject(err);
        }
      }

      resolve();
    });
  }

  async createNewContactToSend() {
    const names = this.name.value.split(' ').filter(v => v.trim());
    const firstName = names.shift();
    const lastName = names.join(' ');

    const contact = new CondoContact();
    contact.firstName = firstName;
    contact.lastName = lastName;
    contact.birthDate = this.birthDate.value ? moment(this.birthDate.value).format('YYYY-MM-DD') : '';
    contact.isForeigner = this.isForeigner.value;
    contact.company = this.company.value || '';
    contact.service = this.service.value || '';

    const ids = [];

    if (this.documentType.value && this.documentNumber.value) {
      if (this.camerasGroups.length) {
        try {
          await this.uploadDocImagesFromCamerasGroup();
        } catch (err) {
          swal({
            type: 'error',
            text: `Não foi possível fazer o upload das fotos do documento do usuário, tente novamente.`
          });
        }
      }

      const contactId = new ContactID({
        type: this.documentType.value,
        number: this.documentNumber.value,
        pictures: []
      });

      if (this.documentFrontPicture.value) {
        contactId.pictures[0] = this.documentFrontPicture.value;
      }
      if (this.documentBackPicture.value) {
        contactId.pictures[1] = this.documentBackPicture.value;
      }

      ids.push(contactId);
    }

    contact.ids = ids;
    contact.emails = [this.email.value];

    // Remove todos caracteres não numéricos do telefone[this.phone.value];
    contact.phones = this.phone.value ? [this.phone.value.toString().replace(/[^0-9]/g, '')] : [];

    if (this.camerasGroups.length) {
      try {
        await this.uploadUserPictureFromCamerasGroup();
      } catch (err) {
        swal({
          type: 'error',
          text: `Não foi possível fazer o upload da foto do usuário, tente novamente.`
        });
      }
    }

    if (this.picture.value) {
      contact.picture = this.picture.value;
    }

    contact.type = this.type.value;
    contact.condoVehicles = this.vehicles.value;
    contact.condo = this.condo;
    contact.specialNeeds = this.specialNeeds.value;
    contact.specialNeedsDetails = this.specialNeedsDetails.value;
    contact.obs = this.obs.value;

    return contact;
  }

  async updateUserToEdit(contact: CondoContact) {
    const names = this.name.value.split(' ').filter(v => v.trim());
    const firstName = names.shift();
    const lastName = names.join(' ');
    const editedCondoContact = Object.assign(Object.create(Object.getPrototypeOf(contact)), contact);
    editedCondoContact.firstName = firstName;
    editedCondoContact.lastName = lastName;

    editedCondoContact.isForeigner = this.isForeigner.value || false;

    if (this.birthDate.value && new Date(String(this.birthDate.value)).getFullYear() === 3000) {
      delete editedCondoContact.birthDate;
    } else {
      editedCondoContact.birthDate = this.birthDate.value ? moment(this.birthDate.value).format('YYYY-MM-DD') : '';
    }

    if (this.phone.value && this.phone.value.includes('*')) {
      delete editedCondoContact.phones;
    } else {
      editedCondoContact.phones = this.phone.value ? [this.phone.value.toString().replace(/[^0-9]/g, '')] : [];
    }

    if (this.email.value && this.email.value.includes('*')) {
      delete editedCondoContact.emails;
    } else {
      editedCondoContact.emails = [this.email.value];
    }

    if ((this.documentType.value && this.documentNumber.value) || editedCondoContact.ids.length) {
      const ids = [];

      const contactId = new ContactID({
        type: this.documentType.value || editedCondoContact.ids[0].type,
        number: (this.documentNumber.value || editedCondoContact.ids[0].number).replace(/[^*a-zA-Z\d]/g, ''),
        pictures: []
      });

      if (this.camerasGroups.length) {
        try {
          await this.uploadDocImagesFromCamerasGroup();
        } catch (err) {
          swal({
            type: 'error',
            text: `Não foi possível fazer o upload das fotos do documento do usuário, tente novamente.`
          });
        }
      }

      if (this.documentFrontPicture.value) {
        contactId.pictures[0] = this.documentFrontPicture.value;
      }
      if (this.documentBackPicture.value) {
        contactId.pictures[1] = this.documentBackPicture.value;
      }

      ids.push(contactId);

      editedCondoContact.ids = ids;
    }

    editedCondoContact.company = this.company.value || '';
    editedCondoContact.service = this.service.value || '';
    editedCondoContact.type = this.type.value;
    editedCondoContact.condoVehicles = this.vehicles.value;
    editedCondoContact.condo = this.condo;
    editedCondoContact.obs = this.obs.value;

    if (this.camerasGroups.length) {
      try {
        await this.uploadUserPictureFromCamerasGroup();
      } catch (err) {
        swal({
          type: 'error',
          text: `Não foi possível fazer o upload da foto do usuário, tente novamente.`
        });
      }
    }

    if (this.picture.value) {
      editedCondoContact.picture = this.picture.value;
    }
    editedCondoContact.specialNeeds = this.specialNeeds.value;
    editedCondoContact.specialNeedsDetails = this.specialNeedsDetails?.value || '';
    return editedCondoContact;
  }

  async createContact() {
    if (this.form.valid) {
      this.isSubmitting = true;
      let contact;
      let request;
      if (this.condoContact) {
        contact = await this.updateUserToEdit(this.condoContact);
        request = this.condoContactService.updateCondoContact(this.condo._id, contact.id, contact.createBackObject());
      } else {
        contact = await this.createNewContactToSend();
        request = this.condoContactService.createCondoContact(this.condo._id, contact.createBackObject());
      }
      request.pipe(timeout(10000)).subscribe(
        createdContactId => {
          if (createdContactId && createdContactId._id) {
            contact.id = createdContactId._id;
            contact._id = createdContactId._id;
          }
          if (this.callback) {
            this.callback(contact);
          }
          this.closeModal();
        },
        err => {
          this.isSubmitting = false;
          swal({
            type: 'error',
            text: `Não foi possível ${this.condoContact ? 'atualizar' : 'criar'} o usuário, tente novamente`
          });
        }
      );
    } else {
      Object.keys(this.form.controls).forEach(key => this.form.get(key).markAsTouched());
    }
  }

  uploadFiles(files, successCallback, errorCallback) {
    for (const f of files) {
      const formData = new FormData();
      formData.append(f.title || 'file', f);
      this.fileService.uploadFilesFromFormData(formData).subscribe(
        response => {
          successCallback(response);
        },
        err => {
          errorCallback(err);
        }
      );
    }
  }

  verifyCondoContactByDoc() {
    const docType = this.documentType.value as keyof typeof ContactID.TYPES;
    const docNumber = this.documentNumber.value;

    const shouldCheckDocument =
      (this.documentType.value === ContactID.TYPES.CPF && this.documentNumber.valid && docNumber?.length) ||
      (this.documentType.value === ContactID.TYPES.RG && docNumber?.length > 3) ||
      false;

    if (shouldCheckDocument) {
      this.loadStatus = true;

      const query: EcondosQuery = {
        $sort: 'firstName lastName',
        $populate: [
          { path: 'picture', select: 'url thumbnail type name format' },
          { path: 'ids.pictures', select: 'url thumbnail type name format' },
          {
            path: 'condoVehicles',
            select: 'plate chassis model type brand color pictures',
            populate: [
              {
                path: 'pictures',
                select: 'url thumbnail type name format'
              }
            ]
          }
        ]
      };

      this.condoContactService
        .searchByDocument(this.condo._id, docType, docNumber, query)
        .pipe(timeout(25_000))
        .subscribe(
          ({ contacts }) => {
            if (contacts.length) {
              if (docType === ContactID.TYPES.CPF) {
                this.documentNumber.setValue('');
              }
              const initialState = {
                docType,
                docNumber,
                contacts,
                onContactSelected: (selectedContact: CondoContact) => {
                  this.condoContact = selectedContact;
                  this.ngOnInit();
                }
              };

              this.modalService.show(ModalSelectDuplicatedCondoContactComponent, {
                initialState,
                ignoreBackdropClick: false
              });
            }

            this.loadStatus = false;
          },
          err => {
            this.loadStatus = false;
            this.toaster.error('Não foi possível realizar a busca de visitantes com esse documento.');
          }
        );
    }
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  removeVehicle(vehicle: CondoVehicle) {
    this.vehicles.value.splice(this.vehicles.value.indexOf(vehicle), 1);
  }

  editVehicle(vehicle: CondoVehicle) {
    this.vehicleCreateModal.editVehicle(vehicle);
  }

  createNewVehicle() {
    this.vehicleCreateModal.createVehicle();
  }

  enableFieldToEdit(field: string) {
    this.editField[field] = !this.editField[field];

    if (field === 'id' && this.editField[field]) {
      this.unmaskField(field);
      this.form.addControl('documentNumber', this.documentNumber);
    } else if (this.editField[field]) {
      this.unmaskField(field);
      this.form.addControl(field, this[field]);
    } else {
      this.returnMaskedValue(field);

      if (field === 'id') {
        this.form.removeControl('documentNumber');
      } else {
        this.form.removeControl(field);
      }
    }

    this.form.updateValueAndValidity();
  }

  unmaskField(field: string) {
    let fieldToSearch = '';
    if (field === 'phone') {
      fieldToSearch = 'phones';
    }
    if (field === 'email') {
      fieldToSearch = 'emails';
    }
    if (field === 'id') {
      fieldToSearch = 'ids';
    }
    if (field === 'birthDate') {
      fieldToSearch = field;
    }

    const query: EcondosQuery = {
      $select: fieldToSearch,
      $and: []
    };

    let callback;

    switch (field) {
      case 'email':
        callback = ({ data }) => {
          if (data[0]) {
            this.email.setValue(data[0]);
          }
        };
        break;

      case 'phone':
        callback = ({ data }) => {
          if (data[0]) {
            this.phone.setValue(this.utilService.formatPhone(data[0]));
          }
        };
        break;

      case 'id':
        callback = ({ data }) => {
          this.documentNumber.setValue(data[0].number);
          this.documentType.setValue(data[0].type);
        };
        break;

      case 'birthDate':
        callback = ({ data }) => {
          if (data) {
            this.birthDate.setValue(moment(data).format('YYYY-MM-DD'), { emitEvent: false });
          }
        };
        break;
    }

    if (this.condoContact) {
      this.condoContactService
        .getCondoContactUnmaskedField(this.condo._id, this.condoContact._id, fieldToSearch, query)
        .pipe(timeout(10000))
        .subscribe(callback);
    }
  }

  returnMaskedValue(field: string) {
    if (this.condoContact) {
      switch (field) {
        case 'email':
          if (this.condoContact.email) {
            this.email.setValue(this.condoContact.email);
          }
          break;

        case 'id':
          if (this.condoContact.ids[0].number) {
            this.documentNumber.setValue(this.condoContact.ids[0].number);
          }
          if (this.condoContact.ids[0].type) {
            this.documentType.setValue(this.condoContact.ids[0].type);
          }
          break;

        case 'phone':
          if (this.condoContact.phones[0]) {
            this.phone.setValue(this.utilService.formatPhone(this.condoContact.phones[0]));
          }
          break;

        case 'birthDate':
          if (this.condoContact.birthDate) {
            this.birthDate.setValue(moment(this.condoContact.birthDate).format('YYYY-MM-DD'), { emitEvent: false });
          }
          break;
      }
    }
  }

  public handleObs(value): void {
    if (!value) {
      this.obs.setValue('');
    }
  }

  public handleSetSelectedCamerasGroupAsDefault(): void {
    this.userLocalSettingsService.saveSetting({ key: 'defaultCamerasGroup', value: this.selectedCamerasGroup._id });
    this.toaster.success(`Grupo de câmeras "${this.selectedCamerasGroup.name}" definido como padão com sucesso.`);
  }

  public get selectedCamerasGroup() {
    return this.camerasGroups.find(cameraGroup => cameraGroup._id === this.selectedCamerasGroupId);
  }

  public get selectedCamerasGroupCameras(): CamerasGroupCameras {
    const faceCamera = this.selectedCamerasGroup.cameras.find(cam => cam.type === 'FACE');
    const docFrontCamera = this.selectedCamerasGroup.cameras.find(cam => cam.type === 'DOC_FRONT');
    const docBackCamera = this.selectedCamerasGroup.cameras.find(cam => cam.type === 'DOC_BACK');

    return {
      faceCamera: faceCamera?.camera,
      docFrontCamera: docFrontCamera?.camera,
      docBackCamera: docBackCamera?.camera
    };
  }

  public get camerasGroupHasAllNeededCameras() {
    const { faceCamera, docFrontCamera, docBackCamera } = this.selectedCamerasGroupCameras;
    return !!faceCamera && !!docFrontCamera && !!docBackCamera;
  }

  private get defaultCamerasGroup() {
    return this.userLocalSettingsService.userLocalSettings.defaultCamerasGroup;
  }

  async captureHikvisionFace() {
    this.actuatorService
      .getActuators(this.condo._id, { hardware: 'HIKVISION' })
      .pipe(
        timeout(10000),
        switchMap(({ actuators }) => {
          if (!actuators || !actuators.length) {
            return throwError('Actuators not found');
          }
          const actuatorsMap = actuators.reduce((acc, actuator) => {
            acc[actuator._id] = actuator;
            return acc;
          }, {});
          const inputOptions: any = actuators.reduce((acc, actuator) => {
            acc[actuator._id] = actuator.name;
            return acc;
          }, {});
          return from(
            swal({
              type: 'question',
              title: 'Selecionar equipamento',
              text: `Selecione o equipamento que você deseja utilizar para capturar a face da pessoa`,
              input: 'select',
              inputOptions,
              inputPlaceholder: 'Selecione o equipamento',
              inputValidator: value => {
                return new Promise((resolve, reject) => {
                  if (value) {
                    resolve();
                  } else {
                    reject('Selecione um equipamento para o registro e tente novamente!');
                  }
                });
              },
              showCancelButton: true,
              confirmButtonText: 'Capturar',
              confirmButtonColor: '#32DB64',
              cancelButtonColor: '#f53d3d',
              cancelButtonText: 'Cancelar',
              reverseButtons: true
            }).catch(() => swal.noop())
          ).pipe(
            switchMap(actuatorId => {
              if (!actuatorId) {
                return throwError('Canceled by user');
              }
              const actuator = actuatorsMap[actuatorId];

              const destroyed$: Subject<boolean> = new Subject<boolean>();
              swal({
                title: 'Registrando imagem...',
                text: 'A pessoa deve ficar na frente do equipamento',
                type: 'info',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#f53d3d',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false
              }).catch(e => {
                destroyed$.next(true);
                if (e?.includes('cancel')) {
                  return throwError('Canceled by user');
                }
              });
              return from(this.sdkService.captureFaceBase64({ actuator })).pipe(
                takeUntil(destroyed$),
                timeout(60000),
                map(({ data }) => `data:image/jpeg;base64,${data}`),
                switchMap(image => this.fileService.uploadBase64(image)),
                map(([file]) => file)
              );
            })
          );
        })
      )
      .subscribe(
        (img: File) => {
          this.picture.setValue(img);
          swal.clickConfirm();
          this.toaster.success('Face capturada com sucesso');
        },
        err => {
          swal.close();
          const msg = err?.message || err;
          if (!msg?.includes('Canceled by user')) {
            swal({
              type: 'error',
              title: 'Ops...',
              text: (err.message || err)?.includes('Actuators not found')
                ? 'Não existem equipamentos cadastrados no sistema. Antes de cadastrar uma facial é necessário você cadastrar os acionadores.'
                : 'Não foi possível realizar a captura da face. Verifique a conexão com o equipamento e tente novamente.'
            });
          }
        }
      );
  }

  public getBlindadoIntegration() {
    this.loadStatus = true;

    this.condoService
      .getBlindadoConfig(this.condo._id)
      .pipe(timeout(10_000), take(1))
      .subscribe({
        next: (response: BlindadoIntegration) => {
          if (response.enabled && response.cpfSearchEnabled) {
            this.ID_TYPES = [ContactID.TYPES.CPF];
            this.documentType.setValue(ContactID.TYPES.CPF);
            this.blindadoIntegration = response;
          } else {
            this.ID_TYPES = Object.keys(this.condo.generalParams.accessLiberation.availableDocumentTypes).filter(
              documentType => this.condo.generalParams.accessLiberation.availableDocumentTypes[documentType]
            );
            this.documentType.setValue(this.ID_TYPES[0]);
            this.blindadoIntegration = undefined;
          }

          this.loadStatus = false;
        },
        error: error => {
          this.loadStatus = false;
          console.error(error);
          swal({
            type: 'error',
            text: 'Não foi possível carregar as configurações de integração com Blindado, tente novamente.'
          });
        }
      });
  }

  public handleSearchCpf({ enableDeepSearch = false } = {}) {
    if (this.documentNumber.invalid || !this.documentNumber.value) {
      return;
    }

    const cpf = this.documentNumber.value;
    const provider = enableDeepSearch ? PROVIDERS_TYPE.BLINDADO : PROVIDERS_TYPE.ECONDOS;

    swal({
      type: 'info',
      text: 'Consultando CPF...',
      allowOutsideClick: false
    });

    swal.showLoading();

    this.externalPersonInfoService
      .searchCpf(this.condo._id, cpf, provider)
      .pipe(timeout(10_000), take(1))
      .subscribe({
        next: (response: any) => {
          if (response.status && response.status === 404) {
            swal({
              type: 'error',
              text: 'Não foi possível buscar as informações do CPF informado. Tente novamente.'
            });

            return;
          }

          const data = response as ExternalPersonInfo;

          this.form.patchValue({
            name: data.name || '',
            birthDate: data.birthDate?.substring(0, 10) || ''
          });

          this.form.markAllAsTouched();

          this.lastSearchDate = data.lastSearchTime;
          this.lastSearchRisk = data.searchHistory?.[0]?.risk;

          swal.hideLoading();
          swal.close();
        },
        error: error => {
          console.error(error);
          swal({
            type: 'error',
            text: 'Não foi possível buscar as informações do CPF informado. Tente novamente.'
          });
        }
      });
  }

  public get isCpfSearchEnabled(): boolean {
    return this.blindadoIntegration && this.blindadoIntegration.enabled && this.blindadoIntegration.cpfSearchEnabled;
  }
}
