/**
 * Created by Rafael on 13/12/2016.
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UtilService } from '../services/util.service';
import { RouteData } from '@api/model/custom-role/permission-route-data';

@Injectable()
export class HasGatekeeperAccessGuard {
  constructor(
    private utilService: UtilService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    let user = this.utilService.getLocalUser();
    const { permissions } = route.data as RouteData;
    if (user.isGatekeeper() || user.isAdmin() || user.isOwner()) {
      return true;
    }

    if (permissions) {
      this.router.navigate(['/no-permission-error']);
    } else {
      this.router.navigate(['/notFound']);
    }

    return false;
  }
}
