import { Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ModalCommentComponent } from '../../modal/comment.modal';
import { ModalCloseDeliveryComponent } from '../modal/modal.close.delivery';
import { LightboxComponent } from '../../../components/lightbox/lightbox';
import { Residence } from '@api/model/interface/residence';
import { Occurrence } from '@api/model/interface/occurrence';
import { OccurrenceService } from '@api/service/occurrence.service';
import { UtilService } from '../../../services/util.service';
import { ConstantService } from '../../../services/constant.service';
import { ParamsService } from '@api/service/params.service';
import { DeliveryTag, ReportService } from '@api/service/report.service';
import { Router } from '@angular/router';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { debounceTime, takeUntil, timeout, timeoutWith } from 'rxjs/operators';
import { User } from '@api/model/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalGateDeliveryConfigComponent } from '../modal-gate-delivery-config/modal-gate-delivery-config.component';
import { EcondosFilter } from '@api/model/filter';
import { EcondosQuery } from '@api/model/query';
import { ModalFilterComponent } from 'app/components/modal-filter/modal-filter.component';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ModalConfigDeliveryTrackingComponent } from '../modal-config-delivery-tracking/modal-config-delivery-tracking.component';
import { DeliveryTrackingHistory } from '@api/model/delivery-tracking-history';
import { DeliveryTrackingHistoryService } from '@api/serviceV3/delivery-tracking-history.service';
import { ModalCloseMultipleDeliveriesComponent } from '../modal/modal-close-multiple-deliveries/modal-close-multiple-deliveries-component';
import { TableColumnDefinition, TableComponent, TableRowClickEventData, TableStatus } from 'app/components/table/table.component';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { RemoveFilterEventData } from 'app/components/applied-filters/applied-filters.component';
import { GateResidenceDetailsModalComponent } from '../../gate-residence-details.modal/gate-residence-details.modal.component';
import { ModalShowDataViewersComponent } from '../../../components/modal-show-data-viewers/modal-show-data-viewers.component';

type RequestStatus = 'LOADING' | 'SUCCESS' | 'ERROR';

class Delivery extends GateOccurrence {
  public isSelected = false;
  public residences: Residence[] = [];

  constructor(occurrence: any) {
    super(occurrence);
    this.isSelected = occurrence.isSelected ?? false;
    this.residences = occurrence?.viewers?.residences ?? [];
  }
}

@Component({
  templateUrl: 'gate.delivery.list.html',
  selector: 'app-gate-delivery-list',
  styleUrls: ['gate.delivery.list.scss']
})
export class GateDeliveryListPage implements OnInit, OnDestroy {
  @Output() onCreateNewAccess = new EventEmitter();

  @ViewChild(ModalCommentComponent, { static: true }) commentModal: ModalCommentComponent;

  @ViewChild(ModalCloseDeliveryComponent, { static: true }) closeDeliveryModal: ModalCloseDeliveryComponent;

  @ViewChild(LightboxComponent) lightboxModal: LightboxComponent;

  @ViewChildren('checkboxes') checkboxes: QueryList<HTMLInputElement>;

  @ViewChild('deliveriesTable', { static: true }) deliveriesTable: TableComponent;
  @ViewChild('deliveriesSelectHeaderTemplate', { static: true }) deliveriesSelectHeaderTemplate: TemplateRef<any>;
  @ViewChild('deliveriesSelectTemplate', { static: true }) deliveriesSelectTemplate: TemplateRef<any>;
  @ViewChild('deliveriesResidenceTemplate', { static: true }) deliveriesResidenceTemplate: TemplateRef<any>;
  @ViewChild('deliveriesAddresseeTemplate', { static: true }) deliveriesAddresseeTemplate: TemplateRef<any>;

  // TODO passar isso para a classe occurrence
  public occursStatus = {
    OPENED: {
      status: 'OPENED',
      label: 'Aguardando entrega',
      labelClass: 'badge-subtle-primary'
    },
    CLOSED: {
      status: 'CLOSED',
      label: 'Entregue',
      labelClass: 'badge-subtle-success'
    },
    CANCELED: {
      status: 'CANCELED',
      label: 'Cancelada',
      labelClass: 'badge-subtle-danger'
    }
  };

  public condo;
  public me;
  public user: User;

  public selectedOccurrences = [];
  public selectedOccurrence;

  public occurrences: Delivery[] = new Array<Delivery>();
  public filteredOccurrences: Delivery[] = new Array<Delivery>();

  public trackingHistory: DeliveryTrackingHistory[] = [];
  public loadTrackingHistoryStatus: RequestStatus;

  residenceFilter = new FormControl<Residence>(null);
  isFiltered = false;

  totalDeliveriesCount = 0;

  status: TableStatus = 'LOADING';
  tableColumns: TableColumnDefinition<Delivery>[] = [];

  filters: EcondosFilter[] = [];

  currentSortData: { field: string; order: 'asc' | 'desc' | '' } = { field: 'createdAt', order: 'desc' };
  statusOptions = [
    { value: '', label: 'Todos' },
    { value: Occurrence.STATUS.OPENED, label: 'Aguardando entrega' },
    { value: Occurrence.STATUS.CLOSED, label: 'Entregue' }
  ];
  statusFilter = this.statusOptions[1];

  initialQuery: EcondosQuery = {
    $populate: [
      {
        path: 'viewers.residences',
        select: 'identification users',
        populate: { path: 'users', select: 'firstName lastName picture phones efone' }
      },
      'residence',
      { path: 'pictures', select: 'url thumbnail type name format' },
      {
        path: 'createdBy',
        select: 'firstName lastName picture',
        populate: { path: 'picture', select: 'url thumbnail type name format' }
      },
      '_commentsTotal',
      { path: 'subTypeAttachments', select: 'url thumbnail type name format' },
      { path: 'signature', select: 'url thumbnail type name format' },
      {
        path: 'destUser',
        select: 'firstName lastName picture',
        populate: [
          { path: 'picture', select: 'url thumbnail type name format' },
          { path: 'residencesUser', select: 'identification' }
        ]
      },
      {
        path: 'destDependent',
        select: 'name',
        populate: [
          { path: 'picture', select: 'url thumbnail type name format' },
          { path: 'residence', select: 'identification' }
        ]
      }
    ],
    type: 'GATE',
    subType: GateOccurrence.DELIVERY_TYPE,
    status: this.statusOptions[1].value
  };
  customQuery = { ...this.initialQuery };
  deliveryQrCodeData = '';

  permissions: { canClose: boolean; canEdit: boolean; canPrint: boolean } = {
    canClose: false,
    canEdit: false,
    canPrint: false
  };

  public unsubscribe$ = new Subject();
  public searchToken = new FormControl('');
  selectMultipleDeliveries = false;
  areAllOccurrencesSelected = false;

  constructor(
    private occurrenceService: OccurrenceService,
    private utilService: UtilService,
    public constantService: ConstantService,
    private modalService: BsModalService,
    private paramsService: ParamsService,
    private reportService: ReportService,
    private router: Router,
    private deliveryTrackingHistoryService: DeliveryTrackingHistoryService
  ) {
    this.me = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();
    this.user = this.utilService.getLocalUser();
    this.filters = [
      {
        element: 'input',
        elementType: 'date',
        elementSize: 'medium',
        name: 'date',
        label: 'Data',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        placeholder: 'Digite uma palavra chave',
        name: 'tags',
        label: 'Palavra chave',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        placeholder: 'Digite um código de rastreio',
        name: 'trackingNumbers',
        label: 'Código de rastreio',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementSize: 'medium',
        placeholder: 'Busque recebido por',
        name: 'receivedBy',
        label: 'Recebido por',
        searchType: 'regex'
      }
    ];
  }

  ngOnInit(): void {
    this.tableColumns = [
      {
        columnId: 'select',
        headerTemplate: this.deliveriesSelectHeaderTemplate,
        valueTemplate: this.deliveriesSelectTemplate,
        show: false
      },
      { columnId: '#', headerLabel: '#', type: 'index' },
      { columnId: 'residence', headerLabel: 'Enviado para', valueTemplate: this.deliveriesResidenceTemplate },
      { columnId: 'addressee', headerLabel: 'Destinatário', valueTemplate: this.deliveriesAddresseeTemplate },
      {
        columnId: 'status',
        type: 'badge',
        headerLabel: 'Status',
        valueFn: delivery => this.occursStatus[delivery.status].label,
        cellClassFn: delivery => this.occursStatus[delivery.status].labelClass
      },
      {
        columnId: 'createAt',
        headerLabel: 'Criado em',
        valueFn: delivery => (delivery.createdAt ? moment(delivery.createdAt).format('DD/MM/YYYY - HH:mm:ss') : '-')
      },
      { columnId: 'receivedBy', headerLabel: 'Recebido por', valueFn: delivery => delivery.receivedBy || '-' }
    ];

    this.searchToken.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(500)).subscribe(token => {
      this.handleClearFilters({ shouldReload: false });
      this.getData({ token });
    });

    this.residenceFilter.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(residence => {
      this.loadOccurrenceList();
    });

    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  loadOccurrenceList() {
    this.status = 'LOADING';
    this.getData();
  }

  getData({ page = 0, token = this.searchToken.value } = {}) {
    const query = this.customQuery;
    const { pageSize } = this.deliveriesTable.getCurrentState();

    if (this.statusFilter.value) {
      query.status = this.statusFilter.value;
    } else {
      delete query.status;
    }

    if (this.residenceFilter.value) {
      query['viewers.residences'] = this.residenceFilter.value._id;
    } else {
      delete query['viewers.residences'];
    }

    query.$page = page;
    query.$limit = pageSize;
    query.$sort = `${this.currentSortData.order === 'desc' ? '-' : ''}${this.currentSortData.field}`;

    let requestObservable: Observable<{ count: number; occurrences: Occurrence[] }>;

    if (token) {
      query.$count = true;
      requestObservable = this.occurrenceService.searchByToken(this.condo._id, token, query);
    } else {
      requestObservable = this.occurrenceService.getOccurrences(this.condo._id, query);
    }

    this.status = 'LOADING';

    requestObservable.pipe(timeoutWith(20_000, ErrorBuilder.throwTimeoutError())).subscribe({
      next: (response: { count: number; occurrences: GateOccurrence[] }) => {
        this.totalDeliveriesCount = response.count;
        this.occurrences = response.occurrences.map(occurrence => new Delivery(occurrence));
        this.filteredOccurrences = this.occurrences;

        const occurrenceId = this.paramsService.getAndClear('deliveryId');

        if (occurrenceId) {
          const occurrence = this.filteredOccurrences.find(occurrence => occurrence.id === occurrenceId);

          if (occurrence) {
            this.selectedOccurrence = occurrence;
            this.selectedOccurrences = [this.selectedOccurrence];
          } else {
            const query: EcondosQuery = { ...this.customQuery };

            delete query.status;

            this.occurrenceService.getById(this.condo.id, occurrenceId, query).subscribe({
              next: occurrence => {
                this.selectedOccurrence = occurrence;
                this.selectedOccurrences = this.selectedOccurrence;
              },
              error: err => {
                this.status = 'ERROR';
              }
            });
          }
        }

        if (this.selectedOccurrence) {
          const updatedOcurrence = this.occurrences.find(occ => {
            return occ.id == this.selectedOccurrence.id;
          });
          this.selectedOccurrence = <Delivery>updatedOcurrence;
          this.permissions = {
            canClose:
              this.selectedOccurrence?.status === 'OPENED' && (this.user.isAdmin() || this.user.isOwner() || this.user.isGatekeeper()),
            canEdit:
              this.selectedOccurrence?.status === 'OPENED' && (this.user.isAdmin() || this.user.isOwner() || this.user.isGatekeeper()),
            canPrint: this.user.isAdmin() || this.user.isOwner() || this.user.isGatekeeper()
          };
          this.selectedOccurrences = [this.selectedOccurrence];
        }

        this.status = 'SUCCESS';
      },
      error: () => {
        this.status = 'ERROR';
      }
    });
  }

  onSelect([selectedDelivery]: Delivery[]) {
    if (selectedDelivery) {
      const selectedOccurrence = this.filteredOccurrences.find(occ => occ._id === selectedDelivery._id);
      this.selectedOccurrences.splice(0, this.selectedOccurrences.length);
      this.selectedOccurrences.push(new GateOccurrence(selectedOccurrence));
      this.selectedOccurrence = this.selectedOccurrences[0];
      this.permissions = {
        canClose: this.selectedOccurrence?.status === 'OPENED' && (this.user.isAdmin() || this.user.isOwner() || this.user.isGatekeeper()),
        canEdit: this.selectedOccurrence?.status === 'OPENED' && (this.user.isAdmin() || this.user.isOwner() || this.user.isGatekeeper()),
        canPrint: this.user.isAdmin() || this.user.isOwner() || this.user.isGatekeeper()
      };
      this.deliveryQrCodeData = JSON.stringify({ _id: this.selectedOccurrence?._id, feature: 'DELIVERY' });

      if (this.condo.params.deliveriesTracking === 'ENABLED') this.getDeliveryTrackingHistory();
    }
  }

  handleDeliveryClick({ rowData: delivery }: TableRowClickEventData) {
    if (this.selectMultipleDeliveries) {
      this.toggleOccurrence(delivery);
    }
  }

  showCommentsModal(occurrence) {
    this.commentModal.showModal(occurrence);
  }

  showCloseOccurrence() {
    this.closeDeliveryModal.showModal();
  }

  onDataChange(dataChanged) {
    if (!Array.isArray(dataChanged)) {
      this.selectedOccurrence = dataChanged;
    }
    this.getData();
  }

  createNewOccurrence() {
    this.router.navigate(['gate/delivery/new']);
  }

  editOccurrence(occurrence) {
    this.paramsService.add(occurrence._id, occurrence);
    this.router.navigate(['gate', 'delivery', occurrence._id]);
  }

  showImagesViewer(pictures, index) {
    this.lightboxModal.showImagesViewer(pictures, index);
  }

  deleteOccurrence(deliveryToDelete) {
    swal({
      text: 'Deseja realmente apagar este recebimento de encomenda?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.occurrenceService
          .deleteOccurrence(this.condo._id, deliveryToDelete._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível apagar, tente novamente...');
          });
      }
    }).then(
      success => {
        this.selectedOccurrence = null;
        this.occurrences = this.occurrences.filter(delivery => delivery._id !== deliveryToDelete._id);
        this.filteredOccurrences = this.filteredOccurrences.filter(delivery => delivery._id !== deliveryToDelete._id);
        swal({
          text: 'Recebimento de encomenda apagado com sucesso!'
        });
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  openDeliveryConfig() {
    const initialState = {
      condo: this.condo,
      user: this.user
    };
    this.modalService.show(ModalGateDeliveryConfigComponent, { initialState });
  }

  printDeliveryTag(occurrence: GateOccurrence, elementId) {
    const qrCodeContent = document.getElementById(elementId).innerHTML;
    const residence = occurrence.viewers.residences[0];

    const deliveryTag: DeliveryTag = {
      _id: occurrence._id,
      residence: { id: residence._id, identification: residence.identification },
      createdAt: occurrence.createdAt,
      trackingNumbers: occurrence.trackingNumbers,
      protocol: occurrence.protocol,
      destPerson: undefined
    };

    if (occurrence.destUser) {
      deliveryTag.destPerson = `${occurrence.destUser.firstName} ${occurrence.destUser.lastName}`;
    }

    if (occurrence.destDependent) {
      deliveryTag.destPerson = `${occurrence.destDependent.name} (dependente)`;
    }

    this.reportService.printDeliveryTag(deliveryTag, qrCodeContent);
  }

  openFilterModal() {
    const initialState = {
      filters: this.filters,
      initialQuery: { ...this.initialQuery },
      callback: ({ query, filters }) => {
        this.searchToken.setValue(undefined, { emitEvent: false });
        const date = filters.find(filterObject => filterObject.name === 'date')?.value;
        if (date) {
          delete query.date;
          query.createdAt = {
            $gte: moment(date).utc().startOf('day').toISOString(),
            $lte: moment(date).utc().endOf('day').toISOString()
          };
        }
        const residence = filters.find(filterObject => filterObject.name === 'residence')?.value;
        if (residence) {
          delete query.residence;
          query['viewers.residences'] = residence._id;
        }
        const status = filters.find(filterObject => filterObject.name === 'status')?.value;
        if (!status) {
          delete query.status;
        }

        this.filters = filters;
        this.isFiltered = this.filters.some(filter => !!filter.value);
        this.customQuery = query;
        this.getData();
      }
    };
    this.modalService.show(ModalFilterComponent, { initialState, class: 'modal-md' });
  }

  handleRemoveIndividualFilter({ filters, removedFilterName }: RemoveFilterEventData) {
    this.filters = filters;
    this.isFiltered = this.filters.some(filter => !!filter.value);

    if (removedFilterName === 'date') {
      delete this.customQuery['createdAt'];
    } else {
      delete this.customQuery[removedFilterName];
    }
    this.getData();
  }

  handleClearFilters({ shouldReload: shouldReloadData = true } = {}) {
    this.filters = this.filters.map(filterObject => {
      return {
        ...filterObject,
        value: '',
        valueLabel: ''
      };
    });

    this.isFiltered = false;
    this.customQuery = { ...this.initialQuery };
    this.getData();
    delete this.customQuery.status;

    if (shouldReloadData) {
      this.getData();
    }
  }

  goToResidence(occurrence: Occurrence) {
    this.router.navigate(['condo', 'residences', occurrence.viewers.residences[0]._id]);
  }

  public openDeliveryTrackingConfig(): void {
    if (this.condo.isDeliveriesTrackingDisabled()) {
      return;
    }

    const initialState = { condo: this.condo, user: this.user };

    this.modalService.show(ModalConfigDeliveryTrackingComponent, { initialState, class: 'modal-lg' });
  }

  private getDeliveryTrackingHistoryQuery(): EcondosQuery {
    const query: EcondosQuery = {
      $select: ['condo', 'residence', 'user', 'delivery', 'date', 'sequence', 'obs', 'createdBy', 'createdAt'].join(' '),
      $populate: [
        { path: 'condo', select: 'name' },
        { path: 'residence', select: 'type identification voter' },
        { path: 'delivery', select: 'type subType title createdAt' },
        {
          path: 'sequence',
          select: 'condo step title description shouldCloseDelivery shouldNotifyUser createdBy createdAt',
          options: { withDeleted: true }
        },
        { path: 'user', select: 'firstName lastName' },
        { path: 'createdBy', select: 'firstName lastName' }
      ],
      $sort: '-createdAt'
    };

    return query;
  }

  public async getDeliveryTrackingHistory(): Promise<void> {
    if (!this.selectedOccurrence) {
      return;
    }

    this.loadTrackingHistoryStatus = 'LOADING';

    try {
      const query = this.getDeliveryTrackingHistoryQuery();

      const history = await this.deliveryTrackingHistoryService
        .getByDeliveryId(this.condo._id, this.selectedOccurrence._id, query)
        .toPromise();

      this.trackingHistory = history;

      this.loadTrackingHistoryStatus = 'SUCCESS';
    } catch (error) {
      this.loadTrackingHistoryStatus = 'ERROR';

      console.error(error);
    }
  }

  toggleSelectedOccurrences() {
    const notClosedOccurrences = this.filteredOccurrences.filter(o => o.status !== 'CLOSED');

    const anyIsNotSelected = notClosedOccurrences.some(o => !o.isSelected);

    this.occurrences = this.filteredOccurrences = this.filteredOccurrences.map(occurrence => {
      occurrence.isSelected = anyIsNotSelected;
      return occurrence;
    });

    this.areAllOccurrencesSelected = this.occurrences.every(o => o.isSelected);
  }

  closeMultipleOccurrences() {
    const occurrences = this.filteredOccurrences
      .filter(occ => occ.status !== 'CLOSED' && occ.isSelected)
      .map(occ => new GateOccurrence(occ));
    if (occurrences.length > 0) {
      const initialState = {
        occurrences: occurrences,
        onSuccess: () => {
          this.getData();
        }
      };
      this.modalService.show(ModalCloseMultipleDeliveriesComponent, { initialState });
    } else {
      swal({ type: 'warning', title: 'Nenhuma encomenda selecionada', text: 'Selecione uma ou mais encomendas para dar baixa' });
    }
  }

  toggleOccurrence(occurrence: Delivery) {
    this.occurrences = this.occurrences.map(occ =>
      occ._id === occurrence._id ? new Delivery({ ...occ, isSelected: !occ.isSelected }) : occ
    );

    this.filteredOccurrences = this.filteredOccurrences.map(occ =>
      occ._id === occurrence._id ? new Delivery({ ...occ, isSelected: !occ.isSelected }) : occ
    );

    this.areAllOccurrencesSelected = this.occurrences.every(occ => occ.isSelected);
  }

  toggleSelectMultipleDeliveries() {
    this.selectMultipleDeliveries = !this.selectMultipleDeliveries;

    this.tableColumns = this.tableColumns.map(column =>
      column.columnId === 'select' ? { ...column, show: this.selectMultipleDeliveries } : column
    );

    if (!this.selectMultipleDeliveries) {
      this.unselectAllDeliveries();
      this.areAllOccurrencesSelected = false;
    }
  }

  unselectAllDeliveries() {
    this.filteredOccurrences = this.filteredOccurrences.map(occurrence => new Delivery({ ...occurrence, isSelected: false }));
  }

  updateStatusFilter(statusOption: { value: string; label: string }) {
    this.statusFilter = statusOption;
    if (statusOption.value === 'CLOSED') {
      this.selectMultipleDeliveries = false;
      this.unselectAllDeliveries();
    }
    this.getData();
  }

  onResidenceSelect(residence: Residence) {
    const initialState = {
      condo: this.condo,
      residenceId: residence._id
    };
    this.modalService.show(GateResidenceDetailsModalComponent, { initialState, class: 'modal-xl', ignoreBackdropClick: true });
  }

  showDataViewersModal() {
    const initialState = {
      condo: this.condo,
      model: 'occurrence',
      documentId: this.selectedOccurrence._id
    };
    this.modalService.show(ModalShowDataViewersComponent, { initialState, class: 'modal-lg' });
  }
}
