import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'ngx-moment';
import { ComponentsModule } from '../../../../../../components/components.module';
import { DirectivesModule } from '../../../../../../directives/directives.module';

import { LogbookMessageReplyComponent } from './logbook-message-reply/logbook-message-reply.component';
import { LogbookMessagesComponent } from './logbook-messages/logbook-messages.component';

@NgModule({
  imports: [CommonModule, ComponentsModule, DirectivesModule, MomentModule],
  declarations: [LogbookMessageReplyComponent, LogbookMessagesComponent],
  exports: [LogbookMessageReplyComponent, LogbookMessagesComponent]
})
export class LogbookModule {}
