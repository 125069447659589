import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { UtilService } from '../../../services/util.service';
import { User } from '@api/model/user';
import * as moment from 'moment';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Vehicle } from '@api/model/vehicle';
import { CondoContact } from '@api/model/contact/condo.contact';
import { Access } from '@api/model/access';
import { Contact } from '@api/model/interface/contact';
import { AccessService } from '@api/service/access.service';
import { Condo } from '@api/model/condo';
import { OccurrenceService } from '@api/service/occurrence.service';
import { filter, map, retry, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ParamsService } from '@api/service/params.service';
import { forkJoin, from, fromEvent, noop, Observable, of, Subject, Subscription } from 'rxjs';
import { Residence } from '@api/model/interface/residence';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalGenerateQrcodeFromGatekeeperComponent } from '../modal/modal-generate-qrcode-from-gatekeeper/modal-generate-qrcode-from-gatekeeper.component';
import { ContactID } from '@api/model/contact/contact.id';
import { ModalNewCondoContactComponent } from './new.condo.contact/new.condo.contact.modal';
import { HikvisionService } from '@api/service/hardware/hikvision.service';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { VehicleCreateModal } from 'app/pages/modal/vehicle.create.modal/vehicle.create.modal';
import { CondoContactService } from '@api/service/condo.contact.service';
import { ModalShowManufacturerChoiceComponent } from '../../../hardware/modal-show-manufacturer-choice/modal-show-manufacturer-choice.component';
import { IntelbrasIncontrolService } from '@api/service/hardware/intelbras-incontrol.service';
import { ModalSelectAccessGroup } from 'app/components/modal-select-access-group/modal-select-access-group.component';
import { EcondosQuery } from '@api/model/query';
import { AccessGroupService } from '@api/service/access-group.service';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { ModalAccessControlConfigComponent } from '../modal-access-control-config/modal-access-control-config.component';
import { AccessServiceV2 } from '@api/serviceV2/access.service';
import { EcondosFilter } from '@api/model/filter';
import { ModalFilterComponent } from 'app/components/modal-filter/modal-filter.component';
import { capitalize, removeAccents, replaceVowelsToAccentedVowels } from '@api/util/util';
import { IntelbrasStandAloneService } from '@api/service/hardware/intelbras-stand-alone.service';
import { CallService } from '../../../services/call.service';
import { Occurrence } from '@api/model/interface/occurrence';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import { GateResidenceDetailsModalComponent } from 'app/pages/gate-residence-details.modal/gate-residence-details.modal.component';
import { Device, DEVICE_STATUS, DEVICE_TYPES } from '@api/model/hardware/device';
import { AlphaDigiService } from '@api/service/hardware/alphadigi.service';
import { AccessGroup } from '@api/model/hardware/access-group';
import { Actuator } from '@api/model/hardware/actuator';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { HardwareSocketService } from '../../../services/hardware-socket.service';
import { ControlIdService } from '@api/service/hardware/control-id.service';
import { CondoServiceV2 } from '@api/serviceV2/condo.service';
import { ModalPermissionDetailsComponent } from '../../../components/modal-permission-details/modal-permission-details.component';
import { DependentServiceV2 } from '@api/serviceV2/dependent.service';
import { ALLOW_VISITORS_ACCESS, Dependent } from '@api/model/dependent';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { SessionService } from '@api/service/session.service';

type Status = 'LOADING' | 'SUCCESS' | 'ERROR';
type ApprovedByListDataSource = User & Partial<Dependent>;

@Component({
  templateUrl: 'access.control.html',
  selector: 'app-access-control',
  styleUrls: ['access.control.scss']
})
export class AccessControlComponent implements OnInit, OnDestroy {
  @ViewChild('vehicleCreateModal', { static: true }) vehicleCreateModal: VehicleCreateModal;

  public VEHICLE_TYPES = Vehicle.TYPES;
  public ACCESS_STATUS = Access.STATUS;
  public ACCESS_STATUS_LABEL = Access.STATUS_LABEL;
  public ALLOW_VISITORS_ACCESS = ALLOW_VISITORS_ACCESS;

  public condo = this.sessionService.condoValue;
  public user = this.sessionService.userValue;

  public ERROR = 'ERROR';
  public SUCCESS = 'SUCCESS';
  public LOADING = 'LOADING';

  public loadingStatus = '';

  isLoading = false;

  public accesses: Access[] = [];
  public filteredAccesses: Access[] = [];
  public selectedAccess: Access;

  public accessForm: UntypedFormGroup;
  public vehicle: AbstractControl;
  public visitType: AbstractControl;
  public obs: AbstractControl;
  public residence: AbstractControl;
  public approvedByName: AbstractControl;
  public status: AbstractControl;

  public intercomCallee: User = null;
  residentsAndDependentsList = [];
  residentsAndDependentsLimit: number = 50;

  public baseSuccessCallback;
  public baseErrorCallback;
  today = new Date();
  numberOfActiveFilters = 0;
  filters: EcondosFilter[] = [];
  itemsPerPage = 30;
  public viewPlate = false;
  currentSortData: { field: string; order: 'asc' | 'desc' | '' } = { field: 'createdAt', order: 'desc' };
  initialQuery: EcondosQuery = {
    $populate: [
      {
        path: 'condoContact',
        select:
          'firstName lastName phones isForeigner type picture ids condoVehicles birthDate company service externalId banned bannedBy banReason bannedAt specialNeeds specialNeedsDetails obs',
        populate: [
          { path: 'picture', select: 'url thumbnail type name format' },
          { path: 'ids.pictures', select: 'url thumbnail type name format' },
          { path: 'condoVehicles', select: 'plate model type brand color pictures' },
          { path: 'bannedBy', select: 'firstName lastName' }
        ]
      },
      {
        path: 'residence',
        select: 'identification type number company lineOfWork extensionLine phones'
      },
      { path: 'approvedBy', select: 'firstName lastName' },
      { path: 'condoVehicle', select: 'plate model type brand color pictures' },
      { path: 'createdBy', select: 'firstName lastName' }
    ]
  };

  customQuery = { ...this.initialQuery };
  contactTypes = [
    { key: Contact.TYPES.VISITOR, value: Contact.TYPES_LABEL.VISITOR },
    { key: Contact.TYPES.RESIDENT, value: Contact.TYPES_LABEL.RESIDENT },
    { key: Contact.TYPES.PROVIDER, value: Contact.TYPES_LABEL.PROVIDER },
    { key: Contact.TYPES.DELIVERYMAN, value: Contact.TYPES_LABEL.DELIVERYMAN },
    { key: Contact.TYPES.DRIVER, value: Contact.TYPES_LABEL.DRIVER },
    { key: Contact.TYPES.PUBLIC_AGENT, value: Contact.TYPES_LABEL.PUBLIC_AGENT },
    { key: Contact.TYPES.EMPLOYEE, value: Contact.TYPES_LABEL.EMPLOYEE },
    { key: Contact.TYPES.RELATIVE, value: Contact.TYPES_LABEL.RELATIVE },
    { key: Contact.TYPES.AIRBNB, value: Contact.TYPES_LABEL.AIRBNB },
    { key: Contact.TYPES.TENANT, value: Contact.TYPES_LABEL.TENANT },
    { key: Contact.TYPES.BROKER, value: Contact.TYPES_LABEL.BROKER },
    { key: Contact.TYPES.OTHER, value: Contact.TYPES_LABEL.OTHER }
  ];

  private subscriptions: Subscription = new Subscription();

  visitorPlate = false;
  visitorQRCode = false;
  visitorPassword = false;
  isVisitorsFacialFromGatekeeperEnabled = false;
  visitorCard = false;

  buttonsStatus: { [type: string]: string } = {};
  hardwareManufacturers = [];
  hasMultipleHardwareManufacturers = false;
  approvedByList = [];

  residenceQuery: EcondosQuery = {
    $select:
      'block identification lot number address users voter type company lineOfWork extensionLine phones specialNeeds specialNeedsDetails',
    // Necessário enviar um $populate vazio para sobescrever o populate padrão do component de busca por unidade
    $populate: []
  };

  hasWriteAccess = false;

  limit = 30;
  count = 0;
  page = 1;

  selectedAccessCondoContactPermissionsData: { occurrence: GateOccurrence; isValidTime: boolean }[] = [];
  checkAccessCondoContactHasTodayPermissionStatus: Status = null;

  approvedByListDataSource$: Observable<ApprovedByListDataSource[]>;
  approvedByListSearch = '';
  approvedByListLoading = false;

  constructor(
    private sessionService: SessionService,
    private utilService: UtilService,
    private formBuilder: UntypedFormBuilder,
    private accessService: AccessService,
    private accessServiceV2: AccessServiceV2,
    private dependentService: DependentServiceV2,
    private paramsService: ParamsService,
    private modalService: BsModalService,
    private hikvisionService: HikvisionService,
    private intelbrasIncontrolService: IntelbrasIncontrolService,
    private intelbrasService: IntelbrasStandAloneService,
    private alphadigiService: AlphaDigiService,
    private controlIdService: ControlIdService,
    private actuatorService: ActuatorService,
    private toastr: ToastrService,
    private occurrenceService: OccurrenceService,
    private condoContactService: CondoContactService,
    private accessGroupService: AccessGroupService,
    private deviceService: HardwareDeviceService,
    private callService: CallService,
    private hardwareDeviceService: HardwareDeviceService,
    private hardwareSocketService: HardwareSocketService,
    private condoService: CondoServiceV2
  ) {
    this.hasWriteAccess = this.user.isAdminOnCondo(this.condo._id) || this.user.isOwnerOnCondo(this.condo._id);

    this.filters = [
      {
        element: 'visitor-picker',
        elementSize: 'medium',
        label: 'Pessoa',
        testId: 'condo-contact',
        name: 'condoContact',
        params: {
          canCreateContact: false,
          condo: this.condo,
          placeholder: 'Digite uma pessoa'
        },
        searchType: 'match'
      },
      {
        element: 'residence-picker',
        elementSize: 'medium',
        label: capitalize(this.condo?.customLabels?.residence?.singular) || 'Unidade',
        name: 'residence',
        testId: 'residence',
        params: {
          condo: this.condo,
          showAsInputGroup: true,
          typeaheadHideResultsOnBlur: true,
          placeholder: 'Digite um(a) ' + this.condo?.customLabels?.residence?.singular || 'unidade',
          adaptivePosition: true,
          clearInputTextAfterSelect: false
        },
        searchType: 'match'
      },
      {
        element: 'vehicles-picker',
        elementSize: 'medium',
        name: 'condoVehicle',
        label: 'Veículo',
        testId: 'condo-vehicle',
        params: {
          placeholder: 'Insira a placa ou chassi do veículo para buscá-lo',
          condo: this.condo,
          filterCriteria: 'VISITORS_ONLY',
          showIcon: true
        },
        searchType: 'match'
      },
      {
        element: 'select',
        elementSize: 'medium',
        selectOptions: [
          { value: '', label: 'Todos' },
          { value: 'IN', label: 'Entrada' },
          { value: 'OUT', label: 'Saida' }
        ],
        name: 'inOrOut',
        testId: 'in-or-out',
        label: 'Tipo',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementType: 'date',
        elementSize: 'medium',
        name: 'date',
        testId: 'date',
        label: 'Data',
        searchType: 'regex'
      },
      {
        element: 'select',
        elementSize: 'medium',
        selectOptions: [
          { value: '', label: 'Todos' },
          { value: 'RESIDENT', label: capitalize(this.condo?.customLabels?.resident?.singular) || 'Condômino' },
          { value: 'VISITOR', label: 'Visitante' },
          { value: 'PROVIDER', label: 'Prestador' },
          { value: 'SUPPLIER', label: 'Fornecedor' },
          { value: 'DELIVERYMAN', label: 'Entregador' },
          { value: 'PUBLIC_AGENT', label: 'Agente publico' },
          { value: 'DRIVER', label: 'Motorista (Aplicativos/Taxi)' },
          { value: 'EMPLOYEE', label: 'Funcionário' },
          { value: 'RELATIVE', label: 'Parente' },
          { value: 'AIRBNB', label: 'Airbnb' },
          { value: 'TENANT', label: 'Locatário' },
          { value: Contact.TYPES.BROKER, label: Contact.TYPES_LABEL.BROKER },
          { value: 'OTHER', label: 'Outros' }
        ],
        name: 'type',
        testId: 'type',
        label: 'Acesso de',
        searchType: 'regex'
      },
      {
        element: 'input',
        elementType: 'text',
        elementSize: 'medium',
        name: 'obs',
        testId: 'obs',
        label: 'Observação',
        placeholder: 'Busque pela observação',
        searchType: 'regex'
      }
    ];
    this.hardwareManufacturers = [
      // { value: 'LINEAR', label: 'Linear', logo: 'assets/img/linear-logo.png', enabled: this.condo.isLinearEnabled() },
      // { value: 'CONTROL_ID', label: 'Control ID', enabled: this.condo.isControlIdEnabled() },
      {
        value: 'HIKVISION',
        label: 'Hikvision',
        logo: 'assets/img/hikvision-logo.png',
        enabled: this.condo.isHikvisionEnabled()
      },
      // { value: 'UTECH', label: 'uTech', enabled: this.condo.isUtechEnabled() },
      {
        value: 'INTELBRAS',
        label: 'Intelbras Incontrol',
        logo: 'assets/img/incontrol.png',
        enabled: this.condo.isIntelbrasEnabled()
      },
      {
        value: 'INTELBRAS_STAND_ALONE',
        label: 'Intelbras',
        logo: 'assets/img/intelbras-logo.png',
        enabled: this.condo.isIntelbrasStandAloneEnabled()
      },
      {
        value: HARDWARES.CONTROL_ID,
        label: 'Control iD',
        logo: 'assets/img/control-id-logo.png',
        enabled: this.condo.isControlIdEnabled()
      },
      // {
      //   value: 'ALPHADIGI',
      //   label: 'Alphadigi',
      //   logo: 'assets/img/alphadigi-logo.png',
      //   enabled: this.condo.isAlphaDigiEnabled()
      // },
      {
        value: HARDWARES.GAREN,
        label: 'Garen',
        logo: 'assets/img/garen-logo.png',
        enabled: this.condo.isGarenEnabled()
      },
      {
        value: HARDWARES.BRAVAS,
        label: 'Bravas',
        logo: 'assets/img/bravas-logo.png',
        enabled: this.condo.isBravasEnabled()
      },
      {
        value: HARDWARES.XPE,
        label: 'XPE',
        logo: 'assets/img/intelbras-xpe-logo.png',
        enabled: this.condo.isXPEEnabled()
      },
      {
        value: HARDWARES.AVICAM,
        label: 'XPE',
        logo: 'assets/img/avicam-logo.png',
        enabled: this.condo.isAvicamEnabled()
      }
    ];

    if (this.hardwareManufacturers.every(hm => !hm.enabled)) {
      this.hardwareManufacturers.push({
        value: 'ALPHADIGI',
        label: 'Alphadigi',
        logo: 'assets/img/alphadigi-logo.png',
        enabled: this.condo.isAlphaDigiEnabled()
      });
    }
    this.visitorPlate =
      this.condo.isLprEnabled() &&
      this.condo.generalParams?.alprParams?.visitorEnabled &&
      this.condo.generalParams?.alprParams?.triggerActuatorForVisitor;
    this.visitorQRCode = this.condo.hardwareParams.visitorsQRCodeFromGatekeeper === 'ENABLED';
    this.visitorPassword = this.condo.hardwareParams.visitorsPasswordFromGatekeeper === 'ENABLED';
    this.isVisitorsFacialFromGatekeeperEnabled = this.condo.hardwareParams.visitorsFacialFromGatekeeper === 'ENABLED';
    this.visitorCard =
      (this.condo.isZktecoEnabled() || this.condo.isLinearEnabled()) && this.condo.hardwareParams.visitorsCardFromGatekeeper === 'ENABLED';

    if (this.user.customRoles.some(role => role?.condo?._id === this.condo._id)) {
      this.visitorPlate =
        this.user.getPermissionValue({
          condoId: this.condo._id,
          permission: PERMISSIONS.visitorDevices.create
        }) && this.visitorPlate;
      this.visitorQRCode =
        this.user.getPermissionValue({
          condoId: this.condo._id,
          permission: PERMISSIONS.visitorDevices.create
        }) && this.visitorQRCode;
      this.visitorPassword =
        this.user.getPermissionValue({
          condoId: this.condo._id,
          permission: PERMISSIONS.visitorDevices.create
        }) && this.visitorPassword;
      this.isVisitorsFacialFromGatekeeperEnabled =
        this.user.getPermissionValue({
          condoId: this.condo._id,
          permission: PERMISSIONS.visitorDevices.create
        }) && this.isVisitorsFacialFromGatekeeperEnabled;
      this.visitorCard =
        this.user.getPermissionValue({
          condoId: this.condo._id,
          permission: PERMISSIONS.visitorDevices.create
        }) && this.visitorCard;
    }

    this.baseSuccessCallback = accesses => {
      // TODO verificar ordenação
      // accesses.sort(function (u1: any, u2: any) {
      //   return ''.concat(u1.firstName, ' ', u1.lastName).localeCompare(''.concat(u2.firstName, ' ', u2.lastName));
      // });

      this.filteredAccesses = this.accesses = accesses;
      this.loadingStatus = this.SUCCESS;
      if (this.selectedAccess) {
        const updatedAccess = this.accesses.find(u => {
          return u.id == this.selectedAccess.id;
        });
        if (updatedAccess) {
          this.selectedAccess = <Access>updatedAccess;
          this.setFormValues(this.selectedAccess);
        }
      }
    };
    this.baseErrorCallback = err => {
      this.loadingStatus = this.ERROR;
      this.filteredAccesses = this.accesses = [];
      this.selectedAccess = null;
    };
    this.modalService.onHide.subscribe(reason => {
      if (reason === 'backdrop-click') {
        this.buttonsStatus['SN'] = this.ERROR;
        this.buttonsStatus['QR'] = this.ERROR;
        this.buttonsStatus['FACIAL'] = this.ERROR;
        this.buttonsStatus['VEHICLE_PLATE'] = this.ERROR;
        this.buttonsStatus['CARD'] = this.ERROR;
      }
    });

    this.approvedByListDataSource$ = new Observable((observer: any) => {
      observer.next(this.approvedByListSearch);
    }).pipe(
      tap(() => (this.approvedByListLoading = true)),
      switchMap((token: string) => {
        const query: EcondosQuery = {
          $page: 0,
          $limit: 15,
          $sort: 'firstName lastName',
          $select: 'firstName lastName',
          $or: [{ residencesUser: this.residence.value._id }, { residencesVoter: this.residence.value._id }]
        };
        const dependentQuery: EcondosQuery = {
          $limit: 30,
          $sort: 'name',
          $select: 'name residence allowVisitors',
          residence: this.residence.value._id,
          allowVisitors: { $ne: 'NO', $exists: true }
        };
        let terms: any = token.trim().toLowerCase();
        terms = terms.split(' ');
        terms = terms.map(word => removeAccents(word));
        terms = terms.map(replaceVowelsToAccentedVowels);

        const $andResident = [];
        for (const term of terms) {
          $andResident.push({ $or: [{ firstName: { $regex: term, $options: 'i' } }, { lastName: { $regex: term, $options: 'i' } }] });
        }
        query.$and = $andResident;

        const $andDependent = [];
        for (const term of terms) {
          $andDependent.push({ name: { $regex: term, $options: 'i' } });
        }
        dependentQuery.$and = $andDependent;

        const residents$ = this.condoService.getCondoResidents(this.condo._id, query).pipe(map(({ users }) => users));
        const dependentsAuthorizingPeople$ = this.dependentService
          .getDependents(this.condo._id, dependentQuery)
          .pipe(map(({ dependents }) => dependents));
        return forkJoin([residents$, dependentsAuthorizingPeople$]);
      }),
      map(([residents, dependents]) => {
        this.residentsAndDependentsList = [
          ...residents.map(resident => ({ name: `${resident.firstName} ${resident.lastName}`, type: 'RESIDENT' })),
          ...dependents.map(dependent => ({
            name: `${dependent.name} (${this.condo.customLabels.dependent.singular})`,
            type: 'DEPENDENT',
            accessPermission: dependent.allowVisitors
          }))
        ];
        return this.residentsAndDependentsList as ApprovedByListDataSource[];
      }),
      tap(() => {
        this.residentsAndDependentsLimit = this.residentsAndDependentsList.length;
        this.approvedByListLoading = false;
      })
    );
  }

  ngOnInit(): void {
    this.hasMultipleHardwareManufacturers = this.hardwareManufacturers.filter(hw => hw.enabled).length > 1;

    this.initializeForm();
    const accessInfo: AccessInfo = this.paramsService.getAndClear('NEW_ACCESS');
    if (accessInfo) {
      this.initializeNewAccess(accessInfo);
    }
    this.subscriptions.add(
      this.paramsService.newParams$.pipe(filter(param => param.key === 'NEW_ACCESS')).subscribe(param => {
        this.initializeNewAccess(param.value);
        this.paramsService.clear('NEW_ACCESS');
      })
    );
    this.downloadData();
  }

  initializeNewAccess(accessInfo: AccessInfo) {
    this.clearSelection();
    if (accessInfo.access) {
      // Is created from a permission
      this.onCreateAccessFromOccurrence(accessInfo.access);
    } else {
      if (accessInfo.contact) {
        // Is created from a contact previous selected
        this.createAccessFromContactAndResidence(accessInfo.contact, accessInfo.residence);
      } else {
        // Is created from a new contact query
        this.createAccessFromContactInfoAndResidence(accessInfo.nameOrId, accessInfo.residence);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  downloadData(successCallback = this.baseSuccessCallback, errorCallback = this.baseErrorCallback, page = 1) {
    this.loadingStatus = this.LOADING;
    const query = this.customQuery;
    query.$page = page - 1;
    query.$limit = this.itemsPerPage;
    query.$sort = `${this.currentSortData.order === 'desc' ? '-' : ''}${this.currentSortData.field}`;
    this.accessService
      .getAccesses(this.condo._id, query)
      .pipe(timeout(20000))
      .subscribe(
        ({ accesses, count }) => {
          if (!!query.condoVehicle) {
            this.viewPlate = true;
          }
          this.count = count;
          this.page = page;
          successCallback(accesses);
        },
        err => {
          errorCallback(err);
        }
      );
  }

  setPage(page) {
    this.downloadData(this.baseSuccessCallback, this.baseErrorCallback, page);
  }

  initializeForm() {
    this.accessForm = this.formBuilder.group({
      vehicle: [''],
      visitType: ['', Validators.compose([Validators.required])],
      obs: [''],
      residence: [''],
      approvedByName: ['', Validators.compose([Validators.required])],
      status: ['']
    });

    this.vehicle = this.accessForm.get('vehicle');
    this.visitType = this.accessForm.get('visitType');
    this.obs = this.accessForm.get('obs');
    this.residence = this.accessForm.get('residence');
    this.approvedByName = this.accessForm.get('approvedByName');
    this.status = this.accessForm.get('status');

    this.subscriptions.add(this.approvedByName.valueChanges.subscribe(() => (this.intercomCallee = null)));
  }

  setFormValues(access: Access) {
    this.accessForm.reset();

    this.intercomCallee = access.approvedBy;
    this.vehicle.setValue(access.condoVehicle || '');
    this.visitType.setValue(access.type || '');
    this.obs.setValue(access.obs || '');
    this.status.setValue(access.status);

    /**
     * Para atender à Carrera, foi feito esse IF para que deixe por padrão,
     * como responsável da liberação, a pessoa logada e unidade, quando ela
     * for morador e porteiro.
     * Card: 1120
     */
    if (!access.isCreatedFromOccurrence() && this.user.isUser() && this.user.isGatekeeper()) {
      const [residence] = this.user.getResidences();
      this.residence.setValue(residence);
      this.approvedByName.setValue(this.user.fullName);
    } else {
      this.residence.setValue(access.residence || null);
      this.approvedByName.setValue(access.approvedByAsString);
    }

    this.enableDisableFields(access);
  }

  enableDisableFields(access: Access) {
    if (access.isRegistered()) {
      this.vehicle.disable();
      this.visitType.disable();
      this.obs.disable();
      this.residence.disable();
      this.approvedByName.disable();
    } else {
      this.vehicle.enable();
      this.visitType.enable();
      this.obs.enable();

      /**
       * Para atender à Carrera, foi feito esse IF para que deixe por padrão,
       * como responsável da liberação, a pessoa logada e unidade, quando ela
       * for morador e porteiro.
       * Card: 1120
       */
      if (!access.isCreatedFromOccurrence() && this.user.isUser() && this.user.isGatekeeper()) {
        this.residence.disable();
        this.approvedByName.disable();
      } else {
        if (access.residence) {
          this.residence.disable();
        } else {
          this.residence.enable();
        }
        this.approvedByName.enable();
      }
    }
  }

  onSelect(access) {
    if (this.selectedAccess && this.selectedAccess._id === access._id) {
      this.clearSelection();
    } else {
      this.selectedAccess = access;
      this.setFormValues(access);
      this.selectedAccessCondoContactPermissionsData = [];
      this.checkIfSelectedAccessCondoContactHasTodayPermission(access?.condoContact);
    }
  }

  formatPhone(phone) {
    return this.utilService.formatPhone(phone);
  }

  onResidencesSelect(residence) {
    if (residence) {
      this.residence.setValue(residence);
      this.approvedByName.setValue(residence.residenceUsers.length ? '' : this.user.fullName);
      this.intercomCallee = residence.residenceUsers.length ? null : this.user;
      this.approvedByName.markAsUntouched();
    } else {
      this.residence.setValue(null);
      this.approvedByName.setValue(this.user.fullName);
      this.intercomCallee = this.user;
      this.approvedByName.markAsUntouched();
    }
    this.approvedByListSearch = '';
  }

  registerAccess(access, isInAccess) {
    const gateKeeperMustRegisterResidence =
      this.condo.generalParams.accessLiberation.gateKeeperMustRegisterResidence && !this.residence.value;

    const gateKeeperMustRegisterVisitorDocument =
      this.condo.generalParams.accessLiberation.gateKeeperMustRegisterVisitorDocument && !access.condoContact?.ids?.length;

    if (this.condo.type === 'BUSINESS' && !this.residence.value) {
      this.toastr.error('Necessário selecionar um(a) ' + this.condo?.customLabels?.residence?.singular || 'unidade');
      return;
    }

    if (gateKeeperMustRegisterResidence) {
      this.toastr.error('Necessário selecionar um(a) ' + this.condo?.customLabels?.residence?.singular || 'unidade');
      return;
    }

    if (gateKeeperMustRegisterVisitorDocument) {
      this.toastr.error(`Necessário cadastrar documento do ${this.condo?.customLabels?.visitor?.singular || 'visitante'}`);
      return;
    }

    if (this.accessForm.invalid) {
      for (const key of Object.keys(this.accessForm.controls)) {
        this.accessForm.controls[key].markAsTouched();
      }
      return;
    }

    const newAccess: Access = new Access();
    newAccess.status = Access.STATUS.APPROVED;
    newAccess.condoVehicle = this.vehicle.value;
    newAccess.type = this.visitType.value;
    newAccess.residence = this.residence.value || null;
    newAccess.obs = this.obs.value;
    newAccess.source = access.source;
    newAccess.condoContact = access.condoContact;

    if (access.isCreatedFromOccurrence()) {
      newAccess.occurrence = access.occurrence;
      newAccess.approvedByName =
        access.occurrence.createdBy && access.occurrence.createdBy.firstName + ' ' + access.occurrence.createdBy.lastName;
    } else if (this.residence.value) {
      if (this.approvedByName.value.includes(')')) {
        newAccess.approvedByName = this.approvedByName.value.split(')')[0] + ')';
      } else {
        newAccess.approvedByName = this.approvedByName.value;
      }
    } else {
      newAccess.approvedByName = this.user.fullName;
    }

    if (isInAccess) {
      newAccess.inDate = new Date();
    } else {
      newAccess.outDate = new Date();
    }
    if (
      !this.checkCanRegisterPassword(access.condoContact) &&
      isInAccess &&
      this.condo.hardwareParams?.visitorsPasswordFromGatekeeper === 'ENABLED' &&
      this.condo?.hardwareParams?.visitorPasswordFromGatekeeperOnlyToLeave
    ) {
      swal({
        type: 'question',
        text: `O usuário ${
          access.condoContact.firstName + ' ' + access.condoContact.lastName
        } não tem RG cadastrado ou o número cadastrado é muito curto. Deseja continuar?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.getRegisterAccessMethod(newAccess)
            .pipe(timeout(10000))
            .toPromise()
            .then((accessCreated: any) => {
              access.id = accessCreated._id;
              if (isInAccess) {
                access.inDate = newAccess.inDate;
              } else {
                access.outDate = newAccess.outDate;
              }
              access.status = newAccess.status;
              access.condoVehicle = newAccess.condoVehicle;
              access.type = newAccess.type;
              access.residence = newAccess.residence;
              access.approvedByName = newAccess.approvedByName;
              access.obs = newAccess.obs;
              this.setFormValues(access);
              this.toastr.success((isInAccess ? 'Entrada' : 'Saída') + ' registrada');
            })
            .catch(err => {
              console.log(err);
              return Promise.reject(`Não foi possível registrar a ${isInAccess ? 'entrada' : 'saída'}, tente novamente...`);
            });
        }
      }).then(
        () => {
          this.downloadData(this.baseSuccessCallback, this.baseErrorCallback);
        },
        () => {
          console.log('Clicked cancel');
        }
      );
    } else {
      swal({
        type: 'question',
        text: `Confirmar a ${isInAccess ? 'entrada' : 'saída'} de ${access.condoContact.firstName + ' ' + access.condoContact.lastName} ?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.getRegisterAccessMethod(newAccess)
            .pipe(
              timeout(10000),
              tap(async res => {
                if (!isInAccess && access.condoContact.externalId) {
                  await this.intelbrasIncontrolService.expirePreviousVisit(access.condoContact.externalId);
                }
              })
            )
            .toPromise()
            .then(async (accessCreated: any) => {
              access.id = accessCreated._id;
              if (isInAccess) {
                access.inDate = newAccess.inDate;
                if (
                  this.condo.hardwareParams?.visitorsPasswordFromGatekeeper === 'ENABLED' &&
                  this.condo?.hardwareParams?.visitorPasswordFromGatekeeperOnlyToLeave
                ) {
                  await this.generateVisitorDeviceFromGatekeeper('SN', {
                    condo: this.condo,
                    condoContact: newAccess.condoContact,
                    residence: newAccess.residence,
                    approvedByName: newAccess.approvedByName,
                    condoVehicleId: this.vehicle.value,
                    obs: newAccess.obs,
                    accessType: newAccess.type
                  });
                }
              } else {
                access.outDate = newAccess.outDate;
              }
              access.status = newAccess.status;
              access.condoVehicle = newAccess.condoVehicle;
              access.type = newAccess.type;
              access.residence = newAccess.residence;
              access.approvedByName = newAccess.approvedByName;
              access.obs = newAccess.obs;
              this.setFormValues(access);
              this.toastr.success((isInAccess ? 'Entrada' : 'Saída') + ' registrada');
            })
            .catch(err => {
              console.log(err);
              return Promise.reject(`Não foi possível registrar a ${isInAccess ? 'entrada' : 'saída'}, tente novamente...`);
            });
        }
      }).then(
        () => {
          this.downloadData(this.baseSuccessCallback, this.baseErrorCallback);
        },
        () => {
          console.log('Clicked cancel');
        }
      );
    }
  }

  // Usado porque o acesso pode ser criado através de ocorrência ou não
  getRegisterAccessMethod(access: Access) {
    if (access.isCreatedFromOccurrence()) {
      const occurrenceId = access.occurrence.id || <any>access.occurrence;
      return this.occurrenceService.createAccessFromOccurrence(this.condo._id, occurrenceId, access.createBackObject());
    } else {
      return this.accessService.createAccess(this.condo._id, access.createBackObject());
    }
  }

  cancelAccess(access) {
    swal({
      type: 'question',
      text: `Deseja realmente cancelar este acesso ?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const oldStatus = this.status.value;
        this.status.setValue(Access.STATUS.CANCELED);
        access.status = Access.STATUS.CANCELED;
        return this.accessService
          .updateAccess(this.condo._id, access.id, {
            _id: access.id,
            status: Access.STATUS.CANCELED
          })
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            this.status.setValue(oldStatus);
            access.status = oldStatus;
            return Promise.reject(`Não foi cancelar este acesso, tente novamente...`);
          });
      }
    }).then(
      () => {
        this.toastr.success('Acesso cancelado com sucesso');
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  nameComparator(contactA, contactB) {
    const nameA = contactA.fullName;
    const nameB = contactB.fullName;
    return nameA.localeCompare(nameB);
  }

  onVisitorSelectCallback(condoContact) {
    const contact = new CondoContact(condoContact);
    const access = new Access();
    access.condoContact = contact;
    access.source = Access.SOURCES.GATE;
    access.status = Access.STATUS.PENDING;
    access.type = contact.type;
    if (this.residence.value) {
      access.residence = this.residence.value;
      access.approvedByName = null;
    } else {
      access.approvedByName = this.user.fullName;
    }
    this.selectedAccess = access;
    this.setFormValues(this.selectedAccess);

    this.checkIfSelectedAccessCondoContactHasTodayPermission(condoContact);

    this.filters = this.filters.map(filterObj => {
      if (filterObj.name === 'condoContact') {
        filterObj.value = condoContact;
        filterObj.valueLabel = condoContact.fullName;
      }
      return filterObj;
    });

    this.numberOfActiveFilters = this.numberOfActiveFilters + 1;

    this.customQuery.condoContact = contact._id;

    this.downloadData();
  }
  clearSelection() {
    this.selectedAccess = null;
    this.accessForm.reset();

    this.filters = this.filters.map(filterObj => {
      if (filterObj.name === 'condoContact') {
        filterObj.value = '';
        filterObj.valueLabel = '';
      }
      return filterObj;
    });

    this.numberOfActiveFilters = this.numberOfActiveFilters - 1;

    delete this.customQuery.condoContact;

    this.downloadData();
  }

  createAccessFromContactAndResidence(contact: CondoContact, residence: Residence) {
    this.onVisitorSelectCallback(contact);
    this.onResidencesSelect(residence);
  }

  createAccessFromContactInfoAndResidence(contactInfo: string, residence: Residence) {
    this.createContact(contactInfo);
    this.onResidencesSelect(residence);
  }

  public onCreateAccessFromOccurrence(access: Access) {
    this.selectedAccess = access;
    this.checkIfSelectedAccessCondoContactHasTodayPermission(access?.condoContact);
    this.setFormValues(this.selectedAccess);
  }

  createAccessFromPrevious(previousAccess: Access) {
    const newAccess = new Access();
    newAccess.condoContact = previousAccess.condoContact;
    newAccess.source = previousAccess.source;
    if (previousAccess.isCreatedFromOccurrence()) {
      newAccess.occurrence = previousAccess.occurrence?._id || (previousAccess.occurrence as any);
    }
    newAccess.status = previousAccess.status;
    newAccess.residence = previousAccess.residence;
    newAccess.approvedByName =
      previousAccess.approvedByName ||
      (previousAccess.approvedBy ? previousAccess.approvedBy.firstName + ' ' + previousAccess.approvedBy.lastName : '');
    newAccess.condoVehicle = previousAccess.condoVehicle;
    newAccess.type = previousAccess.type;
    newAccess.approvedByName = this.user.fullName;
    this.selectedAccess = newAccess;
    this.setFormValues(this.selectedAccess);
  }

  registerFromOther(access, type: 'IN' | 'OUT') {
    const newAccess = new Access();
    newAccess.condoContact = access.condoContact;
    newAccess.source = access.source;
    newAccess.status = Access.STATUS.APPROVED;
    newAccess.residence = access.residence;
    newAccess.approvedBy = access.approvedBy;
    newAccess.approvedByName =
      access.approvedByName || (access.approvedBy ? access.approvedBy.firstName + ' ' + access.approvedBy.lastName : '');
    newAccess.condoVehicle = access.condoVehicle;
    newAccess.type = access.type;
    if (type === 'IN') {
      newAccess.inDate = new Date();
    } else {
      newAccess.outDate = new Date();
    }

    if (access.isCreatedFromOccurrence()) {
      newAccess.occurrence = access.occurrence;
    }
    if (
      !this.checkCanRegisterPassword(access.condoContact) &&
      type === 'IN' &&
      this.condo.hardwareParams?.visitorsPasswordFromGatekeeper === 'ENABLED' &&
      this.condo?.hardwareParams?.visitorPasswordFromGatekeeperOnlyToLeave
    ) {
      swal({
        type: 'question',
        text: `O usuário ${
          access.condoContact.firstName + ' ' + access.condoContact.lastName
        } não tem RG cadastrado ou o número cadastrado é muito curto. Deseja continuar?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.getRegisterAccessMethod(newAccess)
            .pipe(timeout(10000))
            .toPromise()
            .then((accessCreated: any) => {
              this.selectedAccess = newAccess;
              this.selectedAccess.approvedByName = this.user.fullName;
              newAccess.id = accessCreated._id;
              this.setFormValues(newAccess);

              this.toastr.success(`${type === 'IN' ? 'Entrada' : 'Saída'} registrada`);
            })
            .catch(err => {
              console.log(err);
              return Promise.reject(`Não foi possível registrar a ${type === 'IN' ? 'entrada' : 'saída'}, tente novamente...`);
            });
        }
      }).then(
        () => {
          this.downloadData(this.baseSuccessCallback, this.baseErrorCallback);
        },
        () => {
          console.log('Clicked cancel');
        }
      );
    } else {
      swal({
        type: 'question',
        text: `Registrar a ${type === 'IN' ? 'entrada' : 'saída'} de ${
          access.condoContact.firstName + ' ' + access.condoContact.lastName
        } ?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.getRegisterAccessMethod(newAccess)
            .pipe(
              timeout(10000),
              tap(async res => {
                if (type == 'OUT' && access.condoContact.externalId) {
                  await this.intelbrasIncontrolService.expirePreviousVisit(access.condoContact.externalId);
                }
              })
            )
            .toPromise()
            .then(async (accessCreated: any) => {
              this.selectedAccess = newAccess;
              this.selectedAccess.approvedByName = this.user.fullName;
              newAccess.id = accessCreated._id;
              this.setFormValues(newAccess);
              if (
                type === 'IN' &&
                this.condo.hardwareParams?.visitorsPasswordFromGatekeeper === 'ENABLED' &&
                this.condo?.hardwareParams?.visitorPasswordFromGatekeeperOnlyToLeave
              ) {
                await this.generateVisitorDeviceFromGatekeeper('SN', {
                  condo: this.condo,
                  condoContact: newAccess.condoContact,
                  residence: newAccess.residence,
                  approvedByName: newAccess.approvedByName,
                  condoVehicleId: this.vehicle.value,
                  obs: newAccess.obs,
                  accessType: newAccess.type
                });
              }
              this.toastr.success(`${type === 'IN' ? 'Entrada' : 'Saída'} registrada`);
            })
            .catch(err => {
              console.log(err);
              return Promise.reject(`Não foi possível registrar a ${type === 'IN' ? 'entrada' : 'saída'}, tente novamente...`);
            });
        }
      }).then(
        () => {
          this.downloadData(this.baseSuccessCallback, this.baseErrorCallback);
        },
        () => {
          console.log('Clicked cancel');
        }
      );
    }
  }

  openFilterModal() {
    const initialState = {
      filters: this.filters,
      initialQuery: { ...this.initialQuery },
      callback: ({ query, filters }) => {
        if (!filters.find(res => res.name === 'condoVehicle')?.value) {
          this.viewPlate = false;
        }
        const inOrOut = filters.find(filterObject => filterObject.name === 'inOrOut')?.value;
        if (inOrOut) {
          delete query.inOrOut;
          switch (inOrOut) {
            case 'IN':
              query.inDate = { $lt: new Date() };
              break;
            case 'OUT':
              query.outDate = { $lt: new Date() };
              break;
          }
        }
        const date = filters.find(filterObject => filterObject.name === 'date')?.value;
        if (date) {
          delete query.date;
          query.latestAccess = {
            $gte: moment(date).utc().startOf('day').toISOString(),
            $lte: moment(date).utc().endOf('day').toISOString()
          };
        }
        this.countActiveFilters(filters);
        this.filters = filters;
        this.customQuery = query;
        this.downloadData();
      }
    };
    this.modalService.show(ModalFilterComponent, { initialState, class: 'modal-lg' });
  }

  countActiveFilters(filters: EcondosFilter[]) {
    this.numberOfActiveFilters = filters.reduce((accumulator, currentValue) => {
      if (currentValue.value) {
        return accumulator + 1;
      }

      return accumulator;
    }, 0);
  }

  removeIndividualFilter({ index }) {
    this.filters[index].value = '';
    this.filters[index].valueLabel = '';
    delete this.customQuery[this.filters[index].name];
    if (this.filters[index].name === 'inOrOut') {
      delete this.customQuery['inDate'];
      delete this.customQuery['outDate'];
    }
    if (!this.filters.find(res => res.name === 'condoVehicle')?.value) {
      this.viewPlate = false;
    }
    if (this.filters[index].name === 'date') {
      this.customQuery.latestAccess = {
        $gte: moment(new Date()).startOf('day').toISOString(),
        $lte: moment(new Date()).endOf('day').toISOString()
      };
    }
    this.numberOfActiveFilters--;
    this.downloadData();
  }

  clearFilters() {
    this.viewPlate = false;
    this.filters = this.filters.map(filterObject => {
      return {
        ...filterObject,
        value: '',
        valueLabel: ''
      };
    });
    this.numberOfActiveFilters = 0;
    this.customQuery = { ...this.initialQuery };
    this.downloadData();
  }

  onCondoContactUpdated(condoContact: CondoContact) {
    this.downloadData(this.baseSuccessCallback, this.baseErrorCallback);
    if (this.selectedAccess) {
      this.condoContactService
        .getCondoContactById(this.condo._id, condoContact._id, {
          $select:
            'firstName lastName banned banReason bannedAt type company service emails birthDate phones specialNeeds specialNeedsDetails externalId obs',
          $populate: [
            { path: 'picture', select: 'thumbnail url' },
            { path: 'ids', select: 'typeLabel number', populate: { path: 'pictures', select: 'url thumbnail type name format' } },
            {
              path: 'condoVehicles',
              select: 'plate model type brand color pictures',
              populate: { path: 'pictures', select: 'url thumbnail type name format' }
            },
            { path: 'bannedBy', select: 'firstName lastName' }
          ]
        })
        .pipe(retry(3))
        .subscribe({
          next: (updatedCondoContact: CondoContact) => {
            this.selectedAccess.condoContact = updatedCondoContact;
          },
          error: err => {
            console.log(err);
            this.selectedAccess.condoContact = condoContact;
          }
        });
    }
  }

  editContact(condoContact) {
    // Somente condo contacts podem ser editados, caso o acesso seja de condômino, o mesmo não pdoe ser editado
    if (!condoContact) {
      return;
    }
    const initialState = {
      condo: this.condo,
      user: this.user,
      condoContact,
      callback: contact => this.onCondoContactUpdated(contact)
    };
    this.modalService.show(ModalNewCondoContactComponent, {
      initialState,
      class: 'modal-lg modal-xl',
      ignoreBackdropClick: true
    });
  }

  createContact(contactInfo) {
    if (this.selectedAccess) {
      this.clearSelection();
    }

    const newContactInfo = { nameOrId: contactInfo };
    const initialState = {
      condo: this.condo,
      user: this.user,
      newContactInfo,
      callback: contact => this.onVisitorSelectCallback(contact)
    };
    this.modalService.show(ModalNewCondoContactComponent, {
      initialState,
      class: 'modal-lg modal-xl',
      ignoreBackdropClick: true
    });
  }

  async generateVisitorDeviceFromGatekeeper(
    type: string,
    data: {
      condo: Condo;
      condoContact: CondoContact;
      condoVehicleId: string;
      residence?: Residence;
      approvedByName: string;
      accessType: string;
      obs?: string;
    }
  ) {
    const gateKeeperMustRegisterResidence =
      this.condo.generalParams.accessLiberation.gateKeeperMustRegisterResidence && !this.residence.value;

    const gateKeeperMustRegisterVisitorDocument =
      this.condo.generalParams.accessLiberation.gateKeeperMustRegisterVisitorDocument && !data.condoContact?.ids?.length;

    const maskedVisitorDocuments = data.condoContact?.ids?.some(({ number }) => (number || '').includes('*'));

    const params: any = {
      condo: data.condo,
      condoContact: data.condoContact,
      approvedByName: data.approvedByName,
      accessType: data.accessType,
      obs: data.obs
    };

    if (this.condo.type === 'BUSINESS' && !this.residence.value) {
      this.toastr.error('Necessário selecionar um(a) ' + this.condo?.customLabels?.residence?.singular || 'unidade');
      return;
    }

    if (gateKeeperMustRegisterResidence) {
      this.toastr.error('Necessário selecionar um(a) ' + this.condo?.customLabels?.residence?.singular || 'unidade');
      return;
    }

    if (gateKeeperMustRegisterVisitorDocument) {
      this.toastr.error(`Necessário cadastrar documento do ${this.condo?.customLabels?.visitor?.singular || 'visitante'}`);
      return;
    }

    if (data.residence) {
      params.residence = data.residence;
    }
    if (data.condoVehicleId) {
      params.condoVehicle = data.condoContact.condoVehicles.find(v => v._id === data.condoVehicleId);
    }
    switch (type) {
      case 'QR': {
        try {
          const visitorsAccessGroups = await this.accessGroupService
            .get(this.condo._id, {
              type: 'VISITOR',
              $populate: ['actuators timezone']
            })
            .pipe(
              timeout(10000),
              map(({ accessGroups }) => accessGroups)
            )
            .toPromise();

          if (visitorsAccessGroups.length) {
            if (visitorsAccessGroups.length === 1) {
              params.accessGroups = visitorsAccessGroups;
              this.generateQRCodeDevice(params);
            } else {
              const initialState = {
                groups: visitorsAccessGroups,
                backdrop: true,
                ignoreBackdropClick: false,
                callback: {
                  success: accessGroup => {
                    if (accessGroup) {
                      params.accessGroups = [accessGroup];
                      this.generateQRCodeDevice(params);
                    }
                  }
                }
              };
              this.modalService.show(ModalSelectAccessGroup, { initialState, class: 'modal-lg' });
            }
          } else {
            this.generateQRCodeDevice(params);
          }
        } catch (e) {
          this.buttonsStatus['QR'] = this.ERROR;
          swal({
            type: 'error',
            text: 'Não foi possível gerar o qr code de acesso, verifique sua conexão com a internet e tente novamente'
          });
        }
        break;
      }
      case 'SN': {
        if (maskedVisitorDocuments) {
          const query: EcondosQuery = {
            $select: 'ids',
            $and: [],
            $populate: [{ path: 'ids.pictures', select: 'url thumbnail' }]
          };

          let { data: ids } = await this.condoContactService
            .getCondoContactUnmaskedField(this.condo._id, data.condoContact._id, 'ids', query)
            .pipe(timeout(10000))
            .toPromise()
            .catch(() => ({ data: data.condoContact.ids }));
          if (ids?.every(contactId => !contactId?.number?.includes('*'))) {
            ids = ids.map(id => new ContactID(id));
            data.condoContact.ids = ids;
          } else {
            swal({
              type: 'error',
              title: 'Documentos inválidos',
              text: 'Os documentos não foram desmascarados. Tente novamente!'
            });
            return;
          }
        }
        await this.generatePasswordDevice(params);
        break;
      }
      case 'FACIAL': {
        this.generateFacialDevice(params);
        break;
      }
      case 'VEHICLE_PLATE': {
        this.generateVisitorPlateDevice(params);
        break;
      }
      case 'CARD': {
        try {
          const visitorsAccessGroups = await this.accessGroupService
            .get(this.condo._id, {
              type: 'VISITOR',
              $populate: ['actuators timezone']
            })
            .pipe(
              timeout(10000),
              map(({ accessGroups }) => accessGroups)
            )
            .toPromise();

          if (visitorsAccessGroups.length) {
            if (visitorsAccessGroups.length === 1) {
              params.accessGroups = visitorsAccessGroups;
              this.generateVisitorCardDevice(params);
            } else {
              const initialState = {
                groups: visitorsAccessGroups,
                backdrop: true,
                ignoreBackdropClick: false,
                callback: {
                  success: accessGroup => {
                    if (accessGroup) {
                      params.accessGroups = [accessGroup];
                      this.generateVisitorCardDevice(params);
                    }
                  }
                }
              };
              this.modalService.show(ModalSelectAccessGroup, { initialState, class: 'modal-lg' });
            }
          } else {
            this.generateVisitorCardDevice(params);
          }
        } catch (e) {
          this.buttonsStatus['CARD'] = this.ERROR;
          swal({
            type: 'error',
            text: 'Não foi possível gerar o cartão de acesso, verifique sua conexão com a internet e tente novamente'
          });
        }
        break;
      }
    }
  }

  private generateVisitorPlateDevice({ condo, condoContact, condoVehicle, residence, approvedByName, obs, accessType }) {
    if (!condoVehicle) {
      this.toastr.error('Necessário cadastrar ou selecionar um veículo');
      return;
    }
    this.buttonsStatus['VEHICLE_PLATE'] = this.LOADING;
    if (!condoVehicle?._id) {
      condoVehicle = condoContact.condoVehicles?.find(v => v._id === condoVehicle);
    }
    const data: any = {
      condoContactId: condoContact._id,
      approvedByName,
      obs,
      accessType,
      condoVehicleId: condoVehicle._id,
      serial: condoVehicle.plate
    };
    if (residence) {
      data.residenceId = residence._id;
    }
    this.accessService.generateVisitorDeviceFromGatekeeper(condo._id, 'VEHICLE_PLATE', data).subscribe(
      (device: any) => {
        this.toastr.success(`Placa do ${this.condo?.customLabels?.visitor?.singular || 'visitante'} gerado com sucesso`);
        this.buttonsStatus['VEHICLE_PLATE'] = this.SUCCESS;
        this.clearFilters();
      },
      err => {
        console.log(err);
        this.buttonsStatus['VEHICLE_PLATE'] = this.ERROR;
        swal({
          type: 'error',
          text: `Não foi possível gerar a placa do ${
            this.condo?.customLabels?.visitor?.singular || 'visitante'
          }, verifique se os dados do ${
            this.condo?.customLabels?.visitor?.singular || 'visitante'
          } estão preenchidos corretamente, se há conexão com a internet e tente novamente`
        });
      }
    );
  }

  private generateQRCodeDevice({ condo, condoContact, condoVehicle, residence, approvedByName, obs, accessType, accessGroups }) {
    this.buttonsStatus['QR'] = this.LOADING;

    const data: any = { condoContactId: condoContact._id, approvedByName, obs, accessType };
    if (residence) {
      data.residenceId = residence._id;
    }
    if (condoVehicle) {
      data.condoVehicleId = condoVehicle._id || condoVehicle;
    }
    if (accessGroups) {
      data.accessGroups = accessGroups;
    }
    this.accessService.generateVisitorDeviceFromGatekeeper(condo._id, 'QR', data).subscribe(
      (device: any) => {
        this.toastr.success('QR Code gerado com sucesso');
        const initialState = {
          condo,
          condoContactLabel: condoContact.fullName,
          residenceLabel: (residence && residence.identification) || capitalize(this.condo?.customLabels?.condo?.singular),
          condoVehicleLabel: condoVehicle ? (condoVehicle.model || condoVehicle.brand) + ' - ' + condoVehicle.plate : 'Sem veículo',
          approvedByName,
          device
        };
        this.buttonsStatus['QR'] = this.SUCCESS;
        this.modalService.show(ModalGenerateQrcodeFromGatekeeperComponent, { initialState, ignoreBackdropClick: true });
      },
      err => {
        console.log(err);
        this.buttonsStatus['QR'] = this.ERROR;
        swal({
          type: 'error',
          text: `Não foi possível gerar o código de acesso, verifique se os dados do ${
            this.condo?.customLabels?.visitor?.singular || 'visitante'
          } estão preenchidos corretamente, se há conexão com a internet e tente novamente`
        });
      }
    );
  }

  private async generatePasswordDevice({ condo, condoContact, condoVehicle, residence, approvedByName, obs, accessType }) {
    if (condoContact && condoContact.ids.length) {
      let documentType;
      if (condoContact.ids.length > 1) {
        let inputOptions = {};
        condoContact.ids.forEach(({ type }) => (inputOptions = { ...inputOptions, [type]: type }));

        documentType = await swal({
          inputOptions,
          title: 'Documento',
          text: 'Selecione o documento que será usado para gerar a senha',
          input: 'select',
          inputPlaceholder: 'Selecione',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
          inputValidator: async res => {
            if (res) {
              return res;
            } else {
              return Promise.reject('Selecione um documento!');
            }
          }
        });
      } else {
        documentType = condoContact.ids[0]?.type;
      }
      this.buttonsStatus['SN'] = this.LOADING;
      const data: any = { condoContactId: condoContact._id, documentType, approvedByName, obs, accessType };
      if (residence) {
        data.residenceId = residence._id;
      }
      if (condoVehicle) {
        data.condoVehicleId = condoVehicle._id || condoVehicle;
      }
      this.accessService.generateVisitorDeviceFromGatekeeper(condo._id, 'SN', data).subscribe(
        (res: any) => {
          if (res.passwordFeedBack) {
            swal({
              type: 'info',
              title: 'Senha excede o tamanho maximo',
              text: res.passwordFeedBack.message
            });
          }
          this.toastr.success('Senha gerada com sucesso');
          this.buttonsStatus['SN'] = this.SUCCESS;
          this.clearSelection();
        },
        err => {
          console.log(err);
          this.buttonsStatus['SN'] = this.ERROR;
          if (err.originalError.message) {
            swal({
              type: 'error',
              text: err.originalError.message
            });
          } else {
            swal({
              type: 'error',
              text: `Não foi possível gerar a senha, verifique se os dados do ${
                this.condo?.customLabels?.visitor?.singular || 'visitante'
              } estão preenchidos corretamente, se há conexão com a internet e tente novamente`
            });
          }
        }
      );
    } else {
      this.toastr.warning(`${capitalize(this.condo?.customLabels?.visitor?.singular) || 'Visitante'} sem documento cadastrado`);
    }
  }

  // Método para conseguirmos tratar os dados que são inputados pela leitora USB da nice
  private handleLinearCardReader(destroy$: Subject<boolean>) {
    /**
     * O leitor USB simula um teclado fornecendo 3 informações separadas por "TAB"
     * Ele informa um dígito que é a identificação do tipo de dispositivo e depois "aperta" TAB
     * Depois ele digita o serial e "aperta" TAB novamente
     * Depois ele insere alguns dígitos que são o contador (esse não tem utilidade)
     * Para o sistema só interessa o serial, então "escutamos" os eventos de teclado para detectarmos apenas o serial
     */
    let count = 0;
    let serialFromReader = '';

    fromEvent(document, 'keydown')
      .pipe(takeUntil(destroy$))
      .subscribe((event: KeyboardEvent) => {
        if (event.key === 'Tab' || event.code === 'Tab') {
          count++;
          event.preventDefault();
        } else {
          if (count === 1) {
            serialFromReader += event.key;
          }
        }
        if (count >= 2) {
          const swalInput: HTMLInputElement = swal.getInput() as HTMLInputElement;
          swalInput.value = serialFromReader.toUpperCase();
          // Ele seta o focus após preencher o serial com um timeout para não conflitar com a informação do contador digitada pela leitora
          setTimeout(() => swalInput.focus(), 500);
          destroy$.next(true);
          destroy$.complete();
        }
      });
  }

  private handleZKTecoCardReader(destroy$: Subject<boolean>) {
    this.hardwareSocketService.onData$
      .pipe(
        filter((event = {}) => {
          const { condoId = '', command = '', hardwareEvent = {} } = event;
          const validCondoEvent = condoId === this.condo._id && command === 'eventSentAutomatically';
          const { eventType = '', serial = '' } = hardwareEvent;
          const validCardDevice = eventType === 'OTHER' && serial;
          return validCondoEvent && validCardDevice;
        }),
        map(({ hardwareEvent: { serial } }) => serial),
        tap(serial => {
          const isNumber = !isNaN(serial);
          // Remove os zeros a esquerda da string, quando usa leitor o serial vem nesse formato "00000123456"
          if (isNumber) {
            serial = parseInt(serial, 10).toString();
          }
          (swal.getInput() as any).value = serial;
        }),
        takeUntil(destroy$)
      )
      .subscribe(noop);
  }

  private generateVisitorCardDevice({ condo, condoContact, condoVehicle, residence, approvedByName, obs, accessType, accessGroups }): void {
    const destroyed$: Subject<boolean> = new Subject<boolean>();

    if (this.condo.isLinearEnabled()) {
      this.handleLinearCardReader(destroyed$);
    }

    swal({
      type: 'question',
      title: 'Número do cartão',
      text: 'Passe o cartão em uma leitora ou digite o número manualmente',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Concluir',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      inputAttributes: { maxlength: '20' },
      inputValidator: async value => {
        if (!value) {
          return Promise.reject('Digite o número do cartão...');
        } else if (value.length < 4) {
          return Promise.reject('Digite o número completo do cartão...');
        }
        return Promise.resolve(value);
      },
      preConfirm: value => {
        this.buttonsStatus['CARD'] = this.LOADING;
        const data: any = { condoContactId: condoContact._id, approvedByName, obs, accessType, serial: value };
        if (residence) {
          data.residenceId = residence._id;
        }
        if (condoVehicle) {
          data.condoVehicleId = condoVehicle._id || condoVehicle;
        }
        if (accessGroups) {
          data.accessGroups = accessGroups;
        }
        let type: 'CARD' | 'CT' = 'CARD';
        if (condo.isLinearEnabled()) {
          type = 'CT';
        }

        return this.accessService
          .generateVisitorDeviceFromGatekeeper(condo._id, type, data)
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject(`Não foi possível cadastrar o cartão ${value}`);
          });
      }
    })
      .then(() => {
        this.toastr.success('Cartão gerado com sucesso');
        this.buttonsStatus['CARD'] = this.SUCCESS;
        this.clearSelection();
      })
      .catch(err => {
        if (err !== 'cancel') {
          this.toastr.error(
            err.message ||
              `Não foi possível cadastrar um cartão para o ${this.condo?.customLabels?.visitor?.singular || 'visitante'}. Tente novamente!`
          );
        }
        this.buttonsStatus['CARD'] = this.ERROR;
      })
      .finally(() => {
        destroyed$.next(true);
        destroyed$.complete();
      });

    if (this.condo.isZktecoEnabled()) {
      this.handleZKTecoCardReader(destroyed$);
    }
  }

  checkCanRegisterPassword(condoContact: CondoContact) {
    const contactId = condoContact.ids.find(d => d.type === ContactID.TYPES.RG) || condoContact.ids[0];
    const password = contactId?.number.toString() || '';
    return password.length >= 6;
  }

  private generateFacialDevice({ condo, condoContact, condoVehicle, residence, approvedByName, obs, accessType }) {
    if (condoContact && condoContact.picture) {
      const data: any = { condoContactId: condoContact._id, approvedByName, obs, accessType };
      if (residence) {
        data.residenceId = residence._id;
      }
      if (condoVehicle) {
        data.condoVehicleId = condoVehicle._id || condoVehicle;
      }
      const hardwareManufacturersEnableds = [];
      const keys = Object.keys(this.hardwareManufacturers);
      keys.forEach(key => {
        if (this.hardwareManufacturers[key].enabled) {
          hardwareManufacturersEnableds.push(this.hardwareManufacturers[key]);
        }
      });
      if (this.hasMultipleHardwareManufacturers) {
        const initialState = {
          hardwareManufacturersEnableds,
          callbacks: {
            success: manufacturer => {
              this.openSelectedManufacturerModal({ data, condo, condoContact, manufacturer });
            }
          }
        };
        this.modalService.show(ModalShowManufacturerChoiceComponent, {
          initialState,
          class: 'modal-md',
          ignoreBackdropClick: true
        });
      } else {
        const manufacturer = this.hardwareManufacturers.find(hw => hw.enabled === true).value;
        this.openSelectedManufacturerModal({ data, condo, condoContact, manufacturer });
      }
    } else {
      this.toastr.warning(`${capitalize(this.condo?.customLabels?.visitor?.singular) || 'Visitante'} sem imagem registrada`);
    }
  }

  openSelectedManufacturerModal(manufacturer) {
    this.onManufacturerChoice(manufacturer);
  }

  getAccessGroups(manufacturer = null) {
    let params: EcondosQuery;
    if (manufacturer && manufacturer === 'INTELBRAS') {
      params = {
        $populate: [{ path: 'actuators', select: 'clientId condo hardware name intelbrasGroupId type' }],
        type: { $in: ['Visitante', 'VISITOR'] }
      };
    } else {
      params = {
        $populate: [{ path: 'actuators', select: 'clientId condo hardware name intelbrasGroupId' }]
      };
    }

    return this.accessGroupService.get(this.condo.id, params);
  }

  async onManufacturerChoice({ data, condo, condoContact, manufacturer }) {
    const getVisitorAccessGroups$: Observable<AccessGroup[]> = this.accessGroupService
      .get(this.condo._id, {
        type: 'VISITOR',
        $populate: ['actuators timezone']
      })
      .pipe(
        timeout(10000),
        map(({ accessGroups }) => accessGroups)
      );
    /*Linear, Intelbras (Standalone), ZKTeko, Bravas*/
    /*Garen, Alphadigi, Intelbras, Control iD*/

    switch (manufacturer) {
      case 'HIKVISION': {
        getVisitorAccessGroups$.subscribe(accessGroups => {
          if (accessGroups.length > 1) {
            const initialState = {
              groups: accessGroups,
              backdrop: true,
              ignoreBackdropClick: false,
              callback: {
                success: group => {
                  const actuatorsToAdd = group?.actuators || [];
                  this.generateHikvisionDevice({ data, condo, condoContact, manufacturer, actuatorsToAdd });
                }
              }
            };
            this.modalService.show(ModalSelectAccessGroup, { initialState, class: 'modal-lg' });
          } else {
            const accessGroup = accessGroups?.[0];
            const actuatorsToAdd = accessGroup?.actuators || [];
            this.generateHikvisionDevice({ data, condo, condoContact, manufacturer, actuatorsToAdd });
          }
        });
        break;
      }
      case 'INTELBRAS': {
        this.getAccessGroups(manufacturer)
          .pipe(timeout(10000))
          .subscribe(
            ({ accessGroups }) => {
              if (!accessGroups.length) {
                return swal({
                  type: 'error',
                  title: 'Nenhum grupo de acesso encontrado',
                  text: `Cadastre um grupo de acesso para liberar ${this.condo?.customLabels?.visitor?.plural || 'visitantes'}`
                }).then(() => {
                  this.loadingStatus = this.ERROR;
                });
              }
              const initialState = {
                groups: accessGroups,
                backdrop: true,
                ignoreBackdropClick: false,
                callback: {
                  success: group => {
                    data.hardware = manufacturer;
                    data.actuators = group.actuators;
                    this.buttonsStatus['FACIAL'] = this.LOADING;
                    this.generateIntelbrasIncontrolDevice({
                      actuators: group.actuators,
                      data,
                      condo,
                      condoContact,
                      group
                    });
                  },
                  error: () => {
                    this.buttonsStatus['FACIAL'] = this.ERROR;
                  }
                }
              };
              this.modalService.show(ModalSelectAccessGroup, { initialState, class: 'modal-lg' });
            },
            err => {
              console.log(err);
              this.buttonsStatus['FACIAL'] = this.ERROR;
            }
          );
        break;
      }
      case 'GAREN':
      case 'ALPHADIGI': {
        getVisitorAccessGroups$.subscribe(accessGroups => {
          if (!accessGroups.length) {
            return swal({
              type: 'error',
              title: 'Nenhum grupo de acesso encontrado',
              text: `Cadastre um grupo de acesso para liberar ${this.condo?.customLabels?.visitor?.plural || 'visitantes'}`
            }).then(() => {
              this.loadingStatus = this.ERROR;
            });
          }
          if (accessGroups.length > 1) {
            const initialState = {
              groups: accessGroups,
              backdrop: true,
              ignoreBackdropClick: false,
              callback: {
                success: group => {
                  this.generateAlphadigiOrGarenVisitorDevice({
                    data,
                    condo,
                    condoContact,
                    accessGroup: group,
                    manufacturer
                  });
                }
              }
            };
            this.modalService.show(ModalSelectAccessGroup, { initialState, class: 'modal-lg' });
          } else {
            const accessGroup = accessGroups?.[0] || {};
            this.generateAlphadigiOrGarenVisitorDevice({ data, condo, condoContact, accessGroup, manufacturer });
          }
        });
        break;
      }
      case HARDWARES.CONTROL_ID: {
        getVisitorAccessGroups$.subscribe(accessGroups => {
          if (!accessGroups.length) {
            return swal({
              type: 'error',
              title: 'Nenhum grupo de acesso encontrado',
              text: `Cadastre um grupo de acesso para liberar ${this.condo?.customLabels?.visitor?.plural || 'visitantes'}`
            });
          }
          if (accessGroups.length > 1) {
            const initialState = {
              groups: accessGroups,
              backdrop: true,
              ignoreBackdropClick: false,
              callback: {
                success: group => {
                  this.generateControlIdVisitorDevice({
                    data,
                    condo,
                    condoContact,
                    accessGroup: group
                  });
                }
              }
            };
            this.modalService.show(ModalSelectAccessGroup, { initialState, class: 'modal-lg' });
          } else {
            const accessGroup = accessGroups?.[0] || {};
            this.generateControlIdVisitorDevice({
              data,
              condo,
              condoContact,
              accessGroup
            });
          }
        });
        break;
      }
      case 'INTELBRAS_STAND_ALONE': {
        getVisitorAccessGroups$.subscribe(
          accessGroups => {
            if (!accessGroups.length) {
              return swal({
                type: 'error',
                title: 'Nenhum grupo de acesso encontrado',
                text: `Cadastre um grupo de acesso para liberar ${this.condo?.customLabels?.visitor?.plural || 'visitantes'}`
              });
            }
            if (accessGroups.length > 1) {
              const initialState = {
                groups: accessGroups,
                backdrop: true,
                ignoreBackdropClick: false,
                callback: {
                  success: group => {
                    this.generateIntelbrasStandAloneDevice({
                      data,
                      condo,
                      condoContact,
                      accessGroup: group
                    });
                  }
                }
              };
              this.modalService.show(ModalSelectAccessGroup, { initialState, class: 'modal-lg' });
            } else {
              const accessGroup = accessGroups?.[0];
              this.generateIntelbrasStandAloneDevice({
                data,
                condo,
                condoContact,
                accessGroup
              });
            }
          },
          err => {
            console.log(err);
          }
        );
        break;
      }
      case HARDWARES.AVICAM:
      case HARDWARES.XPE:
      case HARDWARES.BRAVAS: {
        const accessGroups = await getVisitorAccessGroups$.toPromise().catch(e => {
          console.log(e);
          return [];
        });
        if (!accessGroups.length) {
          swal({
            type: 'error',
            title: 'Nenhum grupo de acesso encontrado',
            text: `Cadastre um grupo de acesso para poder liberar ${this.condo?.customLabels?.visitor?.plural || 'visitantes'}`
          });
          return;
        } else if (accessGroups.length === 1) {
          const [accessGroup] = accessGroups;
          this.generateVisitorFacialDevice({
            data,
            condo,
            condoContact,
            accessGroup
          });
        } else {
          const initialState = {
            groups: accessGroups,
            backdrop: true,
            ignoreBackdropClick: false,
            callback: {
              success: group => {
                this.generateVisitorFacialDevice({
                  data,
                  condo,
                  condoContact,
                  accessGroup: group
                });
              }
            }
          };
          this.modalService.show(ModalSelectAccessGroup, { initialState, class: 'modal-lg' });
        }
        break;
      }
    }
  }

  generateHikvisionDevice({
    data,
    manufacturer,
    condo,
    condoContact,
    actuatorsToAdd = [],
    qs = { hardware: 'HIKVISION', type: 'FACIAL', prohibitedForVisitors: { $ne: true } }
  }) {
    this.buttonsStatus['FACIAL'] = this.LOADING;
    const actuators$: Observable<Actuator[]> = actuatorsToAdd?.length
      ? of(actuatorsToAdd)
      : this.actuatorService.getActuators(this.condo._id, qs).pipe(map(({ actuators }) => actuators));
    actuators$
      .pipe(
        switchMap(actuators => {
          data.actuators = actuators;
          data.hardware = manufacturer;
          return this.accessService
            .generateVisitorDeviceFromGatekeeper(condo._id, 'FACIAL', data)
            .pipe(map(device => ({ ...device, actuators: actuators, owner: { condoContact }, condo: this.condo })));
        }),
        switchMap((device: any) => {
          const entranceDuration = this.condo.hardwareParams?.visitorsFacialFromGatekeeperDefaultEntranceDuration || 5;
          return this.hikvisionService.create(device.actuators, device, { entranceDuration }).pipe(map(results => ({ results, device })));
        })
      )
      .subscribe(
        (res: any) => {
          const errors = res.results.filter((r: any) => !r.ok);

          // this.clearSelection();
          if (errors.length) {
            const actuatorNames = errors.map(result => result.actuator && result.actuator.name).join(', ');
            this.buttonsStatus['FACIAL'] = this.ERROR;
            swal({
              type: 'error',
              text: `Não foi possível gerar a facial do ${
                this.condo?.customLabels?.visitor?.singular || 'visitante'
              } nos seguintes dispositivos ${actuatorNames}`
            });
          } else {
            this.toastr.success('Facial gerada com sucesso');
            this.buttonsStatus['FACIAL'] = this.SUCCESS;
          }
        },
        err => {
          console.log(err);
          this.errorOnGenerateDevice();
        }
      );
  }

  generateIntelbrasIncontrolDevice({ actuators, data, condo, condoContact, group }) {
    this.isLoading = true;

    this.accessService
      .generateVisitorDeviceFromGatekeeper(condo._id, 'FACIAL', data)
      .pipe(
        map(device => ({ ...device, actuators: actuators, owner: { condoContact } })),
        switchMap(device => {
          return from(this.intelbrasIncontrolService.saveVisit(device.owner.condoContact, condo, group)).pipe(
            map(visit => ({ visit, device }))
          );
        }),
        switchMap(res => {
          if (res.visit && res.visit.visitante.id !== condoContact.externalId) {
            return this.condoContactService
              .updateCondoContact(condo._id, condoContact._id, { externalId: res.visit.visitante.id })
              .pipe(map(() => res));
          } else {
            return of(res);
          }
        }),
        switchMap((res: any) => {
          if (res.device) {
            return this.deviceService.update(condo._id, res.device?._id, {
              hardwareAttributes: {
                ...res.device?.hardwareAttributes,
                incontrolId: res.visit.id
              }
            });
          }
        })
      )
      .subscribe(
        (res: any) => {
          this.isLoading = false;
          this.toastr.success('Facial gerada com sucesso');
          this.buttonsStatus['FACIAL'] = this.SUCCESS;
          // this.clearSelection();
        },
        err => {
          this.isLoading = false;
          console.log(err);
          this.errorOnGenerateDevice();
        }
      );
  }

  generateAlphadigiOrGarenVisitorDevice({ data, condo, condoContact, accessGroup, manufacturer = 'ALPHADIGI' }) {
    this.buttonsStatus['FACIAL'] = this.LOADING;
    const actuators = accessGroup.actuators;
    data.actuators = actuators;
    if (accessGroup) {
      data.accessGroups = [accessGroup];
    }
    data.hardware = manufacturer;
    data.remoteCheck = false;
    data.credits = this.condo.hardwareParams?.visitorsFacialFromGatekeeperDefaultCredits || 1;
    const entranceDuration = this.condo.hardwareParams?.visitorsFacialFromGatekeeperDefaultEntranceDuration || 5;
    this.accessService
      .generateVisitorDeviceFromGatekeeper(condo._id, 'FACIAL', data)
      .pipe(
        map(device => ({ ...device, actuators: actuators, owner: { condoContact } })),
        switchMap((device: any) => this.alphadigiService.addVisitorFacial(device, device.actuators, { entranceDuration }))
      )
      .subscribe(
        res => {
          const errors = res.filter((r: any) => !r.success);
          if (errors.length) {
            const actuatorNames = errors.map(result => result.actuator && result.actuator.name).join(', ');
            this.buttonsStatus['FACIAL'] = this.ERROR;
            swal({
              type: 'error',
              text: `Não foi possível gerar a facial do ${
                this.condo?.customLabels?.visitor?.singular || 'visitante'
              } nos seguintes dispositivos: ${actuatorNames}`
            });
          } else {
            this.toastr.success('Facial gerada com sucesso');
            this.buttonsStatus['FACIAL'] = this.SUCCESS;
          }
        },
        err => {
          console.log(err);
          this.errorOnGenerateDevice();
        }
      );
  }

  generateControlIdVisitorDevice({ data, condo, condoContact, accessGroup }) {
    this.buttonsStatus['FACIAL'] = this.LOADING;
    const actuators = accessGroup.actuators;
    data.actuators = actuators;
    if (accessGroup) {
      data.accessGroups = [accessGroup];
    }
    data.hardware = HARDWARES.CONTROL_ID;
    data.credits = this.condo.hardwareParams?.visitorsFacialFromGatekeeperDefaultCredits || 1;
    this.accessService
      .generateVisitorDeviceFromGatekeeper(condo._id, 'FACIAL', data)
      .pipe(
        map(device => ({ ...device, actuators: actuators, owner: { condoContact } })),
        tap((device: any) => {
          if (device.accessGroups?.length) {
            device.accessGroups = [accessGroup];
          }
        }),
        switchMap((device: any) => this.controlIdService.registerDevice(actuators, device)),
        tap((device: any) => {
          if (device.internalIds?.length) {
            this.deviceService.update(this.condo._id, device._id, { internalIds: device.internalIds }).pipe(retry(3)).toPromise();
          }
        })
      )
      .subscribe(
        res => {
          this.toastr.success('Facial gerada com sucesso');
          this.buttonsStatus['FACIAL'] = this.SUCCESS;
        },
        err => {
          console.log(err);
          this.errorOnGenerateDevice(err?.message?.replace(/['"]+/g, ''));
        }
      );
  }

  generateIntelbrasStandAloneDevice({ data, condo, condoContact, accessGroup }) {
    const actuators = accessGroup.actuators;
    data.actuators = actuators;
    data.hardware = 'INTELBRAS_STAND_ALONE';
    data.accessGroups = [accessGroup._id];
    data.credits = this.condo.hardwareParams?.visitorsFacialFromGatekeeperDefaultCredits || 1;
    const entranceDuration = this.condo.hardwareParams?.visitorsFacialFromGatekeeperDefaultEntranceDuration || 5;
    this.buttonsStatus['FACIAL'] = this.LOADING;
    this.accessService
      .generateVisitorDeviceFromGatekeeper(condo._id, 'FACIAL', data)
      .pipe(
        map(device => ({ ...device, actuators: actuators, owner: { condoContact }, condo })),
        switchMap((device: any) =>
          this.intelbrasService.addVisitorFacial(device, device.actuators, accessGroup.timezone || null, { entranceDuration })
        )
      )
      .subscribe(
        res => {
          const errors = res.filter((r: any) => !r.success);
          if (errors.length) {
            const actuatorNames = errors.map(result => result.actuator && result.actuator.name).join(', ');
            this.buttonsStatus['FACIAL'] = this.ERROR;
            swal({
              type: 'error',
              text: `Não foi possível gerar a facial do ${
                this.condo?.customLabels?.visitor?.singular || 'visitante'
              } nos seguintes dispositivos ${actuatorNames}`
            });
          } else {
            this.toastr.success('Facial gerada com sucesso');
            this.buttonsStatus['FACIAL'] = this.SUCCESS;
          }
        },
        err => {
          console.log(err);
          this.errorOnGenerateDevice();
        }
      );
  }

  generateVisitorFacialDevice({ data, condo, condoContact, accessGroup }) {
    data.hardware = HARDWARES.ANY;
    data.accessGroups = [accessGroup];
    data.credits = this.condo.hardwareParams?.visitorsFacialFromGatekeeperDefaultCredits || 1;
    this.buttonsStatus['FACIAL'] = this.LOADING;
    const entranceDuration = this.condo.hardwareParams?.visitorsCardFromGatekeeperDefaultTime || 5;
    this.accessService
      .generateVisitorDeviceFromGatekeeper(condo._id, 'FACIAL', data)
      .pipe(
        map(device => ({ ...device, owner: { condoContact }, condo })),
        switchMap((device: any) =>
          forkJoin(
            this.hardwareDeviceService.saveOnHardware(
              {
                ...device,
                accessGroups: [accessGroup]
              },
              { entranceDuration, timeZone: this.condo.timeZone }
            )
          )
        )
      )
      .subscribe(
        results => {
          const errors = results.filter((r: any) => !r.success);
          if (errors.length) {
            const actuatorNames = errors.map(result => result.actuator && result.actuator.name).join(', ');
            this.buttonsStatus['FACIAL'] = this.ERROR;
            swal({
              type: 'error',
              text: `Não foi possível gerar a facial do ${
                this.condo?.customLabels?.visitor?.singular || 'visitante'
              } nos seguintes dispositivos ${actuatorNames}`
            });
          } else {
            this.toastr.success('Facial gerada com sucesso');
            this.buttonsStatus['FACIAL'] = this.SUCCESS;
          }
        },
        err => {
          console.log(err);
          this.errorOnGenerateDevice();
        }
      );
  }

  errorOnGenerateDevice(message?) {
    this.buttonsStatus['FACIAL'] = this.ERROR;
    swal({
      type: 'error',
      text:
        message ||
        `'Não foi possível gerar a facial do ${
          this.condo?.customLabels?.visitor?.singular || 'visitante'
        }, verifique as conexões dos equipamentos e tente novamente'`
    });
  }

  createNewVehicle() {
    this.vehicleCreateModal.createVehicle();
  }

  onVehicleSelectedCallback(vehicleCreated) {
    this.selectedAccess.condoContact.condoVehicles.push(vehicleCreated);
    this.vehicle.setValue(vehicleCreated._id || vehicleCreated);
    this.condoContactService
      .updateCondoContact(this.condo._id, this.selectedAccess.condoContact._id, {
        condoVehicles: this.selectedAccess.condoContact.condoVehicles
      })
      .subscribe(res => {
        this.toastr.success('Veículo criado com sucesso');
      });
  }

  revealData(field: string, contact) {
    contact.isDataMasked[field] = false;

    let isFieldAlreadyUnmasked: boolean;

    if (field === 'ids') {
      isFieldAlreadyUnmasked = !contact.ids
        .map(id => id.number)
        .toString()
        .includes('*');
    } else if (field === 'birthDate') {
      isFieldAlreadyUnmasked = !contact.birthDate.toString().includes('3000');
    } else {
      isFieldAlreadyUnmasked = !contact[field].toString().includes('*');
    }

    if (isFieldAlreadyUnmasked) {
      return;
    }

    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    if (field === 'ids') {
      query.$populate = [{ path: 'ids.pictures', select: 'url thumbnail' }];
    }

    const callback = ({ data }) => {
      if (field === 'ids') {
        const ids = data.map(id => new ContactID(id));
        contact[field] = ids;
      } else {
        contact[field] = data;
      }
    };

    this.condoContactService
      .getCondoContactUnmaskedField(this.condo._id, contact._id, field, query)
      .pipe(timeout(10000))
      .subscribe(callback);
  }

  openAccessControlConfig() {
    const initialState = {
      condo: this.condo
    };
    this.modalService.show(ModalAccessControlConfigComponent, {
      initialState,
      class: 'modal-md',
      ignoreBackdropClick: true
    });
  }

  initiateIntercomCall(user: User): void {
    this.callService.call({
      callee: {
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        picture: user.picture
      }
    });
  }

  checkIfSelectedAccessCondoContactHasTodayPermission(condoContact: CondoContact) {
    this.selectedAccessCondoContactPermissionsData = [];
    this.checkAccessCondoContactHasTodayPermissionStatus = 'LOADING';

    const weekDays = {
      0: 'SUNDAY',
      1: 'MONDAY',
      2: 'TUESDAY',
      3: 'WEDNESDAY',
      4: 'THURSDAY',
      5: 'FRIDAY',
      6: 'SATURDAY'
    };
    const today = weekDays[moment().day()];

    const permissionsQuery: EcondosQuery = {
      $populate: [
        { path: 'condoContacts', select: 'firstName lastName picture email phone ids', populate: { path: 'picture', select: 'url ' } },
        { path: 'createdBy', select: 'firstName lastName picture', populate: { path: 'picture', select: 'url ' } },
        { path: 'residence', select: 'identification', options: { withDeleted: false } },
        { path: 'accessGroups', select: 'name actuators' }
      ],
      type: Occurrence.GATE_TYPE,
      subType: GateOccurrence.ALLOW_ACCESS_TYPE,
      startDate: { $lte: moment().startOf('day').toISOString() },
      endDate: { $gte: moment().endOf('day').toISOString() },
      status: Occurrence.STATUS.OPENED,
      condoContacts: condoContact._id
    };

    this.occurrenceService.getOccurrences(this.condo._id, permissionsQuery).subscribe({
      next: response => {
        const occurrences = response.occurrences as GateOccurrence[];

        for (const occurrence of occurrences) {
          const todayPermission = occurrence.daysAllowed.find(d => d.day === today);

          let isValidTime = false;
          if (todayPermission) {
            const [startHour, startMinute] = todayPermission?.startTime.split(':');
            const [endHour, endMinute] = todayPermission?.endTime.split(':');
            const startDate = moment().hour(parseInt(startHour, 10)).minute(parseInt(startMinute, 10));
            const endDate = moment().hour(parseInt(endHour, 10)).minute(parseInt(endMinute, 10));
            isValidTime = moment().isBetween(startDate, endDate);
          }

          this.selectedAccessCondoContactPermissionsData.push({ occurrence, isValidTime });
        }

        this.checkAccessCondoContactHasTodayPermissionStatus = 'SUCCESS';
      },
      error: error => {
        this.checkAccessCondoContactHasTodayPermissionStatus = 'ERROR';
      }
    });
  }

  openResidenceDetailsModal(residenceId: string) {
    if (residenceId) {
      const initialState = { condo: this.condo, residenceId };

      this.modalService.show(GateResidenceDetailsModalComponent, {
        initialState,
        class: 'modal-lg modal-xl',
        ignoreBackdropClick: true
      });
    }
  }

  private showUpdateCondoContactModal(condoContact: CondoContact, permission: GateOccurrence) {
    this.modalService.show(ModalNewCondoContactComponent, {
      initialState: {
        condo: this.condo,
        user: this.user,
        condoContact,
        callback: contact => {
          this.onCondoContactUpdated(contact);
          this.updateFacial(contact, permission);
        }
      },
      class: 'modal-xl',
      ignoreBackdropClick: true
    });
  }

  private processSaveDeviceResults(device: Device, results: Array<{ actuator: Actuator; ok: boolean }>) {
    const wereThereNoErrors = results.every(res => res.ok);

    if (wereThereNoErrors) {
      return this.deviceService
        .update(this.condo._id, device._id, { status: DEVICE_STATUS.ACTIVE })
        .pipe(map(() => ({ results, device: device })));
    }

    return of({ results, device });
  }

  private updateFacial(condoContact: CondoContact, permission: GateOccurrence) {
    swal({
      type: 'info',
      title: `Atualizando foto do ${this.condo?.customLabels?.visitor?.singular || 'visitante'}`,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false
    });

    swal.showLoading();

    const deviceQuery: EcondosQuery = {
      $populate: [
        'actuators',
        {
          path: 'owner.condoContact',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail' }
        },
        { path: 'owner.picture', select: 'url thumbnail' }
      ],
      permission: permission._id,
      'owner.condoContact': condoContact._id
    };

    this.deviceService
      .get(this.condo._id, deviceQuery)
      .pipe(
        map(({ devices: [device] }) => device || null),
        switchMap((device: Device | null) => {
          if (device) {
            // Foi atribuído o condomínio aqui dessa forma porque dentro do método addVisitorFacial() do IntelbrasStandAloneService
            // ele é usado para validar um hardware param.
            device.condo = this.condo;
          }

          if (!device?.actuators?.length) {
            const actuatorsQuery: EcondosQuery = {
              type: DEVICE_TYPES.FACIAL,
              prohibitedForVisitors: { $ne: true }
            };

            let uniqueActuatorsIds: Array<Actuator['_id']> = [];
            let hardware: HARDWARES.HIKVISION | HARDWARES.INTELBRAS_STAND_ALONE;

            const hasAccessGroups = !!permission.accessGroups?.length;

            // Se tem grupo de acesso, a fabricante vai ser deduzido a partir dele.
            if (hasAccessGroups) {
              const [accessGroup] = permission.accessGroups;

              const actuatorsIds = accessGroup.actuators.map(actuator => {
                // Sempre vai pegar o fabricando do último acionador
                hardware = actuator?.hardware || hardware;
                return actuator._id || actuator;
              });

              // Remove acionadores duplicados caso exista mais de um grupo de acesso com acionadores em comum
              uniqueActuatorsIds = [...new Set(actuatorsIds)];
              actuatorsQuery._id = { $in: uniqueActuatorsIds };
            } else {
              if (this.condo.isHikvisionEnabled() && !this.condo.isIntelbrasStandAloneEnabled()) {
                hardware = HARDWARES.HIKVISION;
              } else if (this.condo.isIntelbrasStandAloneEnabled() && !this.condo.isHikvisionEnabled()) {
                hardware = HARDWARES.INTELBRAS_STAND_ALONE;
              } else {
                // No caso de as duas integrações estarem habilitadas, vamos dar prioridade à Intelbras. Isso porque
                // a chance de que a facial seja Intelbras é maior em relação à Hikvision.
                hardware = HARDWARES.INTELBRAS_STAND_ALONE;
              }
            }

            actuatorsQuery.hardware = hardware;

            return this.actuatorService.getActuators(this.condo._id, actuatorsQuery).pipe(
              switchMap(({ actuators }) => {
                const unpopulatedDevice: any = {
                  hardware,
                  status: DEVICE_STATUS.UNSYNCED,
                  accessType: condoContact.type || 'VISITOR',
                  permission: permission._id,
                  owner: {
                    condoContact: condoContact?._id || condoContact,
                    residence: permission.residence?._id || permission.residence,
                    ...(condoContact.picture && { picture: condoContact.picture?._id || condoContact.picture })
                  },
                  type: DEVICE_TYPES.FACIAL,
                  daysAllowed: permission.daysAllowed,
                  validFrom: permission.startDate,
                  validUntil: permission.endDate,
                  approvedByName: this.user.fullName || ''
                };

                if (hasAccessGroups) {
                  unpopulatedDevice.accessGroups = permission.accessGroups.map(accessGroup => accessGroup._id || accessGroup);
                  unpopulatedDevice.actuators = uniqueActuatorsIds;
                } else {
                  unpopulatedDevice.actuators = actuators.map(actuator => actuator?._id || actuator);
                }

                const saveDeviceObservable = device
                  ? this.deviceService.update(this.condo._id, device._id, unpopulatedDevice).pipe(map(() => unpopulatedDevice))
                  : this.deviceService
                      .create(this.condo._id, unpopulatedDevice)
                      .pipe(switchMap(({ _id }) => this.deviceService.getById(this.condo._id, _id)));

                return saveDeviceObservable.pipe(
                  switchMap(savedDevice => {
                    const populatedDevice: Device = {
                      ...savedDevice,
                      owner: {
                        condoContact,
                        residence: permission.residence,
                        ...(condoContact.picture && { picture: condoContact.picture })
                      },
                      permission,
                      actuators
                    };

                    if (hardware === HARDWARES.INTELBRAS_STAND_ALONE) {
                      return this.intelbrasService.addVisitorFacial(populatedDevice, actuators).pipe(
                        map(results => results.map(result => ({ actuator: result.actuator as Actuator, ok: !!result.success }))),
                        switchMap(results => this.processSaveDeviceResults(populatedDevice, results))
                      );
                    }

                    return this.hikvisionService
                      .create(actuators, populatedDevice)
                      .pipe(switchMap(results => this.processSaveDeviceResults(populatedDevice, results)));
                  })
                );
              })
            );
          } else {
            device.owner.picture = condoContact.picture || device.owner.picture;

            if (device.hardware === HARDWARES.INTELBRAS_STAND_ALONE) {
              return this.deviceService.update(this.condo._id, device._id, { 'owner.picture': condoContact.picture }).pipe(
                switchMap(() =>
                  this.intelbrasService
                    .addVisitorFacial(device, device.actuators)
                    .pipe(map(results => results.map(result => ({ actuator: result.actuator as Actuator, ok: !!result.success }))))
                ),
                switchMap(results => this.processSaveDeviceResults(device, results))
              );
            }

            return this.deviceService.update(this.condo._id, device._id, { 'owner.picture': condoContact.picture }).pipe(
              switchMap(() => this.hikvisionService.update(device, device.actuators)),
              switchMap(results => this.processSaveDeviceResults(device, results))
            );
          }
        })
      )
      .subscribe({
        next: res => {
          const wereThereNoErrors = res?.results?.every(r => r.ok);

          if (wereThereNoErrors) {
            swal({
              type: 'success',
              title: 'Foto atualizada',
              html: 'A foto foi atualizada e sincronizada com a liberação criada pelo morador e em todos os dispositivos faciais!'
            });

            return;
          }

          const errorMessage = res?.results
            ?.filter(r => !r.ok)
            .map(({ actuator }) => actuator.name)
            .join(', ');

          swal({
            type: 'error',
            title: 'Erro ao atualizar foto',
            text: `Não foi possível atualizar a foto nos seguintes dispositivos: ${errorMessage}`,
            showCancelButton: true,
            confirmButtonText: 'Tentar novamente',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
          }).then(result => {
            if (result) {
              this.updateFacial(condoContact, permission);
            }
          });
        },
        error: err => {
          swal({
            type: 'error',
            title: 'Erro ao atualizar foto',
            text: `Não foi possível atualizar a foto do ${
              this.condo?.customLabels?.visitor?.singular || 'visitante'
            }. Verifique sua conexão e tente novamente!`
          });
        }
      });
  }

  public async updateContactPicture(condoContact: CondoContact, permission: GateOccurrence) {
    const visitorPictureUpdate = Boolean(
      localStorage.getItem(`econdos-app:${this.condo._id}:${this.user._id}:visitorPictureUpdateFromGatekeeper`)
    );

    if (!visitorPictureUpdate) {
      await swal({
        type: 'warning',
        title: `Atualização da foto do ${this.condo?.customLabels?.visitor?.singular || 'visitante'}`,
        text: `Esse processo vai atualizar a foto do ${
          this.condo?.customLabels?.visitor?.singular || 'visitante'
        } na liberação criada pelo morador e em todos os dispositivos faciais. Você deseja atualizar?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
        preConfirm: () => Promise.resolve(true)
      }).then(result => {
        if (result) {
          localStorage.setItem(`econdos-app:${this.condo._id}:${this.user._id}:visitorPictureUpdateFromGatekeeper`, result);
          this.showUpdateCondoContactModal(condoContact, permission);
        }
      });
    } else {
      this.showUpdateCondoContactModal(condoContact, permission);
    }
  }

  permissionDetails(permission: GateOccurrence) {
    const initialState = { occurrence: permission, condo: this.condo };
    this.modalService.show(ModalPermissionDetailsComponent, { initialState, class: 'modal-lg' });
  }
}

export interface AccessInfo {
  access?: Access;
  residence?: Residence;
  nameOrId?: string;
  contact?: CondoContact;
}
