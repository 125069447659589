import { CondoContact } from '@api/model/contact/condo.contact';
import { Condo } from '@api/model/condo';
import { Residence } from '@api/model/interface/residence';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { ResidenceBuilder } from '@api/model/residence/residence.builder';
import { Access } from '@api/model/access';

export interface ActiveVisitor {
  _id: string;
  condoContact: CondoContact;
  residence?: Residence;
  type: string;
  condo: Condo;
  access: Access;
  inDate: null;
  condoVehicle?: CondoVehicle;
}

export type ActiveVisitorOnlyWithId = Pick<ActiveVisitor, '_id'>;

export const buildActiveVisitor = (activeVisitor): ActiveVisitorOnlyWithId | ActiveVisitor => {
  if (typeof activeVisitor == 'string') {
    return {
      _id: activeVisitor
    };
  } else if (activeVisitor) {
    return {
      _id: activeVisitor._id,
      condoContact: new CondoContact(activeVisitor.condoContact),
      type: activeVisitor.type,
      condo: activeVisitor.condo,
      inDate: activeVisitor.inDate,
      residence: activeVisitor.residence ? ResidenceBuilder.build(activeVisitor.residence) : null,
      condoVehicle: activeVisitor.condoVehicle ? new CondoVehicle(activeVisitor.condoVehicle) : null,
      access: typeof activeVisitor.access === 'string' ? activeVisitor.access : new Access(activeVisitor.access)
    };
  }
};
