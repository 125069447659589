export enum VEHICLES_BRANDS {
  // ACURA =  'ACURA',
  // AGRALE = 'AGRALE',
  ALFA_ROMEO = 'ALFA ROMEO',
  // AM_GEN =  'AM GEN',
  // ASIA_MOTORS =  'ASIA MOTORS',
  ASTON_MARTIN = 'ASTON MARTIN',
  AUDI = 'AUDI',
  // BABY =  'BABY',
  BENTLEY = 'Bentley',
  BMW = 'BMW',
  // BRM = 'BRM',
  BUGATTI = 'Bugatti',
  BUGGY = 'BUGGY',
  BYD = 'BYD',
  // BUGRE =  'BUGRE',
  CADILLAC = 'CADILLAC',
  // CBT_JIPE =  'CBT JIPE',
  // CHANA =  'CHANA',
  // CHANGAN =  'CHANGAN',
  CHEVROLET = 'CHEVROLET',
  CHERY = 'CHERY',
  CHRYSLER = 'CHRYSLER',
  CITROEN = 'CITROEN',
  // CROSS_LANDER =  'CROSS LANDER',
  // DAEWOO =  'DAEWOO',
  // DAIHATSU =  'DAIHATSU',
  DAF = 'DAF',
  DODGE = 'DODGE',
  // EFFA =  'EFFA',
  // ENGESA =  'ENGESA',
  // ENVEMO =  'ENVEMO',
  FERRARI = 'FERRARI',
  FIAT = 'FIAT',
  // FIBRAVAN =  'FIBRAVAN',
  FORD = 'FORD',
  // FOTON =  'FOTON',
  // FYBER =  'FYBER',
  // GEELY =  'GEELY',
  GWM = 'GWM',
  GM = 'GM',
  // GREAT_WALL =  'GREAT WALL',
  // GURGEL =  'GURGEL',
  // HITECH_ELECTRIC =  'HITECH ELECTRIC',
  // HAFEI =  'HAFEI',
  HAVAL = 'HAVAL',
  HONDA = 'HONDA',
  HYUNDAI = 'HYUNDAI',
  IMPORTADO = 'IMPORTADO',
  // ISUZU =  'ISUZU',
  IVECO = 'IVECO',
  JAC = 'JAC',
  JAGUAR = 'JAGUAR',
  JEEP = 'JEEP',
  // JINBEI =  'JINBEI',
  // JPX =  'JPX',
  KIA = 'KIA',
  // LADA =  'LADA',
  LAMBORGHINI = 'LAMBORGHINI',
  LANDROVER = 'LAND ROVER',
  LEXUS = 'LEXUS',
  LIFAN = 'LIFAN',
  // LOBINI =  'LOBINI',
  LOTUS = 'LOTUS',
  // MAHINDRA =  'MAHINDRA',
  MASERATI = 'MASERATI',
  // MATRA =  'MATRA',
  MAZDA = 'MAZDA',
  MCLAREN = 'MCLAREN',
  MERCEDES = 'MERCEDES',
  // MERCURY = 'MERCURY',
  MG = 'MG',
  MINI = 'MINI',
  MITSUBISHI = 'MITSUBISHI',
  // MIURA = 'MIURA',
  MOTO = 'MOTO',
  NISSAN = 'NISSAN',
  VEICULO = 'VEICULO',
  PEUGEOT = 'PEUGEOT',
  // PLYMOUTH = 'PLYMOUTH',
  // PONTIAC = 'PONTIAC',
  PORSCHE = 'PORSCHE',
  RAM = 'RAM',
  // RELY = 'RELY',
  RENAULT = 'RENAULT',
  ROLLS_ROYCE = 'ROLLS-ROYCE',
  ROVER = 'ROVER',
  // SAAB = 'SAAB',
  // SATURN = 'SATURN',
  // SEAT = 'SEAT',
  // SHINERAY = 'SHINERAY',
  SCANIA = 'SCANIA',
  SMART = 'SMART',
  // SSANGYONG = 'SSANGYONG',
  SUBARU = 'SUBARU',
  SUZUKI = 'SUZUKI',
  // TAC = 'TAC',
  TOYOTA = 'TOYOTA',
  TROLLER = 'TROLLER',
  VOLKSWAGEN = 'VOLKSWAGEN',
  VOLVO = 'VOLVO',
  'SEM VEICULO' = 'SEM VEICULO'
}

export enum VEHICLES {
  '0x00' = 'AUDI',
  '0x01' = 'BMW',
  '0x02' = 'CHEVROLET',
  '0x03' = 'CHRYSLER',
  '0x04' = 'CITROEN',
  '0x05' = 'FERRARI',
  '0x06' = 'FIAT',
  '0x07' = 'FORD',
  '0x08' = 'GM',
  '0x09' = 'HONDA',
  '0x0A' = 'HYUNDAI',
  '0x0B' = 'IMPORTADO',
  '0x0C' = 'JAGUAR',
  '0x0D' = 'JEEP',
  '0x0E' = 'KIA',
  '0x0F' = 'LAMBORGHINI',
  '0x10' = 'LAND ROVER',
  '0x11' = 'MAZDA',
  '0x12' = 'MERCEDES',
  '0x13' = 'MITSUBISHI',
  '0x14' = 'MOTO',
  '0x15' = 'NISSAN',
  '0x16' = 'VEICULO',
  '0x17' = 'PEUGEOT',
  '0x18' = 'PORSCHE',
  '0x19' = 'RENAULT',
  '0x1A' = 'SUBARU',
  '0x1B' = 'SUZUKI',
  '0x1C' = 'TOYOTA',
  '0x1D' = 'VOLKSWAGEN',
  '0x1E' = 'VOLVO',
  '0x1F' = 'SEM VEICULO'
}

export enum VEHICLES_COLORS {
  AMARELO = 'AMARELO ',
  AZUL = 'AZUL ',
  BEGE = 'BEGE',
  BRANCO = 'BRANCO ',
  CINZA = 'CINZA ',
  DOURADO = 'DOURADO',
  FANTASIA = 'FANTASIA ',
  GRENA = 'GRENA',
  LARANJA = 'LARANJA ',
  MARROM = 'MARROM',
  PRATA = 'PRATA',
  PRETO = 'PRETO',
  ROSA = 'ROSA',
  ROXO = 'ROXO',
  VERDE = 'VERDE',
  VERMELHO = 'VERMELHO'
}

export const USER_DEVICES_TYPE = {
  1: { label: 'RF - Controle Remoto', value: 'RF' },
  2: { label: 'TA - Tag Ativo', value: 'TA' },
  3: { label: 'CT - Cartão/Chaveiro', value: 'CT' },
  4: { label: 'Cartão', value: 'CARD' },
  5: { label: 'BM - Biometria', value: 'BM' },
  6: { label: 'TP - Tag Passiva', value: 'TP' },
  7: { label: 'SN - Senha', value: 'SN' },
  8: { label: 'Facial', value: 'FACIAL' },
  9: { label: 'QR Code', value: 'QR' },
  10: { label: 'Placa', value: 'VEHICLE_PLATE' },
  11: { label: 'Chave Virtual', value: 'VIRTUAL_KEY' }
};

export const DEVICE_TYPES_LABEL = {
  RF: 'RF - Controle Remoto',
  TA: 'TA - Tag Ativo',
  CT: 'CT - Cartão/Chaveiro',
  TP: 'TP - Tag Passiva',
  SN: 'SN - Senha',
  BM: 'BM - Biometria',
  CARD: 'Cartão ou chaveiro',
  FACIAL: 'Facial',
  QR: 'QR Code',
  VEHICLE_PLATE: 'Placa',
  'Chave Virtual': 'Chave Virtual',
  VIRTUAL_KEY: 'Chave Virtual',
  PRINTED_QR: 'QR Code Impresso'
};

export enum HARDWARES {
  LINEAR = 'LINEAR',
  NICE_CONTROLLER = 'NICE_CONTROLLER',
  CONTROL_ID = 'CONTROL_ID',
  HIKVISION = 'HIKVISION',
  UTECH = 'UTECH',
  INTELBRAS = 'INTELBRAS',
  INTELBRAS_STAND_ALONE = 'INTELBRAS_STAND_ALONE',
  INTELBRAS_LPR = 'INTELBRAS_LPR',
  TASMOTA = 'TASMOTA',
  GAREN = 'GAREN',
  ALPHADIGI = 'ALPHADIGI',
  ALPHADIGI_LPR = 'ALPHADIGI_LPR',
  ZKTECO = 'ZKTECO',
  AVANTIA = 'AVANTIA',
  BRAVAS = 'BRAVAS',
  XPE = 'XPE',
  MIP1000IP = 'MIP1000IP',
  ANY = 'ANY'
}

export enum DEVICES_HARDWARES_LABEL {
  LINEAR = 'Linear',
  NICE_CONTROLLER = 'Controladora Nice',
  ECONDOS = 'eCondos',
  CONTROL_ID = 'Control ID',
  HIKVISION = 'Hikvision',
  UTECH = 'uTech',
  INTELBRAS = 'Intelbras Incontrol',
  INTELBRAS_STAND_ALONE = 'Intelbras',
  INTELBRAS_LPR = 'Intelbras LPR',
  TASMOTA = 'Tasmota',
  GAREN = 'Garen',
  ALPHADIGI = 'Alphadigi',
  ALPHADIGI_LPR = 'Alphadigi LPR',
  ZKTECO = 'ZKTeco',
  AVANTIA = 'Avantia',
  BRAVAS = 'Bravas',
  XPE = 'Xpe',
  MIP1000IP = 'MIP 1000 IP',
  ANY = 'Any'
}
