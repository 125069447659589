import { HttpService } from '../../../services/http.service';
import { ConstantService } from '../../../services/constant.service';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Actuator } from '../../model/hardware/actuator';
import { HardwareSocketService } from '../../../services/hardware-socket.service';
import { HikvisionService } from '@api/service/hardware/hikvision.service';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { ControlIdService } from '@api/service/hardware/control-id.service';
import { UtechService } from '@api/service/hardware/utech.service';
import { IntelbrasIncontrolService } from './intelbras-incontrol.service';
import { AlphaDigiService } from '@api/service/hardware/alphadigi.service';
import { IntelbrasStandAloneService } from '@api/service/hardware/intelbras-stand-alone.service';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { ZktecoService } from '@api/service/hardware/zkteco.service';
import { GarenService } from './garen.service';
import { SdkService } from '@api/service/hardware/sdk.service';
import { IntelbrasLprService } from '@api/service/hardware/intelbras-lpr.service';
import { Deleted } from '@api/model/deleted';
import { Condo } from '@api/model/condo';
import * as moment from 'moment';
import { RemoteCheckService } from '@api/serviceV3/remote-check.service';
import Utech from '@econdos/econdos-utech';
import { Mip1000IPService } from '@api/service/hardware/mip1000ip.service';

type DeletedActuator = Actuator & Deleted;

@Injectable({ providedIn: 'root' })
export class ActuatorService {
  private endPoint;

  constructor(
    private http: HttpService,
    private angularHttp: HttpClient,
    private hardwareSocketService: HardwareSocketService,
    private hikvisionService: HikvisionService,
    private controlIdService: ControlIdService,
    private utechService: UtechService,
    private intelbrasIncontrolService: IntelbrasIncontrolService,
    private intelbrasService: IntelbrasStandAloneService,
    private intelbrasLprService: IntelbrasLprService,
    private zktecoService: ZktecoService,
    private sdkService: SdkService,
    private garenService: GarenService,
    private alphaDigiService: AlphaDigiService,
    private constantService: ConstantService,
    private remoteCheckService: RemoteCheckService,
    private mip1000IPService: Mip1000IPService
  ) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  /**
   * @deprecated Use getActuators instead.
   */
  get(
    condoId: string,
    params: Array<{ [actuator: string]: string }> = []
  ): Observable<{
    count: number;
    actuators: Actuator[];
  }> {
    let httpParams = new HttpParams();
    for (const param of params) {
      httpParams = httpParams.set(Object.keys(param)[0], param[Object.keys(param)[0]]);
    }

    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/actuators`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => {
          return {
            count: res.headers.get('count'),
            actuators: res.body
          };
        })
      );
  }

  getActuators(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; actuators: Actuator[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/actuators`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          actuators: res.body
        }))
      );
  }

  getActuatorsWithToken(condoId: string, query: EcondosQuery, token = ''): Observable<{ count: number; actuators: Actuator[] }> {
    query.token = token;
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = {
      headers: new HttpHeaders(),
      params,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/actuators/searchToken`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        actuators: res.body
      }))
    );
  }

  getDeleted(condoId: string, params: EcondosQuery): Observable<{ count: number; actuators: DeletedActuator[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });

    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/actuators/deleted`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          actuators: res.body as DeletedActuator[]
        }))
      );
  }

  create(condoId: string, actuator: Actuator) {
    return this.http
      .post(`${this.endPoint}${condoId}/actuators`, actuator)
      .pipe(map((res: { _id: string }) => ({ ...actuator, _id: res._id })));
  }

  update(condoId: string, actuatorId: string, actuator: Actuator) {
    return this.http.put(`${this.endPoint}${condoId}/actuators/${actuatorId}`, actuator);
  }

  delete(condoId: string, actuatorId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/actuators/${actuatorId}`);
  }

  restore(condoId: string, actuatorId: string) {
    return this.http.put(`${this.endPoint}${condoId}/actuators/${actuatorId}/restore`, {});
  }

  getById(condoId: string, actuatorId: string): Observable<Actuator> {
    return this.http.get(`${this.endPoint}${condoId}/actuators/${actuatorId}`) as Observable<Actuator>;
  }

  remoteCheck(actuator: Actuator) {
    return this.hikvisionService.remoteCheck(actuator);
  }

  trigger(
    actuator: Actuator,
    params: {
      direction?: 'clockwise' | 'anticlosewise' | 'both' | 'ENTRANCE' | 'EXIT';
      door?: number;
    } = {}
  ) {
    switch (actuator.hardware) {
      case 'LINEAR': {
        if (actuator._id) {
          return this.http.post(`${this.endPoint}${actuator.condo._id || actuator.condo}/actuators/${actuator._id}/trigger`, {});
        } else {
          let input, command;
          if (actuator.output > 4) {
            input = {
              tipo_disp: actuator.type,
              num_disp: actuator.number,
              rele: actuator.output,
              gera_evt: true,
              tempo: 1
            };
            command = 'triggerReceiverAdvanced';
          } else {
            command = 'triggerReceiver';
            input = {
              tipo_disp: actuator.type,
              num_disp: actuator.number,
              num_saida: actuator.output,
              gera_evt: true
            };
          }
          return this.hardwareSocketService.sendCommand(actuator.condo._id || actuator.condo, command, input);
        }
      }
      case 'HIKVISION': {
        let params: Record<string, any> = {};
        if (actuator.type === 'DEEPIN_VIEW_LPR' || actuator.type === 'ANPR') {
          params = { relayTime: actuator.time, door: actuator.output };
        }
        return this.sdkService.trigger(actuator, params);
      }
      case 'CONTROL_ID': {
        const cidParams: any = { ...params };
        if (actuator.time) {
          cidParams.relayTime = actuator.time;
        }
        if (actuator.output) {
          cidParams.door = parseInt(actuator.output.toString(), 10);
        }
        return this.controlIdService.trigger(actuator, cidParams);
      }
      case 'UTECH': {
        return this.utechService.trigger(actuator);
      }
      case 'INTELBRAS': {
        return this.intelbrasIncontrolService.trigger(actuator.clientId, actuator.output);
      }
      case 'INTELBRAS_STAND_ALONE': {
        return this.intelbrasService.trigger(actuator);
      }
      case 'INTELBRAS_LPR': {
        return this.intelbrasLprService.trigger(actuator);
      }
      case 'ALPHADIGI_LPR':
      case 'TASMOTA': {
        return this.http.post(`${this.endPoint}${actuator.condo._id}/actuators/${actuator._id}/trigger`, { actuator });
      }
      case HARDWARES.NICE_CONTROLLER: {
        return this.http.post(`${this.endPoint}${actuator.condo._id || actuator.condo}/actuators/${actuator._id}/trigger`, {});
      }
      case 'GAREN': {
        return this.garenService.trigger(actuator);
      }
      case 'ALPHADIGI': {
        return this.alphaDigiService.trigger(actuator);
      }
      case HARDWARES.ZKTECO: {
        return this.zktecoService.trigger(actuator);
      }
      case HARDWARES.BRAVAS: {
        const bravasParams: { direction?: 'ENTRANCE' | 'EXIT' } = {
          ...(['ENTRANCE', 'EXIT'].includes(params.direction) ? { direction: params.direction as any } : {})
        };
        return this.sdkService.trigger(actuator, bravasParams);
      }
      case HARDWARES.XPE: {
        return this.sdkService.trigger(actuator);
      }
      case HARDWARES.AVICAM: {
        return this.sdkService.trigger(actuator, { door: actuator.output });
      }
      case HARDWARES.MIP1000IP: {
        return this.mip1000IPService.trigger(actuator);
      }
      case HARDWARES.INTELBRAS_ALARM_CENTRAL: {
        return this.http.post(`${this.endPoint}${actuator.condo._id || actuator.condo}/actuators/${actuator._id}/trigger`, {});
      }
    }
  }

  lock(actuator: Actuator) {
    return this.sdkService.lock(actuator);
  }

  unlock(actuator: Actuator) {
    return this.sdkService.unlock(actuator);
  }

  checkConnection(actuator: Actuator) {
    switch (actuator.hardware) {
      case HARDWARES.XPE: {
        return this.sdkService.checkConnection(actuator);
      }
      case 'ZKTECO':
      case 'TASMOTA': {
        return this.http.post(`${this.endPoint}${actuator.condo?._id || actuator.condo}/actuators/${actuator._id}/check-connection`, {
          actuator
        });
      }
      case 'ALPHADIGI_LPR': {
        return this.http.get(
          `${this.constantService.getV2Endpoint()}integrations/plate-recognizer/alphadigi/keep-alive/${actuator.clientId}`
        );
      }
      default: {
        throw new Error('not implemented!');
      }
    }
  }

  reboot(actuator: Actuator) {
    switch (actuator.hardware) {
      case 'TASMOTA': {
        return this.http.post(`${this.endPoint}${actuator.condo?._id || actuator.condo}/actuators/${actuator._id}/reboot`, {
          actuator
        });
      }
      default: {
        return of({ success: false });
      }
    }
  }

  registerTriggerEvent(actuator: Actuator) {
    switch (actuator.hardware) {
      // Don't send to /register-trigger because actuator trigger is API responsibility
      case 'LINEAR':
      case HARDWARES.NICE_CONTROLLER:
      case HARDWARES.ALPHADIGI_LPR:
      case 'TASMOTA': {
        return of('ok');
      }
      default: {
        return this.http.post(`${this.endPoint}${actuator.condo._id}/actuators/${actuator._id}/register-trigger`, { actuator });
      }
    }
  }

  debug(condoId: string, deviceId: string) {
    return this.http.get(`${this.endPoint}${condoId}/actuators/${deviceId}/debug`, {});
  }

  configActuator(condo: Condo, actuator: Actuator) {
    let request: Observable<any>;

    switch (actuator.hardware) {
      case HARDWARES.CONTROL_ID: {
        const controlId = this.controlIdService.getCid(actuator);
        request = from(controlId.activateMonitor());
        break;
      }
      case HARDWARES.UTECH: {
        const utech = new Utech({ ...actuator, condoId: condo._id });
        const now = moment().format('YYYY-MM-DD HH:mm:ss');
        request = from(utech.setDateTime(now)).pipe(switchMap(() => from(utech.activateMonitor())));
        break;
      }
      case HARDWARES.ALPHADIGI: {
        request = this.alphaDigiService.activateMonitor(actuator);
        break;
      }
      case HARDWARES.GAREN: {
        request = this.garenService.activateMonitor(actuator);
        break;
      }
      case HARDWARES.INTELBRAS_STAND_ALONE: {
        request = this.intelbrasService.activateMonitor(actuator).pipe(
          switchMap(() => {
            return this.intelbrasService.disableUploadPicture(actuator);
          })
        );
        break;
      }
      case HARDWARES.HIKVISION: {
        request = this.sdkService.configure(actuator);
        break;
      }
      case HARDWARES.AVICAM:
      case HARDWARES.XPE:
      case HARDWARES.BRAVAS: {
        request = this.sdkService.configure(actuator);
      }
    }

    return request;
  }

  configActuatorOffline(condo: Condo, actuator: Actuator) {
    return this.remoteCheckService
      .sendConfigToRemoteCheck(condo?.remoteCheck.host, condo?.remoteCheck.port || 8090, actuator._id)
      .pipe(map(success => ({ success })));
  }
}
