import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
// Esse handler faz o reload da aplicação se apresentar o erro de loading chunk que pode acontecer ao tentar acessar um módulo lazy quando há atualizações do sistema
@Injectable()
export class LoadChunkErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error: any): void {
    Sentry.captureException(error);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error?.message)) {
      window.location.reload();
    }
  }
}
