import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { ActuatorService } from './hardware/actuator.service';
import { noop, switchMap, timeout } from 'rxjs';
import { HardwareSocketService } from 'app/services/hardware-socket.service';
import { HttpService } from 'app/services/http.service';
import { ConstantService } from 'app/services/constant.service';
import { Condo } from '@api/model/condo';

type CallProcedureData = {
  CONFIG_ACTUATOR_EVENTS: Record<'condoId' | 'actuatorId', string>;
};

type SupportedEventsHandlers = {
  [TKey in keyof CallProcedureData]: (condo: Condo, data: CallProcedureData[TKey]) => Promise<void>;
};

@Injectable({ providedIn: 'root' })
export class RemoteProcedureCallService {
  private endPoint = this.constantService.getV3Endpoint();

  private readonly supportedEventsHandlers: SupportedEventsHandlers = {
    CONFIG_ACTUATOR_EVENTS: this.handleConfigActuatorEvent
  };

  constructor(
    private http: HttpService,
    private constantService: ConstantService,
    private sessionService: SessionService,
    private hardwareSocketService: HardwareSocketService,
    private actuatorService: ActuatorService
  ) {}

  public initialize() {
    this.hardwareSocketService.onRPCData$.subscribe(({ event, data, condoId }) => {
      const condo = this.sessionService.condoValue;

      if (!event || condoId !== condo._id) {
        return;
      }

      const eventHandler = this.supportedEventsHandlers[event]?.bind(this);

      if (eventHandler) {
        eventHandler(condo, data);
      }
    });
  }

  public callProcedure<TProcedure extends keyof CallProcedureData>(procedure: TProcedure, data: CallProcedureData[TProcedure]) {
    return this.http.post(`${this.endPoint}rpc/${procedure}`, data);
  }

  private async handleConfigActuatorEvent(condo: Condo, { condoId, actuatorId }: CallProcedureData['CONFIG_ACTUATOR_EVENTS']) {
    this.actuatorService
      .getById(condoId, actuatorId)
      .pipe(
        timeout(10_000),
        switchMap(actuator => this.actuatorService.configActuator(condo, actuator))
      )
      .subscribe(noop);
  }
}
