import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../../../../components/components.module';
import { LogbookMessage, Logbooks } from '@api/model/logbook';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Status } from '@api/model/status';
import { EcondosQuery } from '@api/model/query';
import { Condo } from '@api/model/condo';
import { User } from '@api/model/user';
import { SessionService } from '@api/service/session.service';
import { ToastrService } from 'ngx-toastr';
import { PipesModule } from '../../../../../pipe/pipes.module';
import { AutosizeModule } from 'ngx-autosize';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { FormControl, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { combineLatest, lastValueFrom, Observable, Subject } from 'rxjs';
import { OccurrenceFileUploader } from '../../../../occurrence.creator/occurrence.creator.file.list/files.scroll';
import { File } from '@api/model/file';
import * as moment from 'moment/moment';
import * as RecordRTC from 'recordrtc';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged, startWith, switchMap, tap, timeout } from 'rxjs/operators';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import swal from 'sweetalert2';
import { CondoFilesService } from '@api/serviceV2/condo.files.service';
import { MomentModule } from 'ngx-moment';
import { LogbookModule } from './components/logbook-message.module';
import { LogbookMessagesService } from '@api/service/logbook-messages.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ChecklistTemplate } from '@api/model/checklistTemplate';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { HasPermissionDirective } from '../../../../../directives/has-permission.directive';
import { ModalGateNotebookChecklistComponent } from '../../../../gate/gate-notebook/modal-gate-notebook-checklist/modal-gate-notebook-checklist.component';
import { ChecklistTemplateService } from '@api/service/checklistTemplate.service';
import { ModalPassagemDePostoComponent } from '../../../../gate/gate-notebook/modal-passagem-de-posto/modal-passagem-de-posto.component';

@Component({
  selector: 'app-logbook',
  templateUrl: './logbook.component.html',
  styleUrls: ['./logbook.component.scss'],
  imports: [
    CommonModule,
    ComponentsModule,
    FontAwesomeModule,
    PipesModule,
    AutosizeModule,
    BsDropdownModule,
    NgxTrimDirectiveModule,
    TooltipModule,
    ReactiveFormsModule,
    MomentModule,
    LogbookModule,
    BsDatepickerModule,
    HasPermissionDirective
  ],
  standalone: true
})
export class LogbookComponent implements OnInit, AfterViewInit {
  @ViewChild('messagesWrapper', { read: ElementRef, static: true }) messagesWrapper: ElementRef;
  @ViewChild('descriptionInput', { read: ElementRef, static: true }) descriptionInput: ElementRef;
  @ViewChild('filePicker', { static: true }) filePicker: OccurrenceFileUploader;

  @Output() navigateBack = new EventEmitter<void>();

  @Input()
  selectedLogbook: Logbooks;

  @Input()
  selectedLogbookMessages: LogbookMessage[] = [];

  @Input()
  logbookMessagesCount: number = 0;

  replyingTo: LogbookMessage;

  condo: Condo;
  user: User;

  page = 0;
  hasMoreData = false;

  audio: Blob | null = null;
  convertedAudio: SafeUrl;

  record: RecordRTC.StereoAudioRecorder;
  recording = false;
  isPaused = false;

  audioRecordedTime: string;
  interval: number | NodeJS.Timeout | undefined;
  startTime: moment.Moment;

  pausedTime: moment.Duration;
  recordingTime = new Subject<string>();

  files: Array<File> = new Array<File>();

  scrollPageToBottom: FormControl = new FormControl('');
  description: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.minLength(3)]));

  date: FormControl = new FormControl('');
  searchEventPriority = new FormControl('');
  searchChecklistStatus = new FormControl('');

  private checklists: ChecklistTemplate[] = [];

  status: Status = new Status();
  uploadFileStatus: Status = new Status();

  constructor(
    private logbookMessagesService: LogbookMessagesService,
    private sessionService: SessionService,
    private toastrService: ToastrService,
    private condoFilesService: CondoFilesService,
    private checklistTemplateService: ChecklistTemplateService,
    private modalService: BsModalService,
    private domSanitizer: DomSanitizer,
    private ref: ChangeDetectorRef
  ) {
    this.condo = this.sessionService.condoValue;
    this.user = this.sessionService.userValue;

    this.scrollPageToBottom.valueChanges
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(() => window.scrollTo(0, document.body.scrollHeight));

    this.getRecordedTime().subscribe(time => {
      this.audioRecordedTime = time;
      this.ref.detectChanges();
    });

    combineLatest([
      this.date.valueChanges.pipe(startWith('')),
      this.searchEventPriority.valueChanges.pipe(startWith('')),
      this.searchChecklistStatus.valueChanges.pipe(startWith(''))
    ]).subscribe(() => {
      this.loadEvents();
    });
  }

  ngOnInit(): void {
    this.hasMoreData = this.selectedLogbookMessages?.length < this.logbookMessagesCount;

    this.status.setAsSuccess();

    this.scrollToBottom();
  }

  ngAfterViewInit(): void {
    this.descriptionInput?.nativeElement?.focus();

    this.loadChecklist();
  }

  onNavigateBack(): void {
    this.navigateBack.emit();
  }

  loadEvents(page = 0) {
    this.status.setAsProcessing();

    const query: EcondosQuery = {
      $select: 'messageType messageDescription priority files role createdBy createdAt checklists',
      $populate: [
        {
          path: 'createdBy',
          select: 'firstName lastName picture',
          populate: { path: 'picture', select: 'url thumbnail type name format' }
        },
        {
          path: 'files',
          select: 'name url thumbnail type format'
        },
        {
          path: 'replyTo',
          select: 'messageType messageDescription priority files role createdBy createdAt',
          populate: [
            {
              path: 'createdBy',
              select: 'firstName lastName picture',
              populate: { path: 'picture', select: 'url thumbnail type name format' }
            },
            { path: 'checklists' },
            {
              path: 'files',
              select: 'name url thumbnail type format'
            }
          ]
        }
      ],
      logbook: this.selectedLogbook._id,
      $limit: 10,
      $page: page++,
      $sort: '-createdAt'
    };

    if (this.date.value) {
      query.$and = [
        {
          createdAt: {
            $gte: moment(this.date.value).startOf('day').utc().toISOString(),
            $lte: moment(this.date.value).endOf('day').utc().toISOString()
          }
        }
      ];
    }

    if (this.searchEventPriority.value) {
      query.priority = this.searchEventPriority.value;
    }

    if (this.searchChecklistStatus.value) {
      query['checklists.status'] = this.searchChecklistStatus.value;
    }

    this.logbookMessagesService.getMessages(this.condo.id, query).subscribe({
      next: res => {
        this.status.setAsSuccess();

        this.page = page;

        const sortedData = (res.logbookMessages || []).reverse();

        if (page === 1) {
          this.selectedLogbookMessages = sortedData;
          this.scrollToBottom();
        } else {
          this.selectedLogbookMessages.unshift(...sortedData);
        }

        this.hasMoreData = this.selectedLogbookMessages?.length < res.count;
      },
      error: err => {
        this.status.setAsSuccess();

        this.page--;

        this.toastrService.error('Falha ao carregar o livro. Tente novamente.');
        console.error(err);

        this.selectedLogbook = null;
        this.selectedLogbookMessages = null;
      }
    });
  }

  scrollToBottom() {
    setTimeout(() => {
      this.messagesWrapper.nativeElement.scrollTop = this.messagesWrapper.nativeElement.scrollHeight;
    }, 50);
  }

  pickFile(type: 'file' | 'sound' | 'image') {
    if (this.filePicker) {
      switch (type) {
        case 'file':
          this.filePicker.pickFile();
          break;
        case 'sound':
          this.filePicker.pickFileSound();
          break;
        case 'image':
          this.filePicker.pickFileImage();
          break;
      }
    }
  }

  handleReplyMessage(event: LogbookMessage) {
    this.replyingTo = null;
    this.ref.detectChanges();
    this.replyingTo = event;
  }

  successCallback(stream: MediaStream) {
    const options = {
      mimeType: 'audio/wav',
      numberOfAudioChannels: 1,
      sampleRate: 48000
    };

    const StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();

    this.startTime = moment();
    this.interval = setInterval(() => {
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));
      const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
      this.recordingTime.next(time);
      if (time === '05:00') {
        this.toastrService.warning('Tamanho máximo de áudio atingido');
        this.stopRecording();
      }
    }, 500);
  }

  errorCallback(error: any) {
    console.log(error);
  }

  initiateRecording() {
    this.filePicker.removeFile();
    this.convertedAudio = '';
    this.recording = true;

    let mediaConstraints = {
      video: false,
      audio: true
    };

    this.recordingTime.next('00:00');
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  stopRecording() {
    this.recording = false;
    this.startTime = null;
    this.isPaused = false;

    clearInterval(this.interval);

    this.record.stop(this.processRecording.bind(this));
  }

  pauseRecording() {
    if (this.recording && !this.isPaused) {
      this.isPaused = true;
      clearInterval(this.interval);
      this.pausedTime = moment.duration(moment().diff(this.startTime));

      this.record?.pause();
    }
  }

  resumeRecording() {
    if (this.recording && this.isPaused) {
      this.isPaused = false;
      this.startTime = moment(moment()).subtract(this.pausedTime);
      this.interval = setInterval(() => {
        const diffTime = moment.duration(moment().diff(this.startTime));
        const minutes = this.toString(diffTime.minutes());
        const seconds = this.toString(diffTime.seconds());
        const time = minutes + ':' + seconds;
        this.recordingTime.next(time);
      }, 500);
      if (this.record) {
        this.record.resume();
      }
    }
  }

  cancelRecording() {
    this.recording = false;
    this.isPaused = false;

    this.startTime = null;
    this.convertedAudio = null;
    this.audio = null;

    clearInterval(this.interval);

    this.filePicker.removeFile(this.audio);
  }

  removeAudio() {
    this.convertedAudio = null;
    this.audio = null;
    this.filePicker.removeFile(this.audio);

    clearInterval(this.interval);
    this.startTime = null;
  }

  // Helper Functions
  private toString(value: number): string {
    if (value === null || value === undefined || value === 0) {
      return '00';
    }
    return value < 10 ? '0' + value : value.toString();
  }

  /**
   @param  {any} blob Blog
   */
  processRecording(blob: Blob) {
    this.convertedAudio = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
    this.audio = blob;
  }

  getRecordedTime(): Observable<string> {
    return this.recordingTime.asObservable();
  }

  sendMessage(): void {
    if (!this.convertedAudio && !this.description.valid) {
      this.toastrService.warning('Digite uma mensagem válida');
      return;
    }

    const files = (this.files?.length && this.files?.map(f => f._id)) || [];

    swal({
      type: 'question',
      title: 'Novo registro',
      text: 'Você deseja enviar a mensagem?',
      input: 'select',
      inputOptions: {
        ROUTINE: 'Rotina',
        LOW: 'Baixa',
        MODERATE: 'Moderada',
        HIGH: 'Alta'
      },
      inputPlaceholder: 'Selecione a prioridade',
      inputValidator: value => {
        return new Promise(resolve => {
          if (value) {
            resolve(value);
          } else {
            resolve(Promise.reject('Selecione uma prioridade para o registro e tente novamente!'));
          }
        });
      },
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async inputValue => {
        const logbookEvent: Partial<LogbookMessage> = {
          messageType: this.convertedAudio ? 'VOICE' : 'MESSAGE',
          messageDescription: !this.convertedAudio ? this.description.value : null,
          priority: inputValue,
          files
        };

        try {
          return await lastValueFrom(this.selectNotebookEventCreateRequest(logbookEvent));
        } catch (err) {
          console.log(err);
          throw 'Não foi possível registrar, verifique sua conexão e tente novamente.';
        }
      }
    }).catch(() => swal.noop());
  }

  selectNotebookEventCreateRequest(logbookEvent: Partial<LogbookMessage>) {
    const updateLogbookEvent = () => {
      const newMessage = {
        messageType: logbookEvent.messageType,
        messageDescription: logbookEvent.messageDescription,
        priority: logbookEvent.priority,
        files: logbookEvent.files || [],
        createdBy: this.replyingTo ? this.user.id : this.user,
        logbook: this.selectedLogbook._id,
        role: this.setEventRole(logbookEvent, this.user),
        replyTo: this.replyingTo ?? null
      };

      return this.logbookMessagesService.sendMessage(this.condo.id, newMessage).pipe(
        timeout(10000),
        tap(() => {
          this.description.reset();
          this.description.updateValueAndValidity();
          this.files = [];
          this.scrollToBottom();
          this.convertedAudio = null;
          this.audio = null;
          this.replyingTo = null;

          this.loadEvents();
        })
      );
    };

    if (this.audio) {
      return this.condoFilesService.uploadFiles(this.condo._id, [this.audio]).pipe(
        switchMap((files: File[]) => {
          const [file] = files;
          logbookEvent.files = file;
          return updateLogbookEvent();
        })
      );
    } else {
      return updateLogbookEvent();
    }
  }

  setEventRole(event: Partial<LogbookMessage>, user: User) {
    event = { ...event };
    if (user.isOwner()) {
      event.role = 'OWNER';
    } else if (user.isAdmin()) {
      event.role = 'ADMIN';
    } else if (user.isGatekeeper()) {
      event.role = 'GATEKEEPER';
    }
    return event.role;
  }

  passPosition() {
    const initialState = {
      condo: this.condo,
      user: this.user,
      checklistsTemplates: this.checklists,
      logbookId: this.selectedLogbook._id,
      callbacks: {
        success: () => {
          this.loadEvents();
        }
      }
    };
    this.modalService.show(ModalPassagemDePostoComponent, { initialState, ignoreBackdropClick: true });
  }

  editChecklist() {
    const initialState = {
      condo: this.condo,
      checklists: this.checklists,
      callbacks: {
        success: (templates: ChecklistTemplate[]) => (this.checklists = templates)
      }
    };
    this.modalService.show(ModalGateNotebookChecklistComponent, { initialState, class: 'modal-lg' });
  }

  private loadChecklist() {
    this.checklistTemplateService.get(this.condo._id).subscribe(checklists => {
      this.checklists = checklists;
    });
  }

  protected readonly PERMISSIONS = PERMISSIONS;
}
