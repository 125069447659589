import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Condo } from '../../../api/model/condo';
import { UtilService } from '../../../services/util.service';
import { CommentService } from '../../../api/service/comment.service';
import { User } from '../../../api/model/user';
import { ToastrService } from 'ngx-toastr';
import { FeedCommentComponent } from '../feed-comment/feed-comment.component';
import { FeedLikersComponent } from '../feed-likers/feed-likers.component';
import { VotingOccurrence } from '../../../api/model/occurrence/occurrence.voting';
import { timeout } from 'rxjs/operators';
import { OccurrenceService } from '../../../api/service/occurrence.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalVotingDetailsComponent } from '../../modal/modal-voting-details/modal-voting-details.component';
import swal from 'sweetalert2';

@Component({
  selector: 'app-voting-card',
  templateUrl: './voting-card.component.html',
  styleUrls: ['./voting-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VotingCardComponent implements OnChanges {
  @ViewChild(FeedCommentComponent) commentsComponent;

  @ViewChild(FeedLikersComponent) likersComponent;

  @Input()
  voting: VotingOccurrence;

  @Input()
  condo: Condo;

  @Input()
  user: User;

  @Input()
  dataTesteIdIndex: number;

  @Output()
  edit: EventEmitter<VotingOccurrence> = new EventEmitter<VotingOccurrence>();

  @Output()
  delete: EventEmitter<VotingOccurrence> = new EventEmitter<VotingOccurrence>();

  @Output()
  disableComments: EventEmitter<VotingOccurrence> = new EventEmitter<VotingOccurrence>();

  @Output()
  enableComments: EventEmitter<VotingOccurrence> = new EventEmitter<VotingOccurrence>();

  sendingVote = false;

  isAdmin;
  data: any = {};
  totalOfVotes = 0;
  selectedChoice = -1;

  commentsTotal;
  commentsEnabled;
  canEdit;
  isLiked = false;

  constructor(
    private utilService: UtilService,
    private commentService: CommentService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService,
    private occurrenceService: OccurrenceService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const voting: VotingOccurrence = changes.voting.currentValue;
    const user = changes.user.currentValue;
    const condo = changes.condo.currentValue;
    this.updateData(voting, user, condo);
  }

  updateData(voting: VotingOccurrence, user: User, condo: Condo) {
    this.isAdmin = user?.isAdmin() || user?.isOwner();
    this.data.createdBy = `${voting.createdBy?.firstName} - ` + 'Administração';
    this.data.createdAt = voting.createdAt;
    this.data.title = voting.title;
    this.data.description = voting.description;
    this.data.userPicture = voting.createdBy?.picture?.url || 'assets/img/empty-user-picture.png';
    this.data.userPictureThumbnail = voting.createdBy?.picture?.thumbnail || 'assets/img/empty-user-picture.png';
    this.data.timeAgo = voting.title;
    this.data.protocol = voting.protocol;
    this.data.choices = voting.votes?.map(choice => ({
      ...choice,
      votes: voting?.voteType === 'USER' ? choice?.users : choice?.residences
    }));
    this.data.isVoting = voting.isVoting;
    this.data.canVote = voting.canVote(user);
    this.data.winnerChoice = voting.winner();
    this.selectedChoice = voting.findUserSelectedChoice(user);
    this.totalOfVotes = voting.totalOfVotes;
    this.commentsTotal = voting.commentsTotal;
    this.isLiked = voting.likers.find(liker => this.user?._id == liker?._id);
    this.commentsEnabled = this.isCommentsEnabled(voting, condo);
    this.canEdit = voting.canEdit(user);
  }

  isCommentsEnabled(voting, condo) {
    let moderation;
    if (condo && (!voting.params?.commentsModeration || voting.params?.commentsModeration === 'CONDO_DEFAULT')) {
      moderation = condo.params?.commentsModeration;
    } else {
      moderation = voting.params?.commentsModeration;
    }
    return moderation === 'NONE';
  }

  loadComments() {
    this.commentsComponent.loadComments();
  }

  onCommentCreated() {
    this.commentsTotal++;
    this.cdr.detectChanges();
  }

  onCommentDeleted() {
    this.commentsTotal--;
    this.cdr.detectChanges();
  }

  like() {
    this.likersComponent.likeSubject.next(null);
  }

  vote(choice, i) {
    if (i !== this.selectedChoice && this.voting.canVote(this.user)) {
      this.sendingVote = true;
      this.occurrenceService
        .vote(this.condo._id, this.voting._id, choice._id)
        .pipe(timeout(10000))
        .subscribe(
          () => {
            this.voting.vote(choice, this.user);
            this.updateData(this.voting, this.user, this.condo);
            this.selectedChoice = i;
            this.sendingVote = false;
            this.cdr.detectChanges();
            this.toastr.success('Voto computado com sucesso');
          },
          err => {
            if (err && err.originalErrorMessage && err.originalErrorMessage === 'Defaulter residences can not vote on this voting') {
              swal({
                type: 'error',
                title: 'Voto não computado',
                text: `Procure a administração de seu(sua) ${this.condo?.customLabels?.condo?.singular} para maiores esclarecimentos`
              });
            } else {
              this.toastr.error('Não foi possível contabilizar seu voto, tente novamente...');
            }
            this.sendingVote = false;
            this.cdr.detectChanges();
          }
        );
    }
  }

  showDetails(voting: VotingOccurrence) {
    const initialState = {
      condo: this.condo,
      voting
    };
    this.modalService.show(ModalVotingDetailsComponent, { initialState, class: 'modal-lg' });
  }
}
