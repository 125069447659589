import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilService } from '../../../services/util.service';
import { ResidenceService } from '@api/service/residence.service';
import { Residence } from '@api/model/interface/residence';
import swal from 'sweetalert2';
import { Error } from '@api/model/error/error';
import { debounceTime, distinctUntilChanged, filter, mergeMap, takeUntil, timeout } from 'rxjs/operators';
import { Condo } from '@api/model/condo';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { formatCep, formatCnpj, formatCpf, formatPhone } from '@api/util/formatters';
import { iif, of, Subject } from 'rxjs';
import { ResidenceBuilder } from '@api/model/residence/residence.builder';
import { HttpService } from '../../../services/http.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { cnpjValidator, cpfValidator, emailValidator } from '@api/util/validators';
import { User } from '@api/model/user';

@Component({
  selector: 'modal-create-residence',
  templateUrl: 'create-residence-modal.component.html',
  styleUrls: ['create-residence-modal.component.scss']
})
export class ModalCreateResidenceComponent implements OnInit, OnDestroy {
  condo: Condo;

  callback;

  form: UntypedFormGroup;
  apartmentForm: UntypedFormGroup;
  houseForm: UntypedFormGroup;
  businessForm: UntypedFormGroup;

  editingResidence: any = null;

  isSubmitting = false;

  filteredUsers: User[] = [];

  zipCodeStatus;
  STATUS = {
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS'
  };

  FLOORS = [
    { value: '', label: 'Não integrado com elevadores' },
    { value: 'S4', label: 'Subsolo 4' },
    { value: 'S3', label: 'Subsolo 3' },
    { value: 'S2', label: 'Subsolo 2' },
    { value: 'S1', label: 'Subsolo 1' },
    { value: 'SX', label: 'Subsolo' },
    { value: 'TT', label: 'Térreo' },
    { value: '01', label: '1º Andar' },
    { value: '02', label: '2º Andar' },
    { value: '03', label: '3º Andar' },
    { value: '04', label: '4º Andar' },
    { value: '05', label: '5º Andar' },
    { value: '06', label: '6º Andar' },
    { value: '07', label: '7º Andar' },
    { value: '08', label: '8º Andar' },
    { value: '09', label: '9º Andar' },
    { value: '10', label: '10º Andar' },
    { value: '11', label: '11º Andar' },
    { value: '12', label: '12º Andar' },
    { value: '13', label: '13º Andar' },
    { value: '14', label: '14º Andar' },
    { value: '15', label: '15º Andar' },
    { value: '16', label: '16º Andar' },
    { value: '17', label: '17º Andar' },
    { value: '18', label: '18º Andar' },
    { value: '19', label: '19º Andar' },
    { value: '20', label: '20º Andar' },
    { value: '21', label: '21º Andar' },
    { value: '22', label: '22º Andar' },
    { value: '23', label: '23º Andar' },
    { value: '24', label: '24º Andar' },
    { value: '25', label: '25º Andar' },
    { value: '26', label: '26º Andar' },
    { value: '27', label: '27º Andar' },
    { value: '28', label: '28º Andar' },
    { value: '29', label: '29º Andar' },
    { value: '--', label: 'Andar Customizado' }
  ];

  floor = UntypedFormBuilder;
  customFloor = UntypedFormBuilder;

  private unsubscribe: Subject<void> = new Subject();

  types = {
    isApartment: false,
    isHouse: false,
    isBusiness: false
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private bsModalRef: BsModalRef,
    private residenceService: ResidenceService,
    private httpService: HttpService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.editingResidence && this.editingResidence.users.length) {
      this.filteredUsers = this.editingResidence.users.filter((user: User) => user.isEfoneEnabled);
    }

    this.initializeForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initializeForm() {
    this.apartmentForm = this.formBuilder.group({
      block: [''],
      number: ['', [Validators.required]],
      identification: ['', [Validators.required]],
      extensionLine: [''],
      efoneCalleeUserId: [''],
      floor: [''],
      customFloor: [''],
      obs: [''],
      type: [Residence.TYPES.APARTMENT],
      fraction: [''],
      rented: [false],
      lessee: [''],
      contract: this.formBuilder.group({
        startDate: [''],
        endDate: [''],
        status: ['']
      })
    });

    this.houseForm = this.formBuilder.group({
      block: [''],
      number: ['', [Validators.required]],
      identification: ['', [Validators.required]],
      zipCode: ['', [Validators.required, Validators.minLength(9)]],
      address: ['', [Validators.required]],
      lot: [''],
      extensionLine: [''],
      efoneCalleeUserId: [''],
      obs: [''],
      type: [Residence.TYPES.HOUSE],
      fraction: [''],
      rented: [false],
      lessee: [''],
      contract: this.formBuilder.group({
        startDate: [''],
        endDate: [''],
        status: ['']
      })
    });

    this.businessForm = this.formBuilder.group({
      block: [''],
      number: ['', [Validators.required]],
      identification: ['', [Validators.required]],
      cpfOrCnpj: [''],
      company: [''],
      phone1: [''],
      phone2: [''],
      extensionLine: [''],
      efoneCalleeUserId: [''],
      lineOfWork: [''],
      obs: [''],
      contract: this.formBuilder.group({
        startDate: [''],
        endDate: [''],
        status: ['']
      }),
      email1: ['', [emailValidator]],
      email2: ['', [emailValidator]],
      lessee: [''],
      type: [Residence.TYPES.BUSINESS],
      fraction: [''],
      rented: [false]
    });

    ['phone1', 'phone2'].forEach(key => {
      this.businessForm
        .get(key)
        .valueChanges.pipe(debounceTime(50), distinctUntilChanged(), takeUntil(this.unsubscribe))
        .subscribe(phone => {
          if (phone) {
            this.businessForm.get(key).setValue(formatPhone(phone.trim()), { emitEvent: false });
          }
        });
    });

    this.businessForm
      .get('cpfOrCnpj')
      .valueChanges.pipe(
        debounceTime(50),
        takeUntil(this.unsubscribe),
        mergeMap(v => iif(() => v.replace(/\D/g, '').length <= 11, of(formatCpf(v)), of(formatCnpj(v))))
      )
      .subscribe(v => {
        if (v.length && v.length <= 14) {
          this.businessForm.get('cpfOrCnpj').setValidators([cpfValidator]);
        } else if (v.length && v.length <= 18) {
          this.businessForm.get('cpfOrCnpj').setValidators([cnpjValidator]);
        } else {
          this.businessForm.get('cpfOrCnpj').clearValidators();
        }
        this.businessForm.get('cpfOrCnpj').setValue(v, { emitEvent: false });
      });

    this.houseForm
      .get('zipCode')
      .valueChanges.pipe(
        debounceTime(300),
        filter(value => {
          value = formatCep(value);
          const zipCodeControl = this.houseForm.get('zipCode');
          zipCodeControl.setValue(value, { emitEvent: false });
          return zipCodeControl.valid;
        }),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(zipCode => {
        if (zipCode) {
          zipCode = zipCode.replace('-', '');
          this.findCEP(zipCode);
        }
      });

    if (this.editingResidence) {
      this.setValuesOnForm(this.editingResidence);
    } else {
      switch (this.condo.type) {
        case Condo.TYPES.APARTMENT: {
          this.form = this.apartmentForm;
          this.types = { isApartment: true, isBusiness: false, isHouse: false };
          break;
        }
        case Condo.TYPES.HOUSE: {
          this.form = this.houseForm;
          this.types = { isApartment: false, isBusiness: false, isHouse: true };
          break;
        }
        case Condo.TYPES.BUSINESS: {
          this.form = this.businessForm;
          this.types = { isApartment: false, isBusiness: true, isHouse: false };
          break;
        }
      }
    }
  }

  setValuesOnForm(residence) {
    if (residence.efoneCalleeUserId && !this.filteredUsers.some((user: User) => user.id === residence.efoneCalleeUserId)) {
      residence.efoneCalleeUserId = null;
    }

    switch (residence.type) {
      case Residence.TYPES.APARTMENT:
        this.form = this.apartmentForm;
        this.types = { isApartment: true, isBusiness: false, isHouse: false };
        this.form.get('block').setValue(residence.block || '', { emitEvent: false });
        this.form.get('number').setValue(residence.number, { emitEvent: false });
        this.form.get('identification').setValue(residence.identification, { emitEvent: false });
        this.form.get('extensionLine').setValue(residence.extensionLine || '', { emitEvent: false });
        this.form.get('efoneCalleeUserId').setValue(residence.efoneCalleeUserId || '', { emitEvent: false });
        this.form.get('obs').setValue(residence.obs || '', { emitEvent: false });
        this.form.get('fraction').setValue(residence.fraction || '', { emitEvent: false });
        this.form.get('rented').setValue(residence.rented || false, { emitEvent: false });
        this.form.get('lessee').setValue(residence.lessee || '', { emitEvent: true });
        this.form.get('floor').setValue(residence.floor || '', { emitEvent: true });
        this.form.get('customFloor').setValue(residence.floor || '', { emitEvent: false });
        if (residence.contract) {
          const contract = {
            ...residence.contract,
            startDate: residence.contract?.startDate ? moment(residence.contract?.startDate).format('YYYY-MM-DD') : '',
            endDate: residence.contract?.endDate ? moment(residence.contract?.endDate).format('YYYY-MM-DD') : ''
          };
          this.form.get('contract').setValue(contract, { emitEvent: false });
        }
        break;
      case Residence.TYPES.BUSINESS:
        this.form = this.businessForm;
        this.types = { isApartment: false, isBusiness: true, isHouse: false };
        this.form.get('block').setValue(residence.block || '', { emitEvent: false });
        this.form.get('number').setValue(residence.number, { emitEvent: false });
        this.form.get('identification').setValue(residence.identification, { emitEvent: false });
        this.form.get('cpfOrCnpj').setValue(residence.cpf || residence.cnpj || '', { emitEvent: true });
        this.form.get('company').setValue(residence.company || '', { emitEvent: false });
        this.form.get('phone1').setValue(residence.phones?.[0] || '', { emitEvent: true });
        this.form.get('phone2').setValue(residence.phones?.[1] || '', { emitEvent: true });
        this.form.get('extensionLine').setValue(residence.extensionLine || '', { emitEvent: false });
        this.form.get('efoneCalleeUserId').setValue(residence.efoneCalleeUserId || '', { emitEvent: false });
        this.form.get('lineOfWork').setValue(residence.lineOfWork || '', { emitEvent: false });
        this.form.get('obs').setValue(residence.obs || '', { emitEvent: false });
        this.form.get('fraction').setValue(residence.fraction || '', { emitEvent: false });
        this.form.get('rented').setValue(residence.rented || false, { emitEvent: false });
        if (residence.contract) {
          const contract = {
            ...residence.contract,
            startDate: residence.contract?.startDate ? moment(residence.contract?.startDate).format('YYYY-MM-DD') : '',
            endDate: residence.contract?.endDate ? moment(residence.contract?.endDate).format('YYYY-MM-DD') : ''
          };
          this.form.get('contract').setValue(contract, { emitEvent: false });
        }
        this.form.get('email1').setValue(residence.emails?.[0] || '', { emitEvent: false });
        this.form.get('email2').setValue(residence.emails?.[1] || '', { emitEvent: false });
        this.form.get('lessee').setValue(residence.lessee || '', { emitEvent: true });
        break;
      case Residence.TYPES.HOUSE:
        this.form = this.houseForm;
        this.types = { isApartment: false, isBusiness: false, isHouse: true };
        this.form.get('block').setValue(residence.block || '', { emitEvent: false });
        this.form.get('number').setValue(residence.number, { emitEvent: false });
        this.form.get('identification').setValue(residence.identification, { emitEvent: false });
        const zipCodeFormatted = formatCep(residence.zipCode || '');
        this.form.get('zipCode').setValue(zipCodeFormatted, { emitEvent: false });
        this.form.get('address').setValue(residence.address, { emitEvent: false });
        this.form.get('lot').setValue(residence.lot || '', { emitEvent: false });
        this.form.get('extensionLine').setValue(residence.extensionLine || '', { emitEvent: false });
        this.form.get('efoneCalleeUserId').setValue(residence.efoneCalleeUserId || '', { emitEvent: false });
        this.form.get('obs').setValue(residence.obs || '', { emitEvent: false });
        this.form.get('fraction').setValue(residence.fraction || '', { emitEvent: false });
        this.form.get('rented').setValue(residence.rented || false, { emitEvent: false });
        this.form.get('lessee').setValue(residence.lessee || '', { emitEvent: true });
        if (residence.contract) {
          const contract = {
            ...residence.contract,
            startDate: residence.contract?.startDate ? moment(residence.contract?.startDate).format('YYYY-MM-DD') : '',
            endDate: residence.contract?.endDate ? moment(residence.contract?.endDate).format('YYYY-MM-DD') : ''
          };
          this.form.get('contract').setValue(contract, { emitEvent: false });
        }
        break;
    }
  }

  submit(values) {
    if (this.form.valid) {
      if (values.zipCode) {
        values.zipCode = values.zipCode.replace('-', '');
      }
      if (!values.efoneCalleeUserId) {
        values.efoneCalleeUserId = null;
      }
      this.isSubmitting = true;
      if (this.editingResidence) {
        this.update(values);
      } else {
        this.create(values);
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  update(values) {
    const condo = this.utilService.getLocalCondo();
    const residence = { ...values };
    residence.phones = [residence.phone1, residence.phone2].filter(v => v).map(v => v.replace(/[^0-9]/g, ''));
    residence.emails = [residence.email1, residence.email2].filter(v => v).map(v => v.trim());

    const cpfOrCnpj = (values.cpfOrCnpj || '').replace(/\D/g, '');
    if (cpfOrCnpj.length === 11) {
      residence.cpf = cpfOrCnpj;
      residence.cnpj = '';
    } else if (cpfOrCnpj.length === 14) {
      residence.cnpj = cpfOrCnpj;
      residence.cpf = '';
    } else {
      residence.cnpj = '';
      residence.cpf = '';
    }
    delete residence.cpfOrCnpj;
    if (residence.contract?.startDate) {
      residence.contract.startDate = moment(residence.contract.startDate).toDate();
    }
    if (residence.contract?.endDate) {
      residence.contract.endDate = moment(residence.contract.endDate).toDate();
    }
    this.residenceService
      .updateResidence(condo._id, this.editingResidence.id, residence)
      .pipe(timeout(10000))
      .subscribe(
        resp => {
          residence._id = this.editingResidence.id;
          if (this.callback) {
            this.callback(residence);
          }
          this.closeModal();
          this.isSubmitting = false;
        },
        (err: Error) => {
          console.log(err);
          let msg = '';
          if (err.originalErrorMessage.includes('duplicate key error')) {
            msg = `Já existe um(a) ${this.condo?.customLabels?.residence?.singular || 'unidade'} com essa identificação no(a) ${
              this.condo?.customLabels?.condo?.singular
            }.`;
          } else {
            msg =
              err.messageTitle ||
              `Não foi possível alterar os dados desta(e) ${
                this.condo?.customLabels?.residence?.singular || 'unidade'
              }. Tente novamente...`;
          }
          swal({
            type: 'error',
            title: err.messageTitle || 'Ops...',
            text: msg
          });
          this.isSubmitting = false;
        }
      );
  }

  create(values) {
    let residence = { ...values };
    const phones = [];
    const emails = [];
    if (residence.phone1) {
      phones.push(residence.phone1.replace(/[^0-9]/g, ''));
      delete residence.phone1;
    }
    if (residence.phone2) {
      phones.push(residence.phone2.replace(/[^0-9]/g, ''));
      delete residence.phone2;
    }
    if (phones.length) {
      residence.phones = phones;
    }
    if (residence.email1) {
      emails.push(residence.email1.trim());
    }
    if (residence.email2) {
      emails.push(residence.email2.trim());
    }
    if (emails.length) {
      residence.emails = emails;
    }
    if (residence.cpfOrCnpj) {
      const cpfOrCnpj = values.cpfOrCnpj.replace(/\D/g, ''); // Remove tudo o que não é dígito
      if (cpfOrCnpj.length === 11) {
        residence.cpf = cpfOrCnpj;
        residence.cnpj = '';
      }
      if (cpfOrCnpj.length === 14) {
        residence.cnpj = cpfOrCnpj;
        residence.cpf = '';
      }
      delete residence.cpfOrCnpj;
    }
    if (residence.contract?.startDate) {
      residence.contract.startDate = moment(residence.contract.startDate).toDate();
    }
    if (residence.contract?.endDate) {
      residence.contract.endDate = moment(residence.contract.endDate).toDate();
    }
    this.residenceService
      .createResidenceOnCondo(this.condo._id, residence)
      .pipe(timeout(10000))
      .subscribe(
        (resp: any) => {
          residence._id = resp._id;
          residence = ResidenceBuilder.build(residence);
          if (this.callback) {
            this.callback(residence);
          }
          this.closeModal();
        },
        (err: Error) => {
          console.log(err);
          let msg = '';
          if (err.originalErrorMessage?.includes('duplicate key error')) {
            msg = `Já existe um(a) ${this.condo?.customLabels?.residence?.singular || 'unidade'} com essa identificação no(a) ${
              this.condo?.customLabels?.condo?.singular
            }.`;
          } else {
            msg =
              err.messageTitle ||
              `Não foi possível criar a(o) ${this.condo?.customLabels?.residence?.singular || 'unidade'}. Tente novamente...`;
          }
          swal({
            type: 'error',
            title: err.messageTitle || 'Ops...',
            text: msg
          });
          this.isSubmitting = false;
        }
      );
  }

  findCEP(zipCode) {
    this.zipCodeStatus = this.STATUS.LOADING;
    this.httpService
      .consultarCEP(zipCode)
      .pipe()
      .subscribe(
        ({ logradouro }) => {
          logradouro = logradouro.trim();
          this.houseForm.get('address').setValue(logradouro);
        },
        error => {
          if (error?.message === 'zipCode invalid') {
            return this.toastr.error('Verifique se você digitou o CEP corretamente', 'CEP não encontrado');
          }
          this.toastr.error('Não foi possível localizar o CEP. Tente novamente!');
        }
      );
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
