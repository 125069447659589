import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Condo } from '@api/model/condo';
import { SessionService } from '@api/service/session.service';
import { ExternalPersonInfoService } from '@api/serviceV3/external-person-info.service';
import { ToastrService } from 'ngx-toastr';
import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { switchMap } from 'rxjs/operators';
import { EXTERNAL_TYPES } from '@api/model/external-person-info';
import * as moment from 'moment';
import { formatCpf, formatCPFToShow } from '@api/util/formatters';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { cpfValidator } from '@api/util/validators';
import { HasPermissionDirective } from '../../../directives/has-permission.directive';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { environment } from '@environment';
import { Subscription } from 'rxjs';

interface Form {
  name: FormControl<string>;
  cpf: FormControl<string>;
  type: FormControl<string>;
}

@Component({
  templateUrl: 'search-external-person-info-modal.component.html',
  imports: [FormsModule, ReactiveFormsModule, NgForOf, NgSwitch, NgSwitchCase, TooltipModule, NgIf, HasPermissionDirective],
  styleUrls: ['search-external-person-info-modal.component.scss'],
  standalone: true
})
export class SearchExternalPersonInfoModalComponent implements OnInit, OnDestroy {
  form: FormGroup<Form>;
  cpf: FormControl<string>;
  name: FormControl<string>;
  type: FormControl<string>;
  personStatus: string;
  date: string;
  birthDate: string;
  provider: 'BLINDADO';
  providerUrl: string;
  condo: Condo;
  status: 'LOADING' | 'SUCCESS' | 'ERROR' | 'TIMEOUT' | 'DEFAULT' = 'DEFAULT';
  callback;
  errorMessage = 'Ocorreu um erro ao processar sua solicitação.';
  private subscriptions: Subscription = new Subscription();
  constructor(
    public bsModalRef: BsModalRef,
    private sessionService: SessionService,
    private externalPersonInfoService: ExternalPersonInfoService,
    private toastService: ToastrService,
    private fb: FormBuilder
  ) {
    this.condo = this.sessionService.condoValue;
    this.form = this.fb.group({
      name: [''],
      cpf: ['', Validators.required],
      type: ['', Validators.required]
    });

    this.name = this.form.controls.name;
    this.cpf = this.form.controls.cpf;
    this.type = this.form.controls.type;
  }
  ngOnInit() {
    this.subscriptions.add(
      this.cpf.valueChanges.subscribe(v => {
        if (v.length) {
          this.cpf.setValidators([Validators.required, cpfValidator]);
        } else {
          this.cpf.setValidators([Validators.required]);
        }
        const formatted = formatCpf(v);
        this.cpf.setValue(formatted, { emitEvent: false });
      })
    );
    this.provider = this.condo.externalPersonInfoData?.integrationType;
    if (this.provider === 'BLINDADO') {
      this.providerUrl = environment.blindadoUrl;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  searchCPF() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.status = 'LOADING';

      const cpf = this.cpf.value.toString().replace(/\D/g, '');

      const data = {
        data: {
          cpf,
          nome: this.name.value || '',
          novaConsulta: true,
          type: this.type.value
        },
        provider: this.provider
      };

      this.externalPersonInfoService
        .postSearchPersonInfoInProvider(this.condo._id, data)
        .pipe(
          switchMap(res => {
            return this.externalPersonInfoService.waitProviderResponse(this.condo._id, res._id);
          })
        )
        .subscribe({
          next: ({ externalPersonInfo }) => {
            this.name.setValue(externalPersonInfo.name);
            this.birthDate = externalPersonInfo.birthDate
              ? moment(externalPersonInfo.birthDate).utc().format('DD/MM/YYYY')
              : 'Não informado';
            this.personStatus = externalPersonInfo.searchHistory[0]?.risk;
            this.date = moment(externalPersonInfo.lastSearchTime).utc().format('DD/MM/YYYY - HH:mm');
            this.status = 'SUCCESS';
          },
          error: err => {
            if (err?.message?.includes('máximo de tentativas')) {
              this.status = 'TIMEOUT';
            } else {
              this.errorMessage = err?.originalError?.error || 'Ocorreu um erro ao processar sua solicitação.';
              this.status = 'ERROR';
            }
          }
        });
    } else {
      this.toastService.error('Existem campos inválidos. Verifique e tente novamente!');
    }
  }

  newSearch() {
    this.form.patchValue({
      name: '',
      cpf: '',
      type: ''
    });
    this.form.markAsUntouched();
    this.status = 'DEFAULT';
  }

  newSearchWithData() {
    this.status = 'DEFAULT';
  }

  closeModal() {
    this.callback();
    this.bsModalRef.hide();
  }

  protected readonly EXTERNAL_TYPES = EXTERNAL_TYPES;
  protected readonly formatCpf = formatCpf;
  protected readonly formatCPFToShow = formatCPFToShow;
  protected readonly PERMISSIONS = PERMISSIONS;
}
