import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Condo } from '@api/model/condo';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { CacheService } from '@api/service/cache/cache.service';
import { takeUntil, timeout } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import * as moment from 'moment';
import { formatCpf, formatPhone } from '@api/util/formatters';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { Router } from '@angular/router';
import { CondoServiceV2 } from '@api/serviceV2/condo.service';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { SessionService } from '@api/service/session.service';

@Component({
  selector: 'app-gate-resident-details-modal',
  templateUrl: 'gate-resident-details.modal.component.html',
  styleUrls: ['gate-resident-details.modal.component.scss']
})
export class GateResidentDetailsModalComponent implements OnInit, OnDestroy {
  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;

  userId: string;
  condo: Condo;

  user;

  status: 'LOADING' | 'ERROR' | 'SUCCESS';

  cpf;
  rg;

  activeTab: string = '1';

  private unsubscribe: Subject<void> = new Subject();

  deviceViewPermission = true;

  moment = moment;
  formatPhone = formatPhone;

  constructor(
    public bsModalRef: BsModalRef,
    private cacheService: CacheService,
    private router: Router,
    private condoServiceV2: CondoServiceV2,
    private sessionService: SessionService
  ) {}

  ngOnDestroy(): void {
    this.cacheService.clearCache();
    this.unsubscribe.unsubscribe();
  }

  ngOnInit(): void {
    const user = this.sessionService.userValue;
    const hasCustomRole = user.customRoles.some(role => role?.condo?._id === this.condo._id);

    if (hasCustomRole) {
      this.deviceViewPermission = user.getPermissionValue({
        condoId: this.condo._id,
        permission: PERMISSIONS.userDevices.view
      });
    }

    this.status = 'LOADING';

    const query: EcondosQuery = {
      $select:
        'ids firstName lastName birthDate email phones specialNeeds createdAt createdBy picture condos condosAdmin customRoles permissions \
      condosGatekeeper condosJanitor condosOwner condosRequester role',
      $populate: [
        { path: 'createdBy', select: 'firstName lastName name' },
        { path: 'picture', select: 'url thumbnail type format name' }
      ]
    };

    this.condoServiceV2
      .getUserById(this.condo.id, this.userId, query)
      .pipe(timeout(15000), takeUntil(this.unsubscribe))
      .subscribe({
        next: res => {
          this.user = res;

          const cpfObject = this.user?.ids.find(id => id.type === 'CPF');
          const rgObject = this.user?.ids.find(id => id.type === 'RG');

          if (cpfObject) {
            this.cpf = formatCpf(cpfObject.number);
          }

          if (rgObject) {
            this.rg = rgObject.number;
          }

          this.status = 'SUCCESS';
        },
        error: err => {
          console.log(err);
          this.status = 'ERROR';
        }
      });
  }

  tabSelected(tabDirective: TabDirective) {
    this.activeTab = tabDirective.id;
  }

  navigateToAccessControl() {
    this.bsModalRef.hide();
    this.router.navigate(['gate', 'access']);
  }

  navigateToResidenceDetails(residence: string) {
    this.bsModalRef.hide();
    this.router.navigate(['/condo/residences/' + residence]);
  }

  revealData(field: string, user) {
    let isFieldAlreadyUnmasked: boolean;

    if (field === 'ids') {
      isFieldAlreadyUnmasked = !user.ids
        .map(id => id.number)
        .toString()
        .includes('*');
    } else if (field === 'birthDate') {
      isFieldAlreadyUnmasked = !user.birthDate.includes('3000');
    } else {
      isFieldAlreadyUnmasked = !user[field].toString().includes('*');
    }

    if (isFieldAlreadyUnmasked) {
      return;
    }

    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    if (field === 'ids') {
      query.$populate = [{ path: 'ids.pictures', select: 'url thumbnail' }];
    }

    const callback = ({ data }) => {
      if (field === 'ids') {
        data = data.map(({ number, type }) => ({ number, type }));

        data.forEach(({ type, number }) => {
          if (type === 'CPF') {
            this.cpf = formatCpf(number.toString());
          } else if (type === 'RG') {
            this.rg = number.toString();
          }
        });
      }

      user[field] = data;
      user.isDataMasked[field] = false;
    };

    this.condoServiceV2.getCondoResidentUnmaskedField(this.condo._id, query, this.userId, field).pipe(timeout(10000)).subscribe(callback);
  }
}
