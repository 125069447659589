export const environment = {
  production: false,
  name: 'DEV_LATITUDE',
  backendUrl: 'https://api-dev-latitude.econdos.com.br/api/v1/',
  backendUrlV2: 'https://api-dev-latitude.econdos.com.br/api/v2/',
  backendUrlV3: 'https://api-dev-latitude.econdos.com.br/api/v3/',
  reportsUrl: 'https://api-dev-latitude.econdos.com.br/api/v1/',
  reportsUrlV2: 'https://api-dev-latitude.econdos.com.br/api/v2/',
  visitorUrl: 'https://dev-visitantes.econdos.com.br',
  socketUrl: 'wss://api-dev-latitude.econdos.com.br',
  blindadoUrl: 'https://condominioblindado.azurewebsites.net/'
};
