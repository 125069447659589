import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { ConstantService } from '../../../services/constant.service';
import { Actuator } from '@api/model/hardware/actuator';
import { catchError, map, mergeMap, timeout } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import swal from 'sweetalert2';
import { SessionService } from '@api/service/session.service';

@Injectable({ providedIn: 'root' })
export class Mip1000IPService {
  protected endPoint;

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private sessionService: SessionService,
    protected constantService: ConstantService
  ) {
    this.endPoint = `${this.constantService.getV2Endpoint()}condos`;
  }

  public checkConnection(condoId: string, actuatorId: string) {
    const query: EcondosQuery = { $select: 'type accessType username host port password hardware condo serial number output' };
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.httpService.get(`${this.endPoint}/${condoId}/actuators/${actuatorId}`, { params }).pipe(
      mergeMap((actuator: any) => {
        const mip1000IPServerUrl = this.getMIP1000IPServerUrl();
        return this.http.post(`${mip1000IPServerUrl}/checkConnection`, { actuator }).pipe(map(res => ({ success: res })));
      }),
      timeout(10000),
      map((result: { success: boolean }) => ({ _id: actuatorId, status: result.success })),
      catchError(() => of({ _id: actuatorId, status: false }))
    );
  }

  public setDateTime(actuator: Actuator, dateTime = new Date(), timeZone = 'America/Sao_Paulo'): Observable<{ success: boolean }> {
    const mip1000IPServerUrl = this.getMIP1000IPServerUrl();
    return this.http
      .post(`${mip1000IPServerUrl}/setDatetime`, {
        actuator,
        data: {
          dateTime,
          timeZone
        }
      })
      .pipe(
        timeout(15000),
        map((result: { success: boolean }) => ({ success: result.success })),
        catchError(() => of({ success: false }))
      );
  }

  trigger(actuator: Actuator) {
    const mip1000IPServerUrl = this.getMIP1000IPServerUrl();
    if (mip1000IPServerUrl) {
      return this.http.post(`${mip1000IPServerUrl}/openDoor`, { actuator });
    }
  }

  getMIP1000IPServerUrl() {
    const condo = this.sessionService.condoValue;
    if (condo?.mip1000ip?.host) {
      let url = `http://${condo.mip1000ip.host}:${condo.mip1000ip.port || 5054}`;
      if (!this.isElectron()) {
        url = `http://localhost:5050/${url}`;
      }
      return url;
    } else {
      swal({ type: 'error', text: 'Servidor mip1000ip não encontrado' });
      throw new Error('Servidor mip1000ip não encontrado');
    }
  }

  private isElectron = () => {
    const userAgent = (window && window.navigator && window.navigator.userAgent) || '';
    return userAgent.toLowerCase().includes('electron');
  };
}
