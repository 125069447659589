import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { Condo } from '@api/model/condo';
import { CondoContact } from '@api/model/contact/condo.contact';
import { VisitorPickerComponent } from '../../../components/visitor-picker/visitor-picker';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Contact } from '@api/model/interface/contact';
import { EcondosQuery } from '@api/model/query';
import { ContactID } from '@api/model/contact/contact.id';
import { CondoContactService } from '@api/service/condo.contact.service';
import { takeUntil, timeout } from 'rxjs/operators';
import { File } from '@api/model/file';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { BehaviorSubject, Subject } from 'rxjs';
import { phoneValidator } from '@api/util/validators';
import { ToastrService } from 'ngx-toastr';

interface IBan {
  reason?: string;
  isBanned?: boolean;
  bannedBy?: any;
  bannedAt?: string;
}

interface IContactMergeFields {
  picture?: File;
  name?: string;
  documentType?: string;
  document?: string;
  documentFrontPicture?: File;
  documentBackPicture?: File;
  isForeigner?: boolean;
  phones?: string;
  type?: string;
  birthDate?: string;
  company?: string;
  service?: string;
  specialNeeds?: string;
  obs?: string;
  vehicles?: CondoVehicle[];
  ban?: IBan;
}

@Component({
  selector: 'app-merge-contacts',
  templateUrl: './merge-contacts.component.html',
  styleUrls: ['./merge-contacts.component.scss']
})
export class MergeContactsComponent implements OnInit, OnDestroy {
  @ViewChild('appVisitorPicker', { static: true }) visitorPicker: VisitorPickerComponent;

  private mergeTarget$ = new BehaviorSubject<IContactMergeFields>({ vehicles: [] });
  private stop$ = new Subject<void>();
  condo: Condo;
  contactList: CondoContact[] = [];
  baseContact: CondoContact | null = null;
  mergeTarget: IContactMergeFields = {};
  form: UntypedFormGroup;
  visitorLabels = Contact.TYPES_LABEL;
  visitorTypes = [
    { key: Contact.TYPES.VISITOR, value: Contact.TYPES_LABEL.VISITOR },
    { key: Contact.TYPES.PROVIDER, value: Contact.TYPES_LABEL.PROVIDER },
    { key: Contact.TYPES.DELIVERYMAN, value: Contact.TYPES_LABEL.DELIVERYMAN },
    { key: Contact.TYPES.DRIVER, value: Contact.TYPES_LABEL.DRIVER },
    { key: Contact.TYPES.PUBLIC_AGENT, value: Contact.TYPES_LABEL.PUBLIC_AGENT },
    { key: Contact.TYPES.RESIDENT, value: Contact.TYPES_LABEL.RESIDENT },
    { key: Contact.TYPES.EMPLOYEE, value: Contact.TYPES_LABEL.EMPLOYEE },
    { key: Contact.TYPES.RELATIVE, value: Contact.TYPES_LABEL.RELATIVE },
    { key: Contact.TYPES.AIRBNB, value: Contact.TYPES_LABEL.AIRBNB },
    { key: Contact.TYPES.TENANT, value: Contact.TYPES_LABEL.TENANT },
    { key: Contact.TYPES.OTHER, value: Contact.TYPES_LABEL.OTHER }
  ];
  documentTypes = [
    { key: ContactID.TYPES.RG, value: ContactID.TYPES_LABEL.RG },
    { key: ContactID.TYPES.CPF, value: ContactID.TYPES_LABEL.CPF },
    { key: ContactID.TYPES.CNPJ, value: ContactID.TYPES_LABEL.CNPJ },
    { key: ContactID.TYPES.DRIVER_LICENCE, value: ContactID.TYPES_LABEL.DRIVER_LICENCE },
    { key: ContactID.TYPES.PASSPORT, value: ContactID.TYPES_LABEL.PASSPORT }
  ];
  fieldValidationFunctions: { [field: string]: (c: CondoContact) => boolean };
  obligatoryFields: string[] = [];
  emptyPicture = new File();
  toSolveFields: number;
  showField = {
    picture: false,
    name: false,
    documentType: false,
    document: false,
    documentFrontPicture: false,
    documentBackPicture: false,
    phones: false,
    type: false,
    birthDate: false,
    company: false,
    service: false,
    obs: false,
    ban: false,
    specialNeeds: false,
    vehicles: false
  };

  phones: AbstractControl;
  name: AbstractControl;
  docType: AbstractControl;
  document: AbstractControl;
  type: AbstractControl;
  birthDate: AbstractControl;
  company: AbstractControl;
  service: AbstractControl;
  specialNeeds: AbstractControl;
  obs: AbstractControl;
  picture: AbstractControl;

  constructor(
    private utilService: UtilService,
    private fb: UntypedFormBuilder,
    private condoContactService: CondoContactService,
    private toastr: ToastrService
  ) {
    // TODO - Remover chamadas para RevealData na template
    this.condo = this.utilService.getLocalCondo();
    this.form = this.fb.group({
      picture: ['', Validators.required],
      documentType: ['', Validators.required],
      document: ['', Validators.required],
      name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      phones: ['', Validators.compose([Validators.required, phoneValidator])],
      type: ['', Validators.required],
      birthDate: ['', Validators.required],
      company: ['', Validators.required],
      service: ['', Validators.required],
      specialNeeds: ['', Validators.required],
      obs: ['', Validators.required]
    });
    this.name = this.form.get('name');
    this.phones = this.form.get('phones');
    this.docType = this.form.get('documentType');
    this.document = this.form.get('document');
    this.type = this.form.get('type');
    this.birthDate = this.form.get('birthDate');
    this.company = this.form.get('company');
    this.service = this.form.get('service');
    this.specialNeeds = this.form.get('specialNeeds');
    this.obs = this.form.get('obs');
    this.picture = this.form.get('picture');

    this.fieldValidationFunctions = {
      picture: (c: CondoContact) => !!c.picture,
      name: (c: CondoContact) => !!c.fullName,
      documentType: (c: CondoContact) => c.ids[0] && !!c.ids[0].type,
      document: (c: CondoContact) => c.ids[0] && !!c.ids[0].number,
      documentFrontPicture: (c: CondoContact) => c.ids[0] && c.ids[0].pictures && !!c.ids[0].pictures[0],
      documentBackPicture: (c: CondoContact) => c.ids[0] && c.ids[0].pictures && !!c.ids[0].pictures[1],
      phones: (c: CondoContact) => !!c.phone,
      type: (c: CondoContact) => !!c.type,
      birthDate: (c: CondoContact) => !!c.birthDate,
      company: (c: CondoContact) => !!c.company,
      service: (c: CondoContact) => !!c.service,
      specialNeeds: (c: CondoContact) => c.specialNeeds,
      obs: (c: CondoContact) => !!c.obs,
      vehicles: (c: CondoContact) => c.condoVehicles && !!c.condoVehicles.length,
      ban: (c: CondoContact) => c.banned
    };
    this.obligatoryFields = ['name', 'type'];
  }

  ngOnInit(): void {
    this.updateToSolveFields({}, this.getRequiredFields());
    this.mergeTarget$.pipe(takeUntil(this.stop$)).subscribe(value => {
      this.mergeTarget = value;
      this.updateToSolveFields(value, this.getRequiredFields());
    });
  }

  ngOnDestroy(): void {
    this.stop$.next(null);
    this.stop$.complete();
  }

  onVisitorSelect(contact: CondoContact) {
    if (!!this.contactList.find(c => c._id === contact._id)) {
      this.visitorPicker.onResetValue();
      return;
    }
    ['birthDate', 'ids', 'emails', 'phones'].forEach(field => {
      this.revealData(field, contact);
    });
    if (!this.baseContact && this.contactList.length === 0) {
      this.setAsBaseForMerge(contact);
    }
    this.contactList.push(contact);
    this.visitorPicker.onResetValue();
    this.updateToSolveFields(this.mergeTarget$.value, this.getRequiredFields());
  }

  getRequiredFields() {
    const fieldsSet = new Set();
    const fields = Object.keys(this.fieldValidationFunctions);
    this.contactList.forEach(contact => {
      fields.forEach(field => {
        const check = this.fieldValidationFunctions[field](contact);
        if (check) {
          fieldsSet.add(field);
        }
      });
    });
    this.obligatoryFields.forEach(f => fieldsSet.add(f));
    const setAsArray = [...fieldsSet] as string[];
    fields
      .map(f => ({ field: f, value: setAsArray.includes(f) }))
      .forEach(({ field, value }) => {
        this.showField[field] = value;
      });
    return setAsArray;
  }

  setAsBaseForMerge(contact: CondoContact) {
    if (this.baseContact && this.baseContact._id === contact._id) {
      this.baseContact = null;
    } else {
      this.baseContact = contact;
    }
  }

  mergeTargetSetFieldsCount(value: IContactMergeFields, fields?: string[]) {
    return (fields || Object.keys(value)).reduce((pv, cv) => {
      const condition = cv === 'vehicles' ? value[cv] && value[cv].length : value[cv];
      if (condition) {
        return pv + 1;
      }
      return pv;
    }, 0);
  }

  removeFromList(id: string) {
    const count = this.mergeTargetSetFieldsCount(this.mergeTarget);
    if (count > 0) {
      this.toastr.warning('Usuários não podem ser removidos enquanto campos estivem selecionados');
      return;
    }

    const index = this.contactList.findIndex(c => c._id === id);
    if (index > -1) {
      this.contactList.splice(index, 1);
      if (this.baseContact && this.baseContact._id === id && this.contactList.length > 0) {
        this.baseContact = this.contactList[0];
      } else {
        this.baseContact = null;
      }
    }
    this.updateToSolveFields({}, this.getRequiredFields());
  }

  selectField(field: string, value?: any) {
    if (!value) {
      const formField = this.form.get(field);
      if (formField.valid) {
        this.setValue(field, formField.value);
      } else if (!['name', 'type', 'document'].includes(field) && !formField.value) {
        this.setEmptyValue(field);
      } else {
        this.form.get(field).markAsTouched();
      }
    } else {
      this.setValue(field, value);
    }
  }

  setEmptyValue(field: string) {
    const current = this.mergeTarget$.value;
    const value = field === 'documentType' ? { documentType: '', document: '' } : { [field]: '' };
    this.mergeTarget$.next({ ...current, ...value });
  }

  resetField(field: string, value?: any) {
    const current = this.mergeTarget$.value;
    if (field === 'vehicles') {
      value = new CondoVehicle(value);
      current.vehicles = current.vehicles && current.vehicles.filter(v => v._id !== value._id);
    } else {
      delete current[field];
      const formField = this.form.get(field);
      if (formField) {
        formField.reset('');
        if (formField.disabled) {
          formField.enable();
        }
      }
    }
    this.mergeTarget$.next(current);
  }

  setValue(field, value) {
    const current = this.mergeTarget$.value;
    if (field === 'vehicles') {
      value = new CondoVehicle(value);
      const exists = current.vehicles && current.vehicles.find(v => v._id === value._id);
      if (!exists) {
        value = [...(current.vehicles || []), value];
        this.mergeTarget$.next({ ...current, [field]: value });
      }
    } else if (field === 'ban') {
      value = new CondoContact(value);
      value = {
        isBanned: value.banned,
        bannedAt: value.bannedAt,
        bannedBy: value.bannedBy,
        reason: value.banReason
      } as IBan;
      this.mergeTarget$.next({ ...current, [field]: value });
    } else {
      if (field === 'documentType') {
        this.docType.disable();
      }
      this.mergeTarget$.next({ ...current, [field]: value });
    }
  }

  updateToSolveFields(value: IContactMergeFields, fields: string[]) {
    const count = fields.reduce((pv, cv) => {
      if (value[cv] || value[cv] === '') {
        return pv + 1;
      }
      return pv;
    }, 0);
    this.toSolveFields = fields.length - count;
  }

  revealData(field: string, contact: CondoContact) {
    contact.isDataMasked[field] = false;

    let isFieldAlreadyUnmasked: boolean;

    if (field === 'ids') {
      isFieldAlreadyUnmasked = !contact.ids
        .map(id => id.number)
        .toString()
        .includes('*');
    } else if (field === 'birthDate') {
      isFieldAlreadyUnmasked = !contact.birthDate.toString().includes('3000');
    } else {
      isFieldAlreadyUnmasked = !contact[field].toString().includes('*');
    }
    if (isFieldAlreadyUnmasked) {
      return;
    }

    const query: EcondosQuery = {
      $select: field,
      $and: []
    };

    if (field === 'ids') {
      query.$populate = [{ path: 'ids.pictures', select: 'url thumbnail' }];
    }

    let callback;
    if (field === 'ids') {
      callback = ({ data }) => (contact[field] = data.map(id => new ContactID(id)));
    } else {
      callback = ({ data }) => (contact[field] = data);
    }

    this.condoContactService
      .getCondoContactUnmaskedField(this.condo._id, contact._id, field, query)
      .pipe(timeout(10000))
      .subscribe(callback);
  }

  formatPhone() {
    let phoneNumber = this.form.value.phones;
    phoneNumber = this.utilService.formatPhone(phoneNumber);
    this.form.get('phones').setValue(phoneNumber);
  }

  submitForm() {
    const { value } = this.mergeTarget$;
    const split = value.name.split(' ').filter(v => v.trim());
    const baseContact = {
      _id: this.baseContact._id,
      ...(value.ban && {
        banned: value.ban.isBanned,
        bannedBy: value.ban.bannedBy,
        bannedAt: value.ban.bannedAt,
        banReason: value.ban.reason
      }),
      firstName: split.shift(),
      lastName: split.join(' '),
      picture: value?.picture?._id || null,
      ids:
        value.document && value.documentType
          ? [
              {
                type: value.documentType,
                number: value.document,
                pictures: [
                  ...(value.documentFrontPicture && value.documentFrontPicture._id ? [value.documentFrontPicture] : []),
                  ...(value.documentBackPicture && value.documentBackPicture._id ? [value.documentBackPicture] : [])
                ]
              }
            ]
          : [],
      phones: value.phones ? [value.phones] : [],
      birthDate: value.birthDate,
      condoVehicles: value.vehicles,
      company: value.company,
      service: value.service,
      specialNeeds: !!value.specialNeeds,
      specialNeedsDetails: value.specialNeeds,
      obs: value.obs
    };
    const mergingContacts = this.contactList.filter(c => c._id !== baseContact._id).map(c => c._id);
    const data = { baseContact, mergingContacts };

    this.condoContactService
      .mergeCondoContacts(this.condo._id, data)
      .pipe(timeout(10000))
      .subscribe({
        next: () => {
          this.toastr.success('Contato mesclado com sucesso');
          this.cleanUp();
        },
        error: () => {
          this.toastr.error('Um erro ocorreu tente novamente');
        }
      });
  }

  cleanUp() {
    this.contactList = [];
    this.mergeTarget$.next({});
    this.baseContact = null;
    this.form.reset('');
  }

  isVehicleOnMerge(vehicle: CondoVehicle) {
    return this.mergeTarget.vehicles && this.mergeTarget.vehicles.find(v => v._id === vehicle._id);
  }

  protected readonly ContactID = ContactID;
}
