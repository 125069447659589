import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { econdosTheme } from './econdos-theme';
import { WhiteLabelService } from '@api/service/white-label.service';
import { Router } from '@angular/router';
import { WhiteLabel } from '@api/model/white-label';
import { catchError, map, retry, tap, timeout } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _activeTheme: BehaviorSubject<WhiteLabel> = new BehaviorSubject(null);

  constructor(
    private whiteLabelService: WhiteLabelService,
    private router: Router,
    private title: Title,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public defineTheme() {
    if (location.href.includes('econdos')) {
      this.updateActiveTheme(econdosTheme);
      return of(econdosTheme);
    } else {
      return this.whiteLabelService.get({ webAppUrl: location.host }).pipe(
        timeout(8000),
        retry(2),
        map(res => (res.whiteLabels && res.whiteLabels[0]) || econdosTheme),
        catchError(err => {
          this.router.navigate(['white-label-error']);
          return of(econdosTheme);
        }),
        tap(theme => {
          this.updateActiveTheme(theme);
          this.whiteLabelService.getEcondosWhiteLabel().subscribe();
        })
      );
    }
  }

  private replaceFavIcon(iconUrl: string) {
    if (iconUrl) {
      const favicon: any = this.document.getElementById('favicon');
      favicon.href = iconUrl;
    }
  }

  private replaceLogo(logoUrl: string) {
    const logo = this.document.getElementById('logo') as HTMLImageElement;
    const logoWrapper = this.document.getElementById('logoWrapper');
    if (logoWrapper && logo) {
      logo.src = logoUrl;
      logoWrapper.style.display = 'block';
    }
  }

  private changeTitle(title: string) {
    this.title.setTitle(title);
  }

  private changeMobileToolbarColor(color: string) {
    const metaThemeColor = this.document.querySelector('meta[name=theme-color]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', color);
    }
  }

  public getActiveTheme() {
    return this._activeTheme.asObservable();
  }

  get activeTheme() {
    return this._activeTheme.value;
  }

  public updateActiveTheme(theme: WhiteLabel) {
    this.replaceLogo(theme.logo);
    this.replaceFavIcon(theme.favIcon);
    this.changeTitle(theme.pageTitle);
    this.changeMobileToolbarColor((theme.cssVariables && theme.cssVariables['--primary']) || '#ffffff');
    this._activeTheme.next(theme);

    swal.setDefaults({ reverseButtons: true });
  }
}

export const defineWhitelabel = (themeService: ThemeService) => {
  return () => {
    return new Promise(resolve => {
      themeService
        .defineTheme()
        .toPromise()
        .then(() => {
          resolve('');
        });
    });
  };
};
