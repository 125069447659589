import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Condo } from '@api/model/condo';
import { Actuator } from '@api/model/hardware/actuator';

@Injectable({ providedIn: 'root' })
export class LinearServerModeService {
  private endpoint = 'http://localhost:5055';

  constructor(private http: HttpService) {}

  public trigger(actuator: Pick<Actuator, 'type' | 'number' | 'output' | 'time'>, serverMode: Condo['linear']['serverMode']) {
    if (!serverMode.enabled) {
      throw new Error('Server mode is disabled on this condo');
    }

    const data = {
      actuator: {
        type: actuator.type,
        number: actuator.number,
        output: actuator.output,
        time: actuator.time
      },
      serverMode: {
        host: serverMode.host,
        port: serverMode.port,
        password: serverMode.password
      }
    };

    return this.http.post(`${this.endpoint}/trigger`, data);
  }
}
